
import { Platform } from "react-native";
import axios from 'axios';
import base64 from 'react-native-base64';
import JSZip from 'jszip';
import { XMLParser } from 'fast-xml-parser';
import globalVars from '../store';
import configs from '../../helpers/config';
import { DashPathEffect, LinearGradient, Path, RadialGradient, SkPath, TwoPointConicalGradient, useFont, vec } from "@shopify/react-native-skia";
import { useState, useEffect } from "react";

export const webAPIUser = 'cpwebapi'
export const webAPIPass = 'ZVE8rh2DWJPQEWar'
export const webAPIUserCloudPrint = 'cpwebapi'
export const webAPIPassCloudPrint = 'ZVE8rh2DWJPQEWar'

const DEFAULT_COLOR_START = "#FFFFFF"
const DEFAULT_COLOR_END = "#000000"
const DEFAULT_GRADIENT_START_POSITION = "gradient.linear.vCenter.top"
const DEFAULT_LINEAR_GRADIENT_END_POSITION = "gradient.linear.vCenter.bottom"
const DEFAULT_RADIAL_GRADIENT_END_RADIUS = "gradient.radial.5"
const DEFAULT_CONICAL_GRADIENT_END_ANGLE = "gradient.angle.0"

// coefficients for dash, dot and interval; properties of border style
const DASH_LENGTH_COEFFICIENT = 3.2
const DOT_LENGTH_COEFFICIENT = 1.3
const INTERVAL_COEFFICIENT = 2.4

export async function getTemplateBytes(templateID: Number) {

  return new Promise((resolve) => {
    try {
      axios({
        url: configs.APPUrls.webApi + "/v1/templates/" + templateID + "/bytes",
        method: 'GET',
        headers: {
          'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
          'Accept': 'application/json',
          ...globalVars.additionalAPIHeaderInfo
        },
      }).then((response: any) => {
        resolve(response.data.templateBytes);
      }).catch(function (error: any) {
        console.log(error);
        resolve(false);
      });

    } catch (e) {
      console.log(e);
      resolve(false);
    }
  });
}

interface cardObj {
  itemsData: any,
  printData: any
}
export async function unzipTemplateBytes(templateBytes: any): Promise<cardObj | boolean> {

  return new Promise((resolve, reject) => {

    try {

      const zip = new JSZip();
      zip.loadAsync(templateBytes, { "base64": true })
        .then(function (zip: any) {

          (async () => {
            try {
              globalVars.zipData = zip;
              const itemsXml: any = await zip.file("design/items.xml").async("string");
              const printXml: any = await zip.file("print/print.xml").async("string");
              const parser = new XMLParser();
              const itemsObj = parser.parse(itemsXml);
              const printObj = parser.parse(printXml);

              let returnData: cardObj = {
                itemsData: itemsObj,
                printData: printObj
              }
              resolve(returnData);

            } catch (e) {
              console.log(e);
              resolve(false);
            }

          })();

        });

    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}


export function ptToPx(pt: number, dpis: number = 300): number {
  // d(in) = d(pt) × 0.03937007874 Or d(in) = d(pt) / 25.4
  const milimeterInchConvertionCtr: number = 0.03937007874;
  const sizeInInnches: number = (pt / 100) * milimeterInchConvertionCtr;
  const sizeInDpis: number = Math.ceil(sizeInInnches * dpis);
  return (sizeInDpis);
}

export function ptToPxStroke(pt: number, dpis: number = 300): number {
  const milimeterInchConvertionCtr: number = 0.031;
  const sizeInInnches: number = pt * milimeterInchConvertionCtr;
  const sizeInDpis: number = Math.ceil(sizeInInnches);
  return (sizeInDpis);
}

export function mmToPx(mm: number, ppi: number = 300): number {
  // 15 mm = 15 × 3.7795275591 pixel (X) = 56.6929133858 pixel (X)
  // const sizeInPx:number = mm * 3.5795275591 
  const sizeInPx: number = mm * (ppi / 25.4)
  return (sizeInPx);
}

export function degreesToRadians(degrees: number) {
  return degrees * Math.PI / 180;
}

// radius unit is not known, only known to be a length or distance value
// conversion to Pixels from a length or distance value done by value*ppi
export function radiusToPixels(radius: number, ppi: number = 300): number {
  return radius * ppi
}

export function mmToPt(mm: number) {
  return mm * 2.83465;
}

export function ptToMm(pt: number) {
  return pt / 2.83465;
}

export function getAutoSize(text: string, textBoxWidth: number, textBoxHeight: number, fontFile: string): number {
  const [minFontSize, setMinFontSize] = useState(0)
  const [maxFontSize, setMaxFontSize] = useState(500)
  const [autoFontSize, setAutoFontSize] = useState(Math.floor((minFontSize + maxFontSize) / 2))
  const [fontSize, setFontSize] = useState(0);
  const [unitOperator, setUnitOperator] = useState(1)
  const font = useFont(fontFile, autoFontSize)

  useEffect(() => {
    if (font === null) {
      setFontSize(0)
      return;
    }
    else {
      if (autoFontSize <= (minFontSize + unitOperator * 2) && autoFontSize >= (maxFontSize - unitOperator * 2)) {
        if (unitOperator == 0.1) return;
        else setUnitOperator(0.1)
      }
      const textWidth = font.getTextWidth(text)
      const { ascent, descent, leading } = font.getMetrics();
      const textHeight = descent - ascent;
      if (textWidth < textBoxWidth && textHeight < textBoxHeight)
        setMinFontSize(autoFontSize - unitOperator);
      if (textWidth > textBoxWidth || textHeight > textBoxHeight)
        setMaxFontSize(autoFontSize + unitOperator);
      if (unitOperator == 0.1) {
        const size = Math.fround((minFontSize + maxFontSize) / 2)
        setAutoFontSize(parseFloat(size.toFixed(1)))
      }
      else setAutoFontSize(Math.floor((minFontSize + maxFontSize) / 2));
      font.setSize(autoFontSize)
      setFontSize(autoFontSize)

    }
  }, [font, fontSize]);
  return fontSize
}

export function getVecLinear(path: string, startX: number, startY: number, width: number, height: number): { x: number, y?: number } {
  const delimiter = "."
  const props = path.split(delimiter);
  const limit = 3
  if (props.length > limit) {
    const position = props[limit - 1] + delimiter + props[limit]
    switch (position) {
      case "vCenter.right":
        return { x: startX + width, y: startY + (height / 2) }
      case "vCenter.left":
        return { x: startX, y: startY + (height / 2) }
      case "vCenter.bottom":
        return { x: startX + (width / 2), y: startY + height }
      case "vCenter.top":
        return { x: startX + (width / 2), y: startY }
      case "vCenter.hCenter":
        return { x: startX + (width / 2), y: startY + (height / 2) }
      case "top.right":
        return { x: startX + width, y: startY }
      case "top.left":
        return { x: startX, y: startY }
      case "top.hCenter":
        return { x: startX + (width / 2), y: startY }
      case "bottom.right":
        return { x: startX + width, y: startY + height }
      case "bottom.left":
        return { x: startX, y: startY + height }
      case "bottom.hCenter":
        return { x: startX + (width / 2), y: startY + height }
    }
  }
  else return { x: Number(props[props.length - 1]) }

  return { x: 0 }
}

export function attributeSet(element: string, attribute: string, value: string, text: string): string {
  let replaceResult: string;
  const attributeExists = text.includes(" " + attribute + "=");
  if (attributeExists) {
    const regExpData = new RegExp(attribute + '\=\"([A-Za-z0-9 #._]*)\"');
    const valueData = attribute + '="' + value + '"';
    replaceResult = text.replace(regExpData, valueData);
  } else {
    replaceResult = text.replace('<' + element + ' ', '<' + element + ' ' + attribute + '="' + value + '" ');
  }

  return replaceResult;

}

export function calculateImageSize(width: number, height: number, maxWidth: number): any {
  let newWidth = width;
  let newHeight = height;
  const aspectRatio = width / height;

  if (width > maxWidth) {
    newWidth = maxWidth;
    newHeight = newWidth / aspectRatio;
  }
  return {
    width: newWidth,
    height: newHeight,
  };
}

export function StrokePathRender(path: SkPath, color: string, width: number, style: string): JSX.Element {

  const dash_length = DASH_LENGTH_COEFFICIENT * width
  const dot_length = DOT_LENGTH_COEFFICIENT * width
  const interval = INTERVAL_COEFFICIENT * width

  switch (style) {
    case "SolidLine":
      return (
        <Path path={path} color={color} style="stroke" strokeWidth={width} blendMode="srcATop">
        </Path>);
    case "DashLine":
      return (
        <Path path={path} color={color} style="stroke" strokeWidth={width} blendMode="srcATop">
          <DashPathEffect intervals={[dash_length, interval, dash_length, interval]} />
        </Path>);
    case "DotLine":
      return (
        <Path path={path} color={color} style="stroke" strokeWidth={width} blendMode="srcATop">
          <DashPathEffect intervals={[dot_length, interval, dot_length, interval]} />
        </Path>);
    case "DashDotLine":
      return (
        <Path path={path} color={color} style="stroke" strokeWidth={width} blendMode="srcATop">
          <DashPathEffect intervals={[dash_length, interval, dot_length, interval]} />
        </Path>
      );
    case "DashDotDotLine":
      return (
        <Path path={path} color={color} style="stroke" strokeWidth={width} blendMode="srcATop">
          <DashPathEffect intervals={[dash_length, interval, dot_length, interval, dot_length, interval]} />
        </Path>
      );
    case "default":
      break;
  }
  return <></>
}

function getDefaultEndPoint(type: string): string {
  if (type === "linear") return DEFAULT_LINEAR_GRADIENT_END_POSITION;
  if (type === "radial") return DEFAULT_RADIAL_GRADIENT_END_RADIUS;
  if (type === "conical") return DEFAULT_CONICAL_GRADIENT_END_ANGLE;
  return ""
}

export function GradientRender(gradient: any, startX: number, startY: number, width: number, height: number): JSX.Element {
  let gradientType, colorStart = null, colorEnd = null, startPoint = null, endPoint = null;
  try {
    if (!Array.isArray(gradient)) {
      gradientType = gradient;
      colorStart = DEFAULT_COLOR_START;
      colorEnd = DEFAULT_COLOR_END;
      startPoint = getVecLinear(DEFAULT_GRADIENT_START_POSITION, startX, startY, width, height);
      endPoint = getVecLinear(getDefaultEndPoint(gradientType), startX, startY, width, height);
    } else {
      gradientType = gradient[0];
      const prop_color1 = gradient[1]?.color1;

      if (prop_color1 === undefined) {
        colorStart = DEFAULT_COLOR_START
        startPoint = getVecLinear(DEFAULT_GRADIENT_START_POSITION, startX, startY, width, height)
      } else if (prop_color1.point === undefined) {
        colorStart = Array.isArray(prop_color1) ? prop_color1[0] : prop_color1;
        startPoint = getVecLinear(DEFAULT_GRADIENT_START_POSITION, startX, startY, width, height);
      } else {
        colorStart = Array.isArray(prop_color1) ? prop_color1[0] : DEFAULT_COLOR_START;
        startPoint = Array.isArray(prop_color1) ? getVecLinear(prop_color1[1].point, startX, startY, width, height) : getVecLinear(prop_color1.point, startX, startY, width, height);
      }

      const prop_color2 = gradient[1]?.color2;

      if (prop_color2 === undefined) {
        colorEnd = DEFAULT_COLOR_END
        endPoint = getVecLinear(getDefaultEndPoint(gradientType), startX, startY, width, height)
      } else if (prop_color2.point === undefined) {
        colorEnd = Array.isArray(prop_color2) ? prop_color2[0] : prop_color1;
        endPoint = getVecLinear(getDefaultEndPoint(gradientType), startX, startY, width, height);
      } else {
        colorEnd = Array.isArray(prop_color2) ? prop_color2[0] : getDefaultEndPoint(gradientType);
        endPoint = Array.isArray(prop_color2) ? getVecLinear(prop_color2[1].point, startX, startY, width, height) : getVecLinear(prop_color2.point, startX, startY, width, height);
      }
    }
    if (colorStart === null || colorEnd === null || startPoint === null || endPoint === null) throw new Error("Gradient invalid.")

    switch (gradientType) {
      case "linear":
        return (<LinearGradient
          start={vec(startPoint.x, startPoint.y)}
          end={vec(endPoint.x, endPoint.y)}
          colors={[colorStart, colorEnd]}
        />);
      case "radial":
        return <RadialGradient
          c={vec(startPoint.x, startPoint.y)}
          r={radiusToPixels(endPoint.x / 10)}
          colors={[colorStart, colorEnd]}
        />
      case "conical":
        console.log(startPoint)
        console.log(endPoint)

        const endcircle_radius = endPoint.x * (Math.PI / 180) + 100
        console.log(endcircle_radius)
        const conical_endPoint = {
          x: startPoint.x + Math.cos((endPoint.x * Math.PI) / 180),
          y: startPoint.y + Math.sin((endPoint.x * Math.PI) / 180),
        };
        return (<TwoPointConicalGradient
          start={vec(startPoint.x, startPoint.y)}
          startR={0}
          end={vec(conical_endPoint.x, conical_endPoint.y)}
          endR={endcircle_radius}
          colors={[colorStart, colorEnd]}
        />);
      case "default":
        break;
    }
  } catch (error) {
    console.log(error)
  }
  return <></>
}

export function findProperty(data: any, searchProperty: string): any | undefined {
  let result;

  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      result = findProperty(data[i], searchProperty);
      if (result) {
        break;
      }
    }
  } else {
    for (let key in data) {
      if (key === searchProperty) {
        return data[key];
      }
      if (typeof data[key] === 'object' && data[key] !== null) {
        result = findProperty(data[key], searchProperty);
        if (result) {
          break;
        }
      }
    }
  }

  return result;
}




// !! Possibly used for generating check digit
// for future implementation of MOD10, MOD 43 [Code 128] and MOD 10 W 7 [Code 39]

// export function checkDigitBarcode(text: string, mod: string) {
//   const arr_chars = text.split("")
//   let sum: number = 0

//   const mod_str = mod.slice(2); // remove "cd" from mod string (ex: "cdmod43" -> "mod43")
//   console.log(mod_str)

//   const modArray = mod.match(/mod\d+/g);
//   const wArray = mod.match(/w\d+/g);
//   console.log(modArray)
//   console.log(wArray)
//   const modValue = modArray ? modArray.map((match) => {
//     const matchDigits = match.match(/\d+/);
//     return matchDigits ? +matchDigits[0] : null;
//   }).filter((match) => match !== null) : [10]; // e.g.: for EAN-8, EAN-13, the module used is module 10 (code 128 is 103 [????])

//   const wValue = wArray ? wArray.map((match) => {
//     const matchDigits = match.match(/\d+/);
//     return matchDigits ? +matchDigits[0] : null;
//   }).filter((match) => match !== null) : [];

//   console.log(modValue)
//   console.log(wValue)

//   for (let i = 1; i < arr_chars.length; i += 2) {
//     if (i % 2 != 0) sum += (wValue.length < 1 && wValue[0] != null)
//       ? parseInt(arr_chars[i]) * wValue[0]
//       : parseInt(arr_chars[i]) * 3
//     else sum += parseInt(arr_chars[i])
//   }


//   const remainder = (sum + 7) % (result.length > 0 ? result[0] : 1);
//   return result[0] - remainder;
// }
