import React, { useState, useEffect, useRef } from "react";
import { View, Text, StyleSheet, Dimensions, Image, TouchableOpacity, Animated, SafeAreaView, Button, LayoutAnimation, UIManager, Easing, Platform } from "react-native";
import { useTranslation } from 'react-i18next';

const RibbonQuantity = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <SafeAreaView style={[(Platform.OS === 'web' ? { justifyContent: "end" } : {}), { alignItems: 'center' }]}>

            <View style={styles.contentHolder}>

                <View style={styles.itemCol}>
                    <View style={[styles.cardLayerFront,
                    {
                        width: Number(props.size),
                        height: (Number(props.size) - 20),
                        backgroundColor: props.backgroundColor,
                        borderColor: props.color,
                    }]}></View>
                    <View style={[styles.cardLayerBack,
                    {
                        width: Number(props.size),
                        height: (Number(props.size) - 20),
                        backgroundColor: props.backgroundColor,
                        borderColor: props.color,
                    }]}></View>
                    <View style={styles.quantityHolder}>
                        <Text style={[styles.quantityTextHolder,
                        {
                            fontSize: Number(props.fontSize),
                            color: props.color

                        }]}>{props.quantity}</Text>
                    </View>
                </View>

                {/* <View style={styles.itemCol}>
                    <Text style={[styles.descriptionText, ((props.textColor !== undefined) ? { color: props.textColor } : {})]}>{t('STR_RIBBON_QUANTITY')}</Text>
                </View> */}

            </View>


            <Text style={[styles.descriptionText, ((props.textColor !== undefined) ? { color: props.textColor } : {})]}>{t('STR_RIBBON_QUANTITY')}</Text>

        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    contentHolder: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 120,
        position: 'relative'

    },
    itemCol: {
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardLayerFront: {
        borderWidth: 4,
        borderRadius: 10,
        zIndex: 2,
    },
    cardLayerBack: {
        borderWidth: 4,
        borderRadius: 10,
        position: 'absolute',
        top: -7,
        left: -7,
        zIndex: 1
    },
    quantityHolder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 3
    },
    quantityTextHolder: {
        fontWeight: 'bold'
    },
    descriptionText: {
        ...Platform.select({
            ios: { 
                position: 'absolute',
                bottom: 0,
            },
            android: {
                position: 'absolute',
                bottom: 0,
            },
            default: {                
            }
        }),
        // zIndex: 4,
        fontWeight: 'bold'
    }
});

export default RibbonQuantity; 