
import React, { useState, useEffect, ReactNode, useRef } from 'react';
import { GradientRender, StrokePathRender, degreesToRadians, findProperty, getAutoSize, mmToPx, ptToPx } from '../../../../helpers/cardRender/utils';
import { Group, Text, useFont, Rect, RoundedRect, Oval, Skia, rect, rrect, FontStyle } from "@shopify/react-native-skia";
import moment from 'moment';

export type props = {
    id: number,
    xmlObj: any,
    customText: string | null
};

//TODO review
const DEFAULT_FONT = require("./fonts/arial.ttf")
const DEFAULT_FONT_COLOR = "#000000"
const DEFAULT_BKG_COLOR = "transparent"
const DEFAULT_CLIPPING_TYPE = "rectangle"
const NO_OPACITY = 100

const RRECT_RADIUS = 25

const textItem: React.FC<props> = ({
    id,
    xmlObj,
    customText
}) => {
    let text: string = ""
    const fontData: any = xmlObj.font; // font info (options [strikeout, underline], color, style [bold, italic, normal], family/name, pointSize (auto or fixed))
    const fillData: any = xmlObj.fill; // fill info (background fill and gradient)
    const itemData: any = xmlObj.item; // item info (printable or not)
    const lineData: any = xmlObj.line; // line info (stroke color, style (solid line, dash, dot, dash dot or dash dot dot), and width)
    const clippingData: any = xmlObj.clipping; // clipping info (rectangle, round rectangle or circle; if not defined, is default)

    // clipping type: no clipping, rectangle, round rectangle or circle
    const itemClipping: string = xmlObj.clipping?.shape ? xmlObj.clipping?.shape : DEFAULT_CLIPPING_TYPE;

    // stroke or not
    // let stroke: boolean = false, strokeColor: string = "", strokeWidth: number = 0, strokeStyle: string = "" 
    let stroke: boolean = false
    if (lineData.style != "NoPen") stroke = true

    // fill/gradient
    let bkgColor: string = DEFAULT_BKG_COLOR
    let gradientData: null = null, gradient: boolean = false

    if (Array.isArray(fillData.type)) {
        if (fillData.type[0] == "solid" || fillData.type[1].solid != null) bkgColor = Array.isArray(fillData.type[1].solid.color) ? fillData.type[1].solid.color[0] : fillData.type[1].solid.color
        else if (fillData.type[0] == "gradient") {
            gradient = true
            gradientData = fillData.type[1].gradient
        }
    }

    // *TEXT*/
    if (customText !== undefined && customText !== null) {
        text = customText;
    } else {
        text = (xmlObj?.data?.final === undefined) ? '' : xmlObj.data.final;
    }

    // DATE/TIME ELEMENT
    const autofill_prop = findProperty(xmlObj.source.dataType, "autoFill")
    if (autofill_prop != undefined) {
        let format_prop = findProperty(xmlObj.source.dataType, "format");
        if (format_prop != undefined) {
            format_prop = format_prop.replace("hh", "HH")
            format_prop = format_prop.replace("dd", "DD")
            format_prop = format_prop.replace("AP", "A")
            text = moment().format(format_prop);
        }
    }

    // *PRINTABLE* //
    const print: boolean = itemData.print ? (itemData.print == 'no' ? false : true) : true
    if (!print) return <></>

    // *TEXT CASE* //
    const textCase: string = xmlObj.font?.capitalization != undefined ? xmlObj.font.capitalization : "noTextCase";
    switch (textCase) {
        case "None":
            text = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
            break;
        case "MixedCase":
            const sentences = text.split(". ");
            const sentenceCaseSentences = sentences.map((sentence) => {
                return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
            });
            text = sentenceCaseSentences.join(". ");
            break;
        case "AllLowerCase":
            text = text.toLowerCase();
            break;
        case "AllUppercase":
            text = text.toUpperCase();
            break;
        case "EachWord":
            // The \b pattern matches the beginning of a word, while \w matches any word character (letters, digits, or underscores).
            // The g flag is used to perform a global search, meaning that all occurrences of the pattern will be replaced.
            text = text.replace(/\b\w/g, (l) => l.toUpperCase());
            break;
        case "ToggleCase":
            let toggledText = "";
            for (let i = 0; i < text.length; i++) {
                const char = text.charAt(i);
                if (char === char.toUpperCase()) {
                    toggledText += char.toLowerCase();
                } else {
                    toggledText += char.toUpperCase();
                }
            }
            text = toggledText;
            break;
        case "default":
            break;
    }


    //TODO font options
    // *FONT* //
    let textBoxWidth = mmToPx((xmlObj.position.pos2.x / 100) - (xmlObj.position.pos1.x / 100))
    let textBoxHeight = mmToPx((xmlObj.position.pos2.y / 100) - (xmlObj.position.pos1.y / 100))

    if (lineData?.style != "NoPen") {
        textBoxWidth -= mmToPx(lineData.width / 100 * 2)
        textBoxHeight -= mmToPx(lineData.width / 100 * 2)
    }

    let fontColor: string = fontData?.color != null ? fontData.color : DEFAULT_FONT_COLOR
    const fontSize = (typeof fontData?.pointSize !== "string" && fontData != null) ?
        fontData.pointSize * 5 : // fixed font size
        getAutoSize(text, textBoxWidth, textBoxHeight, DEFAULT_FONT) // auto font size

    const font = useFont(DEFAULT_FONT, fontSize);
    if (font == null) return null;

    // *POSITION* //
    const startX: number = ptToPx(xmlObj.position.pos1.x);
    const startY: number = ptToPx(xmlObj.position.pos1.y);
    const endX: number = ptToPx(xmlObj.position.pos2.x);
    const endY: number = ptToPx(xmlObj.position.pos2.y);
    const holderWidth: number = endX - startX;
    const holderHeight: number = endY - startY;
    const textWidth: number = font.getTextWidth(text);
    const textMetrics: any = font.getMetrics();
    const textHeight: number = textMetrics.bounds.height;
    const textAscent: number = textMetrics.ascent;
    const textDescent: number = textMetrics.descent;
    const textLeading: number = textMetrics.leading;

    let finalTextPosX = startX;
    let finalTextPosY = endY;

    // Remove font descent
    finalTextPosY = finalTextPosY - textDescent;

    const getXCenter = (startX: number, holderWidth: number, textWidth: number): number => {
        const xPos: number = startX + ((holderWidth / 2) - (textWidth / 2));
        return xPos;
    }

    const getYCenter = (endY: number, holderHeight: number, textHeight: number): number => {
        const yPos: number = endY - (holderHeight / 2) + (textHeight / 2);
        return yPos;
    }

    // *ALIGNMENT* //
    const textVerticalAlignment: string = (xmlObj.alignment?.verticalAlignment !== undefined ? xmlObj.alignment.verticalAlignment : "AlignVCenter");
    const textHorizontalAlignment: string = (xmlObj.alignment?.horizontalAlignment !== undefined ? xmlObj.alignment.horizontalAlignment : "AlignHCenter");

    // Vertical align process
    switch (textVerticalAlignment) {
        case "AlignTop":
            finalTextPosY = startY + textHeight;
            finalTextPosY = finalTextPosY - (textHeight - Math.abs(textAscent));
            break;
        case "AlignVCenter":
            finalTextPosY = getYCenter(finalTextPosY, holderHeight, textHeight);
            break;
        case "AlignBottom":
            finalTextPosY = finalTextPosY;
            break;
    }

    // Horizontal align process
    switch (textHorizontalAlignment) {
        case "AlignLeft":
            finalTextPosX = startX;
            break;
        case "AlignHCenter":
            finalTextPosX = getXCenter(finalTextPosX, holderWidth, textWidth);
            break;
        case "AlignRight":
            finalTextPosX = endX - textWidth;
            break;
    }

    // *ROTATION* //
    let translateX = 0
    let translateY = 0
    const rotationAngle = xmlObj.rotation?.angle ? degreesToRadians(xmlObj.rotation.angle) : 0
    if (rotationAngle != 0) {
        translateX = holderWidth / 2
        translateY = holderHeight / 2
    }

    // *HORIZONTAL AND VERTICAL FLIP* //
    let scaleX = 1, scaleY = 1
    if (xmlObj.text?.transformations?.geometric?.flip) {
        scaleX = xmlObj.text.transformations.geometric.flip.horizontal ? -1 : 1
        scaleY = xmlObj.text.transformations.geometric.flip.vertical ? -1 : 1
    }

    // *OPACITY* //
    const opacity: number = xmlObj.item.opacity ? xmlObj.item.opacity : NO_OPACITY



    function ItemRender(): JSX.Element {
        const strokeWidth = stroke ? mmToPx(lineData.width / 100) : 0
        const path_stroke = Skia.Path.Make();
        switch (itemClipping) {
            case "rectangle":
                path_stroke.moveTo(startX, startY);
                path_stroke.lineTo(endX, startY);
                path_stroke.lineTo(endX, endY);
                path_stroke.lineTo(startX, endY);
                path_stroke.lineTo(startX, startY)
                return (
                    <>
                        {!gradient ? (<Rect x={startX} y={startY} width={holderWidth} height={holderHeight} color={bkgColor} />)
                            : (<Rect x={startX} y={startY} width={holderWidth} height={holderHeight}>
                                {GradientRender(gradientData, startX, startY, holderWidth, holderHeight)}
                            </Rect>)}
                        {stroke && StrokePathRender(path_stroke, lineData.color, mmToPx(lineData.width / 100), lineData.style)}

                    </>);
            case "roundRectangle":
                path_stroke.moveTo(startX, startY);
                const rect_rrect = rect(startX, startY, holderWidth, holderHeight);
                const rrect_path = rrect(rect_rrect, RRECT_RADIUS, RRECT_RADIUS);
                path_stroke.addRRect(rrect_path)
                return (
                    <>
                        {!gradient ? (<RoundedRect x={startX} y={startY} width={holderWidth} height={holderHeight} color={bkgColor} r={RRECT_RADIUS} />)
                            : (<RoundedRect x={startX} y={startY} width={holderWidth} height={holderHeight} r={RRECT_RADIUS}>
                                {GradientRender(gradientData, startX, startY, holderWidth, holderHeight)}
                            </RoundedRect>)}
                        {stroke && StrokePathRender(path_stroke, lineData.color, mmToPx(lineData.width / 100), lineData.style)}

                    </>);
            case "circle":
                path_stroke.moveTo(startX, startY);
                const rect_circle = rect(startX, startY, holderWidth, holderHeight);
                path_stroke.addOval(rect_circle)
                return (
                    <>

                        {!gradient ? (<Oval x={startX} y={startY} width={holderWidth} height={holderHeight} color={bkgColor} />)
                            : (<Oval x={startX} y={startY} width={holderWidth} height={holderHeight}>
                                {GradientRender(gradientData, startX, startY, holderWidth, holderHeight)}
                            </Oval>)}
                        {stroke && StrokePathRender(path_stroke, lineData.color, mmToPx(lineData.width / 100), lineData.style)}
                    </>);
            case "default":
                break;
        }
        return <></>
    }

    return (
        <Group transform={[{ rotate: rotationAngle }]} origin={Skia.Point(startX + translateX, startY + translateY)} opacity={opacity / 100}>
            <Group transform={[{ scaleX: scaleX }, { scaleY: scaleY }]} origin={Skia.Point(startX + (holderWidth / 2), startY + (holderHeight / 2))} >
                {ItemRender()}
                <Text
                    x={finalTextPosX}
                    y={finalTextPosY}
                    font={font}
                    text={text}
                    color={fontColor}
                    blendMode="srcATop"
                />
            </Group>
        </Group>

    );


};

export default textItem;
