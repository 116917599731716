
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View, Dimensions } from 'react-native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DateTimePicker from '@react-native-community/datetimepicker';
import { TouchableOpacity } from 'react-native';
import { Button, Text, useTheme, TextInput } from 'react-native-paper';
import moment from 'moment';

// Usage example 
// <DateTimeInput dateTimeMode='date' onChange={handleChange("teste")} placeholder='Teste date' />
// <DateTimeInput dateTimeMode='time' onChange={handleChange("teste2")} placeholder='Teste time' />
// <DateTimeInput dateTimeMode='datetime' onChange={handleChange("teste3")} placeholder='Teste datetime' />

export type props = {
    value: any,
    dateTimeMode: "date" | "time" | "datetime",
    format: string,
    placeholder: string,
    onChange: any
};

const DateTimeInput: React.FC<props> = ({
    value,
    dateTimeMode,
    format,
    placeholder,
    onChange
}) => {

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [date, setDate] = useState<any>(new Date());
    const [showDate, setShowDate] = useState<any>(value);
    const [show, setShow] = useState<boolean>(false);
    const [showTime, setShowTime] = useState<boolean>(false);
    const [timeDate, setTimeDate] = useState<any>(new Date());
    const [dateValue, setDateValue] = useState<any>(undefined);

    useEffect(
        () => {
            (async () => {
            })();
        },
        []
    )

    const onDateTimeChange = async (event: any, selectedDate: any) => {

        if (event.type === 'dismissed') {
            setShow(false);
            return;
        }

        let currentDate = selectedDate;

        switch (dateTimeMode) {
            case "date":
                if (format === undefined) {
                    format = "DD-MM-YYYY"
                } else {
                    format = format.replace("dd", "DD");
                }

                currentDate = moment(selectedDate).format(format.toUpperCase());
                break;

            case "time":

                if (format === undefined) {
                    format = "HH:mm"
                } else {
                    format = format.replace("hh", "HH");
                    format = format.replace("ss", "00");
                }

                currentDate = moment(selectedDate).format(format);

                break;

            case "datetime":

                if (format === undefined) {
                    format = "DD-MM-YYYY (HH:mm:ss)"
                } else {
                    format = format.replace("dd", "DD");
                    format = format.replace("hh", "HH");
                    format = format.replace("ss", "00");
                }

                currentDate = moment(selectedDate).format(format);
                break;

            default:
                break;
        }

        if (Platform.OS !== 'ios') {
            setShow(false);
        }

        setShowDate(currentDate);
        setDate(selectedDate);
        onChange(currentDate);
    };

    const onDateTimeDateChange = async (event: any, selectedDate: any) => {

        if (event.type === 'dismissed') {
            setShowTime(false);
            setShow(false);
            setDateValue(undefined);
            return;
        }
        setDateValue(selectedDate);
    }

    useEffect(
        () => {
            if (dateValue !== undefined) {
                setShowTime(true);
            } else {
                setShowTime(false);
                if (Platform.OS !== 'ios') {
                    setShow(false);
                }

            }
        },
        [dateValue]
    )


    const onDateTimeTimeChange = async (event: any, selectedTime: any) => {

        if (event.type === 'dismissed') {
            setShowTime(false);
            setShow(false);
            setDateValue(undefined);
            return;
        }

        if (format === undefined) {
            format = "DD-MM-YYYY (HH:mm:00)"
        } else {
            format = format.replace("dd", "DD");
            format = format.replace("hh", "HH");
            format = format.replace("ss", "00");
        }

        const currentDateTime: any = moment(dateValue);
        currentDateTime.set({
            hour: selectedTime.getHours(),
            minute: selectedTime.getMinutes(),
            second: 0,
            millisecond: 0
        });
        const currentDateTimeFormated: any = currentDateTime.format(format);

        if (Platform.OS !== 'ios') {
            setShowTime(false);
            setShow(false);
            setDateValue(undefined);
        }
        setShowDate(currentDateTimeFormated);
        setTimeDate(selectedTime);
        onChange(currentDateTimeFormated);
    }

    const showDatepicker = () => {
        setShow(true)
    };

    return (
        <View style={styles.container}>

            <View>
                {/* <TouchableOpacity onPress={showDatepicker} style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 10
                }}>
                </TouchableOpacity> */}
                <TextInput
                    style={[styles.input, {
                        color: theme.colors.black,
                        zIndex: 1
                    }]}
                    value={(showDate === null) ? "" : showDate.toLocaleString()}
                    label={placeholder}
                    editable={(Platform.OS === 'ios' ? false : true)}
                    onPressIn={showDatepicker}
                    onFocus={showDatepicker}
                    mode="outlined"
                />

            </View>

            {show && dateTimeMode !== "datetime" && (
                <DateTimePicker
                    // testID="dateTimePicker"
                    value={date}
                    mode={dateTimeMode}
                    is24Hour={true}
                    onChange={onDateTimeChange}
                />
            )}

            {show === true && dateTimeMode === "datetime" && showTime === false &&
                <DateTimePicker
                    // testID="dateTimePicker"
                    value={date}
                    mode={'date'}
                    is24Hour={true}
                    onChange={onDateTimeDateChange}
                />
            }
            {show === true && dateTimeMode === "datetime" && showTime === true &&
                <View>
                    <DateTimePicker
                        // testID="dateTimePicker"
                        value={timeDate}
                        mode={'time'}
                        is24Hour={true}
                        onChange={onDateTimeTimeChange}
                    />
                </View>
            }

        </View >
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    container: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 25,
            },
            android: {
                width: deviceWidth - 25,
            },
            default: {
                width: 450,
            }
        }),
    },
    input: {
        // height: 40,
        // width: '97%',
        // margin: 5,
        // borderWidth: 1,
        // padding: 10,
        marginTop: 10,
        // borderRadius: 10
    },

});

const mapStateToProps = (
    state: {
    }
) => {
    return {
    }
}

export default connect(mapStateToProps, {})(DateTimeInput);
