import { Platform } from 'react-native';
import { store } from '../utils/Reducer'
import {
  addErrorMessage, updateRecordList, cleanLoginInfo, cleanTemplate,
  setTemplateList, setTemplateProperties, setTemplateName, cleanRecord, setRecordList,
  setRecordSelected, SetRecordUpdated, setPrintersList, submitRecordInsert, setRecordsCount,
  updateRecordSelected, addRecordToList, setRecordSynced, setRecordSyncError,
  removeRecordsFrom, setRecordsFrom, setUpdateRecordDataKey
} from '../utils/Reducer'
import { webSocketToPrint } from '../JsonRpc'
import base64 from 'react-native-base64';
import axios from 'axios';
import globalVars from '../helpers/store';
import uuid from 'react-native-uuid';
import { Buffer } from "buffer";
import configs from '../helpers/config';
import * as FileSystem from 'expo-file-system';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage'; // WebUse only

// import { parse } from 'path';
import { deviceIsOnline, convertLocalTmpFile } from '../helpers/utils';
import { getTemplateBytes, unzipTemplateBytes } from '../helpers/cardRender/utils';
import recordDB from '../helpers/db/models/record'
import templatesDB from '../helpers/db/models/template'
import resourceDB from '../helpers/db/models/resource'
import printDB from '../helpers/db/models/printer'

// const strings = {
//   en: {
//     unknownError: 'Unknown error',
//     sessionExpiredError: 'Session expired',
//     serverInternalError: 'Server internal error',
//     accountNotActive: 'Account not active, check your email',
//     serverIsDown: 'Server is down',
//     templateNotFound: 'Template not found or you don\'t have access to it',
//     templateRecordNotFound: 'REcord not found or you don\'t have access to it',
//   }
// }
// const translateError = error => {
//   // const { t, i18n } = useTranslation();
//   switch (error) { 
//     case 'SERVER_INTERNAL_ERROR': return strings.en.serverInternalError
//     case 'LOGIN_NOK': return strings.en.sessionExpiredError
//     case 'ACCOUNT_NOT_ACTIVE': return strings.en.accountNotActive
//     case 'TEMPLATE_NOT_FOUND': return strings.en.templateNotFound
//     case 'TEMPLATE_RECORD_NOT_FOUND': return strings.en.templateRecordNotFound
//     case 'Failed to fetch': return strings.en.serverIsDown
//     default: return strings.en.unknownError
//   }
// }

function translateError(error) {
  return new Promise((resolve) => {
    switch (error) {
      case 'SERVER_INTERNAL_ERROR': resolve(globalVars.translationsErrors['STR_API_ERROR_INTERNAL_SERVER_ERROR'])
      case 'LOGIN_NOK': resolve(globalVars.translationsErrors['STR_API_ERROR_SESSION_EXPIRED'])
      case 'ACCOUNT_NOT_ACTIVE': resolve(globalVars.translationsErrors['STR_API_ERROR_ACCOUNT_NOT_ACTIVE'])
      case 'TEMPLATE_NOT_FOUND': resolve(globalVars.translationsErrors['STR_API_ERROR_TEMPLATE_NOT_FOUND'])
      case 'TEMPLATE_RECORD_NOT_FOUND': resolve(globalVars.translationsErrors['STR_API_ERROR_TEMPLATE_RECORD_NOT_FOUND'])
      case 'DEVICE_SESSION_STOLEN': resolve(globalVars.translationsErrors['STR_API_ERROR_SESSION_STOLEN'])
      case 'DEVICES_EXCEDED': resolve(globalVars.translationsErrors['STR_API_ERROR_DEVICES_EXCEDED'])
      case 'INVALID_DEVICE_ID': resolve(globalVars.translationsErrors['STR_API_ERROR_INVALID_DEVICEID'])

      case 'Failed to fetch': resolve(globalVars.translationsErrors['STR_API_ERROR_UNKNOWN'])
      default: resolve(globalVars.translationsErrors['STR_API_ERROR_UNKNOWN'] + ' (' + error + ')')
    }
  })
}

export const webAPIUser = 'cpwebapi'
export const webAPIPass = 'ZVE8rh2DWJPQEWar'
export const webAPIUserCloudPrint = 'cpwebapi'
export const webAPIPassCloudPrint = 'ZVE8rh2DWJPQEWar'

export const gError = (func, error) => {

  const errorsThatResultsOnLogout = ['DEVICE_SESSION_STOLEN', 'DEVICES_EXCEDED', 'INVALID_DEVICE_ID']

  if (__DEV__) {
    console.log("******************************");
    console.log(func);
    console.log(error);
    console.log("******************************");
  }

  if (error === 'LOGIN_NOK' || errorsThatResultsOnLogout.includes(error)) {
    store.dispatch(cleanLoginInfo());
  }

  (async () => {
    // Sentry.Native.captureException(error);  
    const translatedError = await translateError(error);
    store.dispatch(addErrorMessage(translatedError));
  })();
  return;
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getTemplatesWeb
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getTemplatesWeb(sortDesc = true) {
  return new Promise((resolve) => {
    (async () => {
      const onError = error => {
        gError('getTemplates', error)
        resolve(false);
      }

      const url = configs.APPUrls.webApi + '/v1/templates?sort=' + (sortDesc ? 'desc' : 'asc');
      axios({
        url: url,
        method: 'GET',
        headers: {
          'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
          'Accept': 'application/json',
          ...globalVars.additionalAPIHeaderInfo
        },
        signal: globalVars.controller.signal
      }).then((response) => {
        (async () => {

          // for (const key in response.data) {
          // }
          resolve(response.data);

        })()

      }).catch(function (error) {
        if (axios.isCancel(error)) {
          //onError(error.message);
        } else {
          onError(error.message);
        }
      });



    })();
  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getTemplates
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getTemplates(sortDesc = true) {
  return new Promise((resolve) => {
    (async () => {
      const onError = error => {
        gError('getTemplates', error)
        resolve(false);
      }
      // store.dispatch(cleanTemplate())  
      // fetch(configs.APPUrls.webApi + '/v1/templates?sort='+ (sortDesc ? 'desc' : 'asc') , {
      //   method: 'GET',
      //   headers: {
      //     'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
      //     'Accept': 'application/json',
      //     ...globalVars.additionalAPIHeaderInfo
      //   }
      // })
      //   .then(response => {
      //     if (response.ok) {
      //       response.json().then(json => {

      //         // json.map((template) => {            
      //         //   template.thumbnail = JSON.parse(template.thumbnail)            
      //         //   return template
      //         // })                             
      //         store.dispatch(setTemplateList(json))
      //       })
      //       return
      //     }
      //     response.json().then(json => onError(json.message))
      //   })
      //   .catch(error => onError(error.message))

      // await templatesDB.destroyAll();


      const deviceIsOnlineResult = await deviceIsOnline();
      if (deviceIsOnlineResult) {

        const url = configs.APPUrls.webApi + '/v1/templates?sort=' + (sortDesc ? 'desc' : 'asc');
        axios({
          url: url,
          method: 'GET',
          headers: {
            'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
            'Accept': 'application/json',
            ...globalVars.additionalAPIHeaderInfo
          },
          signal: globalVars.controller.signal
        }).then((response) => {
          (async () => {

            for (const key in response.data) {

              let localTemplateData = await templatesDB.query({
                where: {
                  templateId_eq: response.data[key].templateId
                }
              });

              // New template detected
              if (localTemplateData[0]?.templateId === undefined) {

                // Convert thumbnail base64 Image to file
                if (response.data[key].thumbnail.indexOf('base64,') !== -1) {
                  const imageURL = await convertLocalTmpFile(response.data[key].thumbnail);
                  response.data[key].thumbnail = imageURL;
                }

                const templateBytes = await getTemplateBytes(response.data[key].templateId);
                const filename = FileSystem.cacheDirectory + Math.floor(Date.now() * Math.random()).toString() + ".zip";

                if (templateBytes !== false) {
                  await FileSystem.writeAsStringAsync(filename, templateBytes, {
                    encoding: FileSystem.EncodingType.Base64,
                  });
                }

                const templateRowData = {
                  name: response.data[key].name,
                  templateId: response.data[key].templateId,
                  thumbnail: response.data[key].thumbnail,
                  updateDate: response.data[key].updateDate,
                  templateProperties: response.data[key].properties,
                  templateBytesFile: filename
                }

                // Prevent duplicated records 
                await templatesDB.removeTemplate(response.data[key].templateId);
                await templatesDB.create(templateRowData);

                // console.log(filename);
                // const base64Data = await FileSystem.readAsStringAsync(filename, { encoding: FileSystem.EncodingType.Base64 });
                // // console.log(base64Data);  
                // const extractedZipData = await unzipTemplateBytes(base64Data);
                // console.log(extractedZipData);
                // break;

              } else {

                // Is update                
                if (localTemplateData[0].updateDate !== response.data[key].updateDate) {

                  if (response.data[key].thumbnail.indexOf('base64,') !== -1) {
                    const imageURL = await convertLocalTmpFile(response.data[key].thumbnail);
                    response.data[key].thumbnail = imageURL;
                  }

                  // Remove old file 
                  FileSystem.deleteAsync(localTemplateData[0].thumbnail).then(function () {
                  }).catch(function () {
                    // Error
                  });

                  const templateBytes = await getTemplateBytes(response.data[key].templateId);
                  const filename = FileSystem.cacheDirectory + Math.floor(Date.now() * Math.random()).toString() + ".zip";
                  await FileSystem.writeAsStringAsync(filename, templateBytes, {
                    encoding: FileSystem.EncodingType.Base64,
                  });

                  const templateRowData = {
                    id: localTemplateData[0].id, // required
                    name: response.data[key].name,
                    templateId: response.data[key].templateId,
                    thumbnail: response.data[key].thumbnail,
                    updateDate: response.data[key].updateDate,
                    templateProperties: response.data[key].properties,
                    templateBytesFile: filename
                  }
                  await templatesDB.update(templateRowData)
                } else {

                  // Create local thumbnail file if not exists 
                  try {
                    const fileExists = await FileSystem.getInfoAsync(localTemplateData[0].thumbnail);
                    if (fileExists.exists === false) {
                      if (response.data[key].thumbnail.indexOf('base64,') !== -1) {
                        const imageURL = await convertLocalTmpFile(response.data[key].thumbnail);
                        response.data[key].thumbnail = imageURL;
                        const templateRowData = {
                          id: localTemplateData[0].id, // required                        
                          thumbnail: response.data[key].thumbnail
                        }
                        await templatesDB.update(templateRowData)
                        response.data[key].thumbnail = imageURL;
                      } else {
                        response.data[key].thumbnail = localTemplateData[0].thumbnail;
                      }
                    } else {
                      response.data[key].thumbnail = localTemplateData[0].thumbnail;
                    }

                  } catch (e) {
                  }

                  // create bytes file if not exists 
                  try {
                    const fileExists = await FileSystem.getInfoAsync(localTemplateData[0].templateBytesFile);
                    if (fileExists.exists === false) {
                      const templateBytes = await getTemplateBytes(response.data[key].templateId);
                      const filename = FileSystem.cacheDirectory + Math.floor(Date.now() * Math.random()).toString() + ".zip";
                      await FileSystem.writeAsStringAsync(filename, templateBytes, {
                        encoding: FileSystem.EncodingType.Base64,
                      });
                      const templateRowData = {
                        id: localTemplateData[0].id, // required                        
                        templateBytesFile: filename
                      }
                      await templatesDB.update(templateRowData)
                    }

                  } catch (e) {
                  }

                }
              }
            }

            // Look for deleted templates 
            const localRecords = await templatesDB.query({});
            // Remove removed template images
            for (const key in localRecords) {
              let templateExists = false;
              for (const tplKey in response.data) {
                if (localRecords[key].templateId === response.data[tplKey].templateId) {
                  templateExists = true;
                  break;
                }
              }

              if (templateExists === false) {
                FileSystem.deleteAsync(localRecords[key].thumbnail).then(function () {
                }).catch(function () {
                  // Error
                });
                FileSystem.deleteAsync(localRecords[key].templateBytes).then(function () {
                }).catch(function () {
                  // Error
                });
                await templatesDB.destroy(localRecords[key].id);
              }

            }

            resolve(response.data);

          })()

        }).catch(function (error) {
          if (axios.isCancel(error)) {
            //onError(error.message);
          } else {
            onError(error.message);
          }
        });

      } else {

        await templatesDB.removeDuplicatedRows();

        // Return local data
        const localRecords = await templatesDB.query({
          order: 'updateDate ' + (sortDesc ? 'DESC' : 'ASC')
        });
        resolve(localRecords);

      }


    })();
  });
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getTemplateById
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export async function getTemplateById(templateId, createDefaultRecord = false) {
  return new Promise((resolve) => {
    (async () => {

      const onError = error => {
        gError('getTemplateById', error)
        resolve(false);
      }

      try {

        if (Platform.OS !== 'web') {

          let localTemplateData = await templatesDB.query({
            where: {
              templateId_eq: templateId
            }
          });

          // let templateBytesFile = localTemplateData[0].templateBytesFile;
          // console.log(localTemplateData[0]); // Template bytes file  

          var properties = JSON.parse(localTemplateData[0].templateProperties);
          var mobilityName = localTemplateData[0].name;
          store.dispatch(setTemplateName(mobilityName))

          //Transform properties mask from string to array
          properties.items.filter((property) => (property.mask !== '')).forEach(property => {
            var maskString = property.mask
            if (maskString.startsWith('[')) maskString = maskString.substring(1)
            if (maskString.endsWith(']')) maskString = maskString.substring(0, maskString.length - 1)

            property.mask = maskString.split(',').map(m => {
              if (m.startsWith('\'') || m.startsWith('"'))
                return (m.substring(1, m.length - 1))
              else
                return (new RegExp(m.substring(1, m.length - 1)))
            })
          })
          properties.id = templateId;
          store.dispatch(setTemplateProperties(properties))

          if (createDefaultRecord) {
            var defaultRecord = {
              recordId: 0,
              templateId: templateId,
              info: {},
            }
            properties.items.forEach((property) => {
              defaultRecord.info[property.id] = ''
            })
            store.dispatch(setRecordSelected(defaultRecord))
          }

          resolve(properties);
          return;

        }

        if (Platform.OS === 'web') {

          // store.dispatch( cleanTemplate()) 
          fetch(configs.APPUrls.webApi + '/v1/templates/' + templateId, {
            method: 'GET',
            headers: {
              'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
              'Accept': 'application/json',
              ...globalVars.additionalAPIHeaderInfo
            }
          })
            .then(response => {
              if (response.ok) {

                response.json().then(json => {
                  var properties = JSON.parse(json.properties)
                  var mobilityName = json.templateName;
                  store.dispatch(setTemplateName(mobilityName))

                  //Transform properties mask from string to array
                  properties.items.filter((property) => (property.mask !== '')).forEach(property => {
                    var maskString = property.mask
                    if (maskString.startsWith('[')) maskString = maskString.substring(1)
                    if (maskString.endsWith(']')) maskString = maskString.substring(0, maskString.length - 1)

                    property.mask = maskString.split(',').map(m => {
                      if (m.startsWith('\'') || m.startsWith('"'))
                        return (m.substring(1, m.length - 1))
                      else
                        return (new RegExp(m.substring(1, m.length - 1)))
                    })
                  })
                  properties.id = templateId;
                  store.dispatch(setTemplateProperties(properties))

                  if (createDefaultRecord) {
                    var defaultRecord = {
                      recordId: 0,
                      templateId: templateId,
                      info: {},
                    }
                    properties.items.forEach((property) => {
                      defaultRecord.info[property.id] = ''
                    })
                    store.dispatch(setRecordSelected(defaultRecord))
                  }
                  resolve(properties);
                  return;
                })
                
              }
              // response.json().then(json => onError(json.message))
            })
            .catch(error => onError(error.message))

        }

      } catch (error) {
        onError(error);
      }

    })()
  })
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getRecords
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getRecords(templateId, from, searchQuery = null, limit = 10) {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getRecords', error);
      resolve(false);
    }
    (async () => {

      let localRecordsData = [];
      let localRecordsInEdition = [];
      let haveLocalRecords = false;
      let localRecords = undefined;
      let recordCount = 0;
      let recordList = [];

      if (Platform.OS !== 'web') {
        // Check if have local records    
        localRecords = await recordDB.query({
          where: {
            templateId_eq: templateId
          },
        });

        let numberOfNewLocalRecords = 0;
        if (localRecords !== null && localRecords.length > 0) {
          haveLocalRecords = true;
          numberOfNewLocalRecords = localRecords.length;
          // Remove update record from limits
          for (let index = 0; index < localRecords.length; index++) {
            const currentRecord = localRecords[index];
            if (currentRecord.newRecord === false) {
              // numberOfNewLocalRecords--; 
            }
          }
        }

        if (from === 0 && localRecords !== null && localRecords.length > 0) {
          if (localRecords.length >= limit) {
            limit = 0;
          } else {
            limit = limit - numberOfNewLocalRecords;
          }
        } else if (from !== 0 && localRecords !== null && localRecords.length > 0) {
          from = from - numberOfNewLocalRecords;
        }

        for (let index = 0; index < localRecords.length; index++) {

          const currentRecord = localRecords[index];
          let recordObj = JSON.parse(currentRecord.recordInfo);
          let recordItem = {
            recordId: recordObj.recordId,
            info: recordObj.info,
            localRecordId: currentRecord.id,
            syncError: currentRecord.errorInfo,
            newRecord: currentRecord.newRecord,
            templateId: templateId,
            printStatus: 0
          }

          if (currentRecord.newRecord === true && from === 0) {
            localRecordsData.push(recordItem);
            localRecordsInEdition.push(recordItem);
          } else if (currentRecord.newRecord === false) {
            localRecordsInEdition.push(recordItem);
          }

        }

      }

      const deviceIsOnlineResult = await deviceIsOnline();
      if (deviceIsOnlineResult) {

        var url = configs.APPUrls.webApi + '/v1/records/template/' + templateId + '?from=' + from + '&limit=' + limit;
        if (searchQuery !== null && searchQuery !== '') {
          url += '&search=' + encodeURI(searchQuery);
        }
        // console.log(url);
        axios({
          url: url,
          method: 'GET',
          headers: {
            'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
            'Accept': 'application/json',
            ...globalVars.additionalAPIHeaderInfo
          },
          signal: globalVars.controller.signal
        }).then((response) => {

          if (haveLocalRecords === true) {

            // Overwrite the cloud data with local pending data
            for (let index = 0; index < response.data.items.length; index++) {
              let recordIDindex = localRecordsInEdition.findIndex(function (localItem) {
                return localItem.recordId === response.data.items[index].recordId;
              });
              if (recordIDindex !== -1) {
                response.data.items[index] = localRecordsInEdition[recordIDindex];
              }
            }

            // Only concat new records 
            response.data.items = localRecordsData.concat(response.data.items);
            // store.dispatch(setRecordsCount(response.data.count + localRecords.length));
            recordCount = response.data.count + localRecords.length;
          } else {
            // store.dispatch(setRecordsCount(response.data.count));
            recordCount = response.data.count;
          }

          if (from > 0) {
            // store.dispatch(updateRecordList(response.data));
            recordList = response.data;
          } else {
            // store.dispatch(setRecordList(response.data));
            recordList = response.data;
          }

          resolve({
            recordsCount: recordCount,
            recordsList: recordList
          });

        }).catch(function (error) {
          if (axios.isCancel(error)) {
            //onError(error.message);
          } else {
            let errorToReturn = error.message;
            if (error.response?.data?.developerMessage !== undefined) {
              errorToReturn = errorToReturn + " - " + error.response.data.developerMessage
            }
            onError(errorToReturn);
          }
        });

      } else {
        resolve({
          recordsCount: localRecordsInEdition.length,
          recordsList: {
            items: localRecordsInEdition
          }
        });


      }

      // fetch(url, {
      //   method: 'GET',
      //   headers: {
      //     'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
      //     'Accept': 'application/json',
      //   }
      // }).then(response => {
      //   if (response.ok) {
      //     response.json().then(json => {
      //       json.items.map((record) => {
      //         return record
      //       })
      //       if (from > 0) {
      //         store.dispatch(updateRecordList(json))
      //         resolve(true);
      //       }
      //       else {
      //         store.dispatch(setRecordList(json))
      //         resolve(true);
      //       }
      //     })
      //   } else {
      //     response.json().then(json => onError(json.message))
      //   }

      // }).catch(error => onError(error.message))
    })();

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getGoogleCredentials
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getGoogleCredentials(templateId) {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getGoogleCredentials', error)
    }

    fetch(configs.APPUrls.webApi + '/v1/auth/google/template/' + templateId, {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            resolve(json);
          })
        } else {
          response.json().then(json => onError(json.message))
        }
      })
      .catch(error => onError(error.message))

  });
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getRecordById
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getRecordById(recordId, templateId, returnRecordData = false) {
  return new Promise((resolve) => {
    (async () => {

      const onError = error => {
        resolve(false);
        gError('getRecordById', error)
      }

      let isLocalRecord = false;
      if (Platform.OS === 'web') {
        isLocalRecord = false;
      }else{
        const localRecordData = await recordDB.query({
          where: {
            templateId_eq: templateId,
            recordId_eq: recordId
          },
        });
        isLocalRecord = (localRecordData[0] !== undefined ? true : false)
      }

      const deviceIsOnlineResult = await deviceIsOnline();
      if (deviceIsOnlineResult && isLocalRecord == false) {

        //store.dispatch(cleanRecord())
        fetch(configs.APPUrls.webApi + '/v1/records/template/' + templateId + '/record/' + recordId, {
          method: 'GET',
          headers: {
            'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
            'Accept': 'application/json',
            ...globalVars.additionalAPIHeaderInfo
          }
        }).then(response => {
          if (response.ok) {
            response.json().then(json => {
              if (returnRecordData === true) {
                resolve(json.record);
              } else {
                store.dispatch(setRecordSelected(json.record))
                resolve(true);
              }
            })
          } else {
            response.json().then(json => onError(json.message))
          }
        }).catch(error => onError(error.message))

      } else {

        const recordInfo = JSON.parse(localRecordData[0].recordInfo);
        const recordData = {
          info: recordInfo.info,
          printStatus: 0,
          recordId: recordId,
          templateId: templateId,
          isLocalRecord: true
        }

        if (returnRecordData === true) {
          resolve(recordData);
        } else {
          store.dispatch(setRecordSelected(recordData))
          resolve(true);
        }

      }

    })();

  });
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// createRecord
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function createRecord(record, dispatchSync = false) {

  return new Promise((resolve) => {

    (async () => {

      const onError = error => {
        gError('createRecord', error);
        resolve(false);
      }

      const dispatchError = (recordData, record) => {
        (async () => {
          const recordObj = await recordDB.find(record.localRecordId);
          recordObj.error = true;
          recordObj.errorInfo = recordData.error;
          recordObj.save()
          store.dispatch(setRecordSyncError(recordData));
        })();
      }

      //await new Promise(r => setTimeout(r, 3000)); // Simulate delay 
      // let record = _record;
      let filesToDel = [];
      const deviceIsOnlineResult = await deviceIsOnline();

      if (typeof record.info === 'string') {
        record.info = JSON.parse(record.info);
      }

      // Convert tmp file to base64 data
      if (deviceIsOnlineResult) {
        for (const key in record.info) {

          if (record.info[key] != ""
            && record.info[key].indexOf('file:///') !== -1) {
            filesToDel.push(record.info[key]);
            const base64Data = await FileSystem.readAsStringAsync(record.info[key], { encoding: FileSystem.EncodingType.Base64 });
            record.info[key] = 'data:image/png;base64,' + base64Data;
          }
        }
      }

      // CHECK Record Source to check if need to be uploaded to some platform
      const recordSource = record.templateProperties.dataSource?.type;
      if (recordSource === 'GOOGLESHEETS' && deviceIsOnlineResult) {

        // Get folder ID
        let uploadFolder = null;
        if (record?.templateProperties?.dataSource?.uploadFolder !== undefined) {
          uploadFolder = record.templateProperties.dataSource.uploadFolder
        }

        for (const key in record.info) {
          if (
            record.info[key].indexOf('drive.google.com') === -1
            && record.info[key] != ""
            && record.info[key].indexOf('base64,') !== -1) {

            if (record.accessToken === null) {

              if (record.templateProperties?.id !== undefined) {
                const googleCredentials = await getGoogleCredentials(record.templateProperties.id);
                record.accessToken = googleCredentials.accessToken;
              }

            }
            const imageURL = await convertBase64ImageToGoogleDrive(record.info[key], record.accessToken, uploadFolder);
            if (imageURL !== false) {
              record.info[key] = imageURL;
            }
          }
        }
      }

      // if (typeof record.info === 'object' && record.info !== null) {
      // console.log(record.info);
      // record.info = JSON.stringify(record.info)
      // }
      record.printStatus = 0

      if (deviceIsOnlineResult) {

        fetch(configs.APPUrls.webApi + '/v1/records/create', {
          method: 'POST',
          headers: {
            'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...globalVars.additionalAPIHeaderInfo
          },
          body: JSON.stringify(record)
        }).then(response => {
          if (response.ok) {
            response.json().then(json => {
              if (dispatchSync === true) {
                (async () => {

                  recordDB.destroy(record.localRecordId); // Delete record from local DB
                  const recordData = {
                    tmpRecordId: record.localRecordId,
                    cloudRecordId: json.recordId,
                    recordInfo: record.info
                  }
                  store.dispatch(setRecordSynced(recordData));

                  // // Simulate error manually                  
                  // const recordData = {
                  //   tmpRecordId: record.recordId,
                  //   error: "Test Error 123",
                  // }
                  // const recordObj = await recordDB.find(record.localRecordId);
                  // recordObj.error = true;
                  // recordObj.errorInfo = recordData.error;
                  // recordObj.save()
                  // store.dispatch(setRecordSyncError(recordData));
                  // // End - Simulate error manually

                  // Delete tmp files 
                  for (let index = 0; index < filesToDel.length; index++) {
                    const file = filesToDel[index];
                    FileSystem.deleteAsync(file).then(function () {
                    }).catch(function () {
                      // Error
                    });
                  }
                })();

              }
              resolve(json.recordId);
              return;
            })
          } else {
            response.json().then(json => {
              (async () => {
                if (dispatchSync === true) {
                  const recordData = {
                    newRecord: true,
                    tmpRecordId: record.localRecordId,
                    error: json.message,
                  }
                  dispatchError(recordData, record);
                }
                onError(json.message)
              })();
            }).catch(error => {
              if (dispatchSync === true) {
                const recordData = {
                  newRecord: true,
                  tmpRecordId: record.localRecordId,
                  error: error.message,
                }
                dispatchError(recordData, record);
              }
            })
          }
        }).catch(error => {
          (async () => {
            if (dispatchSync === true) {
              const recordData = {
                newRecord: true,
                tmpRecordId: record.localRecordId,
                error: error.message,
              }
              dispatchError(recordData, record);
            }
            onError(error.message)
          })();
        })

      } else {
        const recordData = {
          newRecord: true,
          tmpRecordId: record.localRecordId,
          error: "Device is offline",
        }
        dispatchError(recordData, record);
      }

    })();

  });

}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// updateRecord
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function updateRecord(record, dispatchSync = false) {

  return new Promise((resolve) => {

    const onError = error => {
      gError('updateRecord', error)
    }
    const dispatchError = (recordData, record) => {
      (async () => {
        const recordObj = await recordDB.find(record.localRecordId);
        recordObj.error = true;
        recordObj.errorInfo = recordData.error;
        recordObj.save()
        store.dispatch(setRecordSyncError(recordData));
      })();
    }

    (async () => {

      let filesToDel = [];
      const deviceIsOnlineResult = await deviceIsOnline();

      // Convert tmp file to base64 data
      if (deviceIsOnlineResult) {
        for (const key in record.info) {
          if (record.info[key] != ""
            && record.info[key].indexOf('file:///') !== -1) {
            filesToDel.push(record.info[key]);
            const base64Data = await FileSystem.readAsStringAsync(record.info[key], { encoding: FileSystem.EncodingType.Base64 });
            record.info[key] = 'data:image/png;base64,' + base64Data;
          }
        }
      }

      // CHECK Record Source to check if need to be uploaded to some platform
      const recordSource = record.templateProperties.dataSource?.type;
      if (recordSource === 'GOOGLESHEETS' && deviceIsOnlineResult) {

        // Get folder ID
        let uploadFolder = null;
        if (record?.templateProperties?.dataSource?.uploadFolder !== undefined) {
          uploadFolder = record.templateProperties.dataSource.uploadFolder
        }

        for (const key in record.info) {
          if (
            record.info[key].indexOf('drive.google.com') === -1
            && record.info[key] != ""
            && record.info[key].indexOf('base64,') !== -1) {
            const imageURL = await convertBase64ImageToGoogleDrive(record.info[key], record.accessToken, uploadFolder);
            record.info[key] = imageURL;

            if (store.getState().recordSelected !== undefined) {
              store.dispatch(updateRecordSelected({ field: key, value: imageURL }))
            } else {
              if (dispatchSync === true) {
                const infoToUpdate = {
                  "recordId": record.recordId,
                  "key": key,
                  "value": imageURL
                };
                store.dispatch(setUpdateRecordDataKey(infoToUpdate))
              }
            }

          }
        }
      }

      record.info = JSON.stringify(record.info)

      if (deviceIsOnlineResult) {

        fetch(configs.APPUrls.webApi + '/v1/records/template/' + record.templateId + '/record/' + record.recordId, {
          method: 'PUT',
          headers: {
            'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...globalVars.additionalAPIHeaderInfo
          },
          body: JSON.stringify(record)
        }).then(response => {
          (async () => {

            if (response.ok) {

              if (dispatchSync === true) {
                (async () => {
                  recordDB.destroy(record.localRecordId); // Delete record from local DB
                  const recordData = {
                    tmpRecordId: record.recordId,
                    cloudRecordId: record.recordId,
                  }
                  store.dispatch(setRecordSynced(recordData));
                })();
              }
              // Delete tmp files 
              for (let index = 0; index < filesToDel.length; index++) {
                const file = filesToDel[index];
                FileSystem.deleteAsync(file).then(function () {
                }).catch(function () {
                  // Error
                });
              }

              // // Simulate error manually
              // console.log("------------------------------------------");
              // const recordData = {
              //   tmpRecordId: record.recordId, 
              //   error: "Test Error 123",
              // }
              // const recordObj = await recordDB.find(record.localRecordId);
              // recordObj.error = true;
              // recordObj.errorInfo = recordData.error;
              // recordObj.save()
              // store.dispatch(setRecordSyncError(recordData));
              // // End - Simulate error manually

              //store.dispatch(SetRecordUpdated())
              resolve(true);

            } else {
              try {
                response.json().then(json => {
                  (async () => {
                    if (dispatchSync === true) {
                      const recordData = {
                        newRecord: false,
                        tmpRecordId: record.localRecordId,
                        error: json.message,
                      }
                      dispatchError(recordData, record);
                    }
                    onError(json.message);
                    resolve(false);
                  })();
                }).catch(error => {
                  if (dispatchSync === true) {
                    const recordData = {
                      newRecord: false,
                      tmpRecordId: record.localRecordId,
                      error: error.message,
                    }
                    dispatchError(recordData, record);
                  }
                })

              } catch (e) {
                if (dispatchSync === true) {
                  const recordData = {
                    newRecord: false,
                    tmpRecordId: record.localRecordId,
                    error: json.message,
                  }
                  dispatchError(recordData, record);
                }
                resolve(false);
              }
            }

          })();

        }).catch(error => {
          (async () => {
            if (dispatchSync === true) {
              const recordData = {
                newRecord: false,
                tmpRecordId: record.localRecordId,
                error: error.message,
              }
              dispatchError(recordData, record);
            }
            onError(error.message);
            resolve(false);
          })();
        })

      } else {
        const recordData = {
          newRecord: false,
          tmpRecordId: record.localRecordId,
          error: "Device is offline",
        }
        dispatchError(recordData, record);
      }

    })();

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// convertGoogleDriveImageToBase64
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------

function getIdFromUrl(url) {
  return url.match(/[-\w]{25,}/);
}

export function convertGoogleDriveImageToBase64(imageURL, accessToken) {

  return new Promise((resolve) => {
    try {

      // (async () => {
      const onError = error => {
        gError('convertGoogleDriveImageToBase64', error)
      }

      var imageData = [];

      imageData = getIdFromUrl(imageURL);

      if (imageData === null || imageData[0] === null) {
        resolve(false);
        return;
      }

      var imageID = imageData[0];

      if (typeof imageID === 'undefined') {
        resolve(false);
        return;
      }

      axios({
        url: 'https://www.googleapis.com/drive/v3/files/' + imageID + '?alt=media',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        "Content-Type": "application/json",
        // responseType: 'blob', // important, // only work on web
        responseType: 'arraybuffer', // important,
        signal: globalVars.controller.signal
      }).then((response) => {
        const buffer64 = Buffer.from(response.data, 'binary').toString('base64')
        const imageData = "data:image/png;base64," + buffer64;
        resolve(imageData);
        return;
        // Only works on web
        // const imageBlob = new Blob([response.data]);
        // var reader = new FileReader();
        // reader.readAsDataURL(imageBlob);
        // reader.onloadend = function () {
        //   var base64data = reader.result;
        //   resolve(base64data);
        //   return;
        // }
      }).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          resolve(false);
        } else {
          resolve(false);
        }
      });

    } catch (e) {
      //Sentry.Native.captureException(e);
      resolve(false);
    }

  });

}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// convertBase64ImageToGoogleDrive
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------

export function convertBase64ImageToGoogleDrive(base64Image, accessToken, uploadFolder = null) {

  // https://developers.google.com/drive/api/guides/manage-uploads
  // https://developers.google.com/drive/api/guides/folder  

  return new Promise((resolve) => {

    try {

      const onError = error => {
        gError('convertGoogleDriveImageToBase64', error)
        resolve(false);
      }

      if (base64Image === '' || typeof base64Image === 'undefined') {
        resolve(false);
        return;
      }

      base64Image = base64Image.replace('data:image/png;base64,', '');

      const filename = uuid.v4() + ".png";
      const fileMetadata = {
        name: filename,
        mimeType: "image/png"
      };
      if (uploadFolder !== undefined && uploadFolder !== null) {
        fileMetadata['parents'] = [uploadFolder];
      }

      axios({
        method: "POST",
        url: "https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(fileMetadata),
      }).then(({ headers: { location } }) => {
        const data = Buffer.from(base64Image, 'base64');
        const fileSize = data.length;
        axios({
          method: "PUT",
          url: location,
          headers: {
            "Content-Range": `bytes 0-${fileSize - 1}/${fileSize}`,
            "Content-Type": "image/png",
          },
          data: data,
        }).then(({ data }) => {
          const fileID = data.id;
          const fileURL = 'https://drive.google.com/file/d/' + data.id + '/view?usp=sharing';
          resolve(fileURL);
        }).catch(function (error) {
          console.log("Error 1:")
          console.log(error);
          resolve(false);
        })

      }).catch(function (error) {
        console.log(error);
        resolve(false);
      })

    } catch (e) {
      //Sentry.Native.captureException(e);
      console.log(e)
      resolve(false);
    }

  });

}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// signIn
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function signIn(values) {

  return new Promise((resolve) => {

    (async () => {
      let returnObj = {
        status: null,
        message: null,
        data: null
      };

      if (values.email === '') {
        returnObj.status = 400;
        returnObj.message = "Please fill Email";
        resolve(returnObj);
        return;
      }
      if (values.password === '') {
        returnObj.status = 400;
        returnObj.message = "Please fill Password";
        resolve(returnObj);
        return;
      }
      axios({
        method: "POST",
        url: configs.APPUrls.webApi + '/v1/account/signin',
        headers: {
          'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...globalVars.additionalAPIHeaderInfo
        },
        data: {
          email: values.email,
          password: values.password,
          deviceInfo: values.deviceInfo
        },
      }).then(({ data }) => {
        returnObj.status = 200;
        returnObj.message = "OK";
        returnObj.data = data;
        globalVars.additionalAPIHeaderInfo['cp-api-authorization'] = data.authToken;
        SecureStore.setItemAsync('secure_apiauthorization', data.authToken);
        if (Platform.OS === 'web') {
          AsyncStorage.setItem('secure_apiauthorization', data.authToken)
        }
        resolve(returnObj);
        return;
      }).catch(function (thrown) {
        if (thrown.response.status == 401) {
          returnObj.status = 401;
          returnObj.message = thrown.response.data.message;
        } else {
          returnObj.status = 400;
          returnObj.message = thrown.message;
        }
        resolve(returnObj);
      });

    })();

  });
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// signOut
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function signOut() {

  return new Promise((resolve) => {

    (async () => {

      let returnObj = {
        status: null,
        message: null,
        data: null
      };
      axios({
        method: "PATCH",
        url: configs.APPUrls.webApi + '/v1/account/signout',
        headers: {
          'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...globalVars.additionalAPIHeaderInfo
        },
        data: {},
      }).then(({ data }) => {
        returnObj.status = 200;
        returnObj.message = "OK";
        returnObj.data = data;
        resolve(returnObj);
        return;
      }).catch(function (thrown) {
        if (thrown.response.status == 401) {
          returnObj.status = 401;
          returnObj.message = thrown.response.data.message;
        } else {
          returnObj.status = 400;
          returnObj.message = thrown.message;
        }
        resolve(returnObj);
      });

    })();

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrinters
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrinters() {
  return new Promise((resolve) => {

    (async () => {

      const onError = error => {
        gError('getPrinters', error);
        resolve(false);
      }

      var url = configs.APPUrls.cloudPrint + '/v1/printers/get';
      axios({
        url: url,
        method: 'GET',
        headers: {
          'Authorization': 'Basic ' + base64.encode(webAPIUserCloudPrint + ":" + webAPIPassCloudPrint),
          'Accept': 'application/json',
          ...globalVars.additionalAPIHeaderInfo
        }
      }).then((response) => {

        (async () => {

          if (Platform.OS !== 'web') {
            await printDB.removeCloudPrinters();
            for (let index = 0; index < response.data.avaliablePrinters.length; index++) {
              const printer = response.data.avaliablePrinters[index];

              const printerObj = {
                printerName: printer.printer_name,
                manufacturer: printer.manufacturer,
                model: printer.model,
                modelName: printer.model,
                printerSn: printer.serial_number,
                ipAddress: null,
                localPrinter: false
              }
              const insertResult = await printDB.create(printerObj)

            }
          }
          store.dispatch(setPrintersList(response.data.avaliablePrinters));
          resolve(true);

        })();

      }).catch(function (error) {
        if (axios.isCancel(error)) {
          onError(error.message);
        } else {
          onError(error.message);
        }
      });

    })();

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrinter
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrinter(printerSN) {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getPrinter', error);
      resolve(false);
    }

    var url = configs.APPUrls.cloudPrint + '/v1/printers/get/' + printerSN;
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUserCloudPrint + ":" + webAPIPassCloudPrint),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    }).then((response) => {
      if (typeof response.data.printerData[0] === 'undefined') {
        resolve(false);
      } else {
        resolve(response.data.printerData[0]);
      }
    }).catch(function (error) {
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
    });

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrinterHistory
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrinterHistory(printerSN, limit = 20) {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getPrinterHistory', error);
      resolve(false);
    }

    var url = configs.APPUrls.cloudPrint + '/v1/printers/' + printerSN + '/jobs?limit=' + limit;
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUserCloudPrint + ":" + webAPIPassCloudPrint),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    }).then((response) => {
      resolve(response.data.printerJobs);
    }).catch(function (error) {
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
    });

  });
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// newPrintJob
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function newPrintJob(templateProperties, recordsToPrint, printerSN) {
  return new Promise((resolve) => {

    const onError = error => {
      gError('newPrintJob', error);
      resolve(false);
    }

    let returnObj = {
      status: null,
      message: null
    };

    // recordsToPrint.map(function (item) {
    //   delete item.info.PHOTO;
    //   return item;   
    // });
    // let t = {
    //   printerSN: printerSN,
    //   templateProperties: JSON.stringify(templateProperties),
    //   recordsToPrint: JSON.stringify(recordsToPrint)
    // };
    // console.log(t);
    //  console.log(recordsToPrint); 
    // console.log({
    //   data: {
    //     printerSN: printerSN,
    //     templateProperties: templateProperties,
    //     recordsToPrint: recordsToPrint
    //   }
    // }); 
    // console.log(templateProperties);
    // console.log(recordsToPrint);

    var url = configs.APPUrls.cloudPrint + '/v1/printers/newFullJob';
    axios({
      method: "POST",
      url: url,
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUserCloudPrint + ":" + webAPIPassCloudPrint),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      },
      data: {
        printerSN: printerSN,
        templateProperties: templateProperties,
        recordsToPrint: recordsToPrint
      },
    }).then(({ data }) => {
      returnObj.status = 200;
      returnObj.message = "OK";
      returnObj.printJobId = data.jobID;
      resolve(returnObj);
      return;
    }).catch(function (thrown) {
      console.log(thrown);
      onError(thrown.message);
    });


  });

}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPendingPrintJobs
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPendingPrintJobs() {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getPendingPrintJobs', error);
      resolve(false);
    }

    var url = configs.APPUrls.cloudPrint + '/v1/printers/pendingPrintJobs';
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUserCloudPrint + ":" + webAPIPassCloudPrint),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    }).then((response) => {
      resolve(response.data);
    }).catch(function (error) {
      console.log(error);
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
    });

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrintJobHistory
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrintJobHistory(start = 0, limit = 10, searchQuery = '', states = []) {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getPrintJobHistory', error);
      resolve(false);
    }

    let url = configs.APPUrls.cloudPrint + '/v1/printers/printJobsHistory?start=' + start + '&limit=' + limit;

    if (searchQuery !== null && searchQuery !== '') {
      url += '&search=' + encodeURI(searchQuery);
    }
    if (states.length > 0) {
      url += '&states=' + states.join(",");
    }
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUserCloudPrint + ":" + webAPIPassCloudPrint),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    }).then((response) => {
      resolve(response.data);
    }).catch(function (error) {
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
    });

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDevices
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDevices() {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getDevices', error);
      resolve(false);
    }

    let url = configs.APPUrls.webApi + '/v1/devices';
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUserCloudPrint + ":" + webAPIPassCloudPrint),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    }).then((response) => {
      resolve(response.data);
    }).catch(function (error) {
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
    });

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// removeDevice
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function removeDevice(deviceID) {
  return new Promise((resolve) => {

    const onError = error => {
      //gError('removeDevice', error);
      resolve(false);
    }

    let url = configs.APPUrls.webApi + '/v1/devices/' + deviceID;
    axios({
      url: url,
      method: 'DELETE',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUserCloudPrint + ":" + webAPIPassCloudPrint),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    }).then((response) => {
      resolve(response.data);
    }).catch(function (error) {
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
    });

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// removeTemplate
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function removeTemplate(templateID) {
  return new Promise((resolve) => {

    const onError = error => {
      //gError('removeDevice', error);
      resolve(false);
    }

    let url = configs.APPUrls.webApi + '/v1/templates/' + parseInt(templateID);
    axios({
      url: url,
      method: 'DELETE',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    }).then((response) => {
      if (response.data.message === "OK") {
        resolve(true);
      } else {
        resolve(false);
      }
    }).catch(function (error) {
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
    });

  });
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrinterResources
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrinterResources() {
  return new Promise((resolve) => {

    const onError = error => {
      gError('getPrinterResources', error);
      resolve(false);
    }

    var url = configs.APPUrls.webApi + '/v1/printers/resources';
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
        'Accept': 'application/json',
        ...globalVars.additionalAPIHeaderInfo
      }
    }).then((response) => {
      (async () => {

        if (response.data?.files === undefined || response.data.files.length === 0) {
          resolve(false);
          return;
        }

        for (let index = 0; index < response.data.files.length; index++) {
          const fileObj = response.data.files[index];

          // Check if exists and is updated           
          let localResource = await resourceDB.query({
            where: {
              name_eq: fileObj.filename,
              lastChangeDate_eq: fileObj.lastChange
            }
          });

          if (localResource[0] === undefined) {

            let localResource = await resourceDB.query({
              where: {
                name_eq: fileObj.filename
              }
            });
            if (localResource[0] !== undefined) {
              resourceDB.destroy(localResource[0].id)
            }

            const imageFullUrl = configs.APPUrls.webApi + '/public/printers/' + fileObj.filename;
            const localFileName = FileSystem.cacheDirectory + fileObj.filename;
            const downloadResult = await FileSystem.downloadAsync(imageFullUrl, localFileName);

            const newResource = {
              name: fileObj.filename,
              lastChangeDate: fileObj.lastChange,
              localFilePath: downloadResult.uri
            }
            await resourceDB.create(newResource)

          }
        }

        resolve(true);
      })();

    }).catch(function (error) {
      if (axios.isCancel(error)) {
        onError(error.message);
      } else {
        onError(error.message);
      }
    });

  });
}