module.exports.SecretJWT = "Y*#e$xyr8-U6=6KJr7{$~L:*?9PMueFUM']X!dAYMgyeTLUvcJ>2s#gEZm-?&bsQ"
module.exports.allowDefaultSignIn = true
module.exports.jwtExpiresIn = '60m'
module.exports.port = '3030'


// TO REMOVE
// module.exports.redisCluster = {
//     port: 6379,
//     host: 'licenseredis.vd4w2b.clustercfg.use2.cache.amazonaws.com'
// }
 
module.exports.APPEnvironment = process.env.ENVIRONMENT || 'sandbox';

module.exports.APPUrls = {
    webApi: process.env.WEBAPI_URL || 'https://demo.webapi.cardpresso.com', // 'http://localhost:3000', // 
    cloudPrint: process.env.CLOUDPRINT_URL || 'https://demo.cloudprint.cardpresso.com'    // 'http://localhost:3001' //
} 

module.exports.CustomStyles = {
    developmentMenuPadding: (process.env.NODE_ENV === 'development' ? 0 : 0),
}
  
module.exports.aboutAppUrl = 'https://www.cardpresso.com/cardPresso_Privacy_Policy.html' // 'https://cardpresso.com/about-cardpresso-app'
module.exports.sqlLiteDbName = 'cardpressoSQLite.db';

//AWS - Database
/*module.exports.database = {
    connectionLimit: 10,
    host: 'ls-a637a233226ab861de0f95f612be50b8dd542133.cmumd7kvlibb.us-east-2.rds.amazonaws.com',
    port: 3306,
    database: 'cardpresso_lic',
    user: 'root',
    password: 'i>vSYylMLVWVjJy3>YIw^BsnHF3?>POo'
};*/

//Localhost - Database
// module.exports.database = {
//     connectionLimit: 10,
//     host: 'localhost',
//     port: 3306,
//     database: 'cardpresso_lic',
//     user: 'cardpresso',
//     password: 'cardpresso',
// };

//a2host - Database
/*module.exports.database = {
    connectionLimit: 10,
    host: '70.32.31.210',
    port: 3306,
    database: 'cpadmin_cardpresso_lic_sandbox',
    user: 'cpadmin_cardpresso_lic_sandbox',
    password: 'cP$$o2019',
};*/

module.exports.emailServer = {
    host: 'smtp.office365.com',
    port: 587,
    auth: { user: 'api@cardpresso.com', pass: 'OWT2jQZPj8FCGosacVyW!' },
    from: 'cardPresso Login Account <api@cardpresso.com>'
};
