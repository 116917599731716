import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Alert, ScrollView, Platform, BackHandler, SafeAreaView } from 'react-native';
import { connect } from 'react-redux';
import {
  Modal, Portal, Appbar, Button, Menu, Divider,
  Provider, Text, IconButton, RadioButton, Badge,
  useTheme
} from 'react-native-paper';

import { NavigationContainer, useRoute, CommonActions, useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator, createStackNavigator } from '@react-navigation/stack';

// https://github.com/oblador/react-native-vector-icons/blob/master/glyphmaps/MaterialCommunityIcons.json
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { store, cleanLoginInfo, setRecordsToPrintList, setRecordSelectedId, setPrintCounter, setScreenToGo } from '../../../utils/Reducer'

import { deviceIsOnline } from '../../../helpers/utils';

import SignInScreen from '../auth/SignInScreen';
import SplashScreen from '../SplashScreen';
import RegisterScreen from '../auth/RegisterScreen';
import ActivateScreen from '../auth/ActivateScreen';
import ForgotScreen from '../auth/ForgotScreen';
import ResetScreen from '../auth/ResetScreen';

import TemplateScreen from '../templates/TemplateScreen';
import RecordScreen from '../records/RecordScreen';
import EditRecordScreen from '../records/EditRecordScreen';
import PrintSummaryList from '../records/PrintSummaryList';
import PrintChooseList from '../records/PrintChooseList';

// Printers  
import PrintersListScreen from '../printers/PrinterListScreen';
import PrintersDetailScreen from '../printers/PrinterDetailScreen';
import AddLocalPrinter from '../printers/AddLocalPrinter';

// Print Jobs
import PrintJobsPending from '../printers/PrintJobsPending';
import PrintJobsHistory from '../printers/PrintJobsHistory';

// Devices 
import DevicesList from '../devices/DeviceList';

import CardRender from '../cards/CardRender';
import AboutApp from '../global/AboutApp';
import CardRenderDebug from '../cards/CardRenderDebug';

import globalVars from '../../../helpers/store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MenuItem from '../../components/menu/MenuItem';
import { useTranslation } from 'react-i18next';
// import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import configs from '../../../helpers/config';
import { signOut } from '../../Rest';
import Animated, { BounceInDown, BounceOutDown, FadeIn } from 'react-native-reanimated';
import { loadFromLocalStorage } from '../../../utils/Utils'
import GlobalStyles from '../../GlobalStyles';

import resourceDB from '../../../helpers/db/models/resource'
import { deleteAllCacheFiles } from '../../../helpers/utils';

const Auth = () => {

  // Stack Navigator for SignIn and SignUp Screen
  return (
    <SafeAreaView style={GlobalStyles.droidSafeArea}>
      <Stack.Navigator initialRouteName="SignInScreen" >
        <Stack.Screen
          name="SignIn"
          component={SignInScreen}
          options={{ headerShown: false }}
        />
        {<Stack.Screen
          name="Register"
          component={RegisterScreen}
          options={{
            headerTransparent: true,
            title: ''
          }}
        />}
        {<Stack.Screen
          name="Activate"
          component={ActivateScreen}
          options={{
            headerTransparent: true,
            title: ''
          }}
        />}
        {<Stack.Screen
          name="Forgot"
          component={ForgotScreen}
          options={{
            headerTransparent: true,
            title: ''
          }}
        />}
        {<Stack.Screen
          name="Reset"
          component={ResetScreen}
          options={{
            headerTransparent: true,
            title: ''
          }}
        />}
      </Stack.Navigator>
    </SafeAreaView>
  );
};


function SelectLanguageModalScreen({ navigation }) {
  const { t, i18n } = useTranslation();
  const [checked, setChecked] = useState(i18n.language);
  const languages = [
    { name: "en", label: "English" },
    { name: "pt-PT", label: "Português" },
  ];

  useEffect(() => {
    (async () => {
      if (checked !== undefined) {
        i18n.changeLanguage(checked);
        AsyncStorage.setItem('@userLanguage', checked)
      }
    })();
  }, [checked]);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start', alignContent: 'center', margin: 15 }}>
        {languages.map((lang, index) => (
          <Animated.View key={index} entering={BounceInDown.duration(((Number(index) * 100) + 800))}>
            <TouchableOpacity key={index} onPress={() => setChecked(lang.name)}>
              <View style={{
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 10
              }}>
                <RadioButton
                  value={lang.name}
                  status={checked === lang.name ? 'checked' : 'unchecked'}
                  color='#05AC72'
                  onPress={() => setChecked(lang.name)}
                />
                <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{lang.label}</Text>
              </View>
            </TouchableOpacity>
          </Animated.View>
        ))
        }
      </View>
    </ScrollView>
  );
}

const Stack = createStackNavigator();
const DrawerNavigatorRoutes = (props) => {

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { cleanLoginInfo } = props

  const [visible, setVisible] = useState(false);
  const [recordsToPrintCounter, setRecordsToPrintCounter] = useState(undefined);

  const route = useRoute();
  // const navigation = useNavigation();
  const navigation = React.useRef();

  const openMenu = () => {
    setVisible(true);
  };
  const closeMenu = () => setVisible(false);

  // Handle Redux state changes
  store.subscribe(() => {
    const storeData = store.getState();

    if (storeData.recordsToPrintList !== undefined) {
      setRecordsToPrintCounter(storeData.recordsToPrintList.length);
    } else {
      setRecordsToPrintCounter(undefined);
    }

    if (storeData.screenToGo !== undefined) {

      if (storeData.screenToGo === 'goBack') {
        returnBack();
      } else if (storeData.screenToGo === 'Auth') {
        logoutProcess();
      } else {
        goTo(storeData.screenToGo);
      }

      setScreenToGo(undefined);
    }

  });

  const logoutProcess = () => {
    (async () => {

      const isDeviceOnline = await deviceIsOnline();
      if (isDeviceOnline) {
        const signOutResult = await signOut();
      }

      // Remove all resource records from DB
      resourceDB.destroyAll();

      // Delete all cache files
      deleteAllCacheFiles();

      globalVars.Sentry.setUser(null);
      cleanLoginInfo();
      await AsyncStorage.removeItem('@userLogged');
      await AsyncStorage.removeItem('@email');
      await AsyncStorage.removeItem('@password');
      props.navigation.navigate('Auth');
    })();
  }

  const handleLogout = () => {
    if (Platform.OS === 'web') {
      let text = t('STR_ALERT_ATTENTION') + '\n' + t('STR_ALERT_LOGOUT_CONFIRM');
      if (confirm(text) === true) {
        logoutProcess();
      }
    } else {
      Alert.alert(
        t('STR_ALERT_ATTENTION'),
        t('STR_ALERT_LOGOUT_CONFIRM'),
        [
          {
            text: t('STR_CANCEL'),
            style: "cancel"
          },
          { text: t('STR_YES'), onPress: () => logoutProcess() }
        ]
      );
    }
  }

  const handleGoHome = () => {
    globalVars.cardLoadCancel = true;
    props.navigation.navigate('Template');
  }
  const returnBack = () => {
    // props.navigation.goBack(); 
    // if (props.navigation.canGoBack()) {
    props.navigation.dispatch(CommonActions.goBack());
  }
  const enableRecordPrintSelection = () => {
    store.dispatch(setRecordSelectedId(undefined))
    store.dispatch(setRecordsToPrintList([]));
  }
  const goTo = (screenName) => {
    props.navigation.navigate(screenName);
  }

  // To fix react native paper bug 
  if (Platform.OS === 'web') {
    window.addEventListener('mousedown', function (event) {
      let clickedElement = event.target;
      if (clickedElement.getAttribute('aria-label') === 'Close menu') {
        closeMenu();
      }
    });
  }

  return (
    <Stack.Navigator detachInactiveScreens={true} initialRouteName="SplashScreen">
      <Stack.Screen
        name="SplashScreen"
        component={SplashScreen}
        // Hiding header for Splash Screen
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Auth"
        component={Auth}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Template"
        component={TemplateScreen} options={{
          title: t('STR_TITLE_TEMPLATES'),
          headerStyle: {
            backgroundColor: '#05AC72'
          },
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontWeight: 'bold'
          },
          previous_screen: 'Splash',
          gestureEnabled: false,
          header: () => (
            <Appbar.Header style={{ marginTop: 0, backgroundColor: '#05AC72', zIndex: 9, }}>
              <Appbar.Content title={t('STR_TITLE_TEMPLATES')} titleStyle={{ fontSize: 20, fontWeight: 'bold', color: '#FFF' }} />
              <Menu
                visible={visible}
                onDismiss={Platform.OS !== 'web' ? closeMenu : null}
                theme={{ roundness: 0 }}
                anchor={
                  <Appbar.Action icon={visible ? "close" : "menu"} color="white" onPress={visible ? closeMenu : openMenu} />
                }
                style={{
                  paddingTop: (48 - (Number(configs.CustomStyles.developmentMenuPadding) === 0 && Platform.OS !== 'web' ? 25 : 0)),
                  // position: 'relative'
                  borderRadius: 0
                }}>
                {/* {__DEV__ &&
                  <Menu.Item onPress={() => goTo('CardRenderDebug') & setVisible(false)}
                    style={{ marginTop: 10 }}
                    title={(<MenuItem color='#05AC72' iconColor="#FFD700" icon="cloud-print-outline" text={"CARD RENDER"}></MenuItem>)}
                  />
                } */}
                <Menu.Item onPress={() => goTo('PrintersList') & setVisible(false)}
                  style={Platform.OS === 'ios' ? {
                    width: 250,
                    flex: 1
                  } : {}}
                  title={(
                    <View style={{
                      width: 200,
                      height: 25,
                      // flex: 1, // TODO CHECK ON ANDROID 
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}>
                      <IconButton
                        icon="cloud-print-outline"
                        iconColor={theme.colors.primary}
                        size={28}
                      />
                      <Text
                        style={{
                          color: '#05AC72',
                          fontWeight: 'bold'
                        }}
                      >{t('STR_MENU_PRINTERS')}</Text>
                    </View>)}
                />
                <Menu.Item onPress={() => goTo('PrintJobsPending') & setVisible(false)}
                  style={Platform.OS === 'ios' ? { width: 250, flex: 1 } : {}}
                  title={(
                    <View style={{
                      width: 200, height: 25,
                      // flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'flex-start'
                    }}>
                      <IconButton
                        icon="printer-3d-nozzle-outline"
                        iconColor={theme.colors.primary}
                        size={28}
                      />
                      <Text
                        style={{
                          color: '#05AC72',
                          fontWeight: 'bold'
                        }}
                      >{t('STR_MENU_PRINT_JOBS')}</Text>
                    </View>)}
                />
                {globalVars.isMasterDevice === true &&
                  <Menu.Item onPress={() => goTo('DevicesList') & setVisible(false)}
                    style={Platform.OS === 'ios' ? { width: 250, flex: 1 } : {}}
                    title={(
                      <View style={{
                        width: 200, height: 25,
                        // flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                      }}>
                        <IconButton
                          icon="cellphone-link"
                          iconColor={theme.colors.primary}
                          size={28}
                        />
                        <Text
                          style={{
                            color: '#05AC72',
                            fontWeight: 'bold'
                          }}
                        >{t('STR_MENU_DEVICES_LIST')}</Text>
                      </View>)}
                  />
                }
                <Menu.Item onPress={() => goTo('LanguageSelectModal') & setVisible(false)}
                  style={Platform.OS === 'ios' ? { width: 250, flex: 1 } : {}}
                  title={(
                    <View style={{
                      width: 200, height: 25,
                      // flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'flex-start'
                    }}>
                      <IconButton
                        icon="translate"
                        iconColor={theme.colors.primary}
                        size={28}
                      />
                      <Text
                        style={{
                          color: '#05AC72',
                          fontWeight: 'bold'
                        }}
                      >{t('STR_MENU_LANGUAGES')}</Text>
                    </View>)}
                />
                <Menu.Item onPress={() => goTo('AboutApp') & setVisible(false)}
                  style={Platform.OS === 'ios' ? { width: 250, flex: 1 } : {}}
                  title={(
                    <View style={{
                      width: 200, height: 25,
                      // flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'flex-start'
                    }}>
                      <IconButton
                        icon="copyright"
                        iconColor={theme.colors.primary}
                        size={28}
                      />
                      <Text
                        style={{
                          color: '#05AC72',
                          fontWeight: 'bold'
                        }}
                      >{t('STR_MENU_ABOUT_APP')}</Text>
                    </View>)}
                />
                <Menu.Item onPress={() => handleLogout() & setVisible(false)}
                  style={Platform.OS === 'ios' ? { width: 250, flex: 1 } : {}}
                  title={(
                    <View style={{
                      width: 200, height: 25,
                      // flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'flex-start'
                    }}>
                      <IconButton
                        icon="logout-variant"
                        iconColor={theme.colors.primary}
                        size={28}
                      />
                      <Text
                        style={{
                          color: '#05AC72',
                          fontWeight: 'bold'
                        }}
                      >{t('STR_MENU_LOGOUT')}</Text>
                    </View>)}
                />
              </Menu>
            </Appbar.Header>
          )
        }} />
      <Stack.Screen name="Records" component={RecordScreen} options={{
        title: t('STR_TITLE_RECORDS'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={returnBack}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        ),
        headerRight: () => (
          <View>
            {recordsToPrintCounter === undefined &&
              <TouchableOpacity
                onPress={enableRecordPrintSelection}
              >
                <MaterialCommunityIcons name="printer-check" style={{ marginRight: 10 }} size={40} color="#fff" />
              </TouchableOpacity>
            }
            {recordsToPrintCounter !== undefined &&
              <Animated.View entering={FadeIn.duration(1000)}  >
                <Badge style={{
                  color: theme.colors.black,
                  backgroundColor: theme.colors.white,
                  height: 40,
                  width: 40,
                  fontWeight: 'bold',
                  fontSize: 14,
                  paddingRight: 5,
                  marginRight: 5,
                  ...Platform.select({
                    ios: {
                      lineHeight: 40,
                      borderRadius: 15
                    },
                    android: {
                      borderRadius: 50
                    },
                    default: {
                      lineHeight: 40,
                      borderRadius: 50
                    }
                  })
                }}>{recordsToPrintCounter}</Badge>
              </Animated.View>
            }
          </View>
        ),
      }} />
      <Stack.Screen name="EditRecord" component={EditRecordScreen} options={{
        title: t('STR_TITLE_EDIT_RECORD'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={returnBack}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="NewRecord" component={EditRecordScreen} options={{
        title: t('STR_TITLE_NEW_RECORD'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => (
                globalVars.noDb ? handleGoHome() : returnBack()
              )}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="PrintJobsPending" component={PrintJobsPending} options={{
        title: t('STR_TITLE_PENDING_PRINT_JOBS'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => returnBack()}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        ),
        headerRight: () => (
          <View>
            <TouchableOpacity
              onPress={() => goTo('PrintJobsHistory')}
            >
              <MaterialCommunityIcons name="history" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="PrintJobsHistory" component={PrintJobsHistory} options={{
        title: t('STR_TITLE_PRINT_JOBS_HISTORY'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => handleGoHome()}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="PrintRecordsSummary" component={PrintSummaryList} options={{
        title: t('STR_TITLE_PRINT_SUMMARY_LIST'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },

        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              // onPress={() => goTo('Records')}
              onPress={() => (globalVars.noDb ? props.navigation.navigate('NewRecord') : returnBack())}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        ),
        headerRight: () => (
          <View>
            {recordsToPrintCounter !== undefined &&
              <Animated.View entering={FadeIn.duration(1000)}  >
                <Badge style={{
                  color: theme.colors.black,
                  backgroundColor: theme.colors.white,
                  height: 40,
                  width: 40,
                  fontWeight: 'bold',
                  fontSize: 14,
                  paddingRight: 5,
                  marginRight: 5,
                  ...Platform.select({
                    ios: {
                      lineHeight: 40,
                      borderRadius: 15
                    },
                    android: {
                      borderRadius: 50
                    },
                    default: {
                      lineHeight: 40,
                      borderRadius: 50
                    }
                  })
                }}>{recordsToPrintCounter}</Badge>
              </Animated.View>
            }
          </View>
        )
      }} />
      <Stack.Screen name="PrintChooseList" component={PrintChooseList} options={{
        title: t('STR_TITLE_PRINT_CHOOSE_LIST'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => {
                // UNCOMMENT FOR PRINT COUNTER TEST WITHOUT ACTUAL PRINT 
                store.dispatch(setPrintCounter())
                returnBack()
              }
              }
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        ),
      }} />
      <Stack.Screen name="PrintersList" component={PrintersListScreen} options={{
        title: t('STR_TITLE_PRINTERS'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => returnBack()}
            >

              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        ),
        headerRight: () => {
          if (Platform.OS !== 'web') {
            return (
              <View>
                <TouchableOpacity
                  onPress={() => {
                    //if (Platform.OS !== "web"){
                    goTo('AddLocalPrinter');
                  }}
                >
                  {/* plus-circle-outline plus-circle plus-box plus-box-outline  */}
                  <MaterialCommunityIcons name="plus" size={40} color="#fff" />
                </TouchableOpacity>
              </View>
            )
          }
        }
        // )
        // }
        ,
      }} />
      <Stack.Screen name="PrinterDetail" component={PrintersDetailScreen} options={{
        title: t('STR_TITLE_PRINTER'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => returnBack()}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="AddLocalPrinter" component={AddLocalPrinter} options={{
        title: t('STR_TITLE_ADD_LOCAL_PRINTER'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => returnBack()}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="DevicesList" component={DevicesList} options={{
        title: t('STR_TITLE_DEVICES_LIST'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => returnBack()}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="CardRender" component={CardRender} options={{
        title: "CARD SCREEN",
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => returnBack()}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="CardRenderDebug" component={CardRenderDebug} options={{
        title: "CARD SCREEN",
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => returnBack()}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="AboutApp" component={AboutApp} options={{
        title: "About App",
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={() => returnBack()}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        )
      }} />
      <Stack.Screen name="LanguageSelectModal" component={SelectLanguageModalScreen} options={{
        title: t('STR_TITLE_SELECT_LANGUAGE'),
        headerStyle: {
          backgroundColor: '#05AC72',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign: 'center',
        headerLeft: () => (
          <View>
            <TouchableOpacity
              onPress={returnBack}
            >
              <MaterialCommunityIcons name="arrow-left-thin" size={40} color="#fff" />
            </TouchableOpacity>
          </View>
        ),
      }} />
    </Stack.Navigator>

  );
};

export default connect(null, { cleanLoginInfo })(DrawerNavigatorRoutes);
