
import React, { useState, useEffect } from 'react';
import { ptToPx, ptToPxStroke, mmToPx, attributeSet } from '../../../../helpers/cardRender/utils';
import { Skia, ImageSVG } from "@shopify/react-native-skia";
import base64 from 'react-native-base64'
import { XMLParser } from 'fast-xml-parser';

import { svg as svgPoligon12 } from '../resources/poligons/bPolygon12';
import { svg as svgPoligon10 } from '../resources/poligons/bPolygon10';
import { svg as svgPoligon8 } from '../resources/poligons/bPolygon8';
import { svg as svgPoligon7 } from '../resources/poligons/bPolygon7';
import { svg as svgPoligon6 } from '../resources/poligons/bPolygon6';
import { svg as svgPoligon5 } from '../resources/poligons/bPolygon5';

export type props = {
    id: number,
    xmlObj: any,
    svgData: string | null
};

const ShapeItem: React.FC<props> = ({
    id,
    xmlObj,
    svgData
}) => {

    const startX: number = ptToPx(xmlObj.position.pos1.x);
    const startY: number = ptToPx(xmlObj.position.pos1.y);
    const endX: number = ptToPx(xmlObj.position.pos2.x);
    const endY: number = ptToPx(xmlObj.position.pos2.y);
    const imageWidth: number = endX - startX;
    const imageHeight: number = endY - startY;
    let bkgColor: string = "transparent";
    let shapeFillColor: string = "#000";
    let shapeStrokeColor: string = "#000";
    let svg: any;
    let lineStyle: string | undefined;

    if (xmlObj.fill?.type[1]?.solid?.color[0]) {
        bkgColor = xmlObj.fill?.type[1]?.solid?.color[0];
    }

    if (xmlObj.clipart?.color) {
        shapeFillColor = xmlObj.clipart.color;
    }

    if (xmlObj.clipart?.line?.color) {
        shapeStrokeColor = xmlObj.clipart.line.color;
    }

    // Deprecated - Passed to PolygonItem 
    if (xmlObj.clipping?.shape[0] !== undefined && xmlObj.clipping.shape[0] === 'polygon') {
    
        let svgPoligonData: string = '';
        const poligonCorners = xmlObj.clipping.shape[1].polygon.corners;

        switch (poligonCorners) {
            case 12:
                svgPoligonData = base64.encode(svgPoligon12);
                break;
            case 10:
                svgPoligonData = base64.encode(svgPoligon10);
                break;
            case 8:
                svgPoligonData = base64.encode(svgPoligon8);
                break;
            case 7:
                svgPoligonData = base64.encode(svgPoligon7);
                break;
            case 6:
                svgPoligonData = base64.encode(svgPoligon6);
                break;
            case 5:
                svgPoligonData = base64.encode(svgPoligon5);
                break;
            default:
                break;
        }

        svgData = svgPoligonData;
        if (xmlObj.fill?.type[1]?.solid?.color) {
            shapeFillColor = xmlObj.fill.type[1].solid.color;
        }
        if (xmlObj.line?.color) {
            shapeStrokeColor = xmlObj.line.color;
        }
    }

    if (svgData !== null) {

        let svgItemData = base64.decode(svgData);
        let svgToRep = svgItemData;
        const parser = new XMLParser();
        let svgObj = parser.parse(svgToRep);

        // Apply width and height e allow to transform svg
        svgToRep = attributeSet("svg", "width", imageWidth.toString(), svgToRep);
        svgToRep = attributeSet("svg", "height", imageHeight.toString(), svgToRep);
        svgToRep = attributeSet("svg", "preserveAspectRatio", "none", svgToRep);

        // Apply stroke width if exists
        if (xmlObj.clipart?.line?.width) {
            svgToRep = attributeSet("svg", "stroke-width", ptToPxStroke(xmlObj.clipart.line.width).toString(), svgToRep);
        }

        if (xmlObj.clipping?.shape[0] !== undefined
            && xmlObj.clipping.shape[0] === 'polygon'
            && xmlObj.line?.width) {
            svgToRep = attributeSet("svg", "stroke-width", ptToPxStroke(xmlObj.line.width).toString(), svgToRep);
        }

        if (xmlObj.clipping?.shape[0] !== undefined
            && xmlObj.clipping.shape[0] === 'polygon'
            && xmlObj.line?.style) {
            lineStyle = xmlObj.line.style.toString()
        }

        if (xmlObj.clipart?.line?.style) {
            lineStyle =  xmlObj.clipart.line.style.toString();
        } 

        // Apply line style if exists
        if (lineStyle) {
            const strokeLineStyle: string = lineStyle;
            switch (strokeLineStyle) {
                case "SolidLine":
                    // Do nothing (default)
                    break;
                case "DashDotDotLine": // OK
                    svgToRep = attributeSet("svg", "stroke-dasharray", "12,7,4,6,4,6", svgToRep);
                    break;
                case "DotLine": // OK
                    svgToRep = attributeSet("svg", "stroke-dasharray", "5,6", svgToRep);
                    break;

                case "DashLine": // OK 
                    svgToRep = attributeSet("svg", "stroke-dasharray", "12,7.5", svgToRep);
                    break;
                case "DashDotLine": // OK 
                    svgToRep = attributeSet("svg", "stroke-dasharray", "12,7,5,6", svgToRep);
                    break;
            }
        }

        let childItem = (Object.keys(svgObj.svg)[0]);
        svgToRep = attributeSet(childItem, "fill", shapeFillColor, svgToRep);
        svgToRep = attributeSet(childItem, "stroke", shapeStrokeColor, svgToRep);
        // console.log(svgToRep);
        svg = Skia.SVG.MakeFromString(svgToRep);

    } else {

        svg = Skia.SVG.MakeFromString(
            `<svg viewBox='0 0 0 0' xmlns='http://www.w3.org/2000/svg'>             
                </svg>`
        );

    }

    return (
        <ImageSVG
            svg={svg}
            x={startX}
            y={startY}
            width={imageWidth}
            height={imageHeight}
            color={bkgColor}
            blendMode="srcATop"
        />
    );
};

export default ShapeItem;