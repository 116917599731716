
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Text, View, Dimensions, Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPrinterImageUrl, getPrinterStateDescriptionFromCode, getPrinterStateCodeFromHex } from '../../helpers/utils';
import globalVars from '../../helpers/store';

export type props = {
    jobId: string,
    printerSn: string,
    printJobRecordThumbnail: string,
    recordId: number,
    recordState: number,
    recordStateInfo: string,
    printersList: {
        serial_number: string,
        distributor_id: number,
        subdistributor_id: number,
        manufacturer: string,
        model: string,
        info: string,
        last_status: string,
        last_license: string,
        last_card_count: number,
        last_ribbon_remaining: number,
        create_date: number,
        update_date: number,
        printer_name: string,
        printerAgentOnline: Boolean
    }[]
};
interface printJobState {
    hexCode: string;
    code: string;
    description: string;
    label: string;
}

const PrintJobsCard: React.FC<props> = ({
    jobId,
    printerSn,
    printJobRecordThumbnail,
    recordId,
    recordState,
    recordStateInfo,
    printersList
}) => {
    const { t, i18n } = useTranslation();
    const [printerImage, setPrinterImage] = useState(undefined);
    const [recordStateItem, setRecordStateItem] = useState<string>('');
    const [recordStateAdditionalInfo, setRecordStateAdditionalInfo] = useState<string>('');
    let globalVarsObj: any = globalVars;

    let printerData = printersList.filter((printersList) =>
        printersList.serial_number === printerSn
    );

    useEffect(
        () => {
            (async () => {   
                const printJobState: printJobState[] = getPrinterStateDescriptionFromCode(recordState);
                if (printJobState[0] !== undefined) {
                    setRecordStateItem(t(printJobState[0].label));
                    setRecordStateAdditionalInfo(recordStateInfo);
                }
            })();
        },
        []
    )

    if (globalVarsObj.socket !== undefined) {
        globalVarsObj.socket.on('printJobRecordStateChanged', function (data: any) {
            if (data.recordId === recordId && data.jobID === jobId) {

                const printJobState: printJobState[] = getPrinterStateDescriptionFromCode(data.state);
                setRecordStateItem(t(printJobState[0].label));
                setRecordStateAdditionalInfo(data.info);
            }
        });
    }

    (async () => {
        const printerImageURL = await getPrinterImageUrl(printerData[0].manufacturer, printerData[0].model);
        setPrinterImage(printerImageURL);
    })();

    return (
        <View style={styles.container}>

            <View>
                <Text style={styles.jobIdHolder}>{jobId}</Text>
                <Text style={styles.recordIdHolder}>{t('STR_LABEL_RECORD_ID') + ': ' + recordId}</Text>
                {recordStateItem !== '' &&
                    <Text style={styles.recordIdHolder}>{t('STR_LABEL_RECORD_STATE') + ': ' + recordStateItem}</Text>
                }
                {recordStateAdditionalInfo !== '' &&
                    <Text style={styles.recordIdHolder}>{t('STR_LABEL_RECORD_STATE_INFO') + ': ' + recordStateAdditionalInfo}</Text>
                }
            </View>

            <View style={styles.itemsHolder}>
                <View style={styles.itemCol}>
                    <View style={styles.printerImageHolder}>
                        {printerImage !== undefined && <Image style={[styles.printerImageStyle]} source={{ uri: printerImage }} resizeMode='cover' />}
                    </View>
                </View>
                <View style={styles.itemCol}>
                    <Text style={styles.recordIdHolder}>{printerData[0].printer_name}</Text>
                    <Text style={styles.recordIdHolder}>{printerData[0].manufacturer} {printerData[0].model}</Text>
                </View>
            </View> 
            {/* <View style={styles.imageThumbHolder}>
                <Image style={styles.imageStyle} source={{ uri: (printJobRecordThumbnail !== '' ) ? printJobRecordThumbnail : undefined }} resizeMode={ (Platform.OS === 'web' ? 'center' : 'contain') } />
            </View> */} 
        </View >
    );
}; 

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    container: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 25,
            },
            android: {
                width: deviceWidth - 25,
            },
            default: {
                width: 450,
            }
        }),
        backgroundColor: '#CFCFCF',
        minHeight: 150,
        borderRadius: radius,
        borderWidth: 0,
        marginTop: 15,
        paddingHorizontal: 10,
        paddingVertical: 10
    },
    imageThumbHolder: {
      width: '100%',
      borderRadius: radius,
    },
    imageStyle: {
        ...Platform.select({
            ios: {
                width: '100%',
            },
            android: {
                width: '100%',
            },
            default: {
                width: '100%',
            }
        }),
        height: 160,
        marginTop: 0,
        marginHorizontal: 0,           
    },
    jobIdHolder: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#05AC72',
    },
    recordIdHolder: {
        fontWeight: 'bold',
    },
    printerImageStyle: {
        width: 100,
        height: 100
    },
    printerImageHolder: {
        width: 100,
        height: 100
    },
    itemsHolder: {
        paddingTop: 20,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    itemCol: {
        width: '50%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
});

const mapStateToProps = (
    state: {
        printersList: {
            serial_number: string,
            distributor_id: number,
            subdistributor_id: number,
            manufacturer: string,
            model: string,
            info: string,
            last_status: string,
            last_license: string,
            last_card_count: number,
            last_ribbon_remaining: number,
            create_date: number,
            update_date: number,
            printer_name: string,
            printerAgentOnline: Boolean
        }[];
    }
) => {
    return {
        printersList: state.printersList
    }
}

export default connect(mapStateToProps, {})(PrintJobsCard);
