import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ActivityIndicator,
  View,
  StyleSheet,
  Platform
} from 'react-native';
import { store, setLoginInfo } from '../../utils/Reducer';
import { signIn } from '../Rest';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';
import { useTranslation } from 'react-i18next';
import globalVars from '../../helpers/store';
import * as Device from 'expo-device';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { deviceIsOnline } from '../../helpers/utils';

const SplashScreen = (props) => {

  const { authToken, deviceID } = props
  const [animating, setAnimating] = useState(true);
  const { t, i18n } = useTranslation();
  const navigation = useNavigation();

  // Process language saved ou readed from device language
  useEffect(() => {
    (async () => {
      const savedLanguageValue = await AsyncStorage.getItem('@userLanguage');
      if (savedLanguageValue !== null) {
        i18n.changeLanguage(savedLanguageValue);
      } else {
        i18n.changeLanguage(Localization.locale);
      }
    })();
    return () => {

    }
  }, []);

  // Process login
  useEffect(() => {
    (async () => {

      if (deviceID !== undefined) {

        if (authToken === undefined) {
          const userLogged = await AsyncStorage.getItem('@userLogged')

          if (userLogged === 'OK') {

            const deviceInfo = {
              platform: Platform.OS,
              deviceBrand: Device.brand,
              deviceManufacturer: Device.manufacturer,
              deviceOsBuildId: Device.osBuildId,
              deviceOsName: Device.osName,
              deviceOsVersion: Device.osVersion,
              deviceSupportedCpuArchitectures: Device.supportedCpuArchitectures,
              deviceTotalMemory: Device.totalMemory,
              browserInfo: ((Platform.OS === 'web') ? window.navigator.userAgent : null)
            }

            const savedEmail = await AsyncStorage.getItem('@email')
            const savedPassword = await AsyncStorage.getItem('@password')
            globalVars.Sentry.setUser({ email: savedEmail });

            const deviceIsOnlineResult = await deviceIsOnline();
            if (deviceIsOnlineResult) {

              let valuesToPost = {};
              valuesToPost.deviceInfo = JSON.stringify(deviceInfo);
              valuesToPost.email = savedEmail;
              valuesToPost.password = savedPassword;

              const signInResult = await signIn(valuesToPost);
              if (signInResult.status == 200) {
                globalVars.isMasterDevice = signInResult.data.masterDevice;
                store.dispatch(setLoginInfo(signInResult.data));
                // Set navigation stack to Template Screen
                const resetAction = CommonActions.reset({
                  index: 0,
                  routes: [{ name: 'Template' }],
                });
                // Dispatch the reset action to the navigation stack
                navigation.dispatch(resetAction);
                props.navigation.navigate('Template');
              } else {
                await AsyncStorage.removeItem('@userLogged');
                await AsyncStorage.removeItem('@email');
                await AsyncStorage.removeItem('@password');
                props.navigation.navigate('Auth');
              }

            } else { // IF device is offline

              globalVars.isMasterDevice = false;
              const resetAction = CommonActions.reset({
                index: 0,
                routes: [{ name: 'Template' }],
              });
              // Dispatch the reset action to the navigation stack
              navigation.dispatch(resetAction);
              props.navigation.navigate('Template');
            }

          } else {
            setTimeout(() => {
              if (userLogged !== 'OK') {
                props.navigation.navigate('Auth');
              }
            }, 500);
            props.navigation.navigate('Auth');
          }

        } else {
          setTimeout(() => {
            // Set navigation stack to Template Screen
            const resetAction = CommonActions.reset({
              index: 0,
              routes: [{ name: 'Template' }],
            });
            // Dispatch the reset action to the navigation stack
            navigation.dispatch(resetAction);
            props.navigation.navigate('Template');
          }, 100);
        }

      }

    })();
  }, [authToken, deviceID]);

  return (
    <View style={styles.container}>
      <ActivityIndicator
        animating={animating}
        color="#FFFFFF"
        size="large"
        style={styles.activityIndicator}
      />
    </View>
  );
};

const mapStateToProps = state => {
  return {
    authToken: state.authToken,
    deviceID: state.deviceID
  }
}

export default connect(mapStateToProps, {})(SplashScreen)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#05AC72',
  },
  activityIndicator: {
    alignItems: 'center',
    height: 80,
  },
});