
import React, { useState, useEffect } from 'react';
import { ptToPx } from '../../../../helpers/cardRender/utils';
import { Oval } from "@shopify/react-native-skia";

export type props = {
    id: number,
    xmlObj: any,
};

const CircleItem: React.FC<props> = ({
    id,
    xmlObj,
}) => {

    const startX: number = ptToPx(xmlObj.position.pos1.x);
    const startY: number = ptToPx(xmlObj.position.pos1.y);
    const endX: number = ptToPx(xmlObj.position.pos2.x);
    const endY: number = ptToPx(xmlObj.position.pos2.y);
    const imageWidth: number = endX - startX;
    const imageHeight: number = endY - startY;
    let bkgColor: string = "transparent";

    if (xmlObj.fill?.type[1]?.solid?.color[0]) {
        bkgColor = xmlObj.fill?.type[1]?.solid?.color[0];
    }
    
    return (
        <Oval
            x={startX}
            y={startY}
            width={imageWidth}
            height={imageHeight}
            color={bkgColor}
            blendMode="srcATop"
        />
    );
};

export default CircleItem;