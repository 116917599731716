import React, { useEffect, useState, useCallback } from 'react';
import { Platform, StyleSheet, View, ScrollView, Alert } from 'react-native';
import Animated, { BounceInDown } from 'react-native-reanimated';
import { connect } from 'react-redux';
import DeviceCard from '../../components/devices/DeviceCard';
import Loader from '../../components/Loader';
import { getDevices } from '../../Rest';
import { useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { useIsFocused } from "@react-navigation/native";
import { removeDevice } from '../../Rest'
import globalVars from '../../../helpers/store';
interface Item {
    id: number,
    deviceId: string,
    createDate: string,
    deviceInfo: object,
    deviceMaster: boolean,
    deviceActive: boolean,
}

export type props = {
};

const PrintSummaryList: React.FC<props> = ({
}) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState<Item[]>([]);

    const route = useRoute(); 
    const navigation = useNavigation();

    const isFocused = useIsFocused();

    const addNewItem = useCallback((itemToPush: Item) => {
        setDevices(devices => {
            const nextItemId = (devices[devices.length - 1]?.id ?? 0) + 1;
            return [...devices,
            {
                id: nextItemId,
                deviceId: itemToPush.deviceId,
                createDate: itemToPush.createDate,
                deviceInfo: itemToPush.deviceInfo,
                deviceMaster: itemToPush.deviceMaster,
                deviceActive: itemToPush.deviceActive
            }];
        });
    }, []);

    globalVars.socket.on("deviceDisconnected", (deviceID: string) => {
        updateItemOnlineState(deviceID, false);
    });
    globalVars.socket.on("newDeviceConnected", (deviceID: string) => {
        updateItemOnlineState(deviceID, true);
    });

    useEffect(
        () => {
            (async () => {

                if (isFocused) {
                    setLoading(true)
                    const deviceListReturn = await getDevices();

                    if (deviceListReturn.devices !== undefined) {
                        const deviceList = deviceListReturn.devices;
                        for (let i = 0; i < deviceList.length; i++) {
                            const device = deviceList[i];
                            globalVars?.socket?.emit("deviceIsActive", device.device_id, (response: boolean) => {
                                let deviceInfo = {};
                                try {
                                    deviceInfo = JSON.parse(device.device_info);
                                } catch (e) {
                                    // Is not json string 
                                }
                                let dataToPush: {
                                    id: number,
                                    deviceId: string,
                                    createDate: string,
                                    deviceInfo: object,
                                    deviceMaster: boolean,
                                    deviceActive: boolean
                                } = {
                                    id: 0,
                                    deviceId: device.device_id,
                                    createDate: device.create_date,
                                    deviceInfo: deviceInfo,
                                    deviceMaster: (device.master_device === 1),
                                    deviceActive: response,
                                }
                                addNewItem(dataToPush);
                            })
                        }
                    }
                    setLoading(false)
                }
            })();
        },
        [isFocused]
    )

    const alertBox = (title: string, body: string) => {
        if (Platform.OS === 'web') {
            alert(title + '\n' + body);
        } else {
            Alert.alert(
                title,
                body
            );
        }
        return;
    }

    const updateItemOnlineState = (deviceID: string, isOnline: boolean) => {
        setDevices(devices =>
            devices.map(obj => {
                if (obj.deviceId === deviceID) {
                    return { ...obj, deviceActive: isOnline };
                }
                return obj;
            }),
        );
    };

    const removeItem = (deviceID: string) => {
        let index = undefined;
        devices.find((o, i) => {
            if (o.deviceId === deviceID) {
                index = i;
                return true;
            }
        });
        if (index !== undefined) {
            setDevices([
                ...devices.slice(0, index),
                ...devices.slice(index + 1)
            ]);
        }
    }

    const removeDeviceBtn = (deviceID: string) => {
        (async () => {
            const removeDeviceResult = await removeDevice(deviceID);
            if (removeDeviceResult.status === '200') {
                globalVars.socket.emit('deviceKill', deviceID);
                removeItem(deviceID);
            } else {
                alertBox("Error", "Error on remove device");
            }
        })();
    }

    return (
        <View style={styles.container}>
            <Loader loading={loading} />
            <ScrollView style={{ width: '100%' }}>
                <View style={styles.CardContainer}>
                    {devices.map((device, index) => (
                        <Animated.View key={index} entering={BounceInDown.duration(1000)} style={styles.CardHolder} >
                            <DeviceCard
                                deviceId={device.deviceId}
                                createDate={device.createDate}
                                deviceInfo={device.deviceInfo}
                                deviceMaster={device.deviceMaster}
                                deviceIsOnline={device.deviceActive}
                                removeOper={removeDeviceBtn}
                            />
                        </Animated.View>
                    ))}
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        paddingBottom: 10,
    },
    CardContainer: {
        ...Platform.select({
            ios: {
                alignItems: "center",
                justifyContent: "center",
            },
            android: {
                alignItems: "center",
                justifyContent: "center",
            },
            default: {
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: 'wrap',
            }
        })
    },
    CardHolder: {
        ...Platform.select({
            ios: {
                width: '100%',
                alignItems: "center",
                justifyContent: "center",
            },
            android: {
                width: '100%',
                alignItems: "center",
                justifyContent: "center",
            },
            default: {
                // width: 250,
                // marginHorizontal: 10
                maxWidth: '100%'
            }
        })
    },

});

const mapStateToProps = (
    state: {
        googleAccessToken: string;
        templateProperties: object;
        recordsToPrintList: string[];
        recordsToPrintListData: {
            recordId: number;
            info: string;
        }[];
        templateSelectedId: number;
    }
) => {
    return {
        googleAccessToken: state.googleAccessToken,
        templateProperties: state.templateProperties,
        recordsToPrintList: state.recordsToPrintList,
        recordsToPrintListData: state.recordsToPrintListData,
        templateSelectedId: state.templateSelectedId,
    }
}

export default connect(mapStateToProps, {})(PrintSummaryList);
//export default PrintSummaryList;