import React from "react";
import { Platform, View, Image, TouchableOpacity, StyleSheet } from 'react-native'
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from 'react-native-vector-icons/Ionicons';
import { FAB } from 'react-native-paper';

const ImageComponent = (props) => {
    return (
        <View style={{
            justifyContent: 'center',
            width: 150,
        }}>

            {(props.value === '' || props.value === '<image>') && <Image source={require('../../assets/takePhoto.png')}
                style={{
                    width: 150,
                    aspectRatio: 3 / 4,
                    // height: 100,
                    alignSelf: 'center'
                }}
                resizeMode="contain"
            />}
            {props.value !== '' && props.value !== '<image>' && <Image style={{
                alignSelf: 'center',
                width: 150,
                aspectRatio: 3 / 4,
                // height: 100,
                borderRadius: 10,
            }}
                source={{ uri: props.value }}
                resizeMode="cover"
            />}
            {Platform.OS !== 'web' &&
                <View style={styles.fabHolder}>
                    <FAB
                        theme={{ roundness: 10 }}
                        style={styles.fab}
                        color="#FFF"
                        icon="camera"
                        // size="small"
                        onPress={() => props.onPress('camera')}
                    />
                    <FAB
                        theme={{ roundness: 10 }}
                        style={styles.fab}
                        color="#FFF"
                        icon="folder-image"
                        // size="small"
                        onPress={() => props.onPress('gallery')}
                    />
                </View>
            }
        </View>
    )
}
const styles = StyleSheet.create({
    fabHolder: {
        // marginTop: 20,
        // marginBottom: 20,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fab: {
        backgroundColor: "#05AC72",
        display: 'flex',
        marginHorizontal: 5,
        width: 55,
        height: 55
    }
})
export default ImageComponent;