import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Platform, AppState, StyleSheet, Text, View, ScrollView, Dimensions, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { useRoute, useIsFocused } from '@react-navigation/native';
import { Button, useTheme, Dialog, Portal, Checkbox } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { getTemplateBytes, unzipTemplateBytes, ptToPx, calculateImageSize } from '../../../helpers/cardRender/utils';
// import globalVars from '../../../helpers/store';
// import { convertGoogleDriveImageToBase64 } from '../../Rest'
// import { Canvas, Group, useCanvasRef, Skia, ImageFormat } from "@shopify/react-native-skia";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
    store,
    removeRecordToPrintList,
    removeRecordToPrintListData,
    setRenderedCardsData,
    // addRenderedCardsData,
    // removeRenderedCardsData,
    // setRedenderIsProcessing,
    setTemplateRenderInfo,
    setTemplateRenderRecords,
    setRenderedRecordsSaved,
    setRenderedRecordsAlertText,
    // setForceImageBase64StateFillRefresh
} from '../../../utils/Reducer';

import HeadlessRender from '../global/HeadlessRender';
import renderDB from '../../../helpers/db/models/render'
import templatesDB from '../../../helpers/db/models/template'
import * as FileSystem from 'expo-file-system';
import globalVars from '../../../helpers/store';
import CardPreviewer from '../../components/cards/CardPreviewer'

export type props = {
    googleAccessToken: string
    templateProperties: any
    recordsToPrintList: string[]
    recordsToPrintListData: {
        recordId: number;
        info: string;
    }[];
    templateSelectedId: number,
    renderedCardsData: any,
    renderedRecordsSaved: boolean,
    renderRecordsText: string    
};

interface renderedRecordItem {
    id: number,
    renderedData: string | null, // Data on base64 format
    backRenderedData: string | null, // Data on base64 format
    showBackImage: boolean
}
interface recordItem {
    id: number,
    recordId: number,
    cardItems: Item[],
    cardWidth: number,
    cardHeight: number
    renderedData: string | null // Data on base64 format
}
interface Item {
    id: number,
    itemType: string,
    xmlObj: any,
    imageBase64Data: string | null,
    customText: string | null
}

const PrintSummaryList: React.FC<props> = ({
    googleAccessToken,
    templateProperties,
    recordsToPrintList,
    recordsToPrintListData,
    templateSelectedId,
    renderedCardsData,
    renderedRecordsSaved,
    renderRecordsText    
}) => {

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState<string>('');
    const [headLessRenderKey, setHeadLessRenderKey] = useState<number>(new Date().getTime());
    const [headLessRenderState, setHeadLessRenderState] = useState<[]>([]);
    const [itemsStateLoaded, setItemsStateLoaded] = useState<boolean>(false);

    const isFocused = useIsFocused();
    const route = useRoute();
    const navigation = useNavigation();

    const [renderedCardHeightState, setRenderedCardHeightState] = useState<number>(250);
    const [renderedImages, setRenderedImages] = useState<renderedRecordItem[]>([]);
    const [cardSizesModalvisible, setCardSizesModalvisible] = useState(false);
    const [cardHaveFrontBack, setCardHaveFrontBack] = useState(false);
    const [printFrontOption, setPrintFrontOption] = useState(true);
    const [printBackOption, setPrintBackOption] = useState(true);

    const cardImageWidth = Math.round(Dimensions.get('window').width) - 20;
    const [renderedImagesHeight, setRenderedImagesHeight] = useState<number>(0);
    const [portraitCardMargin, setPortraitCardMargin] = useState<number>(0);
    const templateID = templateSelectedId;
    let renderIsFinished = false;

    function sleep(ms: number) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    }

    const goToPrinterChooseWithSides = () => {

        setCardSizesModalvisible(false);
        if (printFrontOption === true && printBackOption === true) {
            goToPrinterChoose("FRONTBACK")
        } else if (printFrontOption === true && printBackOption === false) {
            goToPrinterChoose("FRONT")
        } else if (printFrontOption === false && printBackOption === true) {
            goToPrinterChoose("BACK")
        }

    }

    const goToPrinterChoose = (printSides: string | null = null) => {
        (async () => {
            navigation.navigate('PrintChooseList', { printSides: printSides });
        })();
    }

    const deleteRecordFromPrintList = (recordIndex: number, recordId: number) => {

        const renderedImagesResult = renderedImages.filter(record => record.id != recordId);
        setRenderedImages(renderedImagesResult);

        const newRenderedCardsData = renderedCardsData.filter(record => record.recordId != recordId);
        store.dispatch(setRenderedCardsData(newRenderedCardsData));

        const newTemplateRenderRecord = recordsToPrintListData.filter(record => record.recordId != recordId);
        store.dispatch(setTemplateRenderRecords(newTemplateRenderRecord));

        store.dispatch(removeRecordToPrintList(recordId));
        store.dispatch(removeRecordToPrintListData(recordId));
    }

    const showCardBack = (recordIndex: number, recordId: number) => {
        let index = renderedImages.findIndex(el => el.id === recordId);
        renderedImages[index].showBackImage = !renderedImages[index].showBackImage;
        setRenderedImages(renderedImages);
    }

    // Load on screen focus (active)
    useEffect(
        () => {
            (async () => {
                // if (isFocused) {

                setLoadingText("Starting card render");
                await sleep(1);

                // store.dispatch(setTemplateRenderRecords([]));
                setHeadLessRenderState(headLessRenderState.concat(<HeadlessRender key={new Date().getTime()} />));

                setLoadingText("Reading card information");
                await sleep(1);

                store.dispatch(setRenderedCardsData([]));

                let templateBytes: any = null;

                let localTemplateData = await templatesDB.query({
                    where: {
                        templateId_eq: templateID
                    }
                });

                if (localTemplateData[0]?.templateBytesFile !== undefined) {
                    templateBytes = await FileSystem.readAsStringAsync(localTemplateData[0].templateBytesFile, { encoding: FileSystem.EncodingType.Base64 });
                } else {
                    templateBytes = await getTemplateBytes(templateID);
                }

                const extractedZipData: any = await unzipTemplateBytes(templateBytes);

                store.dispatch(setTemplateRenderInfo(extractedZipData));
                store.dispatch(setTemplateRenderRecords(recordsToPrintListData));

                const sizeData = extractedZipData.printData.print.model.size;
                const sizeArray = sizeData.split(",");

                let cardWidthSizeInPx = ptToPx(sizeArray[0]);
                let cardheightSizeInPx = ptToPx(sizeArray[1]);

                // TODO - Portrait temporary test - in Future the card size is readed from xml - chnage extractedZipData.printData.print.model.size
                const xmlPaperOrientation = extractedZipData?.printData?.print?.paper?.orientation;
                if (xmlPaperOrientation !== undefined) {
                    if (xmlPaperOrientation.toUpperCase() === "PORTRAIT") {
                        cardWidthSizeInPx = ptToPx(sizeArray[1]);
                        cardheightSizeInPx = ptToPx(sizeArray[0]);
                    }
                }

                const newImageSize = calculateImageSize(cardWidthSizeInPx, cardheightSizeInPx, cardImageWidth);
                setRenderedImagesHeight(newImageSize.height);

                // On portrait 
                if (cardheightSizeInPx > cardWidthSizeInPx) {
                    setRenderedCardHeightState(newImageSize.height);
                    setPortraitCardMargin(15);
                }

                // store.subscribe(() => {
                //     (async () => {
                //         const storeData = store.getState();

                //         await sleep(1);
                //         setLoadingText("Rendering " + storeData.renderedCardsData.length + " of " + recordsToPrintListData.length);

                //         if (renderIsFinished === false && storeData.renderedCardsData !== undefined && storeData.renderedCardsData.length >= recordsToPrintListData.length) {
                //             renderIsFinished = true;

                //             for (let index = 0; index < storeData.renderedCardsData.length; index++) {
                //                 const currentRecord: any = storeData.renderedCardsData[index];
                //                 setRenderedImages(renderedImages => {
                //                     const nextItemId = currentRecord.recordId;
                //                     return [...renderedImages,
                //                     {
                //                         id: nextItemId,
                //                         renderedData: currentRecord.base64Image.toString()
                //                     }];
                //                 });
                //             }
                //             setLoadingText("Rendering finish");
                //             await sleep(1);
                //             setItemsStateLoaded(true);
                //             setLoading(false);

                //         }
                //     })();
                // });
                // }
            })();

            // return () => { // On unmount                
            //     console.log("TESTE 2222222222");
            //     setRenderedImages([]);
            //     store.dispatch(setRenderedCardsData([]));
            //     store.dispatch(setTemplateRenderRecords([]));
            //     store.dispatch(setRenderedRecordsSaved(false));
            //     store.dispatch(setRenderedRecordsAlertText(''));
            // }
            // }, [isFocused]
        }, []
    );

    // On unmount        
    useEffect(() => {
        return () => {
            // globalVars.zipData = null;
            // setHeadLessRenderState([]); 
            // renderDB.destroyAll();
            // setHeadLessRenderKey(new Date().getTime());
            // setRenderedImages([]);
            // store.dispatch(setTemplateRenderInfo(undefined)); 
            // store.dispatch(setRenderedRecordsSaved(false));
            // store.dispatch(setRenderedRecordsAlertText(''));
            // store.dispatch(setForceImageBase64StateFillRefresh(true));
        }
    }, []);

    // Load on screen focus (active)
    useEffect(
        () => {
            (async () => {

                // await sleep(1);
                // setLoadingText("Rendering " + renderedCardsData.length + " of " + recordsToPrintListData.length);

                if (renderedRecordsSaved !== undefined && renderedRecordsSaved === true) {
                    store.dispatch(setRenderedRecordsSaved(false));
                    store.dispatch(setRenderedRecordsAlertText(''));

                    if (renderIsFinished === false && renderedCardsData !== undefined && renderedCardsData.length >= recordsToPrintListData.length) {
                        renderIsFinished = true;

                        for (let index = 0; index < renderedCardsData.length; index++) {
                            const currentRecord: any = renderedCardsData[index];

                            const renderDataArray = await renderDB.query({
                                where: {
                                    recordId_eq: currentRecord.recordId
                                }
                            });
                            const renderData = renderDataArray[0];
                            setCardHaveFrontBack(renderData.backBase64Data !== null);
                            setRenderedImages(renderedImages => {
                                const nextItemId = currentRecord.recordId;
                                return [...renderedImages,
                                {
                                    id: nextItemId,
                                    renderedData: renderData.base64Data.toString(),
                                    backRenderedData: (renderData.backBase64Data !== null ? renderData.backBase64Data.toString() : null),
                                    showBackImage: false
                                }];
                            });
                        }
                        setLoadingText("Rendering finish");
                        await sleep(1);
                        setItemsStateLoaded(true);
                        setLoading(false);

                    }
                }
            })();
        }, [renderedCardsData, renderedRecordsSaved]
    );
    useEffect(
        () => {
            (async () => {

                if (renderRecordsText !== undefined && renderRecordsText !== '') {
                    setLoadingText(renderRecordsText);
                }

            })();
        }, [renderRecordsText]
    );

    return (
        <View style={styles.container}>
            <Loader loading={loading} text={loadingText} />
            <ScrollView style={{ width: '100%' }}>

                <View style={styles.CardContainer}>
                    {renderedImages.map((record: renderedRecordItem, index: number) => {
                        return (
                            <View key={index}
                                style={{
                                    marginTop: portraitCardMargin
                                }}>
                                <View style={{
                                    zIndex: -1,
                                    elevation: 0,
                                    position: 'relative'
                                }}>
                                    <View style={{
                                        ...styles.CardHolder,
                                        height: renderedCardHeightState,
                                    }} >
                                        <CardPreviewer
                                            width={cardImageWidth}
                                            height={renderedImagesHeight}
                                            frontData={record.renderedData}
                                            backData={record.backRenderedData}
                                            onDelete={() => deleteRecordFromPrintList(index, record.id)}
                                        />
                                    </View>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </ScrollView >
            <View style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} >
                {renderedImages.length !== 0 &&
                    <View style={styles.bottomContainer} >
                        <Button textColor={theme.colors.white} onPress={() => {
                            if (cardHaveFrontBack) {
                                setCardSizesModalvisible(true);
                            } else {
                                goToPrinterChoose()
                            }
                        }}  >{t('STR_LABEL_CONFIRM_RECORDS_TO_PRINT')}</Button>
                    </View>
                }
            </View>
            {/* <HeadlessRender key={headLessRenderKey} /> */}
            {headLessRenderState}
            <Portal>
                <Dialog
                    visible={cardSizesModalvisible}
                    onDismiss={() => setCardSizesModalvisible(false)}
                >
                    <Dialog.Content>
                        <View style={{
                            paddingBottom: 10
                        }}>
                            <Text>{t('STR_LABEL_CHOOSE_CARD_PRINT_SIDE')}</Text>
                        </View>
                        <View style={{
                            alignItems: "center",
                            flexDirection: 'row',
                        }}>
                            <Checkbox
                                status={printFrontOption ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setPrintFrontOption(!printFrontOption);
                                }}
                            />
                            <Text>{t('STR_LABEL_CARD_PRINT_SIDE_FRONT')}</Text>
                        </View>
                        <View style={{
                            alignItems: "center",
                            flexDirection: 'row',
                            borderStyle: 'solid',
                        }}>
                            <Checkbox
                                status={printBackOption ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setPrintBackOption(!printBackOption);
                                }}
                            />
                            <Text>{t('STR_LABEL_CARD_PRINT_SIDE_BACK')}</Text>
                        </View>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={() => setCardSizesModalvisible(false)}>{t('STR_CANCEL')}</Button>
                        <Button onPress={() => goToPrinterChooseWithSides()}>{t('STR_CONFIRM')}</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        paddingBottom: 70,
    },
    bottomContainer: {
        width: '100%',
        backgroundColor: '#05AC72',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        zIndex: 2
    },
    CardContainer: {
        // ...Platform.select({
        //     ios: {
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     android: {
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     default: {
        //         flex: 1,
        //         alignItems: "center",
        //         justifyContent: "center",
        //         flexDirection: "row",
        //         flexWrap: 'wrap',
        //     }
        // })        
        flex: 1,
        justifyContent: "space-around",
        flexDirection: "column",
    },
    CardHolder: {
        // ...Platform.select({
        //     ios: {
        //         width: '100%',
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     android: {
        //         width: '100%',
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     default: {
        //         // width: 250,
        //         // marginHorizontal: 10
        //         maxWidth: '100%'
        //     }
        // }),
        // backgroundColor: 'red',
        width: '100%',
        // height: 250,
        alignItems: "center",
        justifyContent: "center",
        zIndex: -1,
        elevation: -1
    },
    removeIconStyle: {
        position: 'absolute',
        left: 10,
        top: 10,
        zIndex: 99,
        elevation: 99
    },
    rotateIconStyle: {
        position: 'absolute',
        right: 15,
        top: 10,
        zIndex: 99,
        elevation: 99
    }
});

const mapStateToProps = (
    state: {
        googleAccessToken: string;
        templateProperties: object;
        recordsToPrintList: string[];
        recordsToPrintListData: {
            recordId: number;
            info: string;
        }[];
        templateSelectedId: number;
        renderedCardsData: any;
        renderedRecordsSaved: boolean;
        renderRecordsText: string;
    }
) => {
    return {
        googleAccessToken: state.googleAccessToken,
        templateProperties: state.templateProperties,
        recordsToPrintList: state.recordsToPrintList,
        recordsToPrintListData: state.recordsToPrintListData,
        templateSelectedId: state.templateSelectedId,
        renderedCardsData: state.renderedCardsData,
        renderedRecordsSaved: state.renderedRecordsSaved,
        renderRecordsText: state.renderRecordsText
    }
}

export default connect(mapStateToProps, {})(PrintSummaryList);
//export default PrintSummaryList;