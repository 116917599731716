import update from 'immutability-helper'
import { clearLocalStorage, saveToLocalStorage, loadFromLocalStorage } from './Utils'
import { createStore } from 'redux'

// const persistedState = loadFromLocalStorage()
// export const store = createStore(rootReducer, { ...initialState, ...persistedState })
export const store = createStore(rootReducer, { ...initialState })

export const initialState = {
  activateComplete: 0,

  authToken: undefined,
  userRegist: undefined,

  cancelRecordCardLoading: false,
  redenderIsProcessing: false,

  templateList: undefined,
  templateProperties: undefined,
  templateName: undefined,
  templateInEdition: undefined,
  lastTemplateInEdition: undefined,
  templateSelectedId: undefined,

  templateRenderInfo: undefined,
  templateRenderRecords: [],

  printerSelectedId: undefined,
  printersList: undefined,
  printCounter: undefined,

  recordSelectedId: undefined,
  recordList: undefined,
  recordUpdateData: undefined,
  recordInsertData: undefined,
  recordSynced: undefined,
  recordSyncError: undefined,
  recordToRemove: undefined,
  updateRecordDataKey: undefined,

  recordsToPrintList: undefined,
  recordsToPrintListData: [],
  renderedCardsData: [],
  forceImageBase64StateFillRefresh: false,
  renderedRecordsSaved: false,
  renderRecordsText: '',

  recordSelected: undefined,
  recordUpdated: false,
  recordsCount: undefined,
  recordsFrom: 0,
  googleAccessToken: undefined,

  deviceID: undefined,
  localPrintSearchActive: false,
  screenToGo: undefined,

  errorMessageList: [],
}

export const setActivateComplete = value => ({ type: 'SET_ACTIVATE_COMPLETE', value })
export const setLocalPrintSearchActive = value => ({ type: 'SET_LOCAL_PRINT_SEARCH', value })

export const setUserRegist = value => ({ type: 'SET_USER_REGIST', value })

export const cleanLoginInfo = () => ({ type: 'CLEAN_LOGIN_INFO' })
export const setLoginInfo = value => ({ type: 'SET_LOGIN_INFO', value })

export const cleanTemplate = () => ({ type: 'CLEAN_TEMPLATE' })
export const setTemplateList = value => ({ type: 'SET_TEMPLATE_LIST', value })
export const setTemplateProperties = value => ({ type: 'SET_TEMPLATE_PROPERTIES', value })
export const setTemplateName = value => ({ type: 'SET_TEMPLATE_NAME', value })
export const setTemplateInEdition = value => ({ type: 'SET_TEMPLATE_INEDITION', value })
export const setTemplateSelectedId = value => ({ type: 'SET_TEMPLATE_SELECTED_ID', value })
export const setCancelRecordCardLoading = value => ({ type: 'SET_CANCEL_RECORD_CARD_LOADING', value })
export const setRedenderIsProcessing = value => ({ type: 'SET_RENDER_IS_PROCESSING', value })

// RENDER 
export const setTemplateRenderInfo = value => ({ type: 'SET_TEMPLATE_RENDER_DATA', value })
export const setTemplateRenderRecords = value => ({ type: 'SET_TEMPLATE_RENDER_RECORDS', value })
export const addTemplateRenderRecord = value => ({ type: 'ADD_TEMPLATE_RENDER_RECORDS', value })
export const removeTemplateRenderRecord = value => ({ type: 'REMOVE_TEMPLATE_RENDER_RECORDS', value })
export const setForceImageBase64StateFillRefresh = value => ({ type: 'FORCE_IMAGE_FILL_REFRESH', value })
export const setRenderedRecordsSaved = value => ({ type: 'SET_RENDERED_RECORDS_SAVED', value })
export const setRenderedRecordsAlertText = value => ({ type: 'SET_RENDER_RECORDS_TEXT', value })

export const setPrinterSelectedId = value => ({ type: 'SET_PRINTER_SELECTED_ID', value })

export const setGoogleAccessToken = value => ({ type: 'SET_GOOGLE_ACCESS_TOKEN', value })

export const cleanRecord = () => ({ type: 'CLEAN_RECORD' })
export const cleanRecordInsertData = () => ({ type: 'CLEAN_RECORD_INSERT_DATA' })
export const cleanRecordUpdateData = () => ({ type: 'CLEAN_RECORD_UPDATE_DATA' })
export const cleanRecordSynced = () => ({ type: 'CLEAN_RECORD_SYNCED' })
export const addRecordToList = () => ({ type: 'ADD_RECORD_TO_LIST' })
export const removeRecordFromList = value => ({ type: 'REMOVE_RECORD_FROM_LIST', value })
export const removeLastRecordFromList = value => ({ type: 'REMOVE_LAST_RECORD_FROM_LIST', value })

export const updateRecordList = value => ({ type: 'UPDATE_RECORD_LIST', value })
export const setRecordList = value => ({ type: 'SET_RECORD_LIST', value })
export const updateRecordStatus = value => ({ type: 'UPDATE_RECORD_STATUS', value })
export const setRecordSelectedId = value => ({ type: 'SET_RECORD_SELECTED_ID', value })
export const setRecordSelected = value => ({ type: 'SET_RECORD_SELECTED', value })
export const updateRecordSelected = value => ({ type: 'UPDATE_RECORD_SELECTED', value })
export const SetRecordUpdated = () => ({ type: 'SET_RECORD_UPDATED' })
export const setRecordData = value => ({ type: 'SET_RECORD_DATA', value })

export const setUpdateRecordDataKey = value => ({ type: 'SET_RECORD_UPDATE_DATA_KEY', value })
export const cleanUpdateRecordDataKey = value => ({ type: 'CLEAN_RECORD_UPDATE_DATA_KEY' })

export const setLocalRecordId = value => ({ type: 'SET_RECORD_LOCAL_ID', value })
export const setLocalRecordOperationType = value => ({ type: 'SET_RECORD_LOCAL_OPERATION_TYPE', value })
export const clearRecordLocalData = value => ({ type: 'CLEAR_RECORD_LOCAL_DATA', value })

export const setRecordsCount = value => ({ type: 'SET_RECORDS_COUNT', value })
export const addRecordsCount = () => ({ type: 'ADD_RECORDS_COUNT' })
export const removeRecordsCount = () => ({ type: 'REMOVE_RECORDS_COUNT' })

export const setRecordsFrom = value => ({ type: 'SET_RECORDS_FROM', value })
export const addRecordsFrom = () => ({ type: 'ADD_RECORDS_FROM' })
export const removeRecordsFrom = () => ({ type: 'REMOVE_RECORDS_FROM' })

export const setRecordSynced = value => ({ type: 'SET_RECORD_SYNCED', value })
export const setRecordSyncError = value => ({ type: 'SET_RECORD_SYNC_ERROR', value })
export const clearRecordSyncError = value => ({ type: 'CLEAR_RECORD_SYNC_ERROR', value })

export const submitRecordUpdate = () => ({ type: 'SUBMIT_RECORD_UPDATE' })
export const submitRecordInsert = value => ({ type: 'SUBMIT_RECORD_INSERT', value })

export const setPrintersList = value => ({ type: 'SET_PRINTERS_LIST', value })

export const setRecordsToPrintList = value => ({ type: 'SET_RECORDS_TO_PRINT_LIST', value })
export const addRecordToPrintList = value => ({ type: 'ADD_RECORD_TO_PRINT_LIST', value })
export const removeRecordToPrintList = value => ({ type: 'REMOVE_RECORD_TO_PRINT_LIST', value })

export const setRecordsToPrintListData = value => ({ type: 'SET_RECORDS_TO_PRINT_LIST_DATA', value })
export const addRecordToPrintListData = value => ({ type: 'ADD_RECORD_TO_PRINT_LIST_DATA', value })
export const removeRecordToPrintListData = value => ({ type: 'REMOVE_RECORD_TO_PRINT_LIST_DATA', value })
export const setRecordToPrintListImage = value => ({ type: 'SET_RECORD_TO_PRINT_LIST_IMAGE', value })

export const setRenderedCardsData = value => ({ type: 'SET_RENDERED_CARDS_DATA', value })
export const addRenderedCardsData = value => ({ type: 'ADD_RENDERED_CARDS_DATA', value })
export const removeRenderedCardsData = value => ({ type: 'REMOVE_RENDERED_CARDS_DATA', value })

export const setDeviceID = value => ({ type: 'SET_DEVICE_ID', value })

export const addErrorMessage = value => ({ type: 'ADD_ERROR_MESSAGE', value })
export const clearErrorMessageList = value => ({ type: 'CLEAR_ERROR_MESSAGE_LIST', value })
export const setScreenToGo = value => ({ type: 'SET_GO_TO_SCREEN', value })

// PRINT COUNTER
export const setPrintCounter = value => ({ type: 'SET_PRINT_COUNTER', value })


export default function rootReducer(state = initialState, action) {
  var newState

  switch (action.type) {

    case 'SET_ACTIVATE_COMPLETE':
      newState = { ...state, activateComplete: action.value }
      return newState

    case 'SET_LOCAL_PRINT_SEARCH':
      newState = { ...state, localPrintSearchActive: action.value }
      return newState

    case 'SET_USER_REGIST':
      return { ...state, userRegist: action.value }

    case 'CLEAN_LOGIN_INFO':
      newState = { ...initialState }
      clearLocalStorage()
      return newState

    case 'SET_LOGIN_INFO':
      newState = { ...state, authToken: action.value.authToken }
      saveToLocalStorage(newState)
      return newState

    case 'CLEAN_TEMPLATE':
      return { ...state, templateList: initialState.templateList, templateProperties: initialState.templateProperties, templateName: initialState.templateName, templateInEdition: initialState.templateInEdition, lastTemplateInEdition: initialState.lastTemplateInEdition }
    case 'SET_TEMPLATE_LIST':
      return { ...state, templateList: action.value }
    case 'SET_TEMPLATE_PROPERTIES':
      return { ...state, templateProperties: action.value }
    case 'SET_TEMPLATE_NAME':
      return { ...state, templateName: action.value }
    case 'SET_TEMPLATE_INEDITION':
      if (state.templateList !== undefined) {
        var mobilityName = action.value.templateName

        if (mobilityName === undefined) {
          mobilityName = action.value.templateName;
        }

        state.templateList.map((template, index) => {
          if (template.templateName === mobilityName) {
            template.inEdition = action.value.inEdition
          }
        })
      }

      return { ...state, templateInEdition: action.value.inEdition, lastTemplateInEdition: mobilityName }


    case 'SET_TEMPLATE_SELECTED_ID':
      return { ...state, templateSelectedId: action.value }

    case 'SET_TEMPLATE_RENDER_DATA':
      return { ...state, templateRenderInfo: action.value }

    case 'SET_TEMPLATE_RENDER_RECORDS':
      return { ...state, templateRenderRecords: action.value }

    case 'ADD_TEMPLATE_RENDER_RECORDS':
      if (state.templateRenderRecords === undefined) {
        state.templateRenderRecords = [];
      }
      state.templateRenderRecords.push(action.value);
      return { ...state, templateRenderRecords: state.templateRenderRecords }

    case 'REMOVE_TEMPLATE_RENDER_RECORDS':
      var index = state.templateRenderRecords.findIndex((record) => { return record.recordId === action.value })
      if (index === -1) {
        return state;
      }
      return update(state, {
        templateRenderRecords: {
          $splice: [[index, 1]]
        }
      })

    case 'FORCE_IMAGE_FILL_REFRESH':
      return { ...state, forceImageBase64StateFillRefresh: action.value }

    case 'SET_RENDERED_RECORDS_SAVED':
      return { ...state, renderedRecordsSaved: action.value }

    case 'SET_RENDER_RECORDS_TEXT':
      return { ...state, renderRecordsText: action.value }

    case 'SET_CANCEL_RECORD_CARD_LOADING':
      return { ...state, cancelRecordCardLoading: action.value }

    case 'SET_RENDER_IS_PROCESSING':
      return { ...state, redenderIsProcessing: action.value }

    case 'SET_PRINTER_SELECTED_ID':
      return { ...state, printerSelectedId: action.value }

    case 'SET_DEVICE_ID':
      return { ...state, deviceID: action.value }

    case 'SET_GOOGLE_ACCESS_TOKEN':
      return { ...state, googleAccessToken: action.value }

    case 'SET_GO_TO_SCREEN':
      return { ...state, screenToGo: action.value }

    case 'CLEAN_RECORD':
      return { ...state, recordList: initialState.recordList, recordSelected: initialState.recordSelected, recordUpdated: initialState.recordUpdated }

    case 'CLEAN_RECORD_INSERT_DATA':
      if (state.recordInsertData === undefined) {
        return state;
      }
      return { ...state, recordInsertData: undefined }

    case 'CLEAN_RECORD_UPDATE_DATA':
      if (state.recordUpdateData === undefined) {
        return state;
      }
      return { ...state, recordUpdateData: undefined }

    case 'CLEAN_RECORD_SYNCED':
      if (state.recordSynced === undefined) {
        return state;
      }
      return { ...state, recordSynced: undefined }

    case 'ADD_RECORD_TO_LIST':
      // state.recordList.push(action.value); 
      return { ...state, recordList: state.recordList }

    case 'REMOVE_RECORD_FROM_LIST':
      // const recordListResult = state.recordList.items.filter(record => record.recordId != action.value);
      // state.recordList.items = recordListResult;
      // return { ...state, recordList: state.recordList }  

      // var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value })
      // if (index === -1) {
      //   return state;
      // }
      // return update(state, {
      //   recordList: {
      //     items: {
      //       $splice: [[index, 1]]
      //     }
      //   }
      // }) 
      return { ...state, recordToRemove: action.value }

    case 'REMOVE_LAST_RECORD_FROM_LIST':
      var index = state.recordList.items.length - 1
      return update(state, {
        recordList: {
          items: {
            $splice: [[index, 1]]
          }
        }
      })

    case 'SET_RECORD_LIST':
      return { ...state, recordList: action.value }

    case 'SET_PRINTERS_LIST':
      return { ...state, printersList: action.value }

    case 'SET_RECORDS_TO_PRINT_LIST':
      return { ...state, recordsToPrintList: action.value }

    case 'ADD_RECORD_TO_PRINT_LIST':
      if (state.recordsToPrintList === undefined) {
        state.recordsToPrintList = [];
      }
      state.recordsToPrintList.push(action.value);
      return { ...state, recordsToPrintList: state.recordsToPrintList }

    case 'REMOVE_RECORD_TO_PRINT_LIST':
      const itemIndex = state.recordsToPrintList.indexOf(action.value);
      if (itemIndex > -1) {
        state.recordsToPrintList.splice(itemIndex, 1);
      }
      return { ...state, recordsToPrintList: state.recordsToPrintList }


    case 'SET_RECORDS_TO_PRINT_LIST_DATA':
      return { ...state, recordsToPrintListData: action.value }

    case 'ADD_RECORD_TO_PRINT_LIST_DATA':
      if (state.recordsToPrintListData === undefined) {
        state.recordsToPrintListData = [];
      }
      state.recordsToPrintListData.push(action.value);
      return { ...state, recordsToPrintListData: state.recordsToPrintListData }

    case 'SET_RECORD_TO_PRINT_LIST_IMAGE':
      if (state.recordsToPrintListData === undefined) {
        return state;
      }
      var index = state.recordsToPrintListData.findIndex((record) => { return record.recordId === action.value.recordId });
      return update(state, { recordsToPrintListData: { [index]: { cardTumbnail: { $set: action.value.base64Image } } } });

    case 'REMOVE_RECORD_TO_PRINT_LIST_DATA':
      const printListaDataResult = state.recordsToPrintListData.filter(record => record.recordId != action.value);
      return { ...state, recordsToPrintListData: printListaDataResult }


    case 'UPDATE_RECORD_LIST':
      for (let item of action.value.items) {
        state.recordList.items.push(item)
      }
      return { ...state, recordList: state.recordList }
    case 'UPDATE_RECORD_STATUS':
      if (state.recordList === undefined) {
        return state
      }
      var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value.recordId })
      if (index === -1) return state
      return update(state, { recordList: { items: { [index]: { printStatus: { $set: action.value.status } } } } })

    case 'SET_RECORD_DATA':
      if (state.recordList === undefined) {
        return state
      }
      var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value.recordId })
      if (index === -1) return state
      return update(state, {
        recordList:
        {
          items: {
            [index]: {
              info: { $set: action.value.info }
            }
          }
        }
      })

    case 'SET_RECORD_LOCAL_ID':
      if (state.recordList === undefined) {
        return state
      }
      var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value.recordId })
      if (index === -1) {
        return state;
      }
      return update(state, {
        recordList:
        {
          items: {
            [index]: {
              localRecordId: { $set: action.value.localRecordId }
            }
          }
        }
      })
    case 'SET_RECORD_LOCAL_OPERATION_TYPE':
      if (state.recordList === undefined) {
        return state
      }
      var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value.recordId })
      if (index === -1) {
        return state;
      }
      let newRecord = undefined;
      if (action.value.operationType === 'create') {
        newRecord = true;
      } else if (action.value.operationType = "update") {
        newRecord = false;
      }

      return update(state, {
        recordList:
        {
          items: {
            [index]: {
              newRecord: { $set: newRecord }
            }
          }
        }
      })

    case 'CLEAR_RECORD_LOCAL_DATA':
      if (state.recordList === undefined) {
        return state
      }
      var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value })
      if (index === -1) return state
      return update(state, {
        recordList:
        {
          items: {
            [index]: {
              localRecordId: { $set: undefined },
              syncError: { $set: undefined }
            }
          }
        }
      })

    case 'SET_RECORD_SYNCED':
      // if (state.recordList === undefined) {
      //   return state
      // }
      // var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value.tmpRecordId })
      // if (index === -1) return state

      // // state.recordList.items[index].recordId = action.value.cloudRecordId
      // // delete state.recordList.items[index].localRecordId;
      // // return { ...state, recordList: state.recordList }
      // return update(state, {
      //   recordList: {
      //     items: {
      //       [index]: {
      //         localRecordId: { $set: undefined },
      //         recordId: { $set: action.value.cloudRecordId },
      //         disableAnimation: { $set: true },
      //       }
      //     }
      //   }
      // })
      return { ...state, recordSynced: action.value }

    case 'SET_RECORD_UPDATE_DATA_KEY':
      return { ...state, updateRecordDataKey: action.value }

    case 'CLEAN_RECORD_UPDATE_DATA_KEY':
      return { ...state, updateRecordDataKey: undefined }

    case 'SET_RECORD_SYNC_ERROR':
      // if (state.recordList === undefined) {
      //   return state
      // }
      // var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value.tmpRecordId })
      // if (index === -1) return state

      // return update(state, {
      //   recordList: {
      //     items: {
      //       [index]: {
      //         syncError: { $set: action.value.error },
      //         disableAnimation: { $set: true },
      //       }
      //     }
      //   }
      // })
      return {
        ...state, recordSyncError: {
          recordId: action.value.tmpRecordId,
          newRecord: action.value.newRecord,
          error: action.value.error
        }
      }

    case 'CLEAR_RECORD_SYNC_ERROR':
      if (state.recordList === undefined) {
        return state
      }
      var index = state.recordList.items.findIndex((record) => { return record.recordId === action.value })
      if (index === -1) return state

      return update(state, {
        recordList: {
          items: {
            [index]: {
              syncError: { $set: undefined },
              disableAnimation: { $set: true },
            }
          }
        }
      })

    case 'SET_RECORD_SELECTED_ID':
      return { ...state, recordSelectedId: action.value }
    case 'SET_RECORD_SELECTED':
      return { ...state, recordSelected: action.value }
    case 'UPDATE_RECORD_SELECTED':
      return update(state, { recordSelected: { info: { [action.value.field]: { $set: action.value.value } } } })
    case 'SET_RECORD_UPDATED':
      return { ...state, recordUpdated: true }

    case 'SET_RECORDS_COUNT':
      return { ...state, recordsCount: action.value }

    case 'ADD_RECORDS_COUNT':
      return update(state, { recordsCount: { $set: parseInt(state.recordsCount) + 1 } })

    case 'REMOVE_RECORDS_COUNT':
      return update(state, { recordsCount: { $set: parseInt(state.recordsCount) - 1 } })

    case 'SET_RECORDS_FROM':
      return { ...state, recordsFrom: action.value }

    case 'ADD_RECORDS_FROM':
      return update(state, { recordsFrom: { $set: parseInt(state.recordsFrom) + 1 } })

    case 'REMOVE_RECORDS_FROM':
      return update(state, { recordsFrom: { $set: parseInt(state.recordsFrom) - 1 } })

    case 'SUBMIT_RECORD_UPDATE':
      // if (state.recordList === undefined) {
      //   return state;
      // }
      // var index = state.recordList.items.findIndex((record) => { return record.recordId === state.recordSelectedId })
      // return update(state, { recordList: { items: { [index]: { $set: state.recordSelected } } } });
      return { ...state, recordUpdateData: state.recordSelected }

    case 'SUBMIT_RECORD_INSERT':

      // if (typeof action.value.info !== 'object') {
      //   action.value.info = JSON.parse(action.value.info);
      // }
      // state.recordList.items.unshift(action.value);
      // return { ...state, recordList: state.recordList }
      // return update(state, { recordList: { items: { [index]: { $set: state.recordSelected } } } });
      return { ...state, recordInsertData: state.recordSelected }

    case 'SET_RENDERED_CARDS_DATA':
      return { ...state, renderedCardsData: action.value }

    case 'ADD_RENDERED_CARDS_DATA':
      if (state.renderedCardsData === undefined) {
        state.renderedCardsData = [];
      }
      const recordExists = state.renderedCardsData.some(record => record.recordId === action.value.recordId);
      if (recordExists) {
        // return state;
        var index = state.renderedCardsData.findIndex((record) => { return record.recordId === action.value.recordId })
        return update(state, { renderedCardsData: { [index]: { $set: action.value } } });
      }
      state.renderedCardsData.push(action.value);
      return { ...state, renderedCardsData: state.renderedCardsData }

    case 'REMOVE_RENDERED_CARDS_DATA':
      const cardDataResult = state.renderedCardsData.filter(record => record.recordId != action.value);
      return { ...state, renderedCardsData: cardDataResult }

    case 'ADD_ERROR_MESSAGE':
      // if ( state.errorMessageList.find( item => { return item === action.value } ) === undefined )
      //   return { ...state, errorMessageList: [ ...state.errorMessageList, action.value ] }
      return { ...state, errorMessageList: [action.value] }

    case 'CLEAR_ERROR_MESSAGE_LIST':
      return { ...state, errorMessageList: [] }

    case 'SET_PRINT_COUNTER':
      if(action.value != undefined) return { ...state, printCounter: action.value }
      else return { ...state, printCounter: state.printCounter + 1 }

    default:
      return state
  }
}
