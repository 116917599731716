import React, { useEffect, useState } from 'react'
import { Alert, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { connect } from 'react-redux';
import 'react-native-gesture-handler';
import { clearErrorMessageList, setDeviceID, cleanLoginInfo } from './utils/Reducer';
import SignInScreen from './src/screens/auth/SignInScreen';
import SplashScreen from './src/screens/SplashScreen';
//import RegisterScreen from './src/screens/RegisterScreen';
import DrawerNavigationRoutes from './src/screens/navigation/DrawerNavigationRoutes';
import RegisterScreen from './src/screens/auth/RegisterScreen';
import ActivateScreen from './src/screens/auth/ActivateScreen';
import ForgotScreen from './src/screens/auth/ForgotScreen';
import ResetScreen from './src/screens/auth/ResetScreen';
import { io } from "socket.io-client";
import globalVars from './helpers/store';
import { store, setPrintersList } from './utils/Reducer';

import GlobalStyles from './src/GlobalStyles';
import { SafeAreaView } from "react-native";
import { useTranslation } from 'react-i18next';
import configs from './helpers/config';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import * as Sentry from 'sentry-expo';
import { Native, Browser } from 'sentry-expo';

import AsyncStorage from '@react-native-async-storage/async-storage';
import 'react-native-get-random-values';
import uuid from 'react-native-uuid';

Sentry.init({
  dsn: 'https://09da193092e3488a85d1d72fb19c0148@o4504208411721728.ingest.sentry.io/4504208419389440',
  environment: configs.APPEnvironment,
  enableInExpoDevelopment: false,
  tracesSampleRate: 1.0,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});
if (Platform.OS === 'web') {
  globalVars.Sentry = Browser;
} else {
  globalVars.Sentry = Native;
}

const Stack = createNativeStackNavigator();

const Auth = () => {

  // Stack Navigator for SignIn and SignUp Screen
  return (
    <SafeAreaView style={GlobalStyles.droidSafeArea}>
      <Stack.Navigator initialRouteName="SignInScreen" >
        <Stack.Screen
          name="SignIn"
          component={SignInScreen}
          options={{ headerShown: false }}
        />
        {<Stack.Screen
          name="Register"
          component={RegisterScreen}
          options={{
            headerTransparent: true,
            title: ''
          }}
        />}
        {<Stack.Screen
          name="Activate"
          component={ActivateScreen}
          options={{
            headerTransparent: true,
            title: ''
          }}
        />}
        {<Stack.Screen
          name="Forgot"
          component={ForgotScreen}
          options={{
            headerTransparent: true,
            title: ''
          }}
        />}
        {<Stack.Screen
          name="Reset"
          component={ResetScreen}
          options={{
            headerTransparent: true,
            title: ''
          }}
        />}
      </Stack.Navigator>
    </SafeAreaView>
  );
};

function App(props) {

  const { authToken, errorMessageList } = props
  const { t, i18n } = useTranslation();
  const [appLoaded, setAppLoaded] = useState(false);


  // DEFINE GLOBAL REST ERRORS 
  globalVars.translationsErrors['STR_API_ERROR_INTERNAL_SERVER_ERROR'] = t('STR_API_ERROR_INTERNAL_SERVER_ERROR');
  globalVars.translationsErrors['STR_API_ERROR_SESSION_EXPIRED'] = t('STR_API_ERROR_SESSION_EXPIRED');
  globalVars.translationsErrors['STR_API_ERROR_ACCOUNT_NOT_ACTIVE'] = t('STR_API_ERROR_ACCOUNT_NOT_ACTIVE');
  globalVars.translationsErrors['STR_API_ERROR_TEMPLATE_NOT_FOUND'] = t('STR_API_ERROR_TEMPLATE_NOT_FOUND');
  globalVars.translationsErrors['STR_API_ERROR_TEMPLATE_RECORD_NOT_FOUND'] = t('STR_API_ERROR_TEMPLATE_RECORD_NOT_FOUND');
  globalVars.translationsErrors['STR_API_ERROR_SESSION_STOLEN'] = t('STR_API_ERROR_SESSION_STOLEN');
  globalVars.translationsErrors['STR_API_ERROR_DEVICES_EXCEDED'] = t('STR_API_ERROR_DEVICES_EXCEDED');
  globalVars.translationsErrors['STR_API_ERROR_INVALID_DEVICEID'] = t('STR_API_ERROR_INVALID_DEVICEID');
  globalVars.translationsErrors['STR_API_ERROR_UNKNOWN'] = t('STR_API_ERROR_UNKNOWN');

  const populatePrintersListState = () => {
    globalVars.socket.emit("getPrinters", (response) => {
      store.dispatch(setPrintersList(response.avaliablePrinters));
    });
  }
  const logoutProcess = () => {
    (async () => {
      globalVars.Sentry.setUser(null);
      store.dispatch(cleanLoginInfo());
      await AsyncStorage.removeItem('@userLogged');
      await AsyncStorage.removeItem('@email');
      await AsyncStorage.removeItem('@password');
      // navigation.navigate('SplashScreen');  
    })();
  }
  useEffect(
    () => {

      if (authToken !== undefined && authToken !== '') {

        globalVars.socket = io(configs.APPUrls.cloudPrint, {
          "transports": ['websocket']
        });
        globalVars.socket.on('connect', function (socket) {
          // console.log('Client is connected');        
        });
        globalVars.socket.emit('authenticateUserWithToken', authToken);
        globalVars.socket.on('userAuthenticated', function (authResponse) {
          if (authResponse.authStateCode !== 200) {
            Alert.alert(
              "Error",
              'Cloud printer server connection error'
            );
          } else {
            populatePrintersListState();
            if (globalVars.additionalAPIHeaderInfo['device-ID'] !== undefined) {
              globalVars.socket.emit('initDevice', globalVars.additionalAPIHeaderInfo['device-ID']);
            }
          }
        });
        globalVars.socket.on('printerConnected', function (data) {
          populatePrintersListState();
        });
        globalVars.socket.on('printerDisconnected', function (data) {
          populatePrintersListState();
        });

        globalVars.socket.on("deviceKilled", (deviceID) => {
          if (globalVars.additionalAPIHeaderInfo['device-ID'] === deviceID) {
            logoutProcess();
          }
        });

      }

    },
    [authToken]
  )

  // Global error Handler 
  useEffect(
    () => {
      if (errorMessageList !== undefined && errorMessageList.length > 0) {
        Alert.alert(
          "Error",
          errorMessageList.join(' and ')
        );
      }
    },
    [errorMessageList]
  )
  // Register Device ID 
  useEffect(
    () => {
      (async () => {
        let uuidToken = uuid.v4();
        let fetchUUID = await AsyncStorage.getItem('secure_deviceid');
        //if user has already signed up prior
        if (fetchUUID) {
          uuidToken = fetchUUID
        }
        await AsyncStorage.setItem('secure_deviceid', uuidToken);
        store.dispatch(setDeviceID(uuidToken));
        globalVars.additionalAPIHeaderInfo['device-ID'] = uuidToken;

        const apiToken = await AsyncStorage.getItem('secure_apiauthorization');
        globalVars.additionalAPIHeaderInfo['cp-api-authorization'] = apiToken;

        setAppLoaded(true);
      })();
    },
    []
  )

  return (
    <SafeAreaView style={GlobalStyles.droidSafeArea}>
      {appLoaded &&
        <NavigationContainer
          ref={navigation}
          onReady={() => {
            // Register the navigation container with the instrumentation
            // routingInstrumentation.registerNavigationContainer(navigation);
          }}>
          <Stack.Navigator initialRouteName="SplashScreen">
            <Stack.Screen
              name="DrawerNavigationRoutes"
              component={DrawerNavigationRoutes}
              // Hiding header for Navigation Drawer
              options={{ headerShown: false }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      }
    </SafeAreaView>
  );
}

const mapStateToProps = state => {
  return {
    authToken: state.authToken,
    errorMessageList: state.errorMessageList,
  }
}

export default connect(mapStateToProps, { clearErrorMessageList })(App)
serviceWorkerRegistration.register();
