
import * as SQLite from 'expo-sqlite'
import { BaseModel, types } from '../classes/index';
import configs from '../../config';

export default class render extends BaseModel {
    constructor(obj: any) {
        super(obj)
    }

    static get database(): any {
        return async () => SQLite.openDatabase(configs.sqlLiteDbName)
    } 

    static get tableName(): any {
        return 'render' 
    } 

    static get columnMapping(): any {
        return { 
            id: { type: types.INTEGER, primary_key: true, autoincrement: true },
            recordId: { type: types.INTEGER, not_null: true },
            base64Data: { type: types.BLOB, not_null: true },
            backBase64Data: { type: types.BLOB, not_null: false },
            timestamp: { type: types.INTEGER, default: () => Date.now() }
        }
    }
}