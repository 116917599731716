import React from 'react';
import { StyleSheet, View, Modal, ActivityIndicator, Text } from 'react-native';

const Loader = (props) => {
  const { loading, text, children } = props;

  return (
    <Modal
      transparent={true}
      animationType={'none'}
      visible={loading}
      onRequestClose={() => {
        // console.log('close modal');
      }}>
      <View style={styles.modalBackground}>
        <View style={styles.activityIndicatorWrapper}>
          <ActivityIndicator
            animating={true}
            color="#5BC592"
            size="large"
            style={styles.activityIndicator}
          />
          {text !== undefined && text !== "" &&
            <Text style={styles.textHolder}>{text}</Text>
          }
          {children !== undefined &&
            <View style={styles.childrenHolder}>
              {children}
            </View>
          }
        </View>
      </View>
    </Modal >
  );
};

export default Loader;

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: '#00000040',
  },
  activityIndicatorWrapper: {
    backgroundColor: '#FFFFFF',
    height: 150,
    width: 150,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  activityIndicator: {
    alignItems: 'center',
    height: 150,
  },
  textHolder: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: 10,
    fontSize: 12
  }, 
  childrenHolder: {
    width: '100%'
  }
});