import React from "react";
import { Platform, View, Text, StyleSheet, Dimensions, Image, TouchableOpacity } from "react-native";
import { useTranslation } from 'react-i18next';
import { useTheme } from "react-native-paper";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const TemplateCard = (props) => {

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const udpateDateObj = new Date(props.updateDate * 1000);
    const dateFormated = [
        udpateDateObj.getFullYear(),
        udpateDateObj.getMonth() + 1,
        udpateDateObj.getDate()
    ].join('/') + ' ' +
        [
            ('0' + udpateDateObj.getHours()).slice(-2),
            ('0' + udpateDateObj.getMinutes()).slice(-2),
            ('0' + udpateDateObj.getSeconds()).slice(-2),
        ].join(':');

    return (
        <View style={[styles.cardContainer, { backgroundColor: theme.colors.cardBackground }]}>
            <TouchableOpacity onPress={() => props.confirmDelete(props.id)} style={styles.removeIconStyle}>
                <MaterialCommunityIcons name="delete" size={30} color="#FF5858" />
            </TouchableOpacity>
            <TouchableOpacity onPress={props.onPress}>
                <View style={styles.infoHolder}>
                    <View style={{
                        width: '100%',
                        alignContent: 'center',
                        justifyContent: 'center',
                        borderTopLeftRadius: radius,
                        borderTopRightRadius: radius,
                        borderBottomLeftRadius: radius,
                        borderBottomRightRadius: radius
                    }}>
                        <Image style={styles.imageStyle} source={{ uri: props.image }} resizeMode='contain' />
                    </View>
                    <View style={styles.textHolder}>                         
                        <Text style={styles.textStyle}>{props.text}</Text> 
                        <Text style={styles.editedStyle}>{t('STR_TEMPLATE_EDITED')}: {dateFormated}</Text>
                    </View>
                </View> 
            </TouchableOpacity>
        </View>
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    cardContainer: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 25,
            },
            android: {
                width: deviceWidth - 25,
            },
            default: { // other platforms, web for example
                width: '100%'
            }
        }),
        minHeight: 270,
        borderRadius: radius,
        borderWidth: 1,
        marginBottom: 15,
        borderColor: '#e0e0e0',
    },
    infoHolder: {
        marginTop: 20,
        marginBottom: 20,

        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    imageStyle: {
        ...Platform.select({
            ios: {
                //width: deviceWidth - 60,
                width: deviceWidth * 0.8,
                marginHorizontal: 8,
                height: 200,
            },
            android: {
                //width: deviceWidth - 60,                
                width: deviceWidth * 0.8,
                height: 300,
                marginHorizontal: 8,
                //height: 200,
            },
            default: { // other platforms, web for example
                width: '100%',
                height: 300,
            }
        }),
        alignSelf: 'center',
    },
    textHolder: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 60,
            },
            android: {
                width: deviceWidth - 60,
            },
            default: { // other platforms, web for example
                width: '100%'
            }
        }),
        paddingTop: 5,
        alignSelf: 'center',
    },
    textStyle: {
        fontSize: 20,
        fontWeight: '500',
        color: '#05AC72',
        marginLeft: 8
    },
    editedStyle: {
        fontSize: 14,
        fontWeight: '500',
        color: 'black',
        marginLeft: 8
    },
    removeIconStyle: {
        position: 'absolute',
        right: 10,
        top: 10,
        zIndex: 10
    },
});

export default TemplateCard;