
import * as SQLite from 'expo-sqlite'
import { BaseModel, types } from '../classes/index';
import configs from '../../config';

export default class template extends BaseModel {
    constructor(obj: any) {
        super(obj)
    }

    static get database(): any {
        return async () => SQLite.openDatabase(configs.sqlLiteDbName)
    }

    static get tableName(): any {
        return 'templates'
    }

    static get columnMapping(): any {
        return {
            id: { type: types.INTEGER, primary_key: true, autoincrement: true },
            name: { type: types.TEXT, not_null: true },
            templateId: { type: types.INTEGER, not_null: true },
            thumbnail: { type: types.TEXT, not_null: false },
            updateDate: { type: types.INTEGER, not_null: true },
            templateProperties: { type: types.BLOB, not_null: false },
            templateBytesFile: { type: types.TEXT, not_null: false }
        }
    }

    static removeTemplate(templatedId: string) {
        const sql: any = 'DELETE FROM templates WHERE templateId = ?'
        const params: any = [templatedId];
        return this.repository.databaseLayer.executeSql(sql, params).then(({ rows }) => rows)
    }

    static removeDuplicatedRows() {
        const sql: any = `
            DELETE FROM 
                templates
            WHERE 
                id NOT IN 
            (
                SELECT 
                    MIN(id)
                FROM 
                    templates
                GROUP BY templateId, name
            );
        ` 
        const params: any = [];
        return this.repository.databaseLayer.executeSql(sql, params).then(({ rows }) => rows)
    }


}