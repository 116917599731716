
import React, { useState, useEffect } from 'react';
import { ptToPx, ptToPxStroke, mmToPx, attributeSet } from '../../../../helpers/cardRender/utils';
import { Skia, Points, Path, DashPathEffect, vec, Group } from "@shopify/react-native-skia";

export type props = {
    id: number,
    xmlObj: any
};

const PolygonItem: React.FC<props> = ({
    id,
    xmlObj
}) => {

    const startX: number = ptToPx(xmlObj.position.pos1.x);
    const startY: number = ptToPx(xmlObj.position.pos1.y);
    const endX: number = ptToPx(xmlObj.position.pos2.x);
    const endY: number = ptToPx(xmlObj.position.pos2.y);
    const itemWidth: number = endX - startX;
    const itemHeight: number = endY - startY;
    let itemFillColor: string = "#000";
    let itemStrokeColor: string = "#000";
    let itemStrokeWidth: number = 0;
    let lineStyle: string | undefined;
    let lineStyleArray: number[] = [];

    if (xmlObj.fill?.type[1]?.solid?.color && Array.isArray(xmlObj.fill?.type[1]?.solid?.color) === false) {
        itemFillColor = xmlObj.fill.type[1].solid.color;
    }
    if (xmlObj.fill?.type[1]?.solid?.color && Array.isArray(xmlObj.fill?.type[1]?.solid?.color) === true) {
        itemFillColor = xmlObj.fill.type[1].solid.color[0];
    }

    if (xmlObj.line?.color) {
        itemStrokeColor = xmlObj.line.color;
    }

    if (xmlObj.line?.width) {
        itemStrokeWidth = ptToPx(xmlObj.line.width);
    }

    if (xmlObj.line?.style) {
        lineStyle = xmlObj.line.style;
    }

    // Init path draw
    const path = Skia.Path.Make();
    const poligonCorners = xmlObj.clipping.shape[1].polygon.corners;

    switch (poligonCorners) {
        case 5:
            // TODO - Polygon with 5 corners
            break;
        case 6:
            path.moveTo(startX, startY + (itemHeight / 2));
            path.lineTo(startX + ((itemWidth / 2) / 2), startY);
            path.lineTo(startX + (itemWidth / 2) + ((itemWidth / 2) / 2), startY);
            path.lineTo(endX, startY + (itemHeight / 2));
            path.lineTo(startX + (itemWidth / 2) + ((itemWidth / 2) / 2), endY);
            path.lineTo(startX + ((itemWidth / 2) / 2), endY);
            break;
        case 7:
            // TODO - Polygon with 7 corners
            break;
        case 8:
            // TODO - Polygon with 8 corners
            break;
        case 10:
            // TODO - Polygon with 10 corners
            break;
        case 12:
            // TODO - Polygon with 12 corners
            break;
        default:
            // Do nothing
            break;
    }
 
    // End path draw
    path.close();

    // Apply dotted styles 
    // const dotSize:number = 14;
    // const lineSize:number = 27;
    // const dashSize:number = 54;
    const dotSize:number = itemStrokeWidth;
    const lineSize:number = dotSize*2;
    const dashSize:number = lineSize*2;
    
    switch (lineStyle) {
        case "DashDotDotLine":
            lineStyleArray = [dotSize, lineSize, dotSize, lineSize, dashSize, lineSize];
            break;
        case "DotLine":
            lineStyleArray = [dotSize, lineSize];
            break;
        case "DashLine":
            lineStyleArray = [dashSize, lineSize];
            break;
        case "DashDotLine":
            lineStyleArray = [dotSize, lineSize, dashSize ,lineSize];
            break;
    } 

    if (lineStyle === "NoPen") {
        return (
            <Path
                path={path}
                color={itemFillColor}
            />
        );
    } else {
        return (
            <Group>
                <Path
                    path={path}
                    color={itemFillColor}
                />
                <Path
                    path={path}
                    color={itemStrokeColor}
                    style="stroke"
                    // strokeJoin="round"
                    strokeWidth={itemStrokeWidth}
                    blendMode="src"
                >
                    {lineStyle !== 'SolidLine' &&
                        <DashPathEffect intervals={lineStyleArray} />
                    }
                </Path>
            </Group>
        );
    }
};

export default PolygonItem;