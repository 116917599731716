
const svg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="84.27px" height="84.27px" viewBox="0 0 84.27 84.27" enable-background="new 0 0 84.27 84.27" xml:space="preserve">
<polygon fill-rule="evenodd" clip-rule="evenodd" points="42.135,12.582 49.338,14.562 56.541,16.542 61.814,21.95 67.087,27.358 
	69.018,34.746 70.947,42.135 69.018,49.523 67.087,56.911 61.814,62.32 56.541,67.729 49.338,69.708 42.135,71.688 34.933,69.708 
	27.729,67.729 22.457,62.32 17.184,56.911 15.254,49.523 13.323,42.135 15.254,34.746 17.184,27.358 22.457,21.95 27.729,16.542 
	34.933,14.562 "/>
</svg>`
export { svg };