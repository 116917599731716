import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { StyleSheet, View, KeyboardAvoidingView, ScrollView, Dimensions, Image, Alert, Platform, TextInputMask } from 'react-native';
import { store, updateRecordSelected, setRecordSelected } from '../../../utils/Reducer';
import { getRecordById, convertGoogleDriveImageToBase64, getGoogleCredentials } from '../../Rest'
import Bottom from '../../components/BottomTabEditRecords';
import ImageComponent from '../../components/ImageComponent';
import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone';
import { FAB, TextInput } from 'react-native-paper';
import RecordImage from '../../components/input/RecordImage';
import DateTimeInput from '../../components/input/DateTimeInput';
import * as FileSystem from 'expo-file-system'
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';

import uuid from 'react-native-uuid';
import globalVars from '../../../helpers/store';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import Animated, { BounceInDown } from 'react-native-reanimated';

export const getFileInfo = async (fileURI) => {
  const fileInfo = await FileSystem.getInfoAsync(fileURI);
  return fileInfo;
}
export const isLessThanTheMB = (fileSize, smallerThanSizeMB) => {
  const isOk = fileSize / 1024 / 1024 < smallerThanSizeMB;
  return isOk;
}

function EditRecordScreen(props) {

  const { t, i18n } = useTranslation();
  const { record, recordSelectedId, updateRecordSelected, googleAccessToken } = props
  const { templateSelectedId } = props
  const { templateProperties } = props
  const deviceWidth = Math.round(Dimensions.get('window').width)
  const [imageFieldName, setImageFieldName] = useState(undefined)
  const [imageURI, setImageURI] = useState('')
  const [imageLoading, setImageLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imagePropertyId, setimagePropertyId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [insertMode, setInsertMode] = useState(false);

  const fileInputRefs = useRef([]);

  useEffect(
    () => {
      (async () => {

        if (recordSelectedId !== undefined) {
          setInsertMode(false);
          await getRecordById(recordSelectedId, templateSelectedId);
        } else {
          setInsertMode(true);
          setImageLoading(false);
          // CREATE NEW TMP RECORD  
          let fieldsObj = {};
          var fields = templateProperties.items.map((item) => {
            fieldsObj[item.id] = '';
            return item.id;
          });
          store.dispatch(setRecordSelected({
            recordId: 0,
            templateId: templateSelectedId,
            info: fieldsObj
          }));
        }
      })();

    },
    [recordSelectedId, templateSelectedId]
  )
  useEffect(
    () => {
      (async () => {

        if (record !== undefined && imageLoaded === false) {
          // setImageLoading(false);
          setLoading(false);
          let currentGoogleAccessToken = null;

          if (templateProperties.dataSource.type === 'GOOGLESHEETS') {
            if (googleAccessToken === undefined) {
              const googleCredentialsReturn = await getGoogleCredentials(templateSelectedId);
              currentGoogleAccessToken = googleCredentialsReturn.accessToken;
            } else {
              currentGoogleAccessToken = googleAccessToken;
            }
            setAccessToken(currentGoogleAccessToken);
          }

          var imgObj = templateProperties.items
            .filter((property, index) => (property.type === 'image' && (property.listable === 'true' || property.listable === true)))
            .filter((property, index) => (index <= 0));
          if (imgObj.length > 0) {
            var imageURL = record.info[imgObj[0].id];
            setimagePropertyId(imgObj[0].id);
          }

          if (templateProperties.dataSource.type === 'GOOGLESHEETS' && imageURL !== '') {
            setImageLoading(true);
            const imgObjBase64 = await convertGoogleDriveImageToBase64(imageURL, currentGoogleAccessToken);
            if (imgObjBase64 !== false && globalVars.cardLoadCancel === false) {
              setImageURI(imgObjBase64);
            }
            setImageLoading(false);
          } else {
            if (imageURL !== '' && globalVars.cardLoadCancel === false) {
              setImageURI(imageURL);
              setImageLoading(false);
            }
          }
          setImageLoaded(true);
        }

      })();
    },
    [record]
  );

  // CLEAR ACTIVE RECORD ON UNMOUNT
  useEffect(() => {
    setImageURI('');
    return () => {
      store.dispatch(setRecordSelected(undefined))
      setImageURI('');
    }
  }, []);

  const alertBox = (title, body) => {
    if (Platform.OS === 'web') {
      alert(title + '\n' + body);
    } else {
      Alert.alert(
        title,
        body
      );
    }
    return;
  }

  const imageResize = async (base64Image) => {
    return new Promise((resolve) => {
      (async () => {
        const manipResult = await manipulateAsync(
          base64Image,
          [
            { resize: { width: 1024 } },
            { rotate: 0 },
          ],
          {
            compress: 1,
            format: SaveFormat.PNG,
            base64: true
          }
        );
        // GET base64 from file 
        //const convertedBase64Image = await FileSystem.readAsStringAsync(manipResult.uri, { encoding: FileSystem.EncodingType.Base64 });        
        const convertedBase64ImageToReturn = 'data:image/png;base64,' + manipResult.base64;
        resolve(convertedBase64ImageToReturn);
      })();
    });
  }

  const pickImage = async (index) => {
    fileInputRefs.current[index].click();
  };

  const handleFileChange = (event, index, propertyId) => {
    const selectedFile = event.target.files[0];
    // console.log('Selected file:', selectedFile);
    // const fileToUpload = file;
    var reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = async function () {
      let newImage = await imageResize(reader.result);
      updateRecordSelected({ field: propertyId, value: newImage });
    };
  };

  const handleChange = (name, type = null) => event => {

    let value = event;

    // Validate data Types
    if (type != null) {
      switch (type) {

        case "integer":
          const numericValue = value.replace(/[^0-9]/g, '');
          value = numericValue;
          break;

        case "decimal":
          const decimalValue = value.replace(/[^0-9.]/g, '');
          value = decimalValue;
          break;

        case "date":
          // Format the date as YYYY-MM-DD
          const dateNumericValue = value.replace(/\D/g, '');

          if (dateNumericValue.length > 4) {
            value = dateNumericValue.slice(0, 4) + '-' + dateNumericValue.slice(4, 6) + '-' + dateNumericValue.slice(6, 8);
          } else if (value.length > 6) {
            value = dateNumericValue.slice(0, 4) + '-' + dateNumericValue.slice(4, 6) + '-' + dateNumericValue.slice(6, 8);
          }

          value = value;
          break;

        case "time":

          // Format the date as HH:MM:SS
          const TimeNumericValue = value.replace(/\D/g, '');

          if (TimeNumericValue.length > 2) {
            value = TimeNumericValue.slice(0, 2) + ':' + TimeNumericValue.slice(2, 4) + ':' + TimeNumericValue.slice(4, 6);
          } else if (value.length > 4) {
            value = TimeNumericValue.slice(0, 2) + ':' + TimeNumericValue.slice(2, 4) + ':' + TimeNumericValue.slice(4, 6);
          }
          value = value;
          break;

        case "datetime":

          // Format the date as YYYY-MM-DD HH:MM:SS 
          const dateTimeNumericValue = value.replace(/\D/g, '');

          // Add hyphens and colons based on the length of the input
          if (dateTimeNumericValue.length > 4 && dateTimeNumericValue.length < 6) {
            value = dateTimeNumericValue.slice(0, 4) + '-' + dateTimeNumericValue.slice(4, 6) + '-' + dateTimeNumericValue.slice(6, 8);
          }

          if (dateTimeNumericValue.length > 10) {
            value = dateTimeNumericValue.slice(0, 4) + '-' + dateTimeNumericValue.slice(4, 6) + '-' + dateTimeNumericValue.slice(6, 8) + ' ' + dateTimeNumericValue.slice(8, 10) + ':' + dateTimeNumericValue.slice(10, 12) + ':' + dateTimeNumericValue.slice(12, 14);
          } else if (dateTimeNumericValue.length > 6 && dateTimeNumericValue.length < 8) {
            value = dateTimeNumericValue.slice(0, 4) + '-' + dateTimeNumericValue.slice(4, 6) + '-' + dateTimeNumericValue.slice(6, 8);
          }

          value = value;
          break;

        default:
          break;
      }
    }

    updateRecordSelected({ field: name[0], value: value })


  }
  const updateLoadingState = state => {
    setLoading(state);
  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 1,
    multiple: false
  });
  const files = acceptedFiles.map(file => (
    pickImage(imagePropertyId, file)
  ));

  return (
    <View style={styles.container}>
      <Loader loading={loading || imageLoading} />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          justifyContent: 'center',
          alignContent: 'center',
          width: deviceWidth - 25,
        }}>
        {templateProperties.items !== undefined && record !== undefined &&
          <Animated.View entering={BounceInDown.duration(800)}  >
            <View style={styles.formHolder} >
              <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : "height"}>

                {/* {record !== undefined && imageLoading === true && <Image style={styles.imageStyle} source={require('../../../assets/loading.gif')} resizeMode='contain' />}
                {imagePropertyId !== null && imageLoading === false &&
                  <View>
                    <ImageComponent
                      key={uuid.v4()}
                      value={(record !== undefined) ? imageURI : ''} />
                    <View style={styles.dropZoneHolder}>
                      <div {...getRootProps({ className: 'dropzone' })} >
                        <input {...getInputProps()} />
                        <FAB
                          style={styles.fab}
                          color="#FFF"
                          icon="folder-image"
                        />
                      </div>
                      {/* <Dropzone 
                      accept="image/jpg"
                      onDrop={acceptedFiles => { 
                        pickImage(imagePropertyId, acceptedFiles);
                      }}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <FAB
                                style={styles.fab}
                                color="#FFF"
                                icon="folder-image"
                              />
                            </div>
                          </section>
                        )}
                      </Dropzone> */}
                {/* </View>
                  </View> */}
                {/* }  */}

                <View style={styles.imagePickerHolder}>

                  {templateProperties.items
                    .filter((property) => (property.type === 'image'))
                    .map((property, index) => {
                      var imgObjs = templateProperties.items.filter((property, index) => (property.type === 'image' && (property.listable === 'true' || property.listable === true)));
                      return (
                        <View key={index} style={[styles.imagePickerItem, (imgObjs.length === index + 1) ? { alignSelf: 'flex-start', marginTop: 5, marginRight: 'auto' } : {}]} >
                          <input
                            type="file"
                            accept="image/*"  // Specify the file types allowed (e.g., images)
                            style={{ display: 'none' }}
                            ref={(el) => (fileInputRefs.current[index] = el)}
                            onChange={(event) => { handleFileChange(event, index, property.id) }}
                          />
                          <RecordImage
                            key={index}
                            record={record}
                            propertyId={property.id}
                            value={(record !== undefined) ? record.info[property.id] : ''}
                            placeholder={property.displayName}
                            onPress={(source) => {
                              pickImage(index);
                            }}
                          />
                        </View>
                      )
                    })
                  }
                </View>

                {templateProperties.items
                  .filter((property) => (property.type === 'text'))
                  .map((property, index) => (
                    // <TextInput
                    //   key={index}
                    //   style={styles.input}
                    //   onChangeText={handleChange([property.id])}
                    //   value={(record !== undefined) ? record.info[property.id] : ''}
                    //   placeholder={property.displayName}
                    //   keyboardType="default" />
                    <TextInput
                      key={index}
                      style={property.required ? styles.input : styles.optionalInput}
                      onChangeText={handleChange([property.id])}
                      value={(record !== undefined) ? record.info[property.id] : ''}
                      label={property.displayName}
                      keyboardType="default"
                      mode="outlined"
                    />
                  ))
                }

                {templateProperties.items
                  .filter((property) => (property.type === 'integer'))
                  .map((property, index) => (
                    <TextInput
                      key={index}
                      style={styles.input}
                      onChangeText={handleChange([property.id], property.type)}
                      value={(record !== undefined) ? record.info[property.id] : ''}
                      label={property.displayName}
                      keyboardType="numeric"
                      inputMode='numeric'
                      mode="outlined"
                    />
                  ))
                }
                {templateProperties.items
                  .filter((property) => (property.type === 'decimal'))
                  .map((property, index) => (
                    <TextInput
                      key={index}
                      style={styles.input}
                      onChangeText={handleChange([property.id], property.type)}
                      value={(record !== undefined) ? record.info[property.id] : ''}
                      label={property.displayName}
                      keyboardType="numeric"
                      inputMode='decimal'
                      mode="outlined"
                    />
                  ))
                }

                {templateProperties.items
                  .filter((property) => (property.type === 'date'))
                  .map((property, index) => (
                    <TextInput
                      key={index}
                      style={styles.input}
                      onChangeText={handleChange([property.id], property.type)}
                      value={(record !== undefined) ? record.info[property.id] : ''}
                      label={property.displayName + " (YYYY-MM-DD)"}
                      mode="outlined"
                    // render={props =>
                    //   <TextInputMask
                    //     {...props}
                    //     mask="[0000-00-00]"
                    //   />
                    // }
                    />
                  ))
                }
                {templateProperties.items
                  .filter((property) => (property.type === 'time'))
                  .map((property, index) => (
                    <TextInput
                      key={index}
                      style={styles.input}
                      onChangeText={handleChange([property.id], property.type)}
                      value={(record !== undefined) ? record.info[property.id] : ''}
                      label={property.displayName + " (HH:MM:SS)"}
                      mode="outlined"
                    // render={props =>
                    //   <TextInputMask
                    //     {...props} 
                    //     mask="[0000-00-00]"
                    //   />
                    // }
                    />
                  ))
                }
                {templateProperties.items
                  .filter((property) => (property.type === 'datetime'))
                  .map((property, index) => (
                    <TextInput
                      key={index}
                      style={styles.input}
                      onChangeText={handleChange([property.id], property.type)}
                      value={(record !== undefined) ? record.info[property.id] : ''}
                      label={property.displayName + " (YYYY-MM-DD HH:MM:SS)"}
                      mode="outlined"
                    // render={props =>
                    //   <TextInputMask
                    //     {...props} 
                    //     mask="[0000-00-00]"
                    //   />
                    // }
                    />
                  ))
                }

              </KeyboardAvoidingView>
            </View>
          </Animated.View>
        }
      </ScrollView>
      {record !== undefined &&
        <View style={styles.footerStyle} >
          <Bottom option1={t('STR_SAVE')}
            // option2="Save and Print"
            value={record.recordId}
            record={record}
            navigation={props.navigation}
            templateProperties={templateProperties}
            accessToken={accessToken}
            loading={updateLoadingState}
            insertMode={insertMode}
          />
        </View>
      }
    </View>
  );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  formHolder: {
    ...Platform.select({
      ios: {
        width: '100%',
        height: '100%',
        paddingBottom: 80,
      },
      android: {
        width: '100%',
        height: '100%',
        paddingBottom: 80,
      },
      default: {
        maxWidth: '100%',
        width: 500,
        height: '100%',
        paddingBottom: 80,
      }
    }),
    alignSelf: 'center'
  },
  input: {
    // height: 40,
    // width: '97%',
    // margin: 5,
    // borderWidth: 1,
    // padding: 10,
    marginTop: 10,
    // borderRadius: 10,
  },
  optionalInput: {
    // height: 40,
    // width: '97%',
    // margin: 5,
    // borderWidth: 1,
    // padding: 10,
    marginTop: 10,
    // borderRadius: 10,
    backgroundColor: '#F6F6F6',
    fontStyle: 'italic',
  },
  image: {
    height: 200,
    width: 200
  },
  footerStyle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  imageStyle: {
    width: deviceWidth - 500,
    height: 115,
    marginTop: 20,
    marginHorizontal: 8,
    borderRadius: 10,
    alignSelf: 'center'
  },
  dropZoneHolder: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    marginVertical: 10
  },
  fab: {
    backgroundColor: "#05AC72",
    zIndex: -1,
    width: 56,
    height: 56,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  imagePickerHolder: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap', // Add this line
    flexDirection: 'row', // Add this line if it's not already there,
    marginTop: 5
  },
});

const mapStateToProps = state => {
  return {
    record: state.recordSelected,
    recordSelectedId: state.recordSelectedId,
    recordUpdated: state.recordUpdated,
    templateSelectedId: state.templateSelectedId,
    templateProperties: state.templateProperties,
    googleAccessToken: state.googleAccessToken
  }
}

export default connect(mapStateToProps, { updateRecordSelected })(EditRecordScreen)
