
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Animated, { BounceInDown } from 'react-native-reanimated';
import { NativeModules, Platform, StyleSheet, View, ScrollView, TouchableOpacity, Text, Alert, Dimensions, Image } from 'react-native';
import { TextInput, Button, useTheme } from 'react-native-paper';
// import { MaskedTextInput } from 'react-native-mask-text';
import Loader from '../../components/Loader';
import * as cpPrint from '@cardpressodevelopment/expo-cp-print';
import { DeviceEventEmitter } from 'react-native';
import { getPrinterImageUrl } from '../../../helpers/utils'
import printerDB from '../../../helpers/db/models/printer'
import { useNavigation } from '@react-navigation/native';
import DropDownPicker from 'react-native-dropdown-picker';


import axios from 'axios';
import NetInfo from "@react-native-community/netinfo";
import { store, setLocalPrintSearchActive } from '../../../utils/Reducer'
// import { manufacturer } from 'expo-device';

export type props = {
};

interface Printer {
    manufacturer: any,
    model: string,
    modelName: string,
    printerSn: string,
    ipAddress: string,
}
interface NetworkItem {
    id: number,
    ipAddress: string,
    port: number
}

const AddPrinterModal: React.FC<props> = ({
}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const navigation = useNavigation();
    const [printerIp, setPrinterIp] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [networkNotFoundShow, setNetworkNotFoundShow] = useState<boolean>(false);
    const [loaderText, setLoaderText] = useState<string | undefined>(undefined);
    const [searchForDevices, setSearchForDevices] = useState<boolean>(false);

    const [openManufacturer, setOpenManufacturer] = useState(false);
    const [manufacturerValue, setManufacturerValue] = useState("EVOLIS");
    const [avaliableManufacturers, setAvaliableManufacturers] = useState([
        { label: 'Evolis', value: 'EVOLIS' },
        { label: 'Zebra', value: 'ZEBRA' }
    ]);

    const [printerInfo, setPrinterInfo] = useState<Printer | null>(null);
    const [networkItems, setNetworkItems] = useState<NetworkItem[]>([]);
    const [printerImage, setPrinterImage] = useState<string | undefined>(undefined);
    let networkDevices: NetworkItem[] = [];

    function validateIPaddress(ipaddress: string): boolean {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true);
        }
        return (false);
    }

    const addNewItem = useCallback((itemToPush: NetworkItem, networkDevices: NetworkItem[]) => {

        (async () => {

            // Check if item already exists
            const alreadyExists = networkDevices.find(it => it.ipAddress === itemToPush.ipAddress);
            if (alreadyExists) {
                return;
            }

            // const connectionOK = await cpPrint.connectToPrinter(itemToPush.ipAddress);
            // if (connectionOK === false) {
            //     return;
            // }

            setNetworkItems(networkDevices => {
                const nextItemId = (networkDevices[networkDevices.length - 1]?.id ?? 0) + 1;
                return [...networkDevices,
                {
                    id: nextItemId,
                    ipAddress: itemToPush.ipAddress,
                    port: itemToPush.port
                }];
            });

        })();
    }, []);

    const connectToPrinter = (ipAdress: string) => {

        (async () => {

            setNetworkNotFoundShow(false);

            const deviceIsConnectedToNetwork = await connectedToNetwork();
            if (!deviceIsConnectedToNetwork) {
                Alert.alert(t('STR_ERROR_NOT_CONNECTED_TO_WIFI'));
                return;
            }

            if (validateIPaddress(ipAdress) === false) {
                Alert.alert('Invalid IP address');
                return;
            }

            setLoading(true);
            await cpPrint.connectToPrinter(ipAdress).then(async function (connectResult) {
                if (connectResult === true) {
                    await cpPrint.getPrinterInfo().then(async function (printerInfo) {
                        // console.log(printerInfo);
                        setNetworkItems([]);
                        const printerInfoResult: Printer = {
                            manufacturer: manufacturerValue,
                            model: printerInfo.printerModel,
                            modelName: printerInfo.printerModelName,
                            printerSn: printerInfo.printerSerialNumber,
                            ipAddress: ipAdress
                        }
                        setLoading(false);
                        setPrinterInfo(printerInfoResult);
                        const printerImage: string = await getPrinterImageUrl(printerInfo.printerModelName.split(" ")[0], printerInfo.printerModelName.split(" ")[1]);
                        setPrinterImage(printerImage);

                    }).catch(function (error: any) {
                        Alert.alert('Printer data error');
                        console.log(error);
                    });
                } else {
                    Alert.alert('Printer connection error');
                    setLoading(false);
                    return;
                }
            }).catch(function (error: any) {
                Alert.alert('Printer connection error');
                console.log(error);
                setLoading(false);
                return;
            });
            setLoading(false);
        })();
    }

    function sleep(ms: number) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    }

    const findLanDevicesCancel = () => {
        (async () => {
            setLoading(false);
            setLoaderText(undefined);
            setSearchForDevices(false);
            networkDevices = [];
            store.dispatch(setLocalPrintSearchActive(false))
        })();
    }

    const checkIpAddress = async (ipAddress: string) => {
        return new Promise((resolve) => {
            try {
                axios.get('http://' + ipAddress, { timeout: 100 }).then((response) => {
                    resolve(true);
                }).catch((error) => {
                    resolve(false);
                })
            } catch (error) {
                resolve(false);
            }
        });
    };

    // Function to find network devices within a given IP range
    const ipSearch = async (startIp: string, endIp: string) => {
        return new Promise((resolve) => {
            (async () => {
                const start = startIp.split('.');
                const end = endIp.split('.');
                const devices = [];

                for (let a: any = start[0]; a <= end[0]; a++) {
                    for (let b: any = start[1]; b <= end[1]; b++) {
                        for (let c: any = start[2]; c <= end[2]; c++) {
                            for (let d: any = start[3]; d <= end[3]; d++) {

                                const storeData = store.getState();
                                if (storeData.localPrintSearchActive === false) {
                                    break;
                                }

                                const ipAddress = `${a}.${b}.${c}.${d}`;
                                // console.log("IP:" + ipAddress);
                                setLoaderText(ipAddress);
                                const isConnected = await checkIpAddress(ipAddress);
                                if (isConnected) {
                                    devices.push(ipAddress);
                                    let dataToPush: NetworkItem = {
                                        id: 0,
                                        ipAddress: ipAddress,
                                        port: 80,
                                    }
                                    addNewItem(dataToPush, networkDevices);
                                    networkDevices.push(dataToPush)
                                }
                            }
                        }
                    }
                }
                resolve(devices);
            })();
        });
    };

    const connectedToNetwork = async () => {
        return new Promise((resolve) => {
            try {
                NetInfo.fetch().then(async (state: any) => {
                    if (state.isConnected === false) {
                        resolve(false);
                        return;
                    }

                    if (state.isConnected && state.type === 'wifi') {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } catch (error) {
                resolve(false);
            }
        });
    };

    const lanDevicesSearch = async () => {
        return new Promise((resolve) => {
            try {

                NetInfo.fetch().then(async (state: any) => {

                    if (state.isConnected === false || state.type !== "wifi") {
                        Alert.alert(t('STR_ERROR_NOT_CONNECTED_TO_WIFI'));
                        resolve(false);
                        return;
                    }

                    const myIpAddress = state.details.ipAddress
                    if (myIpAddress === undefined) {
                        resolve(false);
                        return;
                    }
                    const myIpAddressArray = myIpAddress.split(".");
                    const startIp = myIpAddressArray.slice(0, 3).join(".") + '.1';
                    const endIp = myIpAddressArray.slice(0, 3).join(".") + '.255';

                    if (state.isConnected && state.type === 'wifi') {
                        const devices = await ipSearch(startIp, endIp);
                        // console.log('Devices:', devices);
                        resolve(true);
                    } else {
                        Alert.alert(t('STR_ERROR_NOT_CONNECTED_TO_WIFI'));
                        resolve(false);
                    }
                });
            } catch (error) {
                resolve(false);
            }
        });
    };


    const findLanDevices = () => {
        (async () => {

            store.dispatch(setLocalPrintSearchActive(true))
            networkDevices = [];
            setSearchForDevices(true);
            setLoading(true);
            setNetworkNotFoundShow(false);
            setPrinterImage(undefined);
            setPrinterInfo(null);
            setPrinterIp('')
            setNetworkItems([]);

            await lanDevicesSearch();

            setNetworkItems(networkDevices);
            setLoaderText(undefined);
            setLoading(false);
            setSearchForDevices(false);

            if (networkDevices.length === 0) {
                setNetworkNotFoundShow(true);
            }

        })();
    }

    const addLocalPrinterToDb = (printerInfo: Printer) => {
        (async () => {
            try {
                const newPrinterInfo = {
                    manufacturer: manufacturerValue,
                    model: printerInfo.model,
                    modelName: printerInfo.modelName,
                    printerSn: printerInfo.printerSn,
                    ipAddress: printerInfo.ipAddress,
                    localPrinter: true,
                }
                // await printerDB.destroyAll();
                // await printerDB.dropTable();
                const printerInfoObj = new printerDB(newPrinterInfo);
                const printerInfoRet = await printerInfoObj.save();
                navigation.navigate('PrintersList' as never);

            } catch (e) {
                Alert.alert(t('STR_ERROR_ADDING_PRINTER'));
                console.log(e);
            }
        })();
    }

    // DEBUG porpuses ONLY !
    // useEffect(() => {
    //     (async () => {
    //         const printerInfoResult: Printer = {
    //             manufacturer: "Evolis",
    //             model: "Primacy",
    //             modelName: "Evolis Primacy",
    //             printerSn: "SN123456789",
    //             ipAddress: "1.2.3.4"
    //         }
    //         const printerImage: string = await getPrinterImageUrl(printerInfoResult.modelName.split(" ")[0], printerInfoResult.modelName.split(" ")[1]);
    //         setPrinterImage(printerImage);
    //         setPrinterInfo(printerInfoResult);
    //     })();
    // }, [])

    useEffect(() => {
        (async () => {
            if (manufacturerValue !== null) {
                await cpPrint.setManufacturer(manufacturerValue);
            }
        })();
    }, [manufacturerValue])

    useEffect(() => {
        (async () => {
            // Check avaliable SDK on current platform (iOS or Android)
            const cpPrintAvaliableManufacturers = await cpPrint.avaliableManufacturers();
                        
            for (let index = 0; index < avaliableManufacturers.length; index++) {
                const manufacturerItem = avaliableManufacturers[index];                
                if (!cpPrintAvaliableManufacturers.includes(manufacturerItem.value)){                    
                    const updatedManufacturers = avaliableManufacturers.filter(
                        manufacturer => manufacturer.value !== manufacturerItem.value
                    );                    
                    setAvaliableManufacturers(updatedManufacturers);
                }
            }

        })();
    }, [])

    return (
        <View>

            <View style={[styles.container, { zIndex: 2, marginBottom: -5 }]}>
                <DropDownPicker
                    open={openManufacturer}
                    value={manufacturerValue}
                    items={avaliableManufacturers}
                    setOpen={setOpenManufacturer}
                    setValue={setManufacturerValue}
                    setItems={setAvaliableManufacturers}
                    placeholder={t('STR_LABEL_CHOOSE_MANUFACTURER')}
                    style={{ borderWidth: 1, borderRadius: 0, borderColor: theme.colors.grey }}
                />
            </View>

            <ScrollView contentContainerStyle={{ flexGrow: 1 }} >
                <View style={[styles.container]}>
                    <Animated.View entering={BounceInDown.duration(800)}  >

                        <View style={styles.rowHolder}>

                            <View style={[styles.colHolder, { width: '55%' }]}>
                                <TextInput
                                    label={t('STR_LABEL_IP_ADDRESS')}
                                    mode="outlined"
                                    style={styles.inputMasked}
                                    keyboardType="numbers-and-punctuation"
                                    onChangeText={text => setPrinterIp(text)}
                                    value={printerIp}
                                //     />
                                // }
                                // render={(props) => (
                                //     <MaskedTextInput
                                //         {...props}
                                //         style={styles.inputMasked}
                                //         value={printerIp}
                                //         //mask="999.999.999.999"
                                //         keyboardType="numeric"
                                //         onChangeText={(text) => {
                                //             props.onChangeText?.(text);
                                //             setPrinterIp(text)
                                //         }}
                                //     />
                                // )}
                                />
                            </View>
                            <View style={[styles.colHolder, { width: '42%' }]}>
                                <Button
                                    color='#000'
                                    mode='contained'
                                    style={{
                                        backgroundColor: '#05AC72',
                                        width: '100%',
                                        height: 50,
                                        textAlign: 'center',
                                        marginTop: 5,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onPress={() => connectToPrinter(printerIp)} >
                                    {t('STR_LABEL_CONNECT')}
                                </Button>
                            </View>
                        </View>
                        <View>
                            <Button
                                color='#000'
                                mode='contained'
                                style={{
                                    backgroundColor: '#05AC72',
                                    width: '100%',
                                    height: 50,
                                    textAlign: 'center',
                                    marginTop: 10,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: 10
                                }}
                                onPress={() => findLanDevices()} >
                                {t('STR_LABEL_FIND_NETWORK_DEVICES')}
                            </Button>
                        </View>

                        <View>
                            {printerInfo !== null &&
                                <Animated.View entering={BounceInDown.duration(1000)} style={styles.cardHolder}>
                                    <View style={styles.itemsHolder}>
                                        <View style={styles.itemCol}>
                                            <View>
                                                {printerImage === undefined && <Image style={styles.imageStyle} source={require('../../../assets/loading.gif')} resizeMode='cover' />}
                                                {printerImage !== undefined && <Image style={[styles.imageStyle]} source={{
                                                    uri: printerImage,
                                                    method: 'GET',
                                                }} resizeMode='cover' />}
                                            </View>
                                        </View>
                                        <View style={styles.itemCol}>
                                            <View style={styles.titleHolder}>
                                                <View style={{ width: '100%' }}>
                                                    <Text style={[styles.infomanufacturer]}>{printerInfo.manufacturer}</Text>
                                                </View>

                                                <View style={styles.infoHolderView}>
                                                    <Text style={[styles.infoLabel]}>{t('STR_PRINTER_MODEL')}</Text>
                                                    <Text style={[styles.infoText]}>{printerInfo.modelName}</Text>
                                                </View>

                                                <View style={styles.infoHolderView}>
                                                    <Text style={[styles.infoLabel]}>{t('STR_PRINTER_SERIAL_NUMBER')}</Text>
                                                    <Text style={[styles.infoText]}>{printerInfo.printerSn}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View>
                                        <Button
                                            color='#000'
                                            mode='contained'
                                            style={{
                                                backgroundColor: '#05AC72',
                                                width: '100%',
                                                height: 50,
                                                textAlign: 'center',
                                                marginTop: 15,
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginBottom: 10
                                            }}
                                            onPress={() => addLocalPrinterToDb(printerInfo)} >
                                            {t('STR_LABEL_ADD_PRINTER')}
                                        </Button>
                                    </View>
                                </Animated.View>
                            }
                            {networkItems.length > 0 &&
                                networkItems.map((item, index) => {
                                    return (
                                        <Animated.View entering={BounceInDown.duration(1000)} style={styles.networkCardHolder} key={index}>
                                            <TouchableOpacity
                                                onPress={() => connectToPrinter(item.ipAddress)}
                                            >
                                                <View style={styles.itemsHolder}>
                                                    <View style={styles.itemColIp}>
                                                        <View style={styles.itemColIconHolder}>
                                                            <MaterialCommunityIcons name="check-network-outline" size={40} color="#05AC72" />
                                                        </View>
                                                    </View>
                                                    <View style={styles.itemColIp}>
                                                        <View style={styles.titleHolder}>
                                                            <View style={styles.infoHolderView}>
                                                                <Text style={[styles.infoLabel]}>{t('STR_LABEL_IP_ADDRESS')}</Text>
                                                                <Text style={[styles.infoText]}>{item.ipAddress}</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            </TouchableOpacity>
                                        </Animated.View>
                                    );
                                })
                            }
                            {networkNotFoundShow &&
                                <Animated.View entering={BounceInDown.duration(1000)} style={styles.networkCardHolder}>

                                    <View style={styles.itemsHolder}>
                                        <View style={styles.itemColIp}>
                                            <View>
                                                <MaterialCommunityIcons name="close-network-outline" size={40} color="#000" />
                                            </View>
                                        </View>
                                        <View style={styles.itemColIp}>
                                            <View style={styles.titleHolder}>
                                                <View style={[styles.infoHolderView, {
                                                    justifyContent: 'center',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    height: '100%'
                                                }]}>
                                                    <Text style={[styles.infoLabel]}>{t('STR_LABEL_FIND_NETWORK_DEVICES_NOT_FOUND')}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </Animated.View>
                            }
                        </View>

                    </Animated.View>
                </View>
                <Loader loading={loading} text={searchForDevices ? loaderText : undefined}>
                    {searchForDevices && loaderText !== undefined &&
                        <View>
                            <Button
                                color='#000'
                                mode='contained'
                                style={{
                                    backgroundColor: '#05AC72',
                                    width: '100%',
                                    height: 50,
                                    textAlign: 'center',
                                    marginTop: 50,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                }}
                                onPress={() => findLanDevicesCancel()} >
                                {t('STR_CANCEL')}
                            </Button>
                        </View>
                    }
                </Loader>
            </ScrollView>
        </View>

    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
        marginHorizontal: 10
    },
    inputMasked: {
        flex: 1,
        height: 50,
    },
    rowHolder: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: "space-between",
    },
    colHolder: {

    },
    cardHolder: {
        marginTop: 5,
        backgroundColor: '#e1e2e3',
        height: 'auto',
        borderRadius: radius,
        borderWidth: 0,
        marginBottom: 15,
        padding: 10,
        paddingBottom: 15,
        position: 'relative',
    },
    networkCardHolder: {
        marginTop: 5,
        backgroundColor: '#e1e2e3',
        height: 'auto',
        borderRadius: radius,
        borderWidth: 0,
        marginBottom: 5,
        paddingTop: 5,
        paddingBottom: 5,
        position: 'relative',
    },
    itemsHolder: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    itemCol: {
        width: '40%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 40,
    },
    itemColIp: {
        width: '40%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 5
    },
    imageStyle: {
        width: 100,
        height: 100
    },
    titleHolder: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        ...Platform.select({
            ios: {
            },
            android: {
            },
            default: {
                maxHeight: 30,
            }
        }),
    },
    infomanufacturer: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#000',
        marginBottom: 5
    },
    infoHolderView: {
        // justifyContent: 'center',
        // alignSelf: 'center',
        // alignItems: 'center',
        // height: '100%'
    },
    infoLabel: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000',
    },
    infoText: {
        fontSize: 14,
        color: '#000',
    },
    itemColIconHolder: {
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        height: '100%'
    }

});

export default AddPrinterModal;
