import Repository from './Repository'

const isFunction = (p: any) =>
  Object.prototype.toString.call(p) === '[object Function]'

export default class BaseModel {
  columnMapping: any;
  tableName: any; 
  id: any;

  constructor(obj = {}) {
    this.setProperties(obj)
  }

  setProperties(props: any) {
    // @ts-ignore
    const cm: any = this.constructor.columnMapping
    Object.keys(cm).forEach( ( k: any ) => {
      if (props[k] !== undefined) {
        // @ts-ignore
        this[k] = props[k]
      } else if (isFunction(cm[k].default)) {
        // @ts-ignore
        this[k] = cm[k].default()
      } else {
        // @ts-ignore
        this[k] = null
      }
    })
    return this
  }

  static get database() {
    throw new Error('Database não definida')
  }

  static get repository() {
    return new Repository(this.database, this.tableName, this.columnMapping)
  }

  static get tableName() {
    throw new Error('tableName não definido')
  }

  static get columnMapping() {
    return {}
  }

  static createTable() {
    return this.repository.createTable()
  }

  static dropTable() {
    return this.repository.dropTable()
  }

  static create(obj: any) {
    return this.repository.insert(obj).then((res: any) => new this(res))
  }

  static update(obj: any) {
    return this.repository.update(obj).then((res: any) => new this(res))
  }

  save() {
    if (this.id) {
      // @ts-ignore
      return this.constructor.repository
        .update(this)
        .then((res: any) => this.setProperties(res))
    } else {
      // @ts-ignore
      return this.constructor.repository
        .insert(this)
        .then((res: any) => this.setProperties(res))
    }
  }

  destroy() {
    // @ts-ignore
    return this.constructor.repository.destroy(this.id)
  }

  static destroy(id: any) {
    return this.repository.destroy(id)
  }

  static destroyAll() {
    return this.repository.destroyAll()
  }

  static find(id: any) {
    return this.repository.find(id).then((res: any) => (res ? new this(res) : res))
  }

  static findBy(where: any) {
    return this.repository
      .findBy(where)
      .then((res: any) => (res ? new this(res) : res))
  }

  /**
   * @param {columns: '*', page: 1, limit: 30, where: {}, order: 'id DESC'} options
   */
  static query(options: any) {
    return this.repository.query(options)
  }
}
