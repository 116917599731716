
import * as SQLite from 'expo-sqlite'
import { BaseModel, types } from '../classes/index';
import configs from '../../config';

export default class printer extends BaseModel {
    constructor(obj: any) {
        super(obj)
    }

    static get database(): any {
        return async () => SQLite.openDatabase(configs.sqlLiteDbName)
    }

    static get tableName(): any {
        return 'printers'
    }

    static get columnMapping(): any {
        return {
            id: { type: types.INTEGER, primary_key: true, autoincrement: true },
            printerName: { type: types.TEXT, not_null: false },
            manufacturer: { type: types.TEXT, not_null: false },
            model: { type: types.TEXT, not_null: false },
            modelName: { type: types.TEXT, not_null: false },
            printerSn: { type: types.TEXT, not_null: true },
            ipAddress: { type: types.TEXT, not_null: false },
            localPrinter: { type: types.BOOLEAN, not_null: true },
            timestamp: { type: types.INTEGER, default: () => Date.now() }
        }
    }

    static removeCloudPrinters() {
        const sql: any = 'DELETE FROM printers WHERE localPrinter = false'
        const params: [] = [] 
        return this.repository.databaseLayer.executeSql(sql, params).then(({ rows }) => rows)
    }
}