import { StyleSheet, Platform } from 'react-native';
import configs from '../helpers/config';

export default StyleSheet.create({
    droidSafeArea: {
        flex: 1,
        backgroundColor: '#05AC72', 
        paddingTop: Platform.OS === 'android' ? configs.CustomStyles.developmentMenuPadding : 50,
        marginTop: Platform.OS === 'android' ? 0 : -50,
    }
});