import React, { useState, createRef, useEffect } from 'react';
import { store } from '../../../utils/Reducer';
import { connect } from 'react-redux';
import {
  Platform, StyleSheet, TextInput, View, Text, ScrollView,
  Image, Keyboard, TouchableOpacity, KeyboardAvoidingView, Alert,
  BackHandler
} from 'react-native';
import { setLoginInfo } from '../../../utils/Reducer'
import { signIn } from '../../Rest';
// import Icon from 'react-native-vector-icons/Ionicons';
// import base64 from 'react-native-base64';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Checkbox } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import Animated, { FadeInDown } from 'react-native-reanimated';
import * as Device from 'expo-device';
import globalVars from '../../../helpers/store';
import { useFocusEffect, CommonActions } from '@react-navigation/native';
import { deviceIsOnline } from '../../../helpers/utils';

const SignInScreen = ({ navigation }) => {

  const { t, i18n } = useTranslation();
  const [values, setValues] = useState({ name: '', email: '', password: '', showPassword: false })
  const passwordInputRef = createRef();
  const [remindMe, setRemindMe] = useState(false);
  // const { webApiUrl } = getEnvVars();

  const alertBox = (title, body) => {
    if (Platform.OS === 'web') {
      alert(title + '\n' + body);
    } else {
      Alert.alert(
        title,
        body
      );
    }
    return;
  }

  // PREVENT GO BACK ON THIS SCREEN
  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        // Return true to stop default back navigaton
        // Return false to keep default back navigaton
        return true;
      };

      // Add Event Listener for hardwareBackPress
      BackHandler.addEventListener(
        'hardwareBackPress',
        onBackPress
      );
      return () => {
        // Once the Screen gets blur Remove Event Listener
        BackHandler.removeEventListener(
          'hardwareBackPress',
          onBackPress
        );
      };
    }, []),
  );

  const handleSubmitPress = () => {
    (async () => {

      if (Platform.OS !== 'web') {

        const deviceIsOnlineResult = await deviceIsOnline();
        if (deviceIsOnlineResult === false) {
          alertBox(
            t('STR_ERROR'),
            t('STR_ALERT_DEVICE_IS_OFFLINE')
          );
          return;
        }

      }

      if (values.email === '') {
        alertBox(
          t('STR_ERROR'),
          t('STR_ALERT_FILL_EMAIL')
        );
        return;
      }
      if (values.password === '') {
        alertBox(
          t('STR_ERROR'),
          t('STR_ALERT_FILL_PASSWORD')
        );
        return;
      }

      const deviceInfo = {
        platform: Platform.OS,
        deviceBrand: Device.brand,
        deviceManufacturer: Device.manufacturer,
        deviceOsBuildId: Device.osBuildId,
        deviceOsName: Device.osName,
        deviceOsVersion: Device.osVersion,
        deviceSupportedCpuArchitectures: Device.supportedCpuArchitectures,
        deviceTotalMemory: Device.totalMemory,
        browserInfo: ((Platform.OS === 'web') ? window.navigator.userAgent : null)
      }

      let valuesToPost = values;
      valuesToPost.deviceInfo = JSON.stringify(deviceInfo);
      const signInResult = await signIn(valuesToPost);
      if (signInResult.status == 200) {

        if (remindMe) {
          await AsyncStorage.setItem('@email', values.email)
          await AsyncStorage.setItem('@password', values.password)
          await AsyncStorage.setItem('@userLogged', 'OK');
        }
        globalVars.Sentry.setUser({ email: values.email });
        globalVars.isMasterDevice = signInResult.data.masterDevice;
        store.dispatch(setLoginInfo(signInResult.data));
        // Set navigation stack to Template Screen
        const resetAction = CommonActions.reset({
          index: 0,
          routes: [{ name: 'Template' }],
        });
        // Dispatch the reset action to the navigation stack
        navigation.dispatch(resetAction);
        navigation.navigate('DrawerNavigationRoutes');
        setValues({ ...values, email: '' })
        setValues({ ...values, password: '' })
        setRemindMe(false)
      } else {

        let returnMessage = 'Unknown error';
        if (signInResult.message === 'DEVICES_EXCEDED') {
          returnMessage = 'Devices exceded';
        } else if (signInResult.message === 'ACCOUNT_NOT_ACTIVE') {
          returnMessage = 'Your account is not active';
        } else if (signInResult.message === 'INVALID_DEVICE_ID') {
          returnMessage = 'Invalid device ID';
        } else if (signInResult.message === 'DEVICES_EXCEDED') {
          returnMessage = 'Devices exceded';
        } else if (signInResult.message === 'LOGIN_NOK') {
          returnMessage = 'Invalid login or password';
        } else {
          console.log(signInResult.message);
        }

        alertBox(
          "Error",
          returnMessage
        )

      }

    })();
  };

  return (
    <View style={styles.mainBody}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <Animated.View entering={FadeInDown.duration(1200)}  >
          <View style={styles.signInHolder}>
            <KeyboardAvoidingView enabled behavior="position">
              <View style={{ alignItems: 'center' }}>
                <Image
                  source={require('../../../assets/cardpresso.png')}
                  style={{
                    width: '50%',
                    height: 100,
                    resizeMode: 'contain',
                    margin: 30,
                  }}
                />
                <Image
                  source={require('../../../assets/userLogin.png')}
                  style={{
                    width: '50%',
                    height: 150,
                    resizeMode: 'contain',
                    margin: 20,
                  }}
                />
              </View>
              <View style={styles.SectionStyle}>
                <TextInput
                  style={styles.inputStyle}
                  onChangeText={(UserEmail) => setValues({ ...values, email: UserEmail })}
                  placeholder={t('STR_LABEL_EMAIL')}
                  placeholderTextColor="#8b9cb5"
                  autoCapitalize="none"
                  keyboardType="email-address"
                  returnKeyType="next"
                  onSubmitEditing={() =>
                    passwordInputRef.current &&
                    passwordInputRef.current.focus()
                  }
                  underlineColorAndroid="#f000"
                  blurOnSubmit={false}
                />
              </View>
              <View style={styles.SectionStyle}>
                <TextInput
                  style={styles.inputStyle}
                  onChangeText={(UserPass) => setValues({ ...values, password: UserPass })}
                  placeholder={t('STR_LABEL_PASSWORD')}
                  placeholderTextColor="#8b9cb5"
                  keyboardType="default"
                  ref={passwordInputRef}
                  onSubmitEditing={Keyboard.dismiss}
                  blurOnSubmit={false}
                  secureTextEntry={true}
                  underlineColorAndroid="#f000"
                  returnKeyType="next"
                  autoCapitalize='none'
                />
              </View>

              <View style={styles.remindeMeHolderStyle}>
                <Checkbox
                  style={styles.remindeMeCheckBoxStyle}
                  status={remindMe ? 'checked' : 'unchecked'}
                  onPress={() => {
                    setRemindMe(!remindMe);
                  }}
                  color="#05AC72"
                />
                <TouchableOpacity
                  onPress={() => {
                    setRemindMe(!remindMe);
                  }}>
                  <Text
                    style={styles.remindeMeTextStyle}>
                    {t('STR_LABEL_REMIND_ME')}
                  </Text>
                </TouchableOpacity>
              </View>

              <TouchableOpacity
                style={styles.buttonStyle}
                activeOpacity={0.5}
                onPress={handleSubmitPress}>
                <Text style={styles.buttonTextStyle}>{t('STR_LABEL_LOGIN')}</Text>
              </TouchableOpacity>
              <Text
                style={styles.registerTextStyle}
                onPress={() => navigation.navigate('Forgot')}>
                {t('STR_LABEL_FORGOT_PASSWORD')}
              </Text>
              {/* <Text
              style={styles.registerTextStyle}
              onPress={() => navigation.navigate('Register')}>
              New Here ? Register
            </Text> */}
            </KeyboardAvoidingView>
          </View>
        </Animated.View>
      </ScrollView>
    </View>
  );
};
export default connect(null, {})(SignInScreen)

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignContent: 'center',
  },
  signInHolder: {
    ...Platform.select({
      ios: {
        width: '100%',
      },
      android: {
        width: '100%',
      },
      default: {
        width: 500,
        maxWidth: '100%'
      }
    }),
    alignSelf: 'center'
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#05AC72',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#05AC72',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },

  remindeMeHolderStyle: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 35,
    marginRight: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  remindeMeTextStyle: {
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
  },
  remindeMeCheckBoxStyle: {

  },
});