import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View, ScrollView, Alert, Image, TextInput, Text } from 'react-native';
import Animated, { BounceInDown } from 'react-native-reanimated';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { useRoute } from '@react-navigation/native';
import { Button, useTheme } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { getPrinters, newPrintJob } from '../../Rest'
import Card from '../../components/PrinterCard';
import {
    store,
    setRecordSelectedId,
    setRecordList,
    cleanRecord,
    setRecordsToPrintListData,
    setTemplateSelectedId,
    setRecordsToPrintList,
    setRenderedCardsData,
    setPrintCounter
} from '../../../utils/Reducer';
import printerDB from '../../../helpers/db/models/printer'

import { Buffer } from "buffer";

import * as FileSystem from 'expo-file-system';
const { StorageAccessFramework } = FileSystem;

import * as ImagePicker from 'expo-image-picker';
import * as MediaLibrary from "expo-media-library";
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';

// import * as Print from 'expo-print';
// import { shareAsync } from 'expo-sharing'; 

import * as png from '@vivaxy/png';
import * as cpPrint from '@cardpressodevelopment/expo-cp-print';
import { bytesToBmp, getImagePixelsData, imagePixel } from '../../../helpers/images/bmp';
import { deviceIsOnline } from '../../../helpers/utils';
import renderDB from '../../../helpers/db/models/render'
import globalVars from '../../../helpers/store';

export type props = {
    printersList: {
        serial_number: string;
        distributor_id: number;
        subdistributor_id: number;
        manufacturer: string;
        model: string;
        info: string;
        last_status: string;
        last_license: string;
        last_card_count: number;
        last_ribbon_remaining: number;
        create_date: number;
        update_date: number;
        printer_name: string;
        printerAgentOnline: boolean;
    }[];
    renderedCardsData: {
        recordId: number;
        base64Image: string;
    }[];
    templateRenderInfo: any;
};

export const SetCounterWithoutPrint = () => {
    store.dispatch(setPrintCounter())
}

const PrintChooseList: React.FC<props> = ({
    printersList,
    renderedCardsData,
    templateRenderInfo
}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedPrinter, setSelectedPrinter] = useState<string>('');
    const [selectedPrinterData, setSelectedPrinterData] = useState<any[]>([]);
    const [testeImage, setTesteImage] = useState<string>('');
    const [localPrinterIP, setLocalPrinterIP] = useState<string>('');
    const [localPrintList, setLocalPrintList] = useState<[]>([]);
    const [loadingText, setLoadingText] = useState<string>('');

    const route = useRoute();
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const { printSides = "FRONT" } = route.params;
    let ribbonType: string = ""


    function sleep(ms: number) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    }

    useEffect(
        () => {
            (async () => {

                const isDeviceOnline = await deviceIsOnline();

                // if (isFocused) {

                // // DEBUG PURPOSES
                // // For each item record
                // for (let index = 0; index < renderedCardsData.length; index++) {
                //     const currentRecord: any = renderedCardsData[index];                        
                //     console.log("-------------------------------------") 
                //     console.log(currentRecord.recordId); 
                //     // console.log(currentRecord.base64Image);                              
                // //     const { status } = await MediaLibrary.requestPermissionsAsync();
                // //     if (status === "granted") {
                // //         // Save image to media library
                // //         const filename = FileSystem.documentDirectory + "some_unique_file_name.png";
                // //         await FileSystem.writeAsStringAsync(filename, currentRecord.base64Image, {
                // //             encoding: FileSystem.EncodingType.Base64,
                // //         }); 
                // //         await MediaLibrary.saveToLibraryAsync(filename);
                // //         console.log("Image successfully saved");
                // //     }
                // // } 

                setLoading(true);
                
                // Load local printers
                if (Platform.OS !== 'web') {
                    // Load local printers
                    let localPrinters = await printerDB.query({
                        where: {
                            localPrinter_eq: true
                        }
                    });

                    if (!isDeviceOnline) {
                        let localCloudPrinters = await printerDB.query({
                            where: {
                                localPrinter_eq: false
                            }
                        });
                        let allLocalPrinters: any = [...localPrinters, ...localCloudPrinters];
                        setLocalPrintList(allLocalPrinters);
                    } else {
                        setLocalPrintList(localPrinters)
                    }
                }

                if (isDeviceOnline) {
                    await getPrinters();
                }

                setLoading(false);
                // const storeData = store.getState();
                // if (storeData.redenderIsProcessing === false) {
                //     setLoading(false);
                // }
                // }
                // else {
                //     setLoading(false);
                // }


            })();
        },
        []
        // [isFocused]
    )

    // store.subscribe(() => {
    //     const storeData = store.getState();
    //     if (storeData.redenderIsProcessing === false) {
    //         setLoading(false);
    //     } else {
    //         setLoading(true);
    //     }
    // });

    const ClearAndGoHome = () => {
        store.dispatch(cleanRecord());
        store.dispatch(setRecordSelectedId(undefined))
        store.dispatch(setRecordList(undefined));
        store.dispatch(setTemplateSelectedId(undefined))
        store.dispatch(setRecordsToPrintList(undefined));
        store.dispatch(setRecordsToPrintListData([]));
        store.dispatch(setRenderedCardsData([]));
        store.dispatch(setPrintCounter())
        navigation.navigate('Template' as never);
    }

    const alertBox = (title: string, body: string) => {
        if (Platform.OS === 'web') {
            alert(title + '\n' + body);
        } else {
            Alert.alert(
                title,
                body
            );
        }
        return;
    }

    const startNewPrintJob = async (cardImageBase64Data: string, ribbonType: string, cardBackImageBase64Data: string | undefined = undefined, orientation: string | undefined = undefined) => {

        return new Promise((resolve) => {
            (async () => {
                await cpPrint.newPrintJob(cardImageBase64Data, ribbonType, undefined, cardBackImageBase64Data, orientation).then(function (result) {
                    if (result) {
                        // Alert.alert(result.toString());
                        resolve(true);
                    } else {
                        Alert.alert(result.toString());
                        resolve(false);
                    }
                }).catch(function (error: any) {
                    Alert.alert(error.toString());
                    console.log(error);
                    resolve(false);
                });
            })();
        });

    }
    const getBMPImage = async (base64PngImage: string, currentRecord: any): Promise<string> => {

        return new Promise((resolve) => {
            (async () => {
                let base64ImageBuffer: any = Buffer.from(base64PngImage, "base64")
                const metadata: any = png.decode(base64ImageBuffer);
                const imageArrayData: any = metadata.data;
                const imagePixelsData: imagePixel[] = getImagePixelsData(imageArrayData, currentRecord.cardWidth, currentRecord.cardHeight);
                const imageBmpResult = bytesToBmp(imagePixelsData, currentRecord.cardWidth, currentRecord.cardHeight);
                resolve(imageBmpResult);
            })();
        });
    }

    const printToLocal = (ip: string, manufacturer: any) => {
        (async () => {

            setLoading(true);
            await sleep(10);

            if (1 === 1) {

                setLoadingText("Connecting to printer...");
                await sleep(1);
                                
                await cpPrint.setManufacturer(manufacturer); 
                await cpPrint.connectToPrinter(ip);
                const printerInfo: any = await cpPrint.getPrinterInfo(ip);
                if (printerInfo !== false) {                    
                    // Load ribbonType
                    if (printerInfo.ribbonInfo.Type.toUpperCase().includes("YMC")) {
                        ribbonType = "RC_" + printerInfo.ribbonInfo.Type;
                    } else {
                        ribbonType = "RM_" + printerInfo.ribbonInfo.Type;
                    }
                    // cpPrint.getPrinterInfo().then(function (printerInfo) {
                    //     if (printerInfo.ribbonInfo.Type.toUpperCase().includes("YMC")) {
                    //         setRibbonType("RC_" + printerInfo.ribbonInfo.Type);
                    //     } else {
                    //         setRibbonType("RM_" + printerInfo.ribbonInfo.Type);
                    //     }
                    // }).catch(function (error: any) {
                    //     Alert.alert('Printer data error');
                    //     console.log(error);
                    // });
                } else {
                    Alert.alert('Printer connection error');
                    setLoading(false);
                    return;
                }
            }

            // For each item record 
            // console.log(renderedCardsData.length);
            for (let index = 0; index < renderedCardsData.length; index++) {

                setLoadingText("Printing " + (index + 1) + " of " + renderedCardsData.length);
                await sleep(1);

                const currentRecord: any = renderedCardsData[index];
                const renderDataArray = await renderDB.query({
                    where: {
                        recordId_eq: currentRecord.recordId
                    }
                });
                const renderData = renderDataArray[0];

                // let base64ImageBuffer: any = Buffer.from(renderData.base64Data.toString(), "base64")
                // console.log("TESTE 1.1");
                // await sleep(1);
                // const metadata: any = png.decode(base64ImageBuffer);
                // console.log("TESTE 1.2");
                // await sleep(1);
                // const imageArrayData: any = metadata.data;
                // const imagePixelsData: imagePixel[] = getImagePixelsData(imageArrayData, currentRecord.cardWidth, currentRecord.cardHeight);
                // console.log("TESTE 1.3");
                // await sleep(1);
                // const imageBmpResult = bytesToBmp(imagePixelsData, currentRecord.cardWidth, currentRecord.cardHeight);

                let cardOrientation = "LANDSCAPE";
                const xmlPaperOrientation = templateRenderInfo?.printData?.print?.paper?.orientation;
                if (xmlPaperOrientation !== undefined){
                    cardOrientation = xmlPaperOrientation.toUpperCase();
                }
                
                if (printSides === "FRONTBACK") {

                    const imageBmpResult: string = await getBMPImage(renderData.base64Data.toString(), currentRecord);
                    if (renderData.backBase64Data !== null) {
                        const backImageBmpResult: string = await getBMPImage(renderData.backBase64Data.toString(), currentRecord);
                        await startNewPrintJob(imageBmpResult, ribbonType, backImageBmpResult, cardOrientation);
                    } else {
                        await startNewPrintJob(imageBmpResult, ribbonType, undefined, cardOrientation);
                    }

                } else if (printSides === "FRONT") {

                    const imageBmpResult: string = await getBMPImage(renderData.base64Data.toString(), currentRecord);
                    await startNewPrintJob(imageBmpResult, ribbonType, undefined, cardOrientation); // , "PORTRAIT"

                } else if (printSides === "BACK") {

                    if (renderData.backBase64Data !== null) {
                        const backImageBmpResult: string = await getBMPImage(renderData.backBase64Data.toString(), currentRecord);
                        await startNewPrintJob("", ribbonType, backImageBmpResult, cardOrientation);
                    }

                }

                // getBMPImage(currentRecord.base64Image.toString(), currentRecord); 

                // Debug Image 
                // setTesteImage("data:image/bmp;base64," + imageBmpResult);

                // await startNewPrintJob(imageBmpResult, ribbonType);

                // const { status } = await MediaLibrary.requestPermissionsAsync();
                // if (status === "granted") {
                //     // Save image to media library 
                //     const filename = FileSystem.documentDirectory + "cpCardImage.bmp";
                //     await FileSystem.writeAsStringAsync(filename, imageBmpResult, {
                //         encoding: FileSystem.EncodingType.Base64,
                //     });
                //     await MediaLibrary.saveToLibraryAsync(filename);
                //     console.log("Image successfully saved");
                // }

            }

            setLoadingText("Printing finish");
            await sleep(500);
            setLoading(false);
            Alert.alert(
                t('STR_SUCCESS'),
                "All card(s) are printed",
                [
                    { text: "OK", onPress: () => ClearAndGoHome() }
                ]
            )
        })();
    }

    const printToCloud = () => {
        (async () => {
            setLoading(true);
            const templateProperties = store.getState().templateProperties;
            const recordsToPrintListData = store.getState().recordsToPrintListData;
            const newPrintJobReturn: {
                status: number
                message: string
                printJobId: string
            } = await newPrintJob(templateProperties, recordsToPrintListData, selectedPrinter);

            if (newPrintJobReturn.status === 200) {
                if (Platform.OS === 'web') {
                    alertBox(t('STR_SUCCESS'),
                        t('STR_SOCKET_MESSAGE_NEW_JOB', { jobInfo: newPrintJobReturn.printJobId }))
                    ClearAndGoHome();
                } else {
                    Alert.alert(
                        t('STR_SUCCESS'),
                        t('STR_SOCKET_MESSAGE_NEW_JOB', { jobInfo: newPrintJobReturn.printJobId }),
                        [
                            { text: "OK", onPress: () => ClearAndGoHome() }
                        ]
                    )
                }
            } else {
                if (Platform.OS === 'web') {
                    alertBox(t('STR_ERROR'),
                        t('STR_API_ERROR_UNKNOWN'))
                }
            }
            setLoading(false);
        })();
    }

    const sendDataToPrinter = () => {

        // DEBUG -------------------------------
        // for (let index = 0; index < renderedCardsData.length; index++) {
        //     const currentRecord: any = renderedCardsData[index];
        //     let base64ImageBuffer: any = Buffer.from(currentRecord.base64Image.toString(), "base64")
        //     const metadata: any = png.decode(base64ImageBuffer);
        //     const imageArrayData: any = metadata.data;
        //     const imagePixelsData: imagePixel[] = getImagePixelsData(imageArrayData, currentRecord.cardWidth, currentRecord.cardHeight);
        //     const imageBmpResult = bytesToBmp(imagePixelsData, currentRecord.cardWidth, currentRecord.cardHeight);
        //     setTesteImage("data:image/bmp;base64," + imageBmpResult);
        // }
        // DEBUG -------------------------------

        if (selectedPrinterData.ipAddress !== undefined) {
            printToLocal(selectedPrinterData.ipAddress, selectedPrinterData.manufacturer);
        } else {
            printToCloud();
        }
    }

    return (
        <View style={styles.container}>
            <Loader loading={loading} text={loadingText} />
            <ScrollView>

                <View style={styles.printerContainer}>
                    {testeImage !== "" &&
                        <Image
                            resizeMode='contain'
                            style={{
                                width: 380,
                                height: 380,
                            }}
                            source={{
                                uri: testeImage,
                            }}
                        />
                    }

                    {localPrintList !== undefined &&

                        <View style={styles.CardContainer}>

                            {/* <View style={{
                                backgroundColor: '#e1e2e3',
                                height: 'auto',
                                borderWidth: 0,
                                marginBottom: 15,
                                padding: 10,
                                paddingBottom: 15,
                                position: 'relative',
                                width: 350,
                                display: 'flex',
                                flexDirection: 'row',
                                alignContent: 'space-between'
                            }}>
                                <TextInput
                                    style={{
                                        width: 200
                                    }}
                                    onChangeText={setLocalPrinterIP}
                                    value={localPrinterIP}
                                    placeholder={"IP address"}
                                    keyboardType="default" />
                                <Button color='#ffffff' style={{ backgroundColor: '#05AC72' }} onPress={() => printToLocal(localPrinterIP)} >{"Local Print"}</Button>
                            </View> */}

                            {localPrintList.map((printer, index) => (
                                <Animated.View style={styles.CardHolder} key={index} entering={BounceInDown.duration(((Number(index) * 250) + 1000))}>
                                    <Card
                                        onPress={() => {

                                            if (printer.localPrinter === false) {
                                                alertBox(
                                                    t('STR_ERROR'),
                                                    t('STR_ALERT_PRINTER_IS_OFFLINE')
                                                )
                                            } else {
                                                setSelectedPrinterData(printer);
                                                setSelectedPrinter(printer.serial_number);
                                                setLocalPrinterIP(printer.ipAddress)
                                            }

                                        }}
                                        key={index}
                                        printer={printer}
                                        selected={(printer.serial_number === selectedPrinter && printer.ipAddress === localPrinterIP)}
                                    />
                                </Animated.View>
                            ))}
                        </View>
                    }

                    {printersList !== undefined &&

                        <View style={styles.CardContainer}>
                            {printersList.map((printer, index) => (
                                <Animated.View style={styles.CardHolder} key={index} entering={BounceInDown.duration(((Number(index) * 250) + 1000))}>
                                    <Card
                                        onPress={() => {

                                            if (printer.printerAgentOnline === true) {
                                                setSelectedPrinterData(printer);
                                                setSelectedPrinter(printer.serial_number)
                                            } else {
                                                alertBox(
                                                    t('STR_ERROR'),
                                                    t('STR_ALERT_PRINTER_IS_OFFLINE')
                                                )
                                            }
                                        }}
                                        key={index}
                                        printer={printer}
                                        selected={(printer.serial_number === selectedPrinter)}
                                    />
                                </Animated.View>
                            ))}
                        </View>
                    }
                </View>

                {printersList === undefined && isFocused &&
                    <Loader loading={loading} text={loadingText} />
                }

            </ScrollView>
            <View style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} >
                <View style={styles.bottomContainer} >
                    <Button textColor={theme.colors.white} onPress={() => sendDataToPrinter()}
                        disabled={(selectedPrinter === '') ? true : false}
                    >{t('STR_TITLE_SEND_DATA_TO_PRINTER')}</Button>
                    {/* <Button color='#ffffff' onPress={() => printToLocal()} >{t('STR_TITLE_SEND_DATA_TO_PRINTER')}</Button> */}
                </View>
            </View>
            <Loader loading={loading} text={loadingText} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        // alignItems: 'center',
        paddingBottom: 70,
    },
    printerContainer: {
        flex: 1,
        backgroundColor: '#fff',

        ...Platform.select({
            ios: {
                alignItems: 'center',
            },
            android: {
                alignItems: 'center',
            },
            default: {
                alignItems: 'flex-start'
            }
        })
    },
    bottomContainer: {
        width: '100%',
        backgroundColor: '#05AC72',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        zIndex: 2
    },
    CardContainer: {
        marginTop: 20,
        ...Platform.select({
            ios: {
                alignItems: 'center',
                justifyContent: "center",
            },
            android: {
                alignItems: 'center',
                justifyContent: "center",
            },
            default: {
                flex: 1,
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: 'wrap',
                maxWidth: '100%'
            }
        })
    },
    CardHolder: {
        marginHorizontal: 20,
        ...Platform.select({
            ios: {
            },
            android: {
            },
            default: {
                // width: 250,
                // marginHorizontal: 10

                maxWidth: '100%',

            }
        })
    },
});

const mapStateToProps = (
    state: {
        printersList: {
            serial_number: string;
            distributor_id: number;
            subdistributor_id: number;
            manufacturer: string;
            model: string;
            info: string;
            last_status: string;
            last_license: string;
            last_card_count: number;
            last_ribbon_remaining: number;
            create_date: number;
            update_date: number;
            printer_name: string;
            printerAgentOnline: boolean;
        }[];
        renderedCardsData: {
            recordId: number;
            base64Image: string;
        }[];
        templateRenderInfo: any;
    }
) => {
    return {
        printersList: state.printersList,
        renderedCardsData: state.renderedCardsData,
        templateRenderInfo: state.templateRenderInfo,
    }
}

export default connect(mapStateToProps, {})(PrintChooseList);