
import * as SQLite from 'expo-sqlite'
import { BaseModel, types } from '../classes/index';
import configs from '../../config';

export default class record extends BaseModel {
    constructor(obj: any) {
        super(obj)
    }

    static get database(): any {
        return async () => SQLite.openDatabase(configs.sqlLiteDbName)
    }

    static get tableName(): any {
        return 'records'
    }

    static get columnMapping(): any {
        return {
            id: { type: types.INTEGER, primary_key: true, autoincrement: true },
            templateId: { type: types.INTEGER, not_null: true },
            recordId: { type: types.INTEGER, not_null: true },
            recordInfo: { type: types.BLOB, not_null: true },
            newRecord: { type: types.BOOLEAN, not_null: true },
            error: { type: types.BOOLEAN, default: () => false },
            errorInfo: { type: types.TEXT },
            timestamp: { type: types.INTEGER, default: () => Date.now() }
        }
    }
}