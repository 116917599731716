import DataTypes from './DataTypes'
import DatabaseLayer from './DatabaseLayer'

export default class Repository {
  columnMapping: any;
  databaseLayer: any;

  constructor(database: any, tableName: any, columnMapping: any) {
    this.columnMapping = columnMapping
    this.databaseLayer = new DatabaseLayer(database, tableName)
  }

  createTable() {
    return this.databaseLayer.createTable(this.columnMapping)
  }

  dropTable() {
    return this.databaseLayer.dropTable()
  }

  insert(_obj: any) {
    const obj = DataTypes.toDatabaseValue(this.columnMapping, this._sanitize(_obj))
    return this.databaseLayer.insert(obj).then((res: any) => DataTypes.toModelValue(this.columnMapping, res))
  }

  update(_obj: any) {
    const obj = DataTypes.toDatabaseValue(this.columnMapping, this._sanitize(_obj))
    return this.databaseLayer.update(obj)
  }

  destroy(id: any) {
    return this.databaseLayer.destroy(id)
  }

  destroyAll() {
    return this.databaseLayer.destroyAll()
  }

  find(id: any) {
    return this.databaseLayer.find(id).then((res: any) => (res ? DataTypes.toModelValue(this.columnMapping, res) : null))
  }

  findBy(where: any = {}) {
    return this.databaseLayer.findBy(where).then((res: any) => (res ? DataTypes.toModelValue(this.columnMapping, res) : null))
  }

  query(options: any = {}) {
    return this.databaseLayer.query(options).then((res: any) => res.map((p: any) => DataTypes.toModelValue(this.columnMapping, p)))
  }

  _sanitize(obj: any) {
    const allowedKeys = Object.keys(this.columnMapping)
    return Object.keys(obj).reduce((ret, key) => {
      return allowedKeys.includes(key) ? { ...ret, [key]: obj[key] } : ret
    }, {})
  }
}
