import React, { useState, useEffect } from "react";
import { Platform, View, Text, StyleSheet, Dimensions, Image, TouchableOpacity, Alert } from "react-native";
import { FAB, useTheme, ActivityIndicator, IconButton } from 'react-native-paper';
import { getPrinterImageUrl } from '../../helpers/utils';
import RibbonQuantity from './design/RibbonQuantity';
import { useTranslation } from 'react-i18next';
import { VictoryPie, VictoryAnimation, VictoryLabel, VictoryBar, VictoryChart, VictoryTheme } from "victory-native";
import * as cpPrint from '@cardpressodevelopment/expo-cp-print';

const PrinterCard = (props) => {

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const getData = (percent) => {
        return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
    }

    let lastStatusData = null;
    const localPrinter = ( props.printer?.ipAddress === undefined || props.printer?.localPrinter !== true) ? false : true;

    if (props.printer.ipAddress !== undefined && props.printer.ipAddress !== null) {
        props.printer.printerAgentOnline = true;
        props.printer.printer_name = "Local Printer"
        lastStatusData = {};
        lastStatusData["ribbonQty"] = 0;
        lastStatusData["ribbonRemain"] = 0;
        props.printer.printerAgentOnline = false;
    } else {
        props.printer.printer_name = props.printer.printerName;
        lastStatusData = props.printer.last_status !== undefined ? JSON.parse(props.printer.last_status) : [];
    }

    const ribbonQtyVal = (lastStatusData?.ribbonQty === null || lastStatusData?.ribbonQty === undefined ? 0 : Number(lastStatusData.ribbonQty));
    const ribbonRemain = (lastStatusData?.ribbonRemain === null || lastStatusData?.ribbonRemain === undefined ? 0 : Number(lastStatusData.ribbonRemain));
    const currentPercentage = (ribbonQtyVal === 0 || ribbonRemain === 0 ? 0 : Math.round((ribbonRemain * 100) / ribbonQtyVal));

    const [ribbonQty, setRibbonQty] = useState(ribbonQtyVal);
    const [printerImage, setPrinterImage] = useState(undefined);
    const [percentageValue, setPercentageValue] = useState(getData(0));
    const [currentPercentageState, setCurrentPercentageState] = useState(currentPercentage);
    const [printerOnline, setPrinterOnline] = useState(props.printer.printerAgentOnline);
    const [printerState, setPrinterState] = useState('');
    const [localPrintStateCheck, setlocalPrintStateCheck] = useState(false);

    // const barPercentage = currentPercentage / 100;
    // const screenWidth = Dimensions.get("window").width;
    useEffect(
        () => {

            setTimeout(function () {
                setPercentageValue(getData(currentPercentage));
            }, 1000);

            (async () => {

                const printerImageURL = await getPrinterImageUrl(props.printer.manufacturer, props.printer.model);                
                setPrinterImage(printerImageURL);
                if (localPrinter === true && props.printer.localPrinter) {

                    await cpPrint.setManufacturer(props.printer.manufacturer)
                    await cpPrint.getPrinterInfo(props.printer.ipAddress).then(async function (printerInfo) {
                        setlocalPrintStateCheck(true)
                        if (printerInfo === false) {
                            setPrinterOnline(false);
                        } else {
                            setPrinterOnline(true);
                            let currentPercentage = Math.round((printerInfo.ribbonInfo.Remaining * 100) / printerInfo.ribbonInfo.Capacity);
                            currentPercentage = (isNaN(currentPercentage) ? 0 : currentPercentage)
                            setPercentageValue(getData(currentPercentage));
                            setCurrentPercentageState(currentPercentage);
                            setRibbonQty(printerInfo.ribbonInfo.Remaining);
                            setPrinterState(printerInfo.printerState);
                        }
                    })
                }
            })();
        },
        []
    )

    return (
        <TouchableOpacity onPress={props.onPress}>
            <View style={[styles.cardHolder, (props.selected !== undefined && props.selected === true ? styles.cardHolderSelected : '')]}>
                <View style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                    backgroundColor: 'transparent',
                    zIndex: 10
                }}></View>

                {localPrinter && localPrintStateCheck === false &&
                    <View style={styles.localPrinterLoadingIndicator}>
                        <ActivityIndicator animating={true} size={80} color={theme.colors.primary} />
                    </View>
                }

                <View style={styles.titleHolder}>
                    <Text style={[styles.printerName, ((!printerOnline) ? { color: 'gray' } : {})]}>
                        {props.printer.printer_name}
                    </Text>
                    <Text style={[styles.manufacturerModel, ((!printerOnline) ? { color: 'gray' } : {})]}>{props.printer.manufacturer} {props.printer.model} </Text>
                </View>

                <View style={styles.itemsHolder}>

                    {/* {!localPrinter && */}
                    <FAB
                        theme={{ roundness: 10 }}
                        style={[styles.fab, ((printerOnline) ? styles.fabOnline : styles.fabOffline)]}
                    />
                    {/* } */}

                    {localPrinter &&
                        <View style={{ position: 'absolute', top: -15, right: 0 }}>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                {printerState != '' && !printerState.toUpperCase().includes("WARNING") && !printerState.toUpperCase().includes("ERROR") &&
                                    <IconButton icon="check-circle" iconColor={theme.colors.primary} size={20} style={{ height: 20, width: 20 }} />
                                }
                                {printerState != '' && printerState.toUpperCase().includes("WARNING") &&
                                    <IconButton icon="alert-circle" iconColor={theme.colors.warning} size={20} style={{ height: 20, width: 20 }} />
                                }
                                {printerState != '' && printerState.toUpperCase().includes("ERROR") &&
                                    <IconButton icon="check-circle" iconColor={theme.colors.error} size={20} style={{ height: 20, width: 20 }} />
                                }
                                <Text>{printerState}</Text>
                            </View>
                        </View>
                    }

                    <View style={styles.itemCol}>
                        <View>
                            {printerImage === undefined && <Image style={styles.imageStyle} source={require('../../assets/loading.gif')} resizeMode='cover' />}
                            {printerImage !== undefined && <Image style={[styles.imageStyle, ((!printerOnline) ? { opacity: 0.3 } : {})]} source={{
                                uri: printerImage,
                                method: 'GET',
                            }} resizeMode='cover' />}
                        </View>
                        {localPrinter &&
                            <View style={{ position: 'absolute', bottom: 0 }}>
                                <Text style={[styles.descriptionText, ((!printerOnline) ? { color: 'gray' } : {})]}>{props.printer.ipAddress}</Text>
                            </View>
                        }
                    </View>

                    <View style={styles.itemCol}>
                        <RibbonQuantity
                            color={((!printerOnline) ? "gray" : theme.colors.primary)}
                            textColor={((!printerOnline) ? "gray" : theme.colors.black)}
                            backgroundColor="#e1e2e3"
                            quantity={ribbonQty}
                            size="70"
                            fontSize="18"
                        ></RibbonQuantity>
                    </View>
                    <View style={styles.itemCol}>
                        <View style={[styles.graphHolder]}>
                            <View style={{ height: 100, width: 100 }}>
                                <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
                                    <VictoryPie
                                        data={percentageValue}
                                        animate={{
                                            duration: 500,
                                            easing: 'linear',
                                        }}
                                        width={160}
                                        height={160}
                                        innerRadius={40}
                                        cornerRadius={25}
                                        labels={() => null}
                                        style={{
                                            data: {
                                                fill: ({ datum }) => {
                                                    const color = ((printerOnline) ? (datum.y < 15 ? '#E35B5B' : (datum.y >= 15 && datum.y < 25 ? '#FFC300' : '#05AC72')) : 'gray');
                                                    return datum.x === 1 ? color : "transparent";
                                                }
                                            }
                                        }}
                                    />
                                    <Text style={[styles.graphPercentageText, ((!printerOnline) ? { color: 'gray' } : {})]}>{currentPercentageState}%</Text>
                                </View>

                            </View>
                        </View>
                        <Text style={[styles.descriptionText, ((!printerOnline) ? { color: 'gray' } : {})]}>{t('STR_RIBBON_REMAIN')}</Text>
                    </View>
                </View >

            </View >
        </TouchableOpacity >
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    cardHolder: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 25,
            },
            android: {
                width: deviceWidth - 25,
            },
            default: {
                width: 500,
                maxWidth: '96%',
                alignSelf: 'center'
            }
        }),
        backgroundColor: '#e1e2e3',
        height: 'auto',
        borderRadius: radius,
        borderWidth: 0,
        marginBottom: 15,
        padding: 10,
        paddingBottom: 15,
        position: 'relative',
    },
    cardHolderSelected: {
        borderColor: '#05AC72',
        borderWidth: 2,
        // backgroundColor: '#bbbbbb'
    },
    printerName: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#05AC72',
        marginBottom: 10
    },
    fab: {
        position: 'absolute',
        margin: 0,
        left: 0,
        top: 0,
        width: 20,
        height: 20,
        zIndex: 2
    },
    fabOnline: {
        backgroundColor: "#05AC72"
    },
    fabOffline: {
        backgroundColor: "#FF5858"
    },
    imageStyle: {
        width: 100,
        height: 100
    },
    label: {
        fontWeight: 'bold'
    },
    graphHolder: {
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 120
    },
    graphPercentageText: {
        position: 'absolute',
        fontWeight: 'bold',
        color: "#05AC72",
        fontSize: 18
    },
    titleHolder: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        ...Platform.select({
            ios: {
            },
            android: {
            },
            default: {
                maxHeight: 30,
            }
        }),
    },
    manufacturerModel: {
        fontWeight: 'bold',
        marginLeft: 10
    },

    itemsHolder: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    itemCol: {
        width: '33%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 140
    },
    descriptionText: {
        fontWeight: 'bold'
    },
    localPrinterLoadingIndicator: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 3

    }

});

export default PrinterCard;