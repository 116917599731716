import React, { useState, useEffect, useRef } from "react";
import { Platform, View, Text, StyleSheet, Dimensions, Image, TouchableOpacity, Animated, SafeAreaView, Button, LayoutAnimation, UIManager, Easing } from "react-native";
import { FAB, IconButton } from 'react-native-paper';

const PrinterCard = (props) => {

    const [expanded, setExpanded] = useState(false);
    const [viewHeight, setViewHeight] = useState(0)
    const spinValue = new Animated.Value(0); 

    if (
        Platform.OS === "android" &&
        UIManager.setLayoutAnimationEnabledExperimental
    ) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    Animated.timing(spinValue, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
        easing: Easing.linear
    }).start(() => {        
    });

    const reverseSpin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['180deg', '0deg']
    });
    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '180deg']
    });

    return (
        <SafeAreaView>
            <View style={{ paddingBottom: 10 }}>
                <View style={styles.messageHolder}>

                    <TouchableOpacity style={styles.titleHolder} onPress={() => {
                        LayoutAnimation.configureNext(LayoutAnimation.create(200, 'easeInEaseOut', 'opacity'));
                        setExpanded(!expanded);
                    }}>
                        <Text style={styles.titleTextHolder} >{props.title}</Text>
                        <Animated.View style={{ transform: [{ rotate: (expanded ? spin : reverseSpin) }] }}>
                            <IconButton style={styles.iconHolder} icon="arrow-down" >
                            </IconButton>
                        </Animated.View>
                    </TouchableOpacity>

                    {expanded && (
                        <View style={[styles.childrenHolder,expanded ? styles.visible : styles.invisible]}>
                            {props.children}
                        </View>
                    )}

                </View>
            </View>
        </SafeAreaView>
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    titleHolder: {
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: "row"
    },
    titleTextHolder: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    iconHolder: {
        width: 30,
        height: 30,
        alignSelf: 'flex-end',
    },

    messageHolder: {        
        ...Platform.select({
            ios: {
                width: deviceWidth - 25,
            },
            android: {
                width: deviceWidth - 25,
            },
            default: {
                width: 500, 
            }
        }),
        backgroundColor: '#e1e2e3',
        height: 'auto',
        borderRadius: radius,
        borderWidth: 0,
        marginBottom: 5,
        padding: 10,
        paddingBottom: 15
    },
    visible: {
        opacity: 1
    },
    invisible: {
        opacity: 0
    },
    childrenHolder:{
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: "row"
    }
});

export default PrinterCard; 