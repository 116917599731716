
// let globalVars =
// {
//     callbackCardLoadCancel: false,
//     controller: new AbortController(),
//     cardLoadCancel: false,
//     socket: undefined,
//     translationsErrors: {},
//     additionalAPIHeaderInfo: {},
//     Sentry: undefined,
//     isMasterDevice: false
// }  

let globalVars: {
    callbackCardLoadCancel: any,
    controller: any,
    cardLoadCancel: any,
    socket: any,
    translationsErrors: any,    
    additionalAPIHeaderInfo: any,
    Sentry: any,
    isMasterDevice: any,
    zipData: any,
    customData: any,
    fonts: any,
    noDb: boolean
} = {
    callbackCardLoadCancel: false,
    controller: new AbortController(),
    cardLoadCancel: false,
    socket: undefined,
    translationsErrors: {},    
    additionalAPIHeaderInfo: {},
    Sentry: undefined,
    isMasterDevice: false,
    zipData: null,
    customData: {},
    fonts: {},
    noDb: false
}

export default globalVars; 