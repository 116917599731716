import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { webAPIUser, webAPIPass } from '../../Rest';
import base64 from 'react-native-base64';
import { useTranslation } from 'react-i18next';

const ActivateScreen = (props) => {
  const { t, i18n } = useTranslation();
  const { userRegist } = props

  const [ values, setValues ] = useState( { masterKey: ''} )

  useEffect(
    () => {
    },
    [ userRegist ]
  )

  const handleSubmitPress = () => {
    if (values.masterKey === '') {
        alert(t('STR_ERROR_INSERT_ACTIVATION_CODE'));
        return;
    }

    fetch( 'https://sandbox.webapi.cardpresso.com' + '/v1/account/activate', {
        method: 'POST',
        headers: {
          'Authorization' : 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
          'Accept' : 'application/json',
          'Content-Type' : 'application/json',
        },
        body : JSON.stringify( { 'email': userRegist.email, 'oemId': 35, 'masterKey': values.masterKey } )
      } )
      .then( response => {
        if ( response.ok ) {
              props.navigation.navigate('SignIn');
              return
          }
      } )
  }; 
 
  return (
    <View style={styles.mainBody}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <View>
          <KeyboardAvoidingView enabled>
            <View style={{alignItems: 'center'}}>
              <Icon name="person-circle-outline" size={150} color="#000000" />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(Code) => setValues( { ...values, masterKey: Code } )}
                placeholder={t('STR_ACTIVATION_CODE')}
                placeholderTextColor="#8b9cb5"
                autoCapitalize="none"
                keyboardType="default"
                returnKeyType="next"
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />
            </View>
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={handleSubmitPress}>
              <Text style={styles.buttonTextStyle}>{t('STR_ACTIVATE_ACCOUNT')}</Text>
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </View>
      </ScrollView>
    </View>
  );
};

const mapStateToProps = state => {
  return {
    userRegist: state.userRegist,
  }
}

export default connect( mapStateToProps, { } )( ActivateScreen )
 
const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#05AC72',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#05AC72',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
});