

import { Buffer } from "buffer";

export interface imagePixel {
    x: number,
    y: number,
    rgbData: number[]
}

export const removeOpacity = (imageBytes: number[]): number[] => {

    const imageArray: number[] = [];
    let ctr = 0;

    for (let i = 0; i < imageBytes.length; i++) {
        if (ctr >= 3) {
            ctr = 0;
            continue;
        }
        imageArray.push(imageBytes[i]);
        ctr++;
    }
    return imageArray;
}

export const bytesToPixels = (imageBytes: number[]): number[][] => {

    const imagePixelsArray: number[][] = [];
    let pixelRGB: number[] = [];
    let ctr = 0;
    for (let i = 0; i <= imageBytes.length; i++) {
        if (ctr >= 3) { 
            ctr = 0;
            imagePixelsArray.push(pixelRGB);
            pixelRGB = [];
            pixelRGB.push(imageBytes[i]);
        } else {
            pixelRGB.push(imageBytes[i]);
        }
        ctr++;
    }
    return imagePixelsArray;
}

export const reversePixelRows = (imagePixels: number[][]): number[][] => {
    return imagePixels.reverse();
}

export const getImagePixelData = (imagePixels: number[][], imageWidth: number, imageHeight: number): imagePixel[] => {

    let imagePixelData: imagePixel[] = [];
    let pixelCtr = 0;
    for (let y = 0; y < imageHeight; y++) {

        const imagePixelDataInverted: imagePixel[] = [];

        // for (var x = imageWidth - 1; x >= 0; x--) { 
        for (let x = 0; x < imageWidth; x++) {

            let dataToPush: {
                x: number,
                y: number,
                rgbData: number[]
            } = { 
                x: x,
                y: y,
                rgbData: imagePixels[pixelCtr]
            }
            imagePixelDataInverted.push(dataToPush);
            pixelCtr++; 
        }
        // imagePixelData = [...imagePixelData, ...imagePixelDataInverted.reverse()]
        imagePixelData.push(...imagePixelDataInverted.reverse()); 
    }
    return imagePixelData;
}

export const getImagePixelsData = (imageBytes: number[], imageWidth: number, imageHeight: number): imagePixel[] => {

    const imageData: number[] = removeOpacity(imageBytes);    
    const pixelsData: number[][] = reversePixelRows(bytesToPixels(imageData));
    // const pixelsData: number[][] = bytesToPixels(imageData);
    const imagePixelsData: imagePixel[] = getImagePixelData(pixelsData, imageWidth, imageHeight);

    // const imageArray: number[][] = [];
    // let pixelRGB: number[] = [];
    // const returnImageArray: number[] = [];

    // // Reverse pixel rows
    // let ctr = 0;
    // for (let i = 0; i < imageBytes.length; i++) {
    //     if (ctr >= 3) {
    //         ctr = 0;
    //         imageArray.push(pixelRGB);
    //         pixelRGB = [];
    //         pixelRGB.push(imageBytes[i]);
    //     } else {
    //         pixelRGB.push(imageBytes[i]);
    //     }
    //     ctr++;
    // }

    // const reorderedImageArray = imageArray.reverse();
    // for (let j = 0; j < reorderedImageArray.length; j++) {
    //     returnImageArray.push(reorderedImageArray[j][0]);
    //     returnImageArray.push(reorderedImageArray[j][1]);
    //     returnImageArray.push(reorderedImageArray[j][2]);
    // }
    // return returnImageArray;
    return imagePixelsData;
}

// export const bytesToBmp = (imageBytes: number[], pixelsWidth: number, pixelsHeight: number): string => {
export const bytesToBmp = (imagePixel: imagePixel[], pixelsWidth: number, pixelsHeight: number): string => {

    // imageBytes: Uint8Array

    const width = pixelsWidth
        , height = pixelsHeight
        , bpp = 24    // Bits per pixel    
        ;

    // https://en.wikipedia.org/wiki/BMP_file_format
    // Each row in the Pixel array is padded to a multiple of 4 bytes in size
    let rowSize = Math.floor((bpp * width + 31) / 32) * 4;
    let pixelArraySize = rowSize * height;

    let BMP = new DataView(new ArrayBuffer(54));

    // Bitmap file header
    'BM'.split('').map((v, i) => {
        BMP.setUint8(i, v.charCodeAt(0));
    });
    BMP.setUint32(2, pixelArraySize + 54, true);   // File size (bytes): pixel array size + headers (=54 bytes)
    BMP.setUint16(6, 0, true);                     // Reserved
    BMP.setUint16(8, 0, true);                     // Reserved
    BMP.setUint32(10, 54, true);                    // Pixel array offset (=54 bytes)

    // DIB header, Windows BITMAPINFOHEADER
    BMP.setUint32(14, 40, true);                    // DIB header size (=40 bytes)
    BMP.setUint32(18, width, true);                 // Width in pixels
    BMP.setUint32(22, height, true);                // Height in pixels
    BMP.setUint16(26, 1, true);                     // Number of color planes (1)
    BMP.setUint16(28, bpp, true);                   // Bits per pixel
    BMP.setUint32(30, 0, true);                     // No compression (0)
    BMP.setUint32(34, pixelArraySize, true);        // Size of the raw bitmap data (bytes) including rows padding
    BMP.setUint32(38, 2835, true);                  // Horizontal resolution (pixels per metre, signed integer), 2835 dpm = 72 dpi
    BMP.setUint32(42, 2835, true);                  // Vertical resolution, 2835 dpm = 72 dpi
    BMP.setUint32(46, 0, true);                     // Number of colors in the palette (keep 0 for default 2^bpp)
    BMP.setUint32(50, 0, true);                     // Important colors (0 = every color is important)

    let bmpData = new DataView(new ArrayBuffer(pixelArraySize));

    // Offset of a pixel value
    let i = 0;
    let imageIndex = 0;
    let byesPerPixel = (bpp / 8) | 0;

    // From bottom row to the top
    // for (let y = height - 1; y >= 0; y--) {
    //     for (let x = 0; x < width; x++) {
    //         bmpData.setUint8(i, 0); // B
    //         bmpData.setUint8(i + 1, 0); // G
    //         bmpData.setUint8(i + 2, 255); // R
    //         // bmpData.setUint8(i, imageBytes[i + 2]); // B
    //         // bmpData.setUint8(i + 1, imageBytes[i + 1]); // G
    //         // bmpData.setUint8(i + 2, imageBytes[i]); // R
    //         i += byesPerPixel;
    //     }
    //     i = rowSize * (height - y);
    // }

    for (let y = height - 1; y >= 0; y--) {
        for (let x = 0; x < width; x++) {

            // if (typeof imagePixel[imageIndex].rgbData === 'undefined') {
            //     // bmpData.setUint8(i, 3); // B 
            //     // bmpData.setUint8(i + 1, 3); // G
            //     // bmpData.setUint8(i + 2, 255); // R
            //     continue;
            // }
 
            // var log = imageBytes[imageIndex + 2] + '-' + imageBytes[imageIndex + 1] + '-' + imageBytes[imageIndex];
            // console.log(log); 
            // bmpData.setUint8(i, imageBytes[imageIndex + 2]); // B
            // bmpData.setUint8(i + 1, imageBytes[imageIndex + 1]); // G
            // bmpData.setUint8(i + 2, imageBytes[imageIndex]); // R
            bmpData.setUint8(i, imagePixel[imageIndex].rgbData[2]); // B 
            bmpData.setUint8(i + 1, imagePixel[imageIndex].rgbData[1]); // G
            bmpData.setUint8(i + 2, imagePixel[imageIndex].rgbData[0]); // R

            i += byesPerPixel;
            // imageIndex = imageIndex + 3
            imageIndex++;
        }
        i = rowSize * (height - y);
    }

    let buffer = Buffer.concat([
        // Buffer.from(imageBytes), 
        Buffer.from(BMP.buffer),
        Buffer.from(bmpData.buffer)
    ]);

    return buffer.toString('base64');
};