const svg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="84.27px" height="84.27px" viewBox="0 0 84.27 84.27" enable-background="new 0 0 84.27 84.27" xml:space="preserve">
<polygon fill-rule="evenodd" clip-rule="evenodd" points="70.227,43.891 63.203,55.303 56.18,66.714 42.135,66.714 28.09,66.714 
	21.066,55.303 14.043,43.891 21.066,32.479 28.09,21.067 42.135,21.067 56.18,21.067 63.203,32.479 "/>
</svg>`
export { svg };

