import React, { useState } from 'react';
import { store } from '../../../utils/Reducer';
import { connect } from 'react-redux';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
  Alert
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { webAPIUser, webAPIPass } from '../../Rest';
import { setUserRegist } from '../../../utils/Reducer';
import base64 from 'react-native-base64';
import { useTranslation } from 'react-i18next';
import configs from '../../../helpers/config';

const RegisterScreen = (props) => {
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState({
    activationCode: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  })

  const alertBox = (title, body) => {
    if (Platform.OS === 'web') {
      alert( title + '\n' + body);
    } else { 
      Alert.alert(
        title,
        body
      );
    }
    return;
  }
 
  const handleSubmitPress = () => {
    if (values.activationCode === '') {
      alertBox(
        t('STR_ERROR'),
        t('STR_ALERT_FILL_ACTIVATION_CODE')
      );
      return; 
    }
    if (values.firstName === '') {
      alertBox(
        t('STR_ERROR'),
        t('STR_ALERT_FILL_FIRST_NAME')
      );
      return;
    }
    if (values.lastName === '') {
      alertBox(
        t('STR_ERROR'),
        t('STR_ALERT_FILL_LAST_NAME')
      );
      return;
    }
    if (values.email === '') {
      alertBox(
        t('STR_ERROR'),
        t('STR_ALERT_FILL_EMAIL')
      );
      return;
    }
    if (values.password === '') {
      alertBox(
        t('STR_ERROR'),
        t('STR_ALERT_FILL_PASSWORD')
      );
      return;
    }
    if (values.password !== values.confirmPassword) {
      alertBox(
        t('STR_ERROR'),
        t('STR_ALERT_PASSWORDS_NOT_MATCH')
      );
      return;
    } 

    fetch(configs.APPUrls.webApi + '/v1/account/create', { 
      method: 'POST',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'activationCode': values.activationCode, 'oemId': 35, 'firstName': values.firstName, 'lastName': values.lastName, 'email': values.email, 'password': values.password })
    })
      .then(response => {
        if (response.ok) {
          store.dispatch(setUserRegist(values));
          props.navigation.navigate('Activate');
          return
        }
      });
  };

  return (
    <View style={styles.mainBody}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <View>
          <KeyboardAvoidingView enabled>
            <View style={{ alignItems: 'center' }}>
              <Icon name="person-circle-outline" size={150} color="#000000" />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserEmail) => setValues({ ...values, activationCode: UserEmail })}
                placeholder={(t('STR_LABEL_ACTIVATION_CODE'))}
                placeholderTextColor="#8b9cb5"
                autoCapitalize="none"
                keyboardType="default"
                returnKeyType="next"
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserEmail) => setValues({ ...values, firstName: UserEmail })}
                placeholder={t('STR_LABEL_FIRST_NAME')}
                placeholderTextColor="#8b9cb5"
                autoCapitalize="none"
                keyboardType="default"
                returnKeyType="next"
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserEmail) => setValues({ ...values, lastName: UserEmail })}
                placeholder={t('STR_LABEL_LAST_NAME')}
                placeholderTextColor="#8b9cb5"
                autoCapitalize="none"
                keyboardType="default"
                returnKeyType="next"
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserEmail) => setValues({ ...values, email: UserEmail })}
                placeholder={t('STR_LABEL_EMAIL')}
                placeholderTextColor="#8b9cb5"
                autoCapitalize="none"
                keyboardType="email-address"
                returnKeyType="next"
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserPass) => setValues({ ...values, password: UserPass })}
                placeholder={t('STR_LABEL_PASSWORD')}
                placeholderTextColor="#8b9cb5"
                keyboardType="default"
                onSubmitEditing={Keyboard.dismiss}
                blurOnSubmit={false}
                secureTextEntry={true}
                underlineColorAndroid="#f000"
                returnKeyType="next"
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserPass) => setValues({ ...values, confirmPassword: UserPass })}
                placeholder={t('STR_LABEL_PASSWORD_CONFIRM')}
                placeholderTextColor="#8b9cb5"
                keyboardType="default"
                onSubmitEditing={Keyboard.dismiss}
                blurOnSubmit={false}
                secureTextEntry={true}
                underlineColorAndroid="#f000"
                returnKeyType="next"
              />
            </View>
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={handleSubmitPress}>
              <Text style={styles.buttonTextStyle}>{t('STR_LABEL_REGISTER')}</Text>
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </View>
      </ScrollView>
    </View>
  );
};
export default connect(null, {})(RegisterScreen)


const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#05AC72',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#05AC72',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
});