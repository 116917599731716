import React, { useState, useEffect, useRef, createRef } from "react";
import { Platform, View, Text, StyleSheet, Dimensions, Image, TouchableOpacity, Alert } from "react-native";
import Icon from 'react-native-vector-icons/Ionicons';
import { convertGoogleDriveImageToBase64 } from '../Rest';
const axios = require("axios");
import globalVars from '../../helpers/store';
import { Checkbox } from 'react-native-paper';
import { store, removeRecordToPrintList, removeRecordToPrintListData } from '../../utils/Reducer';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const RecordCardWeb = (props) => {

    const ref = createRef(null)
    const [imageUriItem, setImageUriItem] = useState('');
    const [imageLoading, setImageLoading] = useState(false);
    const [selectedToPrint, setSelectedToPrint] = useState(props.recordSelectedToPrint ? 'checked' : 'unchecked');
    const [cardThumbnailData, setCardThumbnailData] = useState(null);
    const [hideSelection, setHideSelection] = useState(false);
    const [readyToTouch, setReadyToTouch] = useState(false);

    // Handle Redux state changes 
    store.subscribe(() => {
        const storeData = store.getState();
        if (storeData.recordsToPrintList !== undefined && storeData.recordsToPrintList.includes(props.recordId)) {
            setSelectedToPrint('checked');
        } else {
            setSelectedToPrint('unchecked');
        }
    });

    const deleteRecordFromPrintList = (recordId) => {
        store.dispatch(removeRecordToPrintList(recordId));
        store.dispatch(removeRecordToPrintListData(recordId));
    }
    let imgObj = props.templateProperties.items
        .filter((property, index) => (property.type === 'image' && (property.listable === true || property.listable === 'true')))
        .filter((property, index) => (index <= 0));

    let txtObj = props.templateProperties.items
        .filter((property, index) => ( 
            ( 
                property.type === 'text' || 
                property.type === 'integer' || 
                property.type === 'decimal' || 
                property.type === 'integer' || 
                property.type === 'datetime' || 
                property.type === 'date' || 
                property.type === 'time'  
            )
            && (property.listable === true || property.listable === 'true')))
        .filter((property, index) => (index <= 2));

    let imageURI = '';

    if (imgObj.length > 0) {

        imageURI = props.info[imgObj[0].id];
        useEffect(() => {
            (async () => {
                if (props.templateProperties.dataSource.type == 'GOOGLESHEETS' && imageURI != '') {
                    setImageLoading(true);
                    const imgObjBase64 = await convertGoogleDriveImageToBase64(imageURI, props.googleAccessToken);
                    if (imgObjBase64 !== false && globalVars.cardLoadCancel === false) {
                        setImageUriItem(imgObjBase64.replace('data:application/octet-stream;base64', 'data:image/png;base64'));
                    } else {
                        setImageUriItem(imageURI);
                    }
                    setImageLoading(false);
                    setReadyToTouch(true);
                } else {
                    if (imageURI != '' && globalVars.cardLoadCancel === false) {
                        setImageUriItem(imageURI);
                        setImageLoading(false);
                        setReadyToTouch(true);
                    } else {
                        setImageLoading(false);
                        setReadyToTouch(true);
                    }
                }
            })();
            return () => {
            }
        }, [imageURI]);
    }

    const imageLoaded = () => {
    };

    const cardSelected = () => {
        // if (props.enableRecordSelection !== false) {
        //     (async () => {
        //         setHideSelection(true);
        //     })();
        // } else {
        //     props.onPress(null);
        // }
        props.onPress();
    }

    return ( 
        <View style={styles.vStyle2} onLayout={(event) => { }}>
            <TouchableOpacity ref={ref} onPress={cardSelected} style={props.selected ? styles.cardContainerSelected : styles.cardContainer} 
             //   disabled={readyToTouch == false && imageURI !== ''}
            >
                <View style={styles.vStyle} >
                    {props.allowRemove !== undefined && props.allowRemove === true &&
                        <TouchableOpacity onPress={() => deleteRecordFromPrintList(props.recordId)} style={styles.removeIconStyle}>
                            <MaterialCommunityIcons name="delete" size={30} color="#FF5858" />
                        </TouchableOpacity>
                    }
                    <View style={styles.viewStyle}>
                        {imageLoading === false && imageUriItem === '' && <Icon style={styles.noImageIconStyle} name="camera" size={100} color="#000000" resizeMode='cover' />}
                        {imageLoading === true && <Image style={styles.imageStyle} source={require('../../assets/loading.gif')} resizeMode='cover' />}
                        {imageLoading === false && imageUriItem !== '' && <Image style={styles.imageStyle} source={{ uri: imageUriItem }} onLoad={imageLoaded} resizeMode='cover' />}
                    </View>
                    <View style={styles.infoHolder}>
                        {txtObj[0] !== undefined &&
                            <View style={styles.infoHolderItem}>
                                <Text style={styles.labelStyle} >{txtObj[0].id}</Text>
                                <Text style={styles.nameStyle} >{props.info[txtObj[0].id]}</Text>
                            </View>
                        }
                        {txtObj[1] !== undefined &&
                            <View style={styles.infoHolderItem}>
                                <Text style={styles.labelStyle} >{txtObj[1].id}</Text>
                                <Text style={styles.nameStyle} >{props.info[txtObj[1].id]}</Text>
                            </View>
                        }
                        {txtObj[2] !== undefined &&
                            <View style={styles.infoHolderItem}>
                                <Text style={styles.labelStyle} >{txtObj[2].id}</Text>
                                <Text style={styles.nameStyle} >{props.info[txtObj[2].id]}</Text>
                            </View>
                        }

                    </View>
                    {props.enableRecordSelection &&
                        <View style={[{ position: 'absolute', top: 5, right: 5 }, (hideSelection ? { display: "none" } : { display: "flex" })]} >
                            <Checkbox
                                color='green'
                                status={selectedToPrint}
                            />
                        </View>
                    }
                </View>
            </TouchableOpacity>
        </View>
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    cardContainer: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 25,
            },
            android: {
                width: deviceWidth - 25,
            },
            default: {
                width: 500,
                maxWidth: '100%'
            }
        }),
        backgroundColor: '#e1e2e3',
        minHeight: 150,
        borderRadius: radius,
        borderWidth: 0,
        marginTop: 15,
    },
    cardContainerSelected: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 25,
            },
            android: {
                width: deviceWidth - 25,
            },
            default: {
                width: 500,
                maxWidth: '100%'
            }
        }),
        backgroundColor: '#bbbbbb',
        minHeight: 150,
        borderRadius: radius,
        borderWidth: 2,
        marginTop: 15,
        borderColor: '#05AC72'
    },
    imageStyle: {

        height: 115,
        marginTop: 20,
        marginHorizontal: 8,
        borderRadius: 10,
        ...Platform.select({
            ios: {
                width: deviceWidth - 500,
            },
            android: {
                width: deviceWidth - 500,
            },
            default: {
                width: 80,
            }
        }),
    },
    noImageIconStyle: {
        alignSelf: "center",
    },

    textStyle: {
        fontSize: 20,
        fontWeight: '500',
        color: 'black',
        marginLeft: 6,
    },
    viewStyle: {
        width: 100,
        height: 'auto',
    },
    vStyle: {
        flexDirection: 'row'
    },
    editedStyle: {
        marginLeft: 6,
        marginTop: 30,
        fontWeight: 'bold'
    },
    vStyle2: {
        marginHorizontal: 15,
        // maxWidth: 500,
        maxWidth: '100%',
    },
    infoHolder: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: 10,
        marginBottom: 15,
    },
    infoHolderItem: {
        width: '100%',
        margin: 0,
        marginTop: 5,
        marginLeft: 20
    },
    labelStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'black'
    },
    nameStyle: {
        fontSize: 14,
        color: 'black'
    },
    removeIconStyle: {
        position: 'absolute',
        right: 10,
        top: 10,
        zIndex: 10
    }
});

export default RecordCardWeb;