
import React from 'react';
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import { useTranslation } from 'react-i18next';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Subheading } from 'react-native-paper';
import Animated, { ZoomIn } from 'react-native-reanimated';

export type props = {
};

const EmptyList: React.FC<props> = ({
}) => { 
    const { t, i18n } = useTranslation();

    return (
        <Animated.View entering={ZoomIn.delay(250).duration(500)} style={styles.container} >
            <View style={styles.iconContainer}> 
                <MaterialCommunityIcons style={styles.icon} name="emoticon-sad-outline" size={100} color="#646464" />
                <Subheading style={styles.text}>{t('STR_INFO_DATA_NOT_FOUND')}</Subheading>
            </View>
        </Animated.View>
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    container: {
        width: deviceWidth - 25, 
        minHeight: '100%',
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        zIndex: -1,
    },
    iconContainer: {
        flexDirection: 'column'
    },
    icon: {
        alignSelf: 'center'
    },
    text: {
        marginTop: 30,
        color: '#969696',
        fontSize: 25
    }

});

export default EmptyList;
