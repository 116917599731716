import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Platform, AppState, StyleSheet, Text, View, ScrollView, SafeAreaView, Dimensions
    , Alert, Image as RNImage
    , Clipboard
} from 'react-native';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import Loader from '../../components/Loader';
import { getTemplateBytes, unzipTemplateBytes, ptToPx } from '../../../helpers/cardRender/utils';
import { itemProcess } from '../../components/cardDesigner/utils/itemProcess';

import * as FileSystem from 'expo-file-system';
// import * as MediaLibrary from "expo-media-library";
import { Button } from 'react-native-paper';

import { Canvas, Circle, Group, Path, Image, useImage, useCanvasRef, RoundedRect, Rect, Fill } from "@shopify/react-native-skia";

import globalVars from '../../../helpers/store';

// Item components
import BackgroundItem from '../../components/cardDesigner/background/BackgroundItem';
import ImageItem from '../../components/cardDesigner/image/ImageItem';
import CircleItem from '../../components/cardDesigner/shapes/CircleItem';
import RectangleItem from '../../components/cardDesigner/shapes/RectangleItem';
import ShapeItem from '../../components/cardDesigner/shapes/ShapeItem';
import PolygonItem from '../../components/cardDesigner/shapes/PolygonItem';
import TextItem from '../../components/cardDesigner/text/TextItem';

// Print 
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';

export type props = {
};

interface Item {
    id: number,
    itemType: string,
    xmlObj: any,
    imageBase64Data: string | null
}

const CardRender: React.FC<props> = ({
}) => {
    const ref = useCanvasRef();
    const isFocused = useIsFocused();
    const [renderedImage, setRenderedImage] = useState<string>('');
    const [cardWidthState, setCardWidthState] = useState<number>(0);
    const [cardHeightState, setCardHeightState] = useState<number>(0);
    const [itemsToRender, setItemsToRender] = useState<Item[]>([]);
    const [itemsStateLoaded, setItemsStateLoaded] = useState<boolean>(false);

    const size = 256;
    const r = size * 0.33;

    const getItemsNumber = (items: any) => {
        return items.item.length;
    }
    const getItemType = (item: any) => {
        return item.info.designObject;
    }
    const addNewItem = useCallback((itemToPush: Item) => {
        setItemsToRender(itemsToRender => {
            const nextItemId = (itemsToRender[itemsToRender.length - 1]?.id ?? 0) + 1;
            return [...itemsToRender,
            {
                id: nextItemId,
                itemType: itemToPush.itemType,
                xmlObj: itemToPush.xmlObj,
                imageBase64Data: itemToPush.imageBase64Data
            }];
        });
    }, []);

    const saveImage = () => {
        (async () => {

            // const image = ref.current?.makeImageSnapshot({ x: 0, y: 0, width: cardWidthState, height: cardHeightState });
            const image = ref.current?.makeImageSnapshot();

            if (image) {
                // you can use image in an <Image> component
                // Or save to file using encodeToBytes -> Uint8Array
                const base64Data = image.encodeToBase64();
                // const base64Code = base64Data.split("data:image/png;base64,")[1];
                // // console.log(base64Data);
                // Clipboard.setString(base64Data); 

                const html = `
                <html>
                    <style>
                        @page {
                            margin: 0px;
                        }
                    </style>
                    <img
                        src="data:image/jpeg;base64,`+ base64Data + `"
                        style="width: 100vw;"margin: 0 />
                </html> 
                `;
                await Print.printAsync({
                    html,
                    margins: {
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                    },
                    width: cardWidthState,
                    height: cardHeightState
                    // printerUrl: selectedPrinter?.url, // iOS only
                });

                // try {
                //     // Request device storage access permission
                //     const { status } = await MediaLibrary.requestPermissionsAsync();
                //     if (status === "granted") {
                //         // Save image to media library
                //         const filename = FileSystem.documentDirectory + "some_unique_file_name.png";
                //         await FileSystem.writeAsStringAsync(filename, base64Data, {
                //             encoding: FileSystem.EncodingType.Base64,
                //         });
                //         await MediaLibrary.saveToLibraryAsync(filename);
                //         console.log("Image successfully saved");
                //     }
                //     // const base64Image = 'data:image/png;base64,' + base64Data;
                //     // setRenderedImage(base64Image);
                // } catch (error) {
                //     console.log(error);
                // }
            }




        })();
    }

    // Load on screen focus (active)
    useEffect(
        () => {
            (async () => {

                if (isFocused) {

                    const templateBytes = await getTemplateBytes(34); 
                    const extractedZipData = await unzipTemplateBytes(templateBytes);

                    const sizeData = extractedZipData.printData.print.model.size;
                    const sizeArray = sizeData.split(",");
                    let cardWidthSizeInMM = ptToPx(sizeArray[0]);
                    let cardheightSizeInMM = ptToPx(sizeArray[1]);

                    // TODO - Read Paper Size
                    const paperSize: any = {
                        width: 1006,
                        height: 640
                    };  
                    cardWidthSizeInMM = paperSize.width;
                    cardheightSizeInMM = paperSize.height;

                    // Sample card - 1006 x 640
                    const cardWidth: number = cardWidthSizeInMM;
                    const cardHeight: number = cardheightSizeInMM;
                    setCardWidthState(cardWidth);
                    setCardHeightState(cardHeight);

                    const itemsNumber: number = getItemsNumber(extractedZipData.itemsData.items);
                    for (let index = 0; index < itemsNumber; index++) {

                        const currentItem = extractedZipData.itemsData.items.item[index];
                        const itemType: string = getItemType(currentItem);

                        let base64ImageData = null;

                        // Load image if exists 
                        if (currentItem.imageAcquisition?.picture?.data) {
                            const imageData = await globalVars.zipData.file(currentItem.imageAcquisition.picture.data).async("base64");
                            const regex = new RegExp('[^.]+$');
                            const extension = currentItem.imageAcquisition.picture.data.match(regex);
                            // base64ImageData = 'data:image/' + extension[0] + ';base64,' + imageData;
                            base64ImageData = imageData;
                        }

                        // Load SVG data if exists 
                        if (currentItem.clipart?.image?.data) {
                            const SVGData = await globalVars.zipData.file(currentItem.clipart.image.data).async("base64");
                            base64ImageData = SVGData;
                        }

                        // Add paper size to item data
                        currentItem.paperSize = paperSize;

                        let dataToPush: {
                            id: number,
                            itemType: string,
                            xmlObj: any,
                            imageBase64Data: string | null
                        } = {
                            id: 0,
                            itemType: itemType,
                            xmlObj: currentItem,
                            imageBase64Data: base64ImageData
                        }
                        addNewItem(dataToPush);


                    }
                    setItemsStateLoaded(true);
                    console.log("---------------------")
                }
            })();
        },
        [isFocused]
    )
    

    function ItemRender(item: Item): any {

        switch (item.itemType) {
            case 'background':
                return (<BackgroundItem id={item.id} xmlObj={item.xmlObj} imageBase64Data={item.imageBase64Data} />);
                break;
            case 'imageGallery':
            case 'imageDeviceDisk':
                return (<ImageItem id={item.id} xmlObj={item.xmlObj} imageBase64Data={item.imageBase64Data} />);
                break;
            case 'text':
                return (<TextItem id={item.id} xmlObj={item.xmlObj} customText={null} />);
            case 'circle':
                return (<CircleItem id={item.id} xmlObj={item.xmlObj} />);
                break;
            case 'rectangle':
                return (<RectangleItem id={item.id} xmlObj={item.xmlObj} />);
                break;
            case 'shape':

                if (item.xmlObj?.clipping?.shape[0] !== undefined && item.xmlObj.clipping.shape[0] === 'polygon') {
                    return (<PolygonItem id={item.id} xmlObj={item.xmlObj} />);
                } else {
                    return (<ShapeItem id={item.id} xmlObj={item.xmlObj} svgData={item.imageBase64Data} />);
                }
                break;
        }
    }

    return (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <View style={styles.cardHolder}>
                {renderedImage === '' &&
                    <View style={{
                        // transform: [{ scale: 0.80 }] // ZOOM
                        // transform: [{ scale: 0.58 }] // Tablet 
                        transform: [{ scale: 0.37 }]
                    }}>
                        <Canvas
                            mode="continuous"
                            style={{
                                width: cardWidthState,
                                maxWidth: cardWidthState,
                                height: cardHeightState,
                                maxHeight: cardHeightState,
                            }} ref={ref} >
                            <Group blendMode="multiply">
                                {itemsStateLoaded === true && itemsToRender.map((item, index) => (
                                    <ItemRender key={index} id={item.id} itemType={item.itemType} xmlObj={item.xmlObj} imageBase64Data={item.imageBase64Data} />
                                ))}
                            </Group>
                        </Canvas>
                    </View>
                }
                {renderedImage !== '' &&
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <RNImage
                            style={{
                                width: '100%',
                                height: '100%',
                                maxWidth: (Platform.OS === 'web' ? 600 : (Math.round(Dimensions.get('window').width) - 50))
                            }}
                            source={{ uri: renderedImage }}
                            // resizeMode={(Platform.OS === 'web' ? 'center' : 'contain')}
                            // style={{ width: 30 }}
                            resizeMode='contain'
                        />
                    </View>
                }
                <Button color='#000000' style={{ position: 'absolute', bottom: 5, right: 0, left: 0, backgroundColor: '#969696' }} onPress={() => saveImage()}>{"SAVE IMG"}</Button>
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    cardHolder: {
        // maxWidth: 500
        width: '100%',
        height: '100%',
        alignItems: "center",
        // scaleX: 0.1,
        // scaleY:  0.1

    }
});

const mapStateToProps = (
    state: {
    }
) => {
    return {
    }
}

export default connect(mapStateToProps, {})(CardRender);