
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View, Dimensions, TouchableOpacity, Alert, Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Text, useTheme } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { FAB } from 'react-native-paper';

export type props = {
    width: number,
    height: number,
    frontData: string | null,
    backData: string | null,
    onDelete: any
};

const CardPreviewer: React.FC<props> = ({
    width,
    height,
    frontData,
    backData,
    onDelete
}) => {

    const theme = useTheme();
    const [showBack, setShowBack] = useState<boolean>(false);

    return (    
        <View>
            <View style={[styles.operationsHolder, { width: width }]}>
                <TouchableOpacity
                    onPress={() => onDelete()}
                    style={styles.removeIconStyle}>
                    <MaterialCommunityIcons name="delete" size={30} color="#FF5858" />
                </TouchableOpacity>
                {backData !== null &&
                    <TouchableOpacity
                        onPress={() => setShowBack(!showBack)}
                        style={styles.rotateIconStyle}>
                        <MaterialCommunityIcons name="rotate-3d-variant" size={30} color="#05AC72" />
                    </TouchableOpacity>
                }
            </View>
            {!showBack &&
                <TouchableOpacity
                    onPress={() => {
                        if (backData !== null) {
                            setShowBack(!showBack)
                        }
                    }}
                >
                    <Image
                        style={{
                            width: width,
                            height: height,
                            borderColor: theme.colors.lightGrey,
                            borderWidth: 1,
                            borderRadius: 12
                        }}
                        source={{
                            uri: "data:image/jpg;base64," + frontData,
                        }}
                        resizeMode='contain'
                    />
                </TouchableOpacity>
            }
            {
                showBack && backData !== null &&
                <TouchableOpacity
                    onPress={() => setShowBack(!showBack)}
                >
                    <Image
                        style={{
                            width: width,
                            height: height,
                            borderColor: theme.colors.lightGrey,
                            borderWidth: 1,
                            borderRadius: 12
                        }}
                        source={{
                            uri: "data:image/jpg;base64," + backData,
                        }}
                        resizeMode='contain'
                    />
                </TouchableOpacity>
            }
        </View >
    );
};


const styles = StyleSheet.create({
    operationsHolder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,        
        // backgroundColor: 'rgba(255, 255,255, 0.7)',
        zIndex: 1,
        height: 40,
        borderTopEndRadius: 12,
        borderTopStartRadius: 12
    },
    removeIconStyle: {
        position: 'absolute',
        left: 10,
        top: 5,
        zIndex: 99,
        elevation: 99
    },
    rotateIconStyle: {
        position: 'absolute',
        right: 10,
        top: 5,
        zIndex: 99,
        elevation: 99
    }
});

const mapStateToProps = (
    state: {
    }
) => {
    return {
    }
}

export default connect(mapStateToProps, {})(CardPreviewer);
