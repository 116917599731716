
import React from 'react';
import { findProperty } from '../../../../helpers/cardRender/utils';

import {
    store,
    setPrintCounter
} from '../../../../utils/Reducer'
import TextItem from '../text/TextItem';

export type props = {
    id: number,
    xmlObj: any,
    customText: string | null
};


const printCounterItem: React.FC<props> = ({
    id,
    xmlObj,
    customText
}) => {
    let text: string = ""

    let minimumValue = findProperty(xmlObj.source, "min")
    if (minimumValue == undefined) minimumValue = 0
    let maximumValue = findProperty(xmlObj.source, "max")
    if (maximumValue == undefined) maximumValue = 9999
    let restartValue = findProperty(xmlObj.source, "restart")
    if (restartValue == undefined) restartValue = 0
    let stepValue = findProperty(xmlObj.source, "step")
    if (stepValue == undefined) stepValue = 1



    let eventType = findProperty(xmlObj.source, "eventType")
    if (eventType == undefined) eventType = "PREPRINT"

    const storeData = store.getState()
    const print_counter = storeData.printCounter
    let newValue = 0
    if (print_counter == undefined) {
        newValue = minimumValue + stepValue
        if (eventType == "POSPRINT") text = minimumValue.toString()
    } else if (print_counter + stepValue > maximumValue) {
        newValue = restartValue
        if (eventType == "POSPRINT") text = restartValue.toString()
    } else {
        newValue = print_counter + stepValue
        if (eventType == "POSPRINT") text = print_counter.toString()
    }
    store.dispatch(setPrintCounter(newValue - 1))
    if (eventType == "PREPRINT") text = newValue.toString()

    return (<TextItem id={id} xmlObj={xmlObj} customText={text} />);
};

export default printCounterItem;
