//import { webSocketInit, webSocketDisconnect } from './../JsonRpc'
import AsyncStorage from '@react-native-async-storage/async-storage';

function paddingZero( value ) {
    return ( value < 10 ) ? "0" + value : value;
}

export function formatDateEpoch( epoch ) {
    if ( epoch === null ) return '';
    var date = new Date( epoch*1000 );
    return date.getFullYear() + '-'
    + paddingZero( date.getMonth()+1 ) + '-'
    + paddingZero( date.getDate() ) + ' '
    + paddingZero( date.getHours() ) + ':'
    + paddingZero( date.getMinutes() ) + ':'
    + paddingZero( date.getSeconds() );
}

export async function saveToLocalStorage( newState ) {
    try {
        const serializedState = JSON.stringify( { 
            authToken: newState.authToken
          } )
        await AsyncStorage.setItem('state', serializedState )
        //webSocketInit( newState.authToken )
    }
    catch( e ) {
        console.log( e ) 
        Sentry.Native.captureException(e);
    }
}
  
export async function clearLocalStorage() {
    try {
        //webSocketDisconnect()
        await AsyncStorage.removeItem('state')
    }
    catch( e ) {
        console.log( e )
        Sentry.Native.captureException(e);
    }
}

export async function loadFromLocalStorage() {
    try {
        const serializedState = await AsyncStorage.getItem('state')
        if ( serializedState === null ) return undefined
        var localStorageState = JSON.parse( serializedState )
        //if ( localStorageState.authToken ) webSocketInit( localStorageState.authToken )
        return localStorageState
    }
    catch( e ) {
        console.log( e )
        Sentry.Native.captureException(e);
        return undefined
    }
}