
import configs from './config';
import { Platform } from "react-native";
import NetInfo from '@react-native-community/netinfo';
import * as FileSystem from 'expo-file-system';
import resourceDB from '../helpers/db/models/resource'

export async function deviceIsOnline() {
    return new Promise((resolve) => {
        (async () => {
            try {

                if (Platform.OS === 'web') {
                    resolve(true);
                    return;
                }

                NetInfo.fetch().then((state) => {
                    if (state.isConnected && state.isInternetReachable) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            }
            catch (e) {
                console.log(e)
                Sentry.Native.captureException(e);
            }
        })();
    });
}

export async function convertLocalTmpFile(base64Data) {
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                const base64Code = base64Data.split("data:image/png;base64,")[1];
                const filename = FileSystem.cacheDirectory + Math.floor(Date.now() * Math.random()).toString() + ".png";
                await FileSystem.writeAsStringAsync(filename, base64Code, {
                    encoding: FileSystem.EncodingType.Base64,
                });
                resolve(filename);
            } catch (e) {
                resolve("");
            }
        })();
    });
}

export async function getPrinterImageUrl(manufacturer, model) {

    return new Promise((resolve) => {

        (async () => {

            function checkIfImageExists(url, callback) {
                const img = new Image();
                img.src = url;

                if (img.complete) {
                    callback(true);
                } else {
                    img.onload = () => {
                        callback(true);
                    };

                    img.onerror = () => {
                        callback(false);
                    };
                }
            }

            const manufacturerStr = String(manufacturer);
            const modelStr = String(model);
            let resourceName = manufacturerStr.toLowerCase().replace(/ /g, '') + '_' + model.toLowerCase().replace(/ /g, '_') + '.' + 'png';

            // Local printer name fix
            if (resourceName.indexOf("evolis_evolis") !== -1) {
                resourceName = resourceName.replace("evolis_evolis", "evolis");
            }

            const printerImageUrl = configs.APPUrls.webApi + '/public/printers/' + resourceName;
            const defaultImageUrl = configs.APPUrls.webApi + '/public/printers/default_printer.png';

            if (Platform.OS === 'web') {

                checkIfImageExists(printerImageUrl, (exists) => {
                    if (exists) {
                        resolve(printerImageUrl);
                    } else {
                        resolve(defaultImageUrl);
                    }
                });

            } else {

                let localResource = await resourceDB.query({
                    where: {
                        name_eq: resourceName
                    }
                });
                if (localResource[0] !== undefined) {
                    resolve(localResource[0].localFilePath)
                } else {
                    let defaultPrinterResource = await resourceDB.query({
                        where: {
                            name_eq: "default_printer.png"
                        }
                    });
                    if (defaultPrinterResource[0] !== undefined) {
                        resolve(defaultPrinterResource[0].localFilePath)
                    } else {
                        resolve(false)
                    }
                }

                // DEPRECATED *****************************
                // fetch(printerImageUrl, { method: 'HEAD' })
                //     .then(res => {
                //         if (res.ok) {
                //             resolve(printerImageUrl);
                //         } else {
                //             resolve(defaultImageUrl);
                //         }
                //     }).catch(err => {  
                //         Sentry.Native.captureException(err); 
                //         resolve(defaultImageUrl)
                //     });

            }

        })();
    });
}

export function getPrinterStates() {

    let avaliableStates = [
        {
            hexCode: '0x0101',
            code: getPrinterStateCodeFromHex('0x0101'),
            description: 'Initializing',
            label: 'STR_LABEL_PRINT_JOB_STATE_INITIALIZING'
        },
        {
            hexCode: '0x0201',
            code: getPrinterStateCodeFromHex('0x0201'),
            description: 'Pending',
            label: 'STR_LABEL_PRINT_JOB_STATE_PENDING'
        },
        {
            hexCode: '0x0202',
            code: getPrinterStateCodeFromHex('0x0202'),
            description: 'Running',
            label: 'STR_LABEL_PRINT_JOB_STATE_RUNNING'
        },
        {
            hexCode: '0x0204',
            code: getPrinterStateCodeFromHex('0x0204'),
            description: 'Paused',
            label: 'STR_LABEL_PRINT_JOB_STATE_PAUSED'
        },
        {
            hexCode: '0x0208',
            code: getPrinterStateCodeFromHex('0x0208'),
            description: 'Waiting',
            label: 'STR_LABEL_PRINT_JOB_STATE_WAITING'
        },
        {
            hexCode: '0x0210',
            code: getPrinterStateCodeFromHex('0x0210'),
            description: 'AbortRequested',
            label: 'STR_LABEL_PRINT_JOB_STATE_ABORT_REQUESTED'
        },
        {
            hexCode: '0x0401',
            code: getPrinterStateCodeFromHex('0x0401'),
            description: 'Successful',
            label: 'STR_LABEL_PRINT_JOB_STATE_SUCCESSFUL'
        },
        {
            hexCode: '0x0402',
            code: getPrinterStateCodeFromHex('0x0402'),
            description: 'HasErrors',
            label: 'STR_LABEL_PRINT_JOB_STATE_HAS_ERRORS'
        },
        {
            hexCode: '0x0404',
            code: getPrinterStateCodeFromHex('0x0404'),
            description: 'Aborted',
            label: 'STR_LABEL_PRINT_JOB_STATE_ABORTED'
        },
        {
            hexCode: '0x0000',
            code: getPrinterStateCodeFromHex('0x0000'),
            description: 'InvalidStatus',
            label: 'STR_LABEL_PRINT_JOB_STATE_INVALID_STATUS'
        },

    ];
    return avaliableStates;
}

export function getPrinterStateDescriptionFromCode(code) {
    const printerStates = getPrinterStates();
    const stateData = printerStates.filter((printerStates) =>
        printerStates.code === code
    );
    return stateData;
}

export function getPrinterStateCodeFromHex(hexState) {

    // Initializing		    =0x0101,
    // Pending				=0x0201,
    // Running				=0x0202,
    // Paused				=0x0204,
    // Waiting				=0x0208,
    // //Idle				=0x0208,
    // AbortRequested		=0x0210,
    // Successful			=0x0401,
    // HasErrors			=0x0402,
    // Aborted				=0x0404,
    // InvalidStatus		=0x0000,
    const hexToDecimal = hex => parseInt(hex, 16);
    return hexToDecimal(hexState).toString();
}

export function sleep(ms) {
    return new Promise(
        resolve => setTimeout(resolve, ms)
    );
}

export function deleteFile(filePath) {
    return new Promise((resolve) => {
        (async () => {
            try {
                await FileSystem.deleteAsync(filePath);
                resolve(true);
            } catch (error) {
                resolve(false);
            }
        })();
    });
}

export function deleteAllCacheFiles() {
    return new Promise((resolve) => {
        (async () => {
            try {

                const cacheDirectory = FileSystem.cacheDirectory;
                const listFilesInDirectory = async (directory) => {
                    try {
                        const files = await FileSystem.readDirectoryAsync(directory);
                        return files;
                    } catch (error) {
                        console.error('Error listing files:', error);
                        return [];
                    }
                };
                const filesInCache = await listFilesInDirectory(cacheDirectory);
                filesInCache.forEach((file) => {
                    deleteFile(cacheDirectory + file);
                });
                resolve(true);

            } catch (error) {
                resolve(false);
            }

        })();
    });
} 
