
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View, Dimensions, TouchableOpacity, Alert } from 'react-native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Text } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { FAB } from 'react-native-paper';

export type props = {
    deviceId: string,
    createDate: string,
    deviceInfo: any,
    deviceMaster: boolean
    removeOper: any,
    deviceIsOnline: boolean
};

const DeviceCard: React.FC<props> = ({
    deviceId,
    createDate,
    deviceInfo,
    deviceMaster,
    removeOper,
    deviceIsOnline
}) => {
    const { t, i18n } = useTranslation();

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getBrowserFromAgent = (agentInfo: string) => {

        let browserName:string = '';
        if (agentInfo.match(/edg/i)) {
            browserName = "edge";
        } else if (agentInfo.match(/safari/i)) {
            browserName = "safari";
        } else if (agentInfo.match(/chrome|chromium|crios/i)) {
            browserName = "chrome";
        } else if (agentInfo.match(/firefox|fxios/i)) {
            browserName = "firefox";
        } else if (agentInfo.match(/opr\//i)) {
            browserName = "opera";
        } else if (agentInfo.match(/edg/i)) {

        } else {
            browserName = "No browser detection";
        }
        return capitalizeFirstLetter(browserName);
    }

    const removeDevice = (deviceID: string) => {
        if (Platform.OS === 'web') {
            let text = t('STR_ALERT_ATTENTION') + '\n' + t('STR_ALERT_REMOVE_DEVICE_CONFIRM');
            if (confirm(text) === true) {
                removeOper(deviceID);
            }
        } else {
            Alert.alert(
                t('STR_ALERT_ATTENTION'),
                t('STR_ALERT_REMOVE_DEVICE_CONFIRM'),
                [
                    {
                        text: t('STR_CANCEL'),
                        style: "cancel"
                    },
                    {
                        text: t('STR_YES'), onPress: () => removeOper(deviceID)
                    }
                ]
            );
        }
    }

    return (
        <View style={styles.container}>

            <View >
                <FAB
                    style={[styles.fab, ((deviceIsOnline) ? styles.fabOnline : styles.fabOffline)]}
                />
            </View>

            <View style={styles.itemsHolder}>

                <View style={styles.itemCol1}>
                    {deviceInfo.platform === 'android' &&
                        <MaterialCommunityIcons name="android" size={60} style={{ height: 50 }} />
                    }
                    {deviceInfo.platform === 'ios' &&
                        <MaterialCommunityIcons name="apple-ios" size={60} />
                    }
                    {deviceInfo.platform === 'web' &&
                        <MaterialCommunityIcons name="web" size={60} />
                    }
                </View>

                <View style={styles.itemCol2}>

                    <Text style={styles.idHolder}>{deviceId}</Text>
                    {deviceInfo.deviceManufacturer !== null &&
                        <Text style={styles.textHolder}>{deviceInfo.deviceManufacturer} {deviceInfo.deviceBrand}</Text>
                    }
                    {deviceInfo.deviceOsName !== null &&
                        <Text style={styles.textHolder}>{deviceInfo.deviceOsName} {deviceInfo.deviceOsVersion}</Text>
                    }
                    {deviceInfo.platform === 'web' && deviceInfo.browserInfo !== undefined && deviceInfo.browserInfo !== '' &&
                        <Text style={styles.textHolder}>{getBrowserFromAgent(deviceInfo.browserInfo)}</Text>
                    }
                </View>
                {deviceMaster === false &&
                    <TouchableOpacity onPress={() => removeDevice(deviceId)} style={styles.removeIconStyle}>
                        <MaterialCommunityIcons name="delete" size={30} color="#FF5858" />
                    </TouchableOpacity>
                }
                {deviceMaster === true &&
                    <View style={styles.removeIconStyle}>
                        <MaterialCommunityIcons name="lock" size={30} color="#646464" />
                    </View>
                }
            </View>

        </View >
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    container: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 25,
            },
            android: {
                width: deviceWidth - 25,
            },
            default: {
                width: 450,
            }
        }),
        backgroundColor: '#CFCFCF',
        minHeight: 150,
        borderRadius: radius,
        borderWidth: 0,
        marginTop: 15,
        paddingHorizontal: 10,
        paddingVertical: 10
    },
    idHolder: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    textHolder: {
        fontSize: 16,
    },
    itemsHolder: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    itemCol1: {
        width: '20%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
    },
    itemCol2: {
        height: '100%',
        width: '80%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    removeIconStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 10
    },
    fab: {
        position: 'absolute',
        margin: 0,
        left: 0,
        top: 0,
        width: 20,
        height: 20,
        zIndex: 2
    },
    fabOnline: {
        backgroundColor: "#05AC72"
    },
    fabOffline: {
        backgroundColor: "#FF5858"
    },
});

const mapStateToProps = (
    state: {
    }
) => {
    return {
    }
}

export default connect(mapStateToProps, {})(DeviceCard);
