
import React, { useState, useEffect } from 'react';
import { ptToPx } from '../../../../helpers/cardRender/utils';
import { Skia, Rect, Group, Path, DashPathEffect } from "@shopify/react-native-skia";

export type props = {
    id: number,
    xmlObj: any,
};

const RectangleItem: React.FC<props> = ({
    id,
    xmlObj,
}) => {

    const startX: number = ptToPx(xmlObj.position.pos1.x);
    const startY: number = ptToPx(xmlObj.position.pos1.y);
    const endX: number = ptToPx(xmlObj.position.pos2.x);
    const endY: number = ptToPx(xmlObj.position.pos2.y);
    const imageWidth: number = endX - startX;
    const imageHeight: number = endY - startY;
    let bkgColor: string = "transparent";

    let itemStrokeColor: string = "#000";
    let itemStrokeWidth: number = 0;
    let lineStyle: string | undefined;
    let lineStyleArray: number[] = [];

    if (xmlObj.fill?.type[1]?.solid?.color[0]) {
        bkgColor = xmlObj.fill?.type[1]?.solid?.color[0];
    }

    if (xmlObj.line?.color) {
        itemStrokeColor = xmlObj.line.color;
    }

    if (xmlObj.line?.width) {
        itemStrokeWidth = ptToPx(xmlObj.line.width);
    }

    if (xmlObj.line?.style) {
        lineStyle = xmlObj.line.style;
    }

    const dotSize: number = itemStrokeWidth;
    const lineSize: number = dotSize * 2;
    const dashSize: number = lineSize * 2;

    switch (lineStyle) {
        case "DashDotDotLine":
            lineStyleArray = [dotSize, lineSize, dotSize, lineSize, dashSize, lineSize];
            break;
        case "DotLine":
            lineStyleArray = [dotSize, lineSize];
            break;
        case "DashLine":
            lineStyleArray = [dashSize, lineSize];
            break;
        case "DashDotLine":
            lineStyleArray = [dotSize, lineSize, dashSize, lineSize];
            break;
    }


    if (lineStyle === "NoPen") {
        return (
            <Rect
                x={startX}
                y={startY}
                width={imageWidth}
                height={imageHeight}
                color={bkgColor}
                blendMode="srcATop"
            />
        );
    } else {

        // Init path draw
        const path = Skia.Path.Make();
        let itemFillColor: string = "#000";

        if (xmlObj.fill?.type[1]?.solid?.color && Array.isArray(xmlObj.fill?.type[1]?.solid?.color) === false) {
            itemFillColor = xmlObj.fill.type[1].solid.color;
        }

        path.moveTo(startX, startY);
        path.lineTo(startX, endY);
        path.lineTo(endX, endY);
        path.lineTo(endX, startY);
        path.lineTo(startX, startY);

        return (
            <Group>
                <Rect
                    x={startX}
                    y={startY}
                    width={imageWidth}
                    height={imageHeight}
                    color={bkgColor}
                    blendMode="srcATop"
                />
                <Path
                    path={path}
                    color={itemStrokeColor}
                    style="stroke"
                    // strokeJoin="round"
                    strokeWidth={itemStrokeWidth}
                    blendMode="src"
                >
                    {lineStyle !== 'SolidLine' &&
                        <DashPathEffect intervals={lineStyleArray} />
                    }
                </Path>
            </Group>
        );
    }
};

export default RectangleItem;