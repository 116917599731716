import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, StyleSheet, View, ScrollView, Alert } from 'react-native';
import Animated, { BounceInDown, Layout, ZoomOutUp } from 'react-native-reanimated';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { getPendingPrintJobs } from '../../Rest';
import { useTranslation } from 'react-i18next';
import { useIsFocused } from '@react-navigation/native';
import PrintJobCard from '../../components/PrintJobCard';
import globalVars from '../../../helpers/store';
import { getPrinterStateCodeFromHex, deviceIsOnline } from '../../../helpers/utils';
import EmptyList from '../../components/global/emptyList';
import { store, setScreenToGo } from '../../../utils/Reducer';

export type props = {
};

interface Item {
    id: number,
    jobId: string,
    printerSn: string,
    jobStatus: string,
    jobInfo: string,
    recordId: number,
    recordThumbnail: string,
    recordState: number,
    recordStateInfo: string
}

const PrintJobsPending: React.FC<props> = ({
}) => {
    const { t, i18n } = useTranslation();
    const initialMode = useRef<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [pendingPrintDataState, setPendingPrintDataState] = useState<Item[]>([]);
    let globalVarsObj: any = globalVars;

    useEffect(() => {
        initialMode.current = false;
    });

    const isFocused = useIsFocused();

    const addNewItem = useCallback((itemToPush: Item) => {
        setPendingPrintDataState(pendingPrintDataState => {
            const nextItemId = (pendingPrintDataState[pendingPrintDataState.length - 1]?.id ?? 0) + 1;
            return [...pendingPrintDataState,
            {
                id: nextItemId,
                jobId: itemToPush.jobId,
                printerSn: itemToPush.printerSn,
                jobStatus: itemToPush.jobStatus,
                jobInfo: itemToPush.jobInfo,
                recordId: itemToPush.recordId,
                recordThumbnail: itemToPush.recordThumbnail,
                recordState: itemToPush.recordState,
                recordStateInfo: itemToPush.recordStateInfo
            }];
        });
    }, []);

    const deleteItem = useCallback((itemId: number) => {
        setPendingPrintDataState(currentItems => {
            return currentItems.filter(item => item.id !== itemId);
        });
    }, []);

    useEffect(
        () => {
            (async () => {
                if (isFocused) {

                    const isDeviceOnline = await deviceIsOnline();
                    if (isDeviceOnline === false) {
                        Alert.alert(
                            t('STR_ERROR'),
                            t('STR_ERROR_ONLY_WITH_ACTIVE_INTERNET_CONNECTION')
                        );
                        store.dispatch(setScreenToGo("Template"));
                        return;
                    }

                    setLoading(true);
                    const pendingPrintJobs = await getPendingPrintJobs();
                    // console.log(pendingPrintJobs);

                    for (let printJobIndex = 0; printJobIndex < pendingPrintJobs.printJobData.length; printJobIndex++) {
                        const currentJob = pendingPrintJobs.printJobData[printJobIndex];

                        for (let recordIndex = 0; recordIndex < currentJob.recordsData.length; recordIndex++) {
                            const currentRecordJob = currentJob.recordsData[recordIndex];

                            let dataToPush: {
                                id: number,
                                jobId: string,
                                printerSn: string,
                                jobStatus: string,
                                jobInfo: string,
                                jobDate: string,
                                recordId: number,
                                recordThumbnail: string,
                                recordState: number,
                                recordStateInfo: string,
                            } = {
                                id: 0,
                                jobId: currentJob.jobId,
                                printerSn: currentJob.printerSn,
                                jobStatus: currentJob.jobStatus,
                                jobInfo: currentJob.jobInfo,
                                jobDate: currentJob.jobDate,
                                recordId: currentRecordJob.recordId,
                                recordThumbnail: currentRecordJob.cloudJobRecordThumbnail,
                                recordState: currentRecordJob.recordStatus,
                                recordStateInfo: currentRecordJob.recordStatusInfo
                            }
                            addNewItem(dataToPush);
                        }
                    }
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })();
        },
        []
    )

    if (globalVarsObj.socket !== undefined) {
        globalVarsObj.socket.on('printJobRecordStateChanged', function (data: any) {

            if (data.state === getPrinterStateCodeFromHex("0x0401")) {
                // setPendingPrintDataState(pendingPrintDataState =>
                //     pendingPrintDataState.filter(item => {
                //         return item.printerSn === data.printerSN && item.recordId !== data.recordId;
                //     })
                // );                
                let item = pendingPrintDataState.filter(item => {
                    return item.recordId === data.recordId && item.jobId === data.jobID && item.printerSn === data.printerSN;
                })
                if (item.length > 0) {
                    deleteItem(item[0].id);
                }
            }
        });
    }

    return (
        <View style={styles.container}>
            <Loader loading={loading} />
            {pendingPrintDataState.length === 0 && loading === false &&
                <EmptyList />
            }
            <ScrollView contentContainerStyle={{ paddingVertical: 5 }}>
                <View style={styles.CardContainer}>
                    {pendingPrintDataState.length > 0 &&
                        pendingPrintDataState.map((item, index) => {
                            return (
                                <Animated.View
                                    style={styles.CardHolder}
                                    key={item.id}
                                    entering={initialMode.current ? BounceInDown.duration(1000) : BounceInDown.duration(1000)}
                                    //entering={BounceInDown.duration(1000)}
                                    exiting={ZoomOutUp.duration(500)}
                                    layout={Layout.springify()}
                                // onTouchEnd={() => deleteItem(item.id)}
                                >
                                    <PrintJobCard
                                        jobId={item.jobId}
                                        printerSn={item.printerSn}
                                        printJobRecordThumbnail={item.recordThumbnail}
                                        recordId={item.recordId}
                                        recordState={item.recordState}
                                        recordStateInfo={item.recordStateInfo}
                                    ></PrintJobCard>
                                </Animated.View>
                            );
                        })
                    }
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        paddingBottom: 15,
    },
    bottomContainer: {
        width: '100%',
        backgroundColor: '#05AC72',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        zIndex: 2
    },
    CardContainer: {
        ...Platform.select({
            ios: {
                alignItems: 'center',
                justifyContent: "center",
                marginTop: 10,
            },
            android: {
                alignItems: 'center',
                justifyContent: "center",
                marginTop: 10,
            },
            default: {
                flex: 1,
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: 'wrap',
                marginTop: 20,
            }
        })
    },
    CardHolder: {
        ...Platform.select({
            ios: {
                alignItems: "center",
                justifyContent: "center",
            },
            android: {
                alignItems: "center",
                justifyContent: "center",
            },
            default: {
                // width: 250,
                // marginHorizontal: 10
                marginHorizontal: 20
            }
        })
    },
});

const mapStateToProps = (
    state: {
    }
) => {
    return {
    }
}
export default connect(mapStateToProps, {})(PrintJobsPending);