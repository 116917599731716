import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet, View, ScrollView, Text, Alert, Dimensions, Image, Animated as RAnimated } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import { getPrinter, getPrinterHistory } from '../../Rest'
import { connect } from 'react-redux';
import { store, setTemplateRenderInfo, setTemplateRenderRecords } from '../../../utils/Reducer';
import Loader from '../../components/Loader';
import { FAB, IconButton, Button, useTheme } from 'react-native-paper';
import globalVars from '../../../helpers/store';
import ExpandCard from '../../components/ExpandableCard';
import { getPrinterImageUrl } from '../../../helpers/utils';
import RibbonQuantity from '../../components/design/RibbonQuantity';
import { useTranslation } from 'react-i18next';
import Animated, { BounceInDown } from 'react-native-reanimated';
import printerDB from '../../../helpers/db/models/printer'
import * as cpPrint from '@cardpressodevelopment/expo-cp-print';
import { VictoryPie } from "victory-native";


import * as FileSystem from 'expo-file-system';
import { Asset } from 'expo-asset';
import { unzipTemplateBytes, ptToPx } from '../../../helpers/cardRender/utils';
import HeadlessRender from '../global/HeadlessRender';

import { Buffer } from "buffer";
import * as png from '@vivaxy/png';
import { bytesToBmp, getImagePixelsData } from '../../../helpers/images/bmp';
import renderDB from '../../../helpers/db/models/render'

function PrintersDetailScreen({ route, navigation }) {

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const getData = (percent) => {
        return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
    }

    const { printerSN, localPrinter } = route.params;
    const [loading, setLoading] = useState(false);
    const isFocused = useIsFocused();
    const [printerItem, setPrinterItem] = useState(undefined);
    const [printerAgentActive, setPrinterAgentActive] = useState(false);
    const [printJobsNewSate, setPrintJobsNewSate] = useState("----");
    const [printJobsHistory, setPrintJobsHistory] = useState([]);
    const [localPrinterIpAddress, setLocalPrinterIpAddress] = useState(null);

    const [ribbonPercentage, setRibbonPercentage] = useState(0);
    const [printerImage, setPrinterImage] = useState(undefined);
    const [percentageValue, setPercentageValue] = useState(getData(100));
    const [reloading, setReloading] = useState(false);
    const [avaliableOperations, setAvaliableOperations] = useState([]);

    const alertBox = (title, body) => {
        if (Platform.OS === 'web') {
            alert(title + '\n' + body);
        } else {
            Alert.alert(
                title,
                body
            );
        }
        return;
    }

    useEffect(() => {
        (async () => {
            if (printJobsNewSate !== '----') {
                const printerJobsHistory = await getPrinterHistory(printerSN, 20);
                setPrintJobsHistory(printerJobsHistory);
            }
        })();
    }, [printJobsNewSate]);

    const getLocalPrinter = async function (printerSN) {
        let localPrinterArray = await printerDB.query({
            where: {
                printerSn_eq: printerSN
            }
        });

        const localPrinter = localPrinterArray[0];
        await cpPrint.setManufacturer(localPrinter.manufacturer);

        // Load avaliable operations 
        let avaliableOperationsRet = await cpPrint.getAvaliableOperations();        
        if (avaliableOperationsRet !== false) {
            setAvaliableOperations(avaliableOperationsRet)
        }

        const printerInfo = await cpPrint.getPrinterInfo(localPrinter.ipAddress);
        setLocalPrinterIpAddress(localPrinter.ipAddress);
        const retData = {
            printer_name: t('STR_PRINTER_LOCAL'),
            serial_number: localPrinter.printerSn,
            last_card_count: "----",
            ipAddress: localPrinter.ipAddress,
            printerAgentOnline: printerInfo !== false,
            info: {
                driverVersion: '----',
                firmwareVersion: (printerInfo === false) ? '----' : printerInfo.printerFirmwareVersion
            },
            manufacturer: localPrinter.manufacturer,
            model: localPrinter.modelName,
            last_status: {
                ribbonQty: (printerInfo === false) ? 0 : printerInfo.ribbonInfo.Capacity,
                ribbonRemain: (printerInfo === false) ? 0 : printerInfo.ribbonInfo.Remaining,
                ribbonType: (printerInfo === false) ? '----' : printerInfo.ribbonInfo.Type
            },
            printerState: (printerInfo === false) ? "Printer Offline" : printerInfo.printerState
        }
        return retData;
    }

    const loadPrinter = async function (printerSN) {
        const printerData = (localPrinter) ? await getLocalPrinter(printerSN) : await getPrinter(printerSN);

        if (!localPrinter) {
            const printerJobsHistory = await getPrinterHistory(printerSN, 20);
            setPrintJobsHistory(printerJobsHistory);
        }

        if (printerData === false) {
            alertBox(
                t('STR_ERROR'),
                t('STR_ERROR_PRINTER_NOT_FOUND')
            );
        } else {
            printerData.info = (localPrinter) ? printerData.info : JSON.parse(printerData.info);
            printerData.last_status = (localPrinter) ? printerData.last_status : JSON.parse(printerData.last_status);
            setPrinterItem(printerData);
            setPrinterAgentActive(printerData.printerAgentOnline);

            const ribbonQty = (printerData?.last_status?.ribbonQty !== undefined) ? Number(printerData.last_status.ribbonQty) : 0;
            const ribbonRemain = (printerData?.last_status?.ribbonRemain !== undefined) ? Number(printerData.last_status.ribbonRemain) : 0;
            const currentPercentage = (printerData?.last_status?.ribbonQty !== undefined && printerData?.last_status?.ribbonRemain !== undefined) ? Math.round((ribbonRemain * 100) / ribbonQty) : 0;
            setRibbonPercentage(currentPercentage);
            setTimeout(function () {
                setPercentageValue(getData(currentPercentage));
            }, 1000);

            const printerImageURL = await getPrinterImageUrl(printerData.manufacturer, printerData.model);
            setPrinterImage(printerImageURL);
        }
    }

    useEffect(() => {
        (async () => {

            setLoading(true);
            await loadPrinter(printerSN);
            setLoading(false);

            globalVars.socket.on('printerConnected', function (data) {
                setPrinterAgentActive(true);
            });
            globalVars.socket.on('printerDisconnected', function (data) {
                setPrinterAgentActive(false);
            });
            globalVars.socket.on('printJobCanceled', async function (jobID) {
                setPrintJobsNewSate(t('STR_SOCKET_MESSAGE_JOB_CANCELED', { jobID: jobID }));
            });
            // // SENDED TO PRINT JOB ID
            // globalVars.socket.on('printJobStateChanged', async function (printerIDPar, jobID, state) {
            //     setPrintJobsNewSate('Print Job "' + jobID + '" state: ' + state);
            // });
            // SENDED TO PRINT JOB SN 
            globalVars.socket.on('printerJobChanged', async function (printerIDPar, jobID, state) {
                setPrintJobsNewSate(t('STR_SOCKET_MESSAGE_JOB_CHANGED', { jobID: jobID, state: state }));
            });
            globalVars.socket.on('newPrinterJob', async function (jobInfo) {
                setPrintJobsNewSate(t('STR_SOCKET_MESSAGE_NEW_JOB', { jobInfo: jobInfo }));
            });


        })();

        // Run on Unmount
        return () => {
            setPrinterItem(undefined);
        }
    }, []);

    function sleep(ms) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    }

    const reloadPrinter = async function () {
        setReloading(true)
        setLoading(true);
        await sleep(1);
        await loadPrinter(printerSN);
        setReloading(false)
        setLoading(false);
    }

    const confirmRemoveLocalPrint = (templateID) => {
        Alert.alert(
            t('STR_ALERT_ATTENTION'),
            t('STR_ALERT_REMOVE_LOCAL_PRINT_CONFIRM'),
            [
                {
                    text: t('STR_CANCEL'),
                    style: "cancel"
                },
                {
                    text: t('STR_YES'), onPress: () => removeLocalPrint()
                }
            ]
        );
    }

    const removeLocalPrint = async function () {
        let localPrinterArray = await printerDB.query({
            where: {
                printerSn_eq: printerSN
            }
        });
        const delResult = await printerDB.destroy(localPrinterArray[0].id);
        if (delResult) {
            navigation.navigate('PrintersList');
        } else {
            Alert.alert(t('STR_ALERT_LOCAL_PRINTER_REMOVE_ERROR'));
        }
    }

    const insertCard = () => {
        (async () => {
            setLoading(true);
            await sleep(1);
            await cpPrint.insertCard().then(function (result) {
                if (result) {
                    Alert.alert(t('STR_ALERT_PRINTER_INSERT_CARD_SUCCESS'));
                } else {
                    Alert.alert(t('STR_ALERT_PRINTER_INSERT_CARD_ERROR'));
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                Alert.alert(t('STR_ALERT_PRINTER_INSERT_CARD_ERROR'));
                console.log(error);
            });
        })();
    }
    const insertEjectCard = () => {
        (async () => {
            setLoading(true);
            await sleep(1);
            await cpPrint.insertEjectCard().then(function (result) {
                if (result) {
                    Alert.alert(t('STR_ALERT_PRINTER_INSERT_CARD_SUCCESS'));
                } else {
                    Alert.alert(t('STR_ALERT_PRINTER_INSERT_CARD_ERROR'));
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                Alert.alert(t('STR_ALERT_PRINTER_INSERT_CARD_ERROR'));
                console.log(error);
            });
        })();
    }
    const ejectCard = () => {
        (async () => {
            setLoading(true);
            await sleep(1);
            await cpPrint.ejectCard().then(function (result) {
                if (result) {
                    Alert.alert(t('STR_ALERT_PRINTER_EJECT_CARD_SUCCESS'));
                } else {
                    Alert.alert(t('STR_ALERT_PRINTER_EJECT_CARD_ERROR'));
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                Alert.alert(t('STR_ALERT_PRINTER_EJECT_CARD_ERROR'));
                console.log(error);
            });
        })();
    }

    const rejectCard = () => {
        (async () => {
            setLoading(true);
            await sleep(1);
            await cpPrint.rejectCard().then(function (result) {
                if (result) {
                    Alert.alert(t('STR_ALERT_PRINTER_REJECT_CARD_SUCCESS'));
                } else {
                    Alert.alert(t('STR_ALERT_PRINTER_REJECT_CARD_ERROR'));
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                Alert.alert(t('STR_ALERT_PRINTER_REJECT_CARD_ERROR'));
                console.log(error);
            });
        })();
    }
    const restartPrinter = () => {
        (async () => {
            setLoading(true);
            await sleep(1);
            await cpPrint.restartPrinter().then(function (restartResult) {
                if (restartResult) {
                    Alert.alert(t('STR_ALERT_PRINTER_RESTARTED_SUCCESS'));
                } else {
                    Alert.alert(t('STR_ALERT_PRINTER_RESTARTED_ERROR'));
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                Alert.alert(t('STR_ALERT_PRINTER_RESTARTED_ERROR'));
                console.log(error);
            });
        })();
    }
    const startNewPrintJob = async (cardImageBase64Data, ribbonType) => {
        return new Promise((resolve) => {
            (async () => {
                await cpPrint.newPrintJob(cardImageBase64Data, ribbonType).then(function (result) {
                    if (result) {
                        resolve(true);
                    } else {
                        Alert.alert(result.toString());
                        resolve(false);
                    }
                }).catch(function (error) {
                    Alert.alert(error.toString());
                    console.log(error);
                    resolve(false);
                });
            })();
        });
    }

    const getBMPImage = async (base64PngImage, currentRecord) => {

        return new Promise((resolve) => {
            (async () => {
                let base64ImageBuffer = Buffer.from(base64PngImage, "base64")
                const metadata = png.decode(base64ImageBuffer);
                const imageArrayData = metadata.data;
                const imagePixelsData = getImagePixelsData(imageArrayData, currentRecord.cardWidth, currentRecord.cardHeight);
                const imageBmpResult = bytesToBmp(imagePixelsData, currentRecord.cardWidth, currentRecord.cardHeight);
                resolve(imageBmpResult);
            })();
        });
    }
    const printTest = () => {
        (async () => {

            setReloading(true)
            setLoading(true);
            await sleep(1);

            const [{ localUri }] = await Asset.loadAsync(require("../../../assets/files/printTest.zip"));
            const fileBase64Data = await FileSystem.readAsStringAsync(localUri, { encoding: 'base64' });
            const extractedZipData = await unzipTemplateBytes(fileBase64Data);
            store.dispatch(setTemplateRenderInfo(extractedZipData));
            store.dispatch(setTemplateRenderRecords([
                {
                    recordId: 1
                },
            ]));

            await sleep(1000);
            const recordsToRender = 1;
            let storeData = store.getState();

            if (storeData.renderedCardsData === undefined) {
                await sleep(5000);
                storeData = store.getState();
                await sleep(100);
            }

            if (storeData.renderedCardsData !== undefined && storeData.renderedCardsData.length >= recordsToRender) {
                for (let index = 0; index < storeData.renderedCardsData.length; index++) {
                    const currentRecord = storeData.renderedCardsData[index];
                    const renderDataArray = await renderDB.query({
                        where: {
                            recordId_eq: currentRecord.recordId
                        }
                    });
                    const renderData = renderDataArray[0];
                    const imageBmpResult = await getBMPImage(renderData.base64Data.toString(), currentRecord);
                    await cpPrint.getPrinterInfo(localPrinterIpAddress).then(async function (printerInfo) {
                        if (printerInfo !== false) {
                            // Load ribbonType 
                            if (printerInfo.ribbonInfo.Type.toUpperCase().includes("YMC")) {
                                ribbonType = "RC_" + printerInfo.ribbonInfo.Type;
                            } else {
                                ribbonType = "RM_" + printerInfo.ribbonInfo.Type;
                            }
                            const printResult = await startNewPrintJob(imageBmpResult, ribbonType);
                            if (printResult) {
                                Alert.alert('Printed');
                            }
                        } else {
                            Alert.alert('Printer connection error');
                            return;
                        }
                    }).catch(function (error) {
                        setReloading(false)
                        setLoading(false);
                        Alert.alert('Printer connection error');
                        console.log(error);
                        return;
                    });
                }
            }
            setReloading(false)
            setLoading(false);
        })();
    }

    return (
        <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
        >

            <View style={styles.container}>

                {printerItem !== undefined &&
                    <Animated.View entering={BounceInDown.duration(800)}  >
                        <View style={styles.infoHolder}>

                            <View style={styles.titleHolder}>
                                <Text style={styles.manufacturerModel}>{printerItem.manufacturer} {printerItem.model}</Text>
                                <Text style={styles.printerName}>{printerItem.printer_name}</Text>
                            </View>

                            <View style={styles.containerTopRow}>

                                <View style={styles.itemCol3}>
                                    <FAB
                                        theme={{ roundness: 10 }}
                                        style={[styles.fab, ((printerAgentActive) ? styles.fabOnline : styles.fabOffline)]}
                                    />
                                    <View>
                                        {printerImage === undefined && <Image style={styles.imageStyle} source={require('../../../assets/loading.gif')} resizeMode='cover' />}
                                        {printerImage !== undefined && <Image style={styles.imageStyle} source={{ uri: printerImage }} resizeMode='cover' />}
                                    </View>
                                </View>

                                <View style={styles.itemCol3}>
                                    <RibbonQuantity color="#05AC72" backgroundColor="#fff" quantity={(printerItem?.last_status?.ribbonQty !== undefined) ? printerItem.last_status.ribbonQty : 0} size="70" fontSize="18"></RibbonQuantity>
                                </View>

                                <View style={styles.itemCol3}>

                                    <View style={{ alignItems: 'center' }}>
                                        <View style={styles.graphHolder}>
                                            <View style={{ height: 100, width: 100 }}>
                                                <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
                                                    <VictoryPie
                                                        data={percentageValue}
                                                        animate={{
                                                            duration: 500,
                                                            easing: 'linear',
                                                        }}
                                                        width={160}
                                                        height={160}
                                                        innerRadius={40}
                                                        cornerRadius={25}
                                                        labels={() => null}
                                                        style={{
                                                            data: {
                                                                fill: ({ datum }) => {
                                                                    const color = datum.y < 15 ? '#E35B5B' : (datum.y >= 15 && datum.y < 25 ? '#FFC300' : '#05AC72');
                                                                    return datum.x === 1 ? color : "transparent";
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                            <View style={styles.graphPercentageHolder}>
                                                <Text style={styles.graphPercentageText}>{(isNaN(ribbonPercentage)) ? 0 : ribbonPercentage}%</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.descriptionText}>{t('STR_RIBBON_REMAIN')}</Text>
                                    </View>
                                </View>
                            </View>

                            {localPrinter &&
                                <View style={{ paddingTop: 15, display: 'flex', alignItems: 'center' }}>
                                    <Text style={styles.printerStateTitle}>{t('STR_PRINTER_STATE')}</Text>

                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                                        {!printerItem.printerState.toUpperCase().includes("WARNING")
                                            && !printerItem.printerState.toUpperCase().includes("ERROR")
                                            && !printerItem.printerState.toUpperCase().includes("OFFLINE") &&
                                            <IconButton icon="check-circle" iconColor={theme.colors.primary} size={20} style={{ height: 20, width: 20 }} />
                                        }
                                        {printerItem.printerState.toUpperCase().includes("WARNING") &&
                                            <IconButton icon="alert-circle" iconColor={theme.colors.warning} size={20} style={{ height: 20, width: 20 }} />
                                        }
                                        {printerItem.printerState.toUpperCase().includes("ERROR") &&
                                            <IconButton icon="check-circle" iconColor={theme.colors.error} size={20} style={{ height: 20, width: 20 }} />
                                        }
                                        {printerItem.printerState.toUpperCase().includes("OFFLINE") &&
                                            <IconButton icon="printer-off" iconColor={theme.colors.error} size={20} style={{ height: 20, width: 20 }} />
                                        }
                                        <Text>{printerItem.printerState}</Text>
                                    </View>

                                </View>
                            }

                            <View style={[styles.containerRow, { paddingTop: 15 }]}>

                                <View style={styles.itemCol}>

                                    <View style={styles.infoTextHolder}>
                                        <Text style={styles.label} >{t('STR_PRINTER_SERIAL_NUMBER')}:</Text>
                                        <Text >{printerItem.serial_number}</Text>
                                    </View>

                                    <View style={styles.infoTextHolder}>
                                        <Text style={styles.label} >{t('STR_PRINTER_MANUFACTURER')}:</Text>
                                        <Text >{printerItem.manufacturer}</Text>
                                    </View>

                                    <View style={styles.infoTextHolder}>
                                        <Text style={styles.label} >{t('STR_PRINTER_MODEL')}:</Text>
                                        <Text >{printerItem.model}</Text>
                                    </View>

                                    <View style={styles.infoTextHolder}>
                                        <Text style={styles.label} >{t('STR_PRINTER_CARD_COUNT')}:</Text>
                                        <Text >{printerItem.last_card_count}</Text>
                                    </View>
                                </View>

                                <View style={styles.itemCol}>

                                    <View style={styles.infoTextHolder}>
                                        <Text style={styles.label} >{t('STR_PRINTER_RIBBON_TYPE')}:</Text>
                                        <Text >{(printerItem?.last_status?.ribbonType !== undefined) ? printerItem.last_status.ribbonType : 0}</Text>
                                    </View>

                                    <View style={styles.infoTextHolder}>
                                        <Text style={styles.label} >{t('STR_PRINTER_DRIVER_VERSION')}:</Text>
                                        <Text >{printerItem.info.driverVersion}</Text>
                                    </View>

                                    <View style={styles.infoTextHolder}>
                                        <Text style={styles.label} >{t('STR_PRINTER_FIRMWARE_VERSION')}:</Text>
                                        <Text >{printerItem.info.firmwareVersion}</Text>
                                    </View>

                                    <View style={styles.infoTextHolder}>
                                        <Text style={styles.label} >{t('STR_PRINTER_LAST_JOB_MESSAGE')}:</Text>
                                        <Text >{printJobsNewSate}</Text>
                                    </View>

                                </View>
                            </View>
                            {!localPrinter && typeof printJobsHistory === 'object' &&
                                <View style={styles.infoTextHolder}>
                                    <Text style={[styles.label, { paddingTop: 20, paddingBottom: 15, fontSize: 20 }]} >{t('STR_PRINTER_PRINT_JOB_HISTORY')}:</Text>
                                    {printJobsHistory.map((job, index) => (

                                        <ExpandCard key={index} data={job} title={
                                            <View style={{ paddingLeft: -20 }}>
                                                <Text style={styles.label} >{t('STR_PRINTER_PRINT_JOB_ID')}:</Text>
                                                <Text>{job.jobID}</Text>
                                                <Text style={styles.label} >{t('STR_PRINTER_PRINT_DATE')}:</Text>
                                                <Text>{job.jobDate.replace('T', ' ').replace('Z', ' ')}</Text>
                                            </View>
                                        }>
                                            <View>
                                                <Text style={styles.label} >{t('STR_PRINTER_PRINT_STATUS')}:</Text>
                                                <Text>{job.jobStatus}</Text>

                                                <Text style={styles.label} >{t('STR_PRINTER_PRINT_INFO')}:</Text>
                                                <Text>{job.jobInfo}</Text>
                                            </View>

                                        </ExpandCard>

                                    ))}
                                </View>
                            }

                            {localPrinter && printerAgentActive &&
                                <View style={styles.buttonHolder} >
                                    {/* styles.refreshHolder */}
                                    {/* <IconButton style={{ position: 'absolute', right: 0, top: 0, zIndex: 999 }} icon="refresh" ></IconButton> */}
                                    <Button
                                        mode='contained'
                                        icon='refresh'
                                        style={styles.buttonHolderChild}
                                        onPress={() => reloadPrinter()} >
                                        {t('STR_LABEL_PRINTER_RELOAD')}
                                    </Button>
                                    {avaliableOperations.includes("restartPrinter") &&
                                        <Button
                                            mode='contained'
                                            icon='power'
                                            style={styles.buttonHolderChild}
                                            onPress={() => restartPrinter()} >
                                            {t('STR_LABEL_PRINTER_RESTART')}
                                        </Button>
                                    }
                                    {avaliableOperations.includes("rejectCard") &&
                                        <Button
                                            mode='contained'
                                            icon='cancel'
                                            style={styles.buttonHolderChild}
                                            onPress={() => rejectCard()} >
                                            {t('STR_LABEL_PRINTER_REJECT_CARD')}
                                        </Button>
                                    }
                                    {avaliableOperations.includes("ejectCard") &&
                                        <Button
                                            mode='contained'
                                            icon='eject'
                                            style={styles.buttonHolderChild}
                                            onPress={() => ejectCard()} >
                                            {t('STR_LABEL_PRINTER_EJECT_CARD')}
                                        </Button>
                                    }
                                    {avaliableOperations.includes("insertCard") &&
                                        <Button
                                            mode='contained'
                                            icon='arrow-up-bold-outline'
                                            style={styles.buttonHolderChild}
                                            onPress={() => insertCard()} >
                                            {t('STR_LABEL_PRINTER_INSERT_CARD')}
                                        </Button>
                                    }
                                    {avaliableOperations.includes("insertEjectCard") &&
                                        <Button
                                            mode='contained'
                                            icon='arrow-up-bold-outline'
                                            style={styles.buttonHolderChild}
                                            onPress={() => insertEjectCard()} >
                                            {t('STR_LABEL_PRINTER_INSERT_EJECT_CARD')}
                                        </Button>
                                    }
                                    <Button
                                        mode='contained'
                                        icon='test-tube'
                                        style={styles.buttonHolderChild}
                                        onPress={() => printTest()} >
                                        {t('STR_LABEL_PRINTER_PRINT_TEST_CARD')}
                                    </Button>
                                </View>
                            }
                            {localPrinter &&
                                <View style={styles.buttonHolder} >
                                    <Button
                                        mode='contained'
                                        icon='delete'
                                        style={[styles.buttonHolderChildRemove, { backgroundColor: theme.colors.error }]}
                                        onPress={() => confirmRemoveLocalPrint()} >
                                        {t('STR_LABEL_PRINTER_REMOVE')}
                                    </Button>
                                </View>
                            }
                        </View>
                        <HeadlessRender />
                    </Animated.View>
                }

                {printerItem === undefined && isFocused &&
                    <Loader loading={loading} />
                }
                {reloading &&
                    <Loader loading={loading} />
                }
            </View>
        </ScrollView>
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const radius = 15;
const styles = StyleSheet.create({
    containerTopRow: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    containerRow: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start'
    },
    itemCol: {
        width: '50%'
    },
    itemCol3: {
        width: '33%',
        alignItems: "center",
        justifyContent: 'center'
    },
    imageStyle: {
        width: 100,
        height: 100,
        maxWidth: '100%',
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
    },
    rowHolder: {
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    infoHolder: {
        ...Platform.select({
            ios: {
                width: deviceWidth - 30,
            },
            android: {
                width: deviceWidth - 30,
            },
            default: {
                width: 500,
            }
        }),
        marginVertical: 20,
    },
    printerName: {
        fontSize: 20,
        color: '#05AC72',
        alignSelf: 'center',
        fontWeight: 'bold',
        marginBottom: 20
    },
    manufacturerModel: {
        fontWeight: 'bold',
        alignSelf: 'center',
        fontSize: 18,
    },
    fab: {
        position: 'absolute',
        marginTop: 5,
        left: 5,
        top: 5,
        width: 20,
        height: 20,
        zIndex: 2
    },
    fabOnline: {
        backgroundColor: "#05AC72"
    },
    fabOffline: {
        backgroundColor: "#FF5858"
    },
    infoTextHolder: {
        marginTop: 5,
        alignItems: 'center'
    },
    label: {
        fontWeight: 'bold'
    },
    messageHolder: {
        width: deviceWidth - 25,
        backgroundColor: '#e1e2e3',
        height: 'auto',
        borderRadius: radius,
        borderWidth: 0,
        marginBottom: 5,
        padding: 10,
        paddingBottom: 15
    },
    graphHolder: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    graphPercentageHolder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    graphPercentageText: {
        fontWeight: 'bold',
        color: "#05AC72",
        fontSize: 18
    },
    descriptionText: {
        fontWeight: 'bold'
    },
    printerStateTitle: {
        fontWeight: 'bold',
        fontSize: 22
    },
    buttonHolder: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    buttonHolderChild: {
        marginTop: 15,
        paddingHorizontal: 5,
        flexBasis: '48%'
    },
    buttonHolderChildRemove: {
        marginTop: 15,
        paddingHorizontal: 5,
        flexBasis: '98%',
    }
});

export default connect(null, {})(PrintersDetailScreen);
// export default connect(mapStateToProps, {})(PrintersDetailScreen);