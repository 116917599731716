import React, { } from 'react';
import { Platform, StyleSheet, Text, View, ScrollView, SafeAreaView } from 'react-native';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WebView } from 'react-native-webview';
import { useNavigation } from '@react-navigation/native';
import configs from '../../../helpers/config';
import { Title } from 'react-native-paper';

export type props = {
};

const AboutApp: React.FC<props> = ({
}) => {
    const { t, i18n } = useTranslation();
    const navigation = useNavigation();

    const aboutAppUrl = configs.aboutAppUrl;
    const jsonConfig = require('../../../app.json');
    const appVersion = jsonConfig.expo.version;

    const jsCode = `
        document.querySelectorAll('style').forEach(el => el.removeAttribute('style'));
        document.querySelectorAll('link[rel="stylesheet"]').forEach(el => el.parentNode.removeChild(el));
        document.querySelectorAll('style').forEach(el => el.parentNode.removeChild(el));        
    `;

    return (
        <View style={styles.container}>
            <View style={styles.vHolderView} >
                <Title style={styles.vHolder}>V{appVersion}</Title>
            </View>
            <WebView
                source={{ uri: aboutAppUrl }}
                // style={{ height: 400, width: 400, resizeMode: 'stretch', flex: 1 }}
                //  injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=width, initial-scale=0.5, maximum-scale=0.5, user-scalable=2.0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
                scalesPageToFit={true}
                javaScriptEnabled={true}
                injectedJavaScript={jsCode}
            // style={{ display: 'none' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    vHolderView: {
        textAlign: 'center',
        backgroundColor: '#FFF'
    },
    vHolder: {
        color: '#646464',
        textAlign: 'center'
    }
});

const mapStateToProps = (
    state: {
    }
) => {
    return {
    }
}

export default connect(mapStateToProps, {})(AboutApp);