import React from "react";
import { View, StyleSheet,  } from "react-native";
import { Text, IconButton } from 'react-native-paper';

const MenuItem = (props) => {
    return (
        <View style={styles.contentHolder}>
            <IconButton
              icon={props.icon}
              color={props.color}
              size={22}
              style={{
                alignSelf: 'flex-start',
                width: 25,
              }}
            />
            <Text
              style={{
                color: props.color,
                fontWeight: 'bold',
                marginRight: 15
              }}
            >{props.text}</Text>
          </View>
    );
};
 
const styles = StyleSheet.create({
    contentHolder: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'space-around',
        alignItems: 'center',
        minWidth: 100
    }
});

export default MenuItem; 