import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Text, View, ScrollView, SafeAreaView, Image, Alert, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WebView } from 'react-native-webview';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import configs from '../../../helpers/config';
import { Title, useTheme, Button } from 'react-native-paper';
import Loader from '../../components/Loader';

import HeadlessRender from '../global/HeadlessRender';
import * as FileSystem from 'expo-file-system';
import { Asset } from 'expo-asset';
import { getTemplateBytes, unzipTemplateBytes, ptToPx, calculateImageSize } from '../../../helpers/cardRender/utils';
import { store, setForceImageBase64StateFillRefresh, setTemplateRenderInfo, 
    setTemplateRenderRecords, setRenderedCardsData, addRecordToPrintList, 
    addRecordToPrintListData, setRecordList } from '../../../utils/Reducer';
import { Buffer } from "buffer";
import * as png from '@vivaxy/png';
import * as cpPrint from '@cardpressodevelopment/expo-cp-print';
import { bytesToBmp, getImagePixelsData, imagePixel } from '../../../helpers/images/bmp';
// import * as MediaLibrary from "expo-media-library";
import { getRecords, getTemplateById, getGoogleCredentials } from '../../Rest';

export type props = {
    renderedCardsData: [],
    recordsToPrintListData: {
        recordId: number;
        info: string;
    }[];
};

const CardRenderDebug: React.FC<props> = ({
    renderedCardsData,
    recordsToPrintListData
}) => {

    const templateID = 67;

    const { t, i18n } = useTranslation();
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const theme = useTheme();

    const [renderedCardHeightState, setRenderedCardHeightState] = useState<number>(250);
    const [itemsStateLoaded, setItemsStateLoaded] = useState<boolean>(false);
    const [recordsLoaded, setRecordsLoaded] = useState<boolean>(false);
    const [renderedImages, setRenderedImages] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState<string>('');

    const cardImageWidth = Math.round(Dimensions.get('window').width) - 20;
    const [renderedImagesHeight, setRenderedImagesHeight] = useState<number>(0);
    var stateSubscribeCtr: boolean = false;

    function sleep(ms: number) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    }

    useEffect(
        () => {
            (async () => {
                getTemplateById(templateID);
                const recordsData = await getRecords(templateID, 0);                
                store.dispatch(setRecordList(recordsData.recordsList));                
                setRecordsLoaded(true); 
            })();
            return () => { // On unmount
            }
        }, []
    );

    const reRenderCard = () => {
        (async () => {

            store.dispatch(setTemplateRenderInfo(undefined));
            store.dispatch(setTemplateRenderRecords(undefined));
            store.dispatch(setForceImageBase64StateFillRefresh(true));
            
            stateSubscribeCtr = true;
            setLoading(true);
            setRenderedImages([]);
            store.dispatch(setRenderedCardsData([]));
            setLoadingText("Reading card information");

            // getTemplateById(templateID);
            // await getRecords(templateID, 0);

            await sleep(500);

            const storeRecordData = store.getState();
            const firstTemplateRecord = storeRecordData.recordList.items[0];
            store.dispatch(addRecordToPrintList(firstTemplateRecord.recordId));
            store.dispatch(addRecordToPrintListData(firstTemplateRecord));

            const templateBytes: any = await getTemplateBytes(templateID);
            const extractedZipData: any = await unzipTemplateBytes(templateBytes);

            store.dispatch(setTemplateRenderInfo(extractedZipData));
            store.dispatch(setTemplateRenderRecords([firstTemplateRecord]));
            await sleep(500);

            const sizeData = extractedZipData.printData.print.model.size;
            const sizeArray = sizeData.split(",");
            let cardWidthSizeInPx = ptToPx(sizeArray[0]);
            let cardheightSizeInPx = ptToPx(sizeArray[1]);
            setLoadingText("Starting card render");
            await sleep(1);

            const newImageSize = calculateImageSize(cardWidthSizeInPx, cardheightSizeInPx, cardImageWidth);
            setRenderedImagesHeight(newImageSize.height);


        })();
    }

    store.subscribe(() => {
        (async () => {
            
            const storeData = store.getState();
            await sleep(1); 
                        
            // if (stateSubscribeCtr === true && storeData.renderedCardsData !== undefined && storeData.renderedCardsData.length >= 1 && renderedImages.length === 0 ) {
            if (storeData.renderedCardsData !== undefined && storeData.renderedCardsData.length >= 1 && renderedImages.length === 0 ) {
                stateSubscribeCtr = false; 

                for (let index = 0; index < storeData.renderedCardsData.length; index++) {
                    const currentRecord: any = storeData.renderedCardsData[index];
                    setRenderedImages(renderedImages => {
                        const nextItemId = currentRecord.recordId;
                        return [
                        {
                            id: nextItemId, 
                            renderedData: currentRecord.base64Image.toString()
                        }];
                    });
                }
                setLoadingText("Rendering finish");
                await sleep(1);
                setItemsStateLoaded(true);
                setLoading(false);

            }
        })();
    });


    const startNewPrintJob = async (cardImageBase64Data: string, ribbonType: string) => {
        return new Promise((resolve) => {
            (async () => {
                await cpPrint.newPrintJob(cardImageBase64Data, ribbonType).then(function (result) {
                    if (result) {
                        Alert.alert(result.toString());
                        resolve(true);
                    } else {
                        Alert.alert(result.toString());
                        resolve(false);
                    }
                }).catch(function (error: any) {
                    Alert.alert(error.toString());
                    (error);
                    resolve(false);
                });
            })();
        });
    }

    return (
        <View style={styles.container}>
            <Loader loading={loading} text={loadingText} />
            <ScrollView style={{ width: '100%' }}>

                <View style={styles.CardContainer}>
                    {renderedImages.map((record: renderedRecordItem, index: number) => {
                        return (
                            <View key={index}
                                style={{
                                }}>
                                <View key={index} style={{
                                    zIndex: -1,
                                    elevation: 0,
                                    position: 'relative'
                                }}>
                                    <View key={index} style={{
                                        ...styles.CardHolder,
                                        height: renderedCardHeightState,
                                    }} >
                                        <Image
                                            style={{
                                                width: cardImageWidth,
                                                height: renderedImagesHeight,
                                                borderColor: theme.colors.lightGrey,
                                                borderWidth: 1,
                                                borderRadius: 12
                                            }}
                                            source={{
                                                uri: "data:image/jpg;base64," + record.renderedData,
                                            }}
                                            resizeMode='contain'
                                        />
                                    </View>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </ScrollView >
            <View style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} >
                <View style={styles.bottomContainer} >
                    <Button disabled={!recordsLoaded} textColor={theme.colors.white} onPress={() => reRenderCard()}  >{"Re-render"}</Button>
                </View>
            </View>
            <HeadlessRender />
        </View >

    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        paddingBottom: 70,
    },
    bottomContainer: {
        width: '100%',
        backgroundColor: '#05AC72',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        zIndex: 2
    },
    CardContainer: {
        // ...Platform.select({
        //     ios: {
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     android: {
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     default: {
        //         flex: 1,
        //         alignItems: "center",
        //         justifyContent: "center",
        //         flexDirection: "row",
        //         flexWrap: 'wrap',
        //     }
        // })        
        flex: 1,
        justifyContent: "space-around",
        flexDirection: "column",
    },
    CardHolder: {
        // ...Platform.select({
        //     ios: {
        //         width: '100%',
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     android: {
        //         width: '100%',
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     default: {
        //         // width: 250,
        //         // marginHorizontal: 10
        //         maxWidth: '100%'
        //     }
        // }),
        // backgroundColor: 'red',
        width: '100%',
        // height: 250,
        alignItems: "center",
        justifyContent: "center",
        zIndex: -1,
        elevation: -1
    },
    removeIconStyle: {
        position: 'absolute',
        right: 10,
        top: 10,
        zIndex: 99,
        elevation: 99
    }
});

const mapStateToProps = (
    state: {
        renderedCardsData: [],
        recordsToPrintListData: {
            recordId: number;
            info: string;
        }[];
    }
) => {
    return {
        renderedCardsData: state.renderedCardsData,
        recordsToPrintListData: state.recordsToPrintListData,
    }
}

export default connect(mapStateToProps, {})(CardRenderDebug); 