
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, pt } from "./translations";

//empty for now
const resources = {
    en: {
        translation: en,
    },
    pt: {
        translation: pt, 
    }
};

const addNewKey = (key) => {
    // var keyValueToAdd = ',"' + key + '":"' + key + '"';
}

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    // debug: true,
    //language to use if translations in user language are not available
    fallbackLng: "en",
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    react: {
        useSuspense: false,
    },
    // saveMissing: process.env.NODE_ENV === 'development' ? true : false,
    // saveMissingTo: 'all',
    // missingKeyHandler: (code, ns, key) => {
    //     // Only add if in development Mode
    //     if (process.env.NODE_ENV === 'development') {
    //         addNewKey(key);
    //     }
    // }
});

export default i18n;