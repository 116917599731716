import React, { useState } from 'react';
import { GradientRender, StrokePathRender, degreesToRadians, getVecLinear, mmToPx, ptToPx, radiusToPixels } from '../../../../helpers/cardRender/utils';
import { Skia, Rect, Group, Image, RoundedRect, Oval, Path, DashPathEffect, SkPath, rrect, rect } from "@shopify/react-native-skia";


export type props = {
    id: number,
    xmlObj: any,
    imageBase64Data: string | null
};

const DEFAULT_RESIZE_MODE = "fitWidth"
const DEFAULT_BKG_COLOR = "transparent"
const DEFAULT_CLIPPING_TYPE = "rectangle"
const NO_OPACITY = 100

// radius for any clipping "round rectangle"
const RRECT_RADIUS = 25

// matrices for color transformations
// const IDENTITY_MATRIX = [
//     1.0, 0.0, 0.0, 0.0, 0.0,
//     0.0, 1.0, 0.0, 0.0, 0.0,
//     0.0, 0.0, 1.0, 0.0, 0.0,
//     0.0, 0.0, 0.0, 1.0, 0.0,
// ]; // done

// const NEGATIVE_MATRIX = [
//     -1, 0, 0, 0, 255,
//     0, -1, 0, 0, 255,
//     0, 0, -1, 0, 255,
//     0, 0, 0, 1, 0
// ];

// const GRAYSCALE_MATRIX = [
//     0, 1, 0, 0, 0,
//     0, 1, 0, 0, 0,
//     0, 1, 0, 0, 0,
//     0, 1, 0, 1, 0,
// ]; // done

// const MONOCHROME_MATRIX = [
//     -1, 0, 0, 0, 255,
//     0, -1, 0, 0, 255,
//     0, 0, -1, 0, 255,
//     0, 0, 0, 1, 0
// ];

const ImageItem: React.FC<props> = ({
    id,
    xmlObj,
    imageBase64Data
}) => {
    let iHaveImage: boolean = false;
    let image: any = null;
    let resizeMode: any = DEFAULT_RESIZE_MODE
    const fillData: any = xmlObj.fill; // fill info (background fill and gradient)
    const itemData: any = xmlObj.item; // item info (printable or not)
    const lineData: any = xmlObj.line; // line info (stroke color, style (solid line, dash, dot, dash dot or dash dot dot), and width)
    const clippingData: any = xmlObj.clipping; // clipping info (rectangle, round rectangle or circle; if not defined, is default)
    const transformationsData: any = xmlObj.imageAcquisition?.transformations; // geometric/color transformations

    // clipping type: no clipping, rectangle, round rectangle or circle
    const itemClipping: string = clippingData?.shape ? clippingData?.shape : DEFAULT_CLIPPING_TYPE;

    // stroke or not
    let stroke: boolean = false;
    if (lineData.style != "NoPen") stroke = true

    // fill/gradient
    let bkgColor: string = DEFAULT_BKG_COLOR

    let gradientData: null = null, gradient: boolean = false
    if (Array.isArray(fillData.type)) {
        if (fillData.type[0] == "solid") bkgColor = Array.isArray(fillData.type[1].solid.color) ? fillData.type[1].solid.color[0] : fillData.type[1].solid.color
        else if (fillData.type[0] == "gradient") {
            gradient = true
            gradientData = fillData.type[1].gradient
        }
    }

    if (Array.isArray(fillData.type) && fillData.type[1]?.solid) {
        bkgColor = Array.isArray(fillData.type[1].solid.color) ? fillData.type[1].solid.color[0] : fillData.type[1].solid.color
    }

    // *PRINTABLE* //
    const print: boolean = itemData.print ? (itemData.print == 'no' ? false : true) : true
    if (!print) return <></>


    // *POSITION* //
    let startX: number = ptToPx(xmlObj.position.pos1.x);
    let startY: number = ptToPx(xmlObj.position.pos1.y);
    let endX: number = ptToPx(xmlObj.position.pos2.x);
    let endY: number = ptToPx(xmlObj.position.pos2.y);
    let imageWidth: number = endX - startX;
    let imageHeight: number = endY - startY;

    if (lineData?.style != "NoPen") {
        imageWidth -= mmToPx(lineData.width / 100)
        imageHeight -= mmToPx(lineData.width / 100)
    }

    // *ROTATION* //
    let translateX = 0
    let translateY = 0
    const rotationAngle = xmlObj.rotation?.angle ? degreesToRadians(xmlObj.rotation.angle) : 0
    if (rotationAngle != 0) {
        translateX = imageWidth / 2
        translateY = imageHeight / 2
    }

    // *HORIZONTAL AND VERTICAL FLIP* //
    let scaleX = 1, scaleY = 1
    if (transformationsData?.geometric) {
        scaleX = transformationsData.geometric.flipHorizontal ? -1 : 1
        scaleY = transformationsData.geometric.flipVertical ? -1 : 1
    }

    // *OPACITY* //
    const opacity: number = xmlObj.item.opacity ? xmlObj.item.opacity : NO_OPACITY

    if (imageBase64Data !== null && imageBase64Data !== undefined) {
        iHaveImage = true;
        let dataToDecode = imageBase64Data
        if (dataToDecode.split(",")[0] == "data:image/png;base64") dataToDecode = dataToDecode.split(",")[1]
        const imageBase64 = Skia.Image.MakeImageFromEncoded(Skia.Data.fromBase64(dataToDecode));
        image = imageBase64;
    }else{
        // Exit without render image  
        return;
    }

    // *TRANSFORMATIONS* // 
    // const color_transformations = transformationsData.color
    // let identity_matrix = IDENTITY_MATRIX
    // const grayscale = (color_transformations.grayscale && (color_transformations.grayscale == true)) ? true : false
    // if (grayscale) identity_matrix = GRAYSCALE_MATRIX;

    // else {
    //     const monochrome = (color_transformations.monochrome && (color_transformations.monochrome == true)) ? true : false
    //     if (monochrome) identity_matrix = MONOCHROME_MATRIX;

    // }
    // const negative = (color_transformations.negative && (color_transformations.negative == true)) ? true : false
    // if (negative) identity_matrix = matrixMultiply(MONOCHROME_MATRIX, NEGATIVE_MATRIX);

    // *FIT OPTION*//
    if (xmlObj.image?.fitOption !== undefined) {
        if (xmlObj.image?.fitOption == 1) {
            if (imageWidth > imageHeight) {
                resizeMode = "fitHeight";
            } else {
                resizeMode = "fitWidth";
            }
        } else {
            resizeMode = "cover";
        }
    }


    function ItemRender(): JSX.Element {
        const strokeWidth = stroke ? mmToPx(lineData.width / 100) : 0

        const startPosX = startX - (strokeWidth / 2);
        const startPosY = startY - (strokeWidth / 2);
        const endPosX = endX + (strokeWidth / 2)
        const endPosY = endY + (strokeWidth / 2)

        let resize_width: number = imageWidth, resize_height: number = imageHeight;
        if (iHaveImage) {
            const aspect_ratio = image.width() / image.height()
            if (resizeMode == "fitHeight") {
                resize_width = aspect_ratio * imageHeight - strokeWidth * 2
            }
            else if (resizeMode == "fitWidth") resize_height = imageWidth / aspect_ratio - strokeWidth * 2
        }

        const path_stroke: SkPath = Skia.Path.Make();
        const path_image: SkPath = Skia.Path.Make()

        switch (itemClipping) {
            case "rectangle":
                path_stroke.moveTo(startPosX, startPosY);
                path_stroke.lineTo(endPosX, startPosY);
                path_stroke.lineTo(endPosX, endPosY);
                path_stroke.lineTo(startPosX, endPosY);
                path_stroke.lineTo(startPosX, startPosY)

                path_image.moveTo(startPosX, startPosY);
                path_image.lineTo(endPosX, startPosY);
                path_image.lineTo(endPosX, endPosY);
                path_image.lineTo(startPosX, endPosY);
                path_image.lineTo(startPosX, startPosY)
                return (
                    <>
                        {!gradient ? (<Rect x={startX} y={startY} width={imageWidth} height={imageHeight} color={bkgColor} />)
                            : (<Rect x={startX} y={startY} width={imageWidth} height={imageHeight}>
                                {GradientRender(gradientData, startX, startY, imageWidth, imageHeight)}
                            </Rect>)}
                        {stroke && StrokePathRender(path_stroke, lineData.color, mmToPx(lineData.width / 100), lineData.style)}
                        <Group clip={path_image}>
                            {image && (
                                <Image
                                    image={image}
                                    fit={(resizeMode != "cover") ? "contain" : resizeMode}
                                    x={startX}
                                    y={startY}
                                    width={imageWidth}
                                    height={imageHeight}
                                    blendMode="srcATop"
                                />
                            )}
                        </Group>

                    </>);

            case "roundRectangle":
                path_stroke.moveTo(startPosX, startPosY);
                const rect_rrect = rect(startX, startY, imageWidth, imageHeight);
                const rrect_stroke = rrect(rect_rrect, RRECT_RADIUS, RRECT_RADIUS);
                path_stroke.addRRect(rrect_stroke)

                const rect_rrect2 = rect(startX + (imageWidth / 2 - resize_width / 2 + strokeWidth / 2), startY + (imageHeight / 2 - resize_height / 2 - strokeWidth / 2), resize_width, resize_height);
                const rrect_image = rrect(rect_rrect2, RRECT_RADIUS, RRECT_RADIUS);
                path_image.addRRect(rrect_image)
                return (
                    <>
                        {!gradient ? (<RoundedRect x={startX} y={startY} width={imageWidth} height={imageHeight} color={bkgColor} r={RRECT_RADIUS} />)
                            : (<RoundedRect x={startX} y={startY} width={imageWidth} height={imageHeight} r={RRECT_RADIUS}>
                                {GradientRender(gradientData, startX, startY, imageWidth, imageHeight)}
                            </RoundedRect>)}
                        {stroke && StrokePathRender(path_stroke, lineData.color, mmToPx(lineData.width / 100), lineData.style)}
                        <Group clip={path_image}>
                            {image && (
                                <Image
                                    image={image}
                                    fit={(resizeMode != "cover") ? "contain" : resizeMode}
                                    x={startX}
                                    y={startY}
                                    width={imageWidth}
                                    height={imageHeight}
                                    blendMode="srcATop"
                                />
                            )}
                        </Group>
                    </>);

            case "circle":
                path_stroke.moveTo(startPosX, startPosY);
                const rect_stroke = rect(startX, startY, imageWidth, imageHeight);
                path_stroke.addOval(rect_stroke)

                const rect_image = rect(startX + (imageWidth / 2 - resize_width / 2 + strokeWidth / 2), startY + (imageHeight / 2 - resize_height / 2 - strokeWidth / 2), resize_width, resize_height);
                path_image.addOval(rect_image)
                return (
                    <>
                        {!gradient ? (<Oval x={startX} y={startY} width={imageWidth} height={imageHeight} color={bkgColor} />)
                            : (<Oval x={startX} y={startY} width={imageWidth} height={imageHeight}>
                                {GradientRender(gradientData, startX, startY, imageWidth, imageHeight)}
                            </Oval>)}
                        {stroke && StrokePathRender(path_stroke, lineData.color, mmToPx(lineData.width / 100), lineData.style)}
                        <Group clip={path_image}>
                            {image && (
                                <Image
                                    image={image}
                                    fit={(resizeMode != "cover") ? "contain" : resizeMode}
                                    x={startX}
                                    y={startY}
                                    width={imageWidth}
                                    height={imageHeight}
                                    blendMode="srcATop"
                                />
                            )}
                        </Group >
                    </>);

            case "default":
                break;
        }
        return <></>
    }

    return (
        <Group transform={[{ rotate: rotationAngle }]} origin={Skia.Point(startX + translateX, startY + translateY)} opacity={opacity / 100}>
            <Group transform={[{ scaleX: scaleX }, { scaleY: scaleY }]} origin={Skia.Point(startX + (imageWidth / 2), startY + (imageHeight / 2))} >
                {ItemRender()}
            </Group>
        </Group >
    );
};

export default ImageItem;
