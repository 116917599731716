import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Platform, StyleSheet, View, ScrollView, SafeAreaView, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import configs from '../../../helpers/config';

import { findProperty, mmToPt, mmToPx, ptToMm, ptToPx } from '../../../helpers/cardRender/utils';
import { convertGoogleDriveImageToBase64 } from '../../Rest'
import { Canvas, Group, ImageFormat, Fill, useFont } from "@shopify/react-native-skia";
import globalVars from '../../../helpers/store';
import { store, addRenderedCardsData, setForceImageBase64StateFillRefresh, setRenderedRecordsSaved, setRenderedRecordsAlertText } from '../../../utils/Reducer';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';

import renderDB from '../../../helpers/db/models/render'
// import * as MediaLibrary from "expo-media-library";
import * as FileSystem from 'expo-file-system';
import { Image } from 'react-native';

// Item components
import BackgroundItem from '../../components/cardDesigner/background/BackgroundItem';
import PrintCounterItem from '../../components/cardDesigner/printcounter/PrintCounterItem';
import ImageItem from '../../components/cardDesigner/image/ImageItem';
import CircleItem from '../../components/cardDesigner/shapes/CircleItem';
import RectangleItem from '../../components/cardDesigner/shapes/RectangleItem';
import ShapeItem from '../../components/cardDesigner/shapes/ShapeItem';
import PolygonItem from '../../components/cardDesigner/shapes/PolygonItem';
import TextItem from '../../components/cardDesigner/text/TextItem';
import BarcodeItem from '../../components/cardDesigner/barcode/BarcodeItem';

import * as FaceDetector from 'expo-face-detector';
// import bwipjs from 'bwip-js' // Not work on ios
import { Asset } from 'expo-asset';
const DEFAULT_FONT_COLOR = "#000000"


interface itemRefMap {
    recordId: number,
    cardWidth: number,
    cardHeight: number,
    haveBackCard: boolean
}
interface recordItem {
    id: number,
    recordId: number,
    cardItems: Item[],
    cardWidth: number,
    cardHeight: number
}
interface Item {
    id: number,
    itemType: string,
    xmlObj: any,
    imageBase64Data: string | null,
    customText: string | null,
    isCardFront: boolean,
}

interface ItemRender {
    id: number,
    itemType: string,
    xmlObj: any,
    imageBase64Data: string | null,
    customText: string | null,
}

export type props = {
    templateProperties: any,
    templateRenderInfo: any,
    templateRenderRecords: any,
    googleAccessToken: string
};

type BarcodeOptions = {
    bcid: string,
    text: string,
    includetext: boolean,
    includecheck: boolean,
    includecheckintext: boolean,
    textxalign: 'offleft' | 'left' | 'center' | 'right' | 'offright' | 'justify' | undefined;
    textyalign: 'below' | 'center' | 'above' | undefined;
    width?: number | undefined,
    height?: number | undefined,
    // guardwhitespace: boolean,
    // guardleftpos: number | undefined,
    // guardrightpos: number | undefined,
    showborder: boolean,
    borderwidth?: number,
    textcolor: string,
    textsize?: number
}

async function getImageSizes(filename: string): Promise<any> {
    const image = Asset.fromModule(filename);

    await image.downloadAsync();

    return new Promise((resolve, reject) => {
        (async () => {
            await Image.getSize(
                image.localUri,
                (width, height) => {
                    resolve({ width: width, height: height })
                },
                error => {
                    console.error('Error:', error);
                    resolve(false)
                }
            );
        })();
    });
}

//TODO DELETE, different branch
async function convertLocalTmpFile(base64Data: string): Promise<string> {
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                //const base64Code = base64Data.split("data:image/jpg;base64,")[1];
                const filename = FileSystem.cacheDirectory + Math.floor(Date.now() * Math.random()).toString() + ".jpg";
                await FileSystem.writeAsStringAsync(filename, base64Data, {
                    encoding: FileSystem.EncodingType.Base64,
                });
                resolve(filename);
            } catch (e) {
                console.log(e)
                resolve("");

            }
        })();
    });
}


const HeadlessRender: React.FC<props> = ({
    templateProperties,
    templateRenderInfo,
    templateRenderRecords,
    googleAccessToken
}) => {

    const subRefs = useRef<any>([]);
    const backSubRefs = useRef<any>([]);

    const [templateInfo, setTemplateInfo] = useState<any | undefined>(undefined);
    const [templateRecords, setTemplateRecords] = useState<any | undefined>(undefined);
    const [renderedCardHeightState, setRenderedCardHeightState] = useState<number>(250);
    const [cardWidthState, setCardWidthState] = useState<number>(0);
    const [cardHeightState, setCardHeightState] = useState<number>(0);
    const [renderFinish, setRenderFinish] = useState<boolean>(false);
    const [recordsToRender, setRecordsToRender] = useState<recordItem[]>([]);

    let recordsRendered = 0;
    let itemsList: any = [];
    var itemRefMap: itemRefMap[] = [];

    function sleep(ms: number) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    }

    const getItemsNumber = (items: any) => {
        return items.item.length;
    }
    const getItemType = (item: any) => {
        return item.info.designObject;
    }
    const getColumnName = (sheetCol: string) => {
        const dataArray: any = sheetCol.split(".");
        return dataArray[1];
    }
    const deleteRecordFromPrintList = (recordIndex: number, recordId: number) => {
        var array = [...recordsToRender];
        array.splice(recordIndex, 1);
        setRecordsToRender(array);
        subRefs.current.splice(recordIndex, 1);
    }

    const addNewItemToList = (itemToPush: Item) => {
        const nextItemId = (itemsList[itemsList.length - 1]?.id ?? 0) + 1;
        itemsList.push({
            id: nextItemId,
            itemType: itemToPush.itemType,
            xmlObj: itemToPush.xmlObj,
            imageBase64Data: itemToPush.imageBase64Data,
            customText: itemToPush.customText,
            isCardFront: itemToPush.isCardFront,
        });
    };
    const finishRender = (recordID: number) => {
        console.log("TESTE: " + recordID);
    };

    store.subscribe(() => {
        (async () => {
            const storeData = store.getState();
            if (storeData.forceImageBase64StateFillRefresh !== undefined && storeData.forceImageBase64StateFillRefresh === true) {
                setRenderFinish(false);
                store.dispatch(setForceImageBase64StateFillRefresh(false));
            }
        })();
    });

    useEffect(() => {
        return () => {
            setRecordsToRender([]);
        }
    }, []);

    useEffect(() => {
        (async () => {

            if (renderFinish === true) {
                try {

                    await sleep(2000);
                    for (let refIndex = 0; refIndex < subRefs.current.length; refIndex++) {

                        store.dispatch(setRenderedRecordsAlertText("Rendering " + (refIndex + 1) + " of " + subRefs.current.length));
                        const currentRef = subRefs.current[refIndex];
                        const currentRecordData = globalVars.customData.itemRefMap[refIndex];
                        const image = await currentRef?.makeImageSnapshot();
                        if (image) {
                            try {

                                const base64Data = image.encodeToBase64(ImageFormat.JPEG);
                                // const base64Data = image.encodeToBase64();

                                // Compress image to card size
                                const manipResult = await manipulateAsync(
                                    'data:image/png;base64,' + base64Data,
                                    [
                                        { resize: { width: currentRecordData.cardWidth, height: currentRecordData.cardHeight } },
                                        { rotate: 0 },
                                    ],
                                    {
                                        compress: 1,
                                        format: SaveFormat.PNG,
                                        base64: true
                                    }
                                );

                                // Back card process -------------------------------------------

                                let compressedBackBase64Data: string | null | undefined = null;
                                if (currentRecordData.haveBackCard) {

                                    const BackCurrentRef = backSubRefs.current[refIndex];
                                    const backImage = await BackCurrentRef?.makeImageSnapshot();
                                    const backBase64Data = backImage.encodeToBase64(ImageFormat.JPEG);

                                    // Compress image to card size
                                    const manipBackResult = await manipulateAsync(
                                        'data:image/png;base64,' + backBase64Data,
                                        [
                                            { resize: { width: currentRecordData.cardWidth, height: currentRecordData.cardHeight } },
                                            { rotate: 0 },
                                        ],
                                        {
                                            compress: 1,
                                            format: SaveFormat.PNG,
                                            base64: true
                                        }
                                    );
                                    compressedBackBase64Data = manipBackResult.base64;
                                }

                                // Back card process -------------------------------------------

                                const compressedBase64Data = manipResult.base64;
                                const renderDBData = {
                                    recordId: currentRecordData.recordId,
                                    base64Data: compressedBase64Data,
                                    backBase64Data: compressedBackBase64Data
                                }
                                const renderDBObj = new renderDB(renderDBData);
                                await renderDBObj.save();
                                let data = {
                                    recordId: currentRecordData.recordId,
                                    // base64Image: compressedBase64Data,
                                    cardWidth: currentRecordData.cardWidth,
                                    cardHeight: currentRecordData.cardHeight
                                }

                                store.dispatch(addRenderedCardsData(data));

                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }

                    store.dispatch(setRenderedRecordsSaved(true));
                } catch (error) {
                    console.log(error);
                }
            }
        })();
    }, [renderFinish]);


    useEffect(() => {
        (async () => {

            if (templateRenderInfo !== undefined && templateRenderRecords !== undefined) {

                let iHaveItems = true;
                let recordDataObj: recordItem[] = [];
                const sizeData = templateRenderInfo.printData.print.model.size;

                const sizeArray = sizeData.split(",");
                let cardWidthSizeInMM = ptToPx(sizeArray[0]);
                let cardheightSizeInMM = ptToPx(sizeArray[1]);
                const datasourceType: string = (templateProperties?.dataSource?.type === undefined ? 'unknown' : templateProperties.dataSource?.type);
                // TODO - Read Card Size - Diego needs to add on XML
                let paperSize: any = {
                    width: 1006,
                    height: 640
                };

                const xmlPaperOrientation = templateRenderInfo?.printData?.print?.paper?.orientation;
                if (xmlPaperOrientation !== undefined) {
                    if (xmlPaperOrientation.toUpperCase() === "PORTRAIT") {
                        paperSize.width = 640;
                        paperSize.height = 1006;
                    }
                }

                cardWidthSizeInMM = paperSize.width;
                cardheightSizeInMM = paperSize.height;
                // Sample card - 1006 x 640
                const cardWidth: number = cardWidthSizeInMM;
                const cardHeight: number = cardheightSizeInMM;
                setCardWidthState(cardWidth);
                setCardHeightState(cardHeight);

                const deviceWidth = Math.round(Dimensions.get('window').width)
                const calculatedCardHeight = Math.round((cardHeight * deviceWidth) / cardWidth);
                setRenderedCardHeightState(calculatedCardHeight);

                const cardItemsNumber = getItemsNumber(templateRenderInfo.itemsData.items);
                if (cardItemsNumber === 0) {
                    return;
                }

                // For each item record
                if (globalVars.noDb && templateRenderRecords.length > 1) templateRenderRecords = templateRenderRecords.slice(-1)[0]
                for (let rIndex = 0; rIndex < templateRenderRecords.length; rIndex++) {

                    store.dispatch(setRenderedRecordsAlertText("Processing items from card " + (rIndex + 1) + " of " + templateRenderRecords.length));

                    const currentRecord: any = templateRenderRecords[rIndex];
                    const itemsNumber: number = getItemsNumber(templateRenderInfo.itemsData.items);

                    // For each item on card
                    for (let index = 0; index < itemsNumber; index++) {

                        store.dispatch(setRenderedRecordsAlertText("Processing item (" + (index + 1) + "/" + itemsNumber + ") from card " + (rIndex + 1) + " of " + templateRenderRecords.length));

                        const currentItem = templateRenderInfo.itemsData.items.item[index];
                        const itemType: string = getItemType(currentItem);

                        let base64ImageData: string | null = null;
                        let itemBdText: any = null;
                        // Load image if exists
                        if (currentItem.imageAcquisition?.picture?.data
                            && (itemType === "imageGallery" || itemType === "imageDeviceDisk" || itemType === "background")
                        ) {
                            let imageData = ""
                            if (currentItem.item.userID !== undefined && currentRecord.info !== undefined && Object.keys(currentRecord.info).includes(currentItem.item.userID)) {
                                imageData = currentRecord.info[currentItem.item.userID]
                            }
                            else {
                                imageData = await globalVars.zipData.file(currentItem.imageAcquisition.picture.data).async("base64");
                                const regex = new RegExp('[^.]+$');
                                const extension = currentItem.imageAcquisition.picture.data.match(regex);
                            }
                            // base64ImageData = 'data:image/' + extension[0] + ';base64,' + imageData;
                            base64ImageData = imageData;
                        }

                        if (
                            (
                                itemType === "image_database"
                                && currentItem.database?.column !== undefined
                                && currentRecord.info[getColumnName(currentItem.database.column)] !== undefined
                            )
                            ||
                            (
                                itemType === "imageDeviceDisk"
                                && currentItem.database?.column !== undefined
                                && currentRecord.info[getColumnName(currentItem.database.column)] !== undefined
                                && currentItem.source?.type !== undefined
                                && currentItem.source?.type === 'database'
                            )
                        ) {

                            if (datasourceType === "GOOGLESHEETS") {

                                if (currentRecord.info[getColumnName(currentItem.database.column)].indexOf('drive.google.com/file') !== -1) {
                                    const imgObjBase64 = await convertGoogleDriveImageToBase64(currentRecord.info[getColumnName(currentItem.database.column)], googleAccessToken);
                                    if (imgObjBase64 !== false) {
                                        const base64Result: string[] = imgObjBase64.split("base64,");
                                        base64ImageData = base64Result[1];
                                    }
                                } else if (currentRecord.info[getColumnName(currentItem.database.column)].indexOf('file:///data') !== -1) {
                                    const imgObjBase64 = await FileSystem.readAsStringAsync(currentRecord.info[getColumnName(currentItem.database.column)], { encoding: FileSystem.EncodingType.Base64 });
                                    base64ImageData = imgObjBase64;
                                }

                            } else {
                                const imgObjBase64 = currentRecord.info[getColumnName(currentItem.database.column)];
                                const base64Result: string[] = imgObjBase64.split("base64,");
                                base64ImageData = base64Result[1];
                            }

                        }

                        if (itemType === "text_database"
                            && currentItem.database?.column !== undefined
                            && currentRecord.info[getColumnName(currentItem.database.column)] !== undefined
                        ) {
                            itemBdText = currentRecord.info[getColumnName(currentItem.database.column)].toString();
                        }

                        if (itemType === "text"
                            && currentRecord.info !== undefined) {

                            if (currentItem.source?.type !== undefined
                                && currentItem.source?.type === 'database'
                                && currentRecord.info[getColumnName(currentItem.database.column)] !== undefined
                            ) {
                                itemBdText = currentRecord.info[getColumnName(currentItem.database.column)].toString();
                            } else if (currentRecord.info[currentItem.item.userID] !== undefined) {
                                itemBdText = currentRecord.info[currentItem.item.userID].toString()
                            }

                        }

                        if (itemType === "integer"
                            && currentItem.database?.column !== undefined
                            && currentRecord.info[getColumnName(currentItem.database.column)] !== undefined
                        ) {
                            itemBdText = parseInt(currentRecord.info[getColumnName(currentItem.database.column)]).toString();
                        }

                        if (itemType === "decimal"
                            && currentItem.database?.column !== undefined
                            && currentRecord.info[getColumnName(currentItem.database.column)] !== undefined
                        ) {
                            itemBdText = parseFloat(currentRecord.info[getColumnName(currentItem.database.column)]).toString();
                        }

                        if ((itemType === "date" || itemType === "time" || itemType === "dateTime")
                            && currentItem.database?.column !== undefined
                            && currentRecord.info[getColumnName(currentItem.database.column)] !== undefined
                        ) {
                            itemBdText = currentRecord.info[getColumnName(currentItem.database.column)].toString();
                        }

                        // Load SVG data if exists
                        if (currentItem.clipart?.image?.data) {
                            const SVGData = await globalVars.zipData.file(currentItem.clipart.image.data).async("base64");
                            base64ImageData = SVGData;
                        }

                        const faceDetection = findProperty(currentItem.imageAcquisition, "faceDetection")
                        if (faceDetection != undefined && faceDetection.enabled) {
                            try {
                                const filename: string = await convertLocalTmpFile(base64ImageData)
                                const options = {
                                    detectLandmarks: FaceDetector.FaceDetectorLandmarks.all,
                                    mode: FaceDetector.FaceDetectorMode.accurate
                                };
                                const faces = await FaceDetector.detectFacesAsync(filename, options);

                                if (faces.faces.length > 0) {
                                    const face_bounds = faces.faces[0].bounds;

                                    const imageSizes = await getImageSizes(filename)

                                    const manipulatorOptions = {
                                        crop: {
                                            originX: (face_bounds.origin.x - 125 < 0) ? 0 : face_bounds.origin.x - 125,
                                            originY: (face_bounds.origin.y - 120 < 0) ? 0 : face_bounds.origin.y - 120,
                                            width: (face_bounds.origin.x + face_bounds.size.width + 250 > imageSizes.width) ? imageSizes.width : face_bounds.size.width + 250,
                                            height: (face_bounds.origin.y + face_bounds.size.height + 250 > imageSizes.height) ? imageSizes.height : face_bounds.size.height + 250,
                                        }
                                    };

                                    const manipulatedImage = await manipulateAsync(
                                        filename,
                                        [manipulatorOptions],
                                        { base64: true }
                                    )

                                    if (manipulatedImage.base64 != undefined) base64ImageData = manipulatedImage.base64
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }

                        // Barcode
                        if (itemType == "barcode1D" && 1 === 2) {
                            const barcode_item = currentItem.barcode
                            const barcode_text = String(currentItem.data.final)

                            // Barcode type
                            let current_bcid = barcode_item.symbol.toLowerCase()
                            if (current_bcid == "3of9") current_bcid = "code39"

                            // Quiet zone
                            let quiet_zone = findProperty(barcode_item, "QUIET")
                            if (quiet_zone != undefined) quiet_zone = mmToPt(quiet_zone / 100)

                            // Module width
                            let module_width = findProperty(barcode_item.BAR, "MODULEWIDTH")
                            if (module_width == undefined || module_width == 0) {
                                module_width = (currentItem.position.pos2.x / 100) - (currentItem.position.pos1.x / 100)
                                // if (barcode_item.QUIET == 500) console.log(module_width)
                                if (quiet_zone != undefined) module_width = module_width - (ptToMm(quiet_zone) * 2)
                                // if (barcode_item.QUIET == 500) console.log(module_width)
                                if (currentItem.line?.width != undefined) module_width = module_width - currentItem.line.width / 100
                                // if (barcode_item.QUIET == 500) console.log(module_width)
                            }

                            // Module height - for better presentation of the text 
                            // (in auto size, the font height is set at approximately 0.25cm or 2.5 mm)
                            let module_height: number | undefined = undefined
                            const font_size = currentItem.font?.pointSize
                            if (font_size == undefined || typeof font_size != "number") module_height = mmToPx((currentItem.position.pos2.y / 100) - (currentItem.position.pos1.y / 100)) - mmToPx(5)

                            // Bearer bar
                            const bearer_bar = Array.isArray(barcode_item.BORDER) ? barcode_item.BORDER[0] : barcode_item.BORDER

                            // Bearer width
                            let bearer_width = findProperty(barcode_item.BORDER, "WIDTH")
                            if (bearer_width == undefined && barcode_item.BORDER != undefined)
                                bearer_width = 80

                            // Human readable
                            const textYAlign = Array.isArray(barcode_item.HUMANEX) ? barcode_item.HUMANEX[0].toLowerCase() : "below"

                            // Fit text to barcode
                            let font_property = findProperty(barcode_item.HUMANEX, "FONT"), fittexttobarcode: boolean = false
                            if (font_property != undefined) {
                                fittexttobarcode = findProperty(fittexttobarcode, "FITTEXTTOBARCODE") == 1 ? true : false
                            }

                            // Check digit - warning control for 
                            // -- CDMOD10 AND CDMOD43 - 'Code 128'
                            // -- CDMOD11W7 - 'Code 3 of 9/Code 39'
                            // Warning control
                            const mods_not_supported: Array<string> = ["CDMOD10", "CDMOD43", "CDMOD11W7"]
                            const barcode_cd = barcode_item.CHECKDIGIT
                            const checkdigit: boolean = barcode_cd != undefined && barcode_cd != "CDNONE";

                            // Font color
                            let fontColor: string = currentItem.font?.color != undefined ? currentItem.font.color : DEFAULT_FONT_COLOR
                            try {
                                if (mods_not_supported.some(substring => substring.includes(barcode_cd))) {
                                    console.log("WARNING: This barcode type is not supported in mobile version. Please contact for support.")
                                    throw new Error(current_bcid + " " + barcode_cd + " not supported")
                                }
                                const barcode_options: BarcodeOptions = {
                                    bcid: current_bcid,
                                    text: barcode_text,
                                    includetext: barcode_item.HUMANEX == "NONE" ? false : true,
                                    includecheck: checkdigit,
                                    includecheckintext: checkdigit,
                                    textxalign: fittexttobarcode ? "justify" : undefined,
                                    textyalign: textYAlign,
                                    // guardwhitespace: barcode_item.QUIET != undefined,
                                    // guardleftpos: quiet_zone,
                                    // guardrightpos: quiet_zone,
                                    showborder: barcode_item.BORDER != undefined && barcode_item.BORDER != "NOBORDER",
                                    //UNCOMMENT FOR TOP/BOTTOM IMPLEMENTATION borderwidth: (bearer_width != undefined && bearer_bar == "RECTANGLE") ? mmToPt(bearer_width / 100) : 0,
                                    textcolor: fontColor
                                }

                                if (module_width != 0) barcode_options.width = module_width
                                if (module_height != undefined) barcode_options.height = module_height
                                if (font_size != undefined && typeof font_size == "number") barcode_options.textsize = font_size
                                /**
                                * CONTROL!!
                                */
                                // if (barcode_item.QUIET == 500) {
                                //     console.log("**** BEGIN CONTROL ****")
                                //     const jsonString = JSON.stringify(currentItem, null, 2)
                                //     console.log(jsonString)
                                //     console.log("module_height: " + module_height)
                                //     console.log("module_width: " + module_width)
                                //     console.log("Barcode options: \n" + JSON.stringify(barcode_options, null, 2))
                                //     console.log("**** END CONTROL ****")
                                // }

                                // const img = await bwipjs.toDataURL(barcode_options); // Not work on ios
                                // base64ImageData = img.uri
                                base64ImageData = "";
                            } catch (error) {
                                console.log(error)
                                base64ImageData = "" + error
                            }
                        }
                        // Add paper size to item data
                        currentItem.paperSize = paperSize;

                        // Check if is front or back
                        let isCardFront = true;
                        if (currentItem.item?.side == "8192") {
                            isCardFront = false;
                        }

                        let dataToPush: {
                            id: number,
                            itemType: string,
                            xmlObj: any,
                            imageBase64Data: string | null,
                            customText: string | null,
                            isCardFront: boolean,
                        } = {
                            id: 0,
                            itemType: itemType,
                            xmlObj: currentItem,
                            imageBase64Data: base64ImageData,
                            customText: itemBdText,
                            isCardFront: isCardFront,
                        }
                        addNewItemToList(dataToPush);
                    }

                    let frontCardItems = itemsList.filter(item => item.isCardFront === true);
                    let backCardItems = itemsList.filter(item => item.isCardFront === false);

                    // Clear back card items if only have the background item. 
                    if (backCardItems.length < 2) {
                        backCardItems = null;
                    }

                    let itemsToAdd: {
                        id: number,
                        recordId: number,
                        cardItems: Item[],
                        cardBackItems: Item[],
                        cardWidth: number,
                        cardHeight: number
                    } = {
                        id: rIndex,
                        recordId: currentRecord.recordId,
                        cardItems: frontCardItems,
                        cardBackItems: backCardItems,
                        cardWidth: paperSize.width,
                        cardHeight: paperSize.height
                    }

                    // Prevent create empty canvas
                    if (itemsList.length === 0) {
                        iHaveItems = false;
                        break;
                    }
                    recordDataObj.push(itemsToAdd)
                    itemsList = [];
                }

                if (iHaveItems) {
                    setRecordsToRender(recordDataObj);
                }

                await sleep(1000);
                setRenderFinish(true);
            }

        })();
    }, [templateRenderInfo, templateRenderRecords]);

    function ItemRender(item: ItemRender): any {
        switch (item.itemType) {
            case 'background':
                return (<BackgroundItem id={item.id} xmlObj={item.xmlObj} imageBase64Data={item.imageBase64Data} />);
            // case 'barcode1D':
            //     const err_split = item.imageBase64Data?.split(":")
            //     if (err_split != undefined && err_split[0] == "Error")
            //         return (<TextItem id={item.id} xmlObj={item.xmlObj} customText={err_split[err_split.length - 1]} />);
            //     return (<BarcodeItem id={item.id} xmlObj={item.xmlObj} imageBase64Data={item.imageBase64Data} />)
            case 'imageGallery':
            case 'imageDeviceDisk':
            case 'image_database':
                return (<ImageItem id={item.id} xmlObj={item.xmlObj} imageBase64Data={item.imageBase64Data} />);
            case 'counter':
                return (<PrintCounterItem id={item.id} xmlObj={item.xmlObj} customText={item.customText} />);
            case 'date':
            case 'dateTime':
            case 'text':
            case 'time':
            case 'integer':
            case 'decimal':
            case 'text_database':
                return (<TextItem id={item.id} xmlObj={item.xmlObj} customText={item.customText} />);
            case 'circle':
                return (<CircleItem id={item.id} xmlObj={item.xmlObj} />);
            case 'rectangle':
                return (<RectangleItem id={item.id} xmlObj={item.xmlObj} />);
            case 'shape':
                if (item.xmlObj?.clipping?.shape[0] !== undefined && item.xmlObj.clipping.shape[0] === 'polygon') {
                    return (<PolygonItem id={item.id} xmlObj={item.xmlObj} />);
                } else {
                    return (<ShapeItem id={item.id} xmlObj={item.xmlObj} svgData={item.imageBase64Data} />);
                }
                break;
        }
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{ width: '100%' }}>
                <View style={styles.CardContainer}>
                    {recordsToRender.map((record: any, index: number) => {
                        itemRefMap[index] = {
                            recordId: record.recordId,
                            cardWidth: cardWidthState,
                            cardHeight: cardHeightState,
                            haveBackCard: (record.cardBackItems === null ? false : true)
                        };
                        globalVars.customData['itemRefMap'] = itemRefMap;
                        // console.log(record.cardItems)
                        return (
                            <View key={index}
                                style={{
                                }}>
                                <View key={index} style={{
                                    // transform: [{ scale: 0.80 }] // ZOOM
                                    // transform: [{ scale: 0.58 }] // Tablet
                                    transform: [{ scale: 0.37 }],
                                    zIndex: -1,
                                    elevation: 0,
                                    position: 'relative'
                                }}>
                                    <View // TODO - Temporary border solution
                                        style={{
                                            position: 'absolute',
                                            borderColor: "#000000",
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            width: cardWidthState,
                                            height: cardHeightState,
                                            top: -195,
                                            left: -305,
                                        }}>
                                    </View>
                                    <View key={index} style={{
                                        ...styles.CardHolder,
                                        height: renderedCardHeightState,
                                    }} >
                                        {renderFinish &&
                                            <View>
                                                {/* Front card items **************************************************************** */}
                                                <Canvas
                                                    mode="default"
                                                    // mode="continuous"
                                                    style={{
                                                        width: cardWidthState,
                                                        maxWidth: cardWidthState,
                                                        height: cardHeightState,
                                                        maxHeight: cardHeightState,
                                                    }}
                                                    ref={ref => (subRefs.current[index] = ref)}
                                                // onLayout={() => { finishRender(record.recordId) }}
                                                >
                                                    <Group blendMode="multiply">
                                                        <Fill color='#FFFFFF' />
                                                        {record.cardItems.map((itemToRender: any, indexItem: number) => {
                                                            // const uniqueKey = Math.floor(new Date().valueOf() * Math.random());                                                      
                                                            return (
                                                                <ItemRender
                                                                    key={indexItem}
                                                                    id={itemToRender.id}
                                                                    itemType={itemToRender.itemType}
                                                                    xmlObj={itemToRender.xmlObj}
                                                                    imageBase64Data={itemToRender.imageBase64Data}
                                                                    customText={itemToRender.customText}
                                                                />
                                                            )
                                                        })}
                                                    </Group>
                                                </Canvas>
                                                {/* Back card items **************************************************************** */}
                                                {record.cardBackItems !== null &&
                                                    <Canvas
                                                        mode="default"
                                                        // mode="continuous"
                                                        style={{
                                                            width: cardWidthState,
                                                            maxWidth: cardWidthState,
                                                            height: cardHeightState,
                                                            maxHeight: cardHeightState,
                                                        }}
                                                        ref={ref => (backSubRefs.current[index] = ref)}
                                                    // onLayout={() => { finishRender(record.recordId) }}
                                                    >
                                                        <Group blendMode="multiply">
                                                            <Fill color='#FFFFFF' />
                                                            {record.cardBackItems.map((itemToRender: any, indexItem: number) => {
                                                                // const uniqueKey = Math.floor(new Date().valueOf() * Math.random());                                                      
                                                                return (
                                                                    <ItemRender
                                                                        key={indexItem}
                                                                        id={itemToRender.id}
                                                                        itemType={itemToRender.itemType}
                                                                        xmlObj={itemToRender.xmlObj}
                                                                        imageBase64Data={itemToRender.imageBase64Data}
                                                                        customText={itemToRender.customText}
                                                                    />
                                                                )
                                                            })}
                                                        </Group>
                                                    </Canvas>
                                                }
                                            </View>
                                        }
                                    </View>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </ScrollView >
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',

        // Turn invisible 
        top: -9999,
        position: 'absolute'
    },
    CardContainer: {
        flex: 1,
        justifyContent: "space-around",
        flexDirection: "column",
    },
    CardHolder: {
        width: '100%',
        alignItems: "center",
        justifyContent: "center",
        zIndex: -1,
        elevation: -1,
    }
});

const mapStateToProps = (
    state: {
        templateProperties: any,
        templateRenderInfo: any,
        templateRenderRecords: any,
        googleAccessToken: string,
    }
) => {
    return {
        templateProperties: state.templateProperties,
        templateRenderInfo: state.templateRenderInfo,
        templateRenderRecords: state.templateRenderRecords,
        googleAccessToken: state.googleAccessToken,
    }
}

export default connect(mapStateToProps, {})(HeadlessRender);