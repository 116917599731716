import React, {useState} from 'react';
import { store } from '../../../utils/Reducer';
import { connect } from 'react-redux';
import {
  Platform,
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { webAPIUser, webAPIPass } from '../../Rest';
import { setUserRegist } from '../../../utils/Reducer';
import base64 from 'react-native-base64';
import { useTranslation } from 'react-i18next';
import configs from '../../../helpers/config';

const ForgotScreen = (props) => {

  const { t, i18n } = useTranslation();
  const [ values, setValues ] = useState( { email: '', oemID: 35, masterKey: ''} )

  const handleSubmitPress = () => {
    if (values.email === '') {
        alert('Please fill Email');
        return;
    }


    fetch( configs.APPUrls.webApi + '/v1/account/forgot', {
        method: 'POST',
        headers: {
          'Authorization' : 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
          'Accept' : 'application/json',
          'Content-Type' : 'application/json',
        },
        body : JSON.stringify( { 'email' : values.email, 'oemId' : values.oemID, 'masterKey' : values.masterKey } )
      } )
      .then( response => {
        if ( response.ok ) {
            store.dispatch( setUserRegist( values ) );
            props.navigation.navigate('Reset');
            return
          }
        });
  };
 
  return (
    <View style={styles.mainBody}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <View style={styles.formHolder}>
          <KeyboardAvoidingView enabled behavior="position">
            <View style={{alignItems: 'center'}}>
              <Icon name="person-circle-outline" size={150} color="#000000" />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserEmail) => setValues( { ...values, email: UserEmail } )}
                placeholder={t('STR_LABEL_EMAIL')}
                placeholderTextColor="#8b9cb5"
                autoCapitalize="none"
                keyboardType="default"
                returnKeyType="next"
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />
            </View>
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={handleSubmitPress}>
              <Text style={styles.buttonTextStyle}>{t('STR_RESET')}</Text>
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </View>
      </ScrollView>
    </View>
  );
};
export default connect( null, { } )( ForgotScreen )

 
const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignContent: 'center',
  },
  formHolder: {
    ...Platform.select({
      ios: {
        width: '100%',
      },
      android: {
        width: '100%',
      },
      default: {
        width: 500,
      }
    }),
    alignSelf: 'center'
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#05AC72',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#05AC72',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
});