import QueryBuilder from './queryBuilder'

export default class DatabaseLayer {
  database: any;
  tableName: any;

  constructor(database: any, tableName: any) {
    this.database = database
    this.tableName = tableName
  } 

  async executeBulkSql(sqls: any, params: any = []) {
    const database = await this.database()
    return new Promise((txResolve, txReject) => {
      database.transaction((tx: any) => {
        Promise.all(sqls.map((sql: any, index: any) => {
          return new Promise((sqlResolve, sqlReject) => {
            tx.executeSql(
              sql,
              params[index],
              (_: any, { rows, insertId }: any) => {
                sqlResolve({ rows: rows._array, insertId })
              },
              (_: any, error: any) => { sqlReject(error) }
            )
          })
        })).then(txResolve).catch(txReject)
      })
    })
  }

  async executeSql(sql: any, params: any = []) {
    return this.executeBulkSql([sql], [params])
      .then((res: any) => res[0])
      .catch(error => { throw error })
  }

  createTable(columnMapping: any) {
    const sql = QueryBuilder.createTable(this.tableName, columnMapping)
    return this.executeSql(sql).then(() => true)
  }

  dropTable() {
    const sql = QueryBuilder.dropTable(this.tableName)
    return this.executeSql(sql).then(() => true)
  }

  insert(obj: any) {
    const sql = QueryBuilder.insert(this.tableName, obj)
    const params = Object.values(obj)
    return this.executeSql(sql, params).then(({ insertId }) => this.find(insertId))
  }

  update(obj: any) {
    const sql = QueryBuilder.update(this.tableName, obj)
    const { id, ...props } = obj
    const params = Object.values(props)
    return this.executeSql(sql, [...params, id])
  }

  bulkInsertOrReplace(objs: any) {
    const list = objs.reduce((accumulator: any, obj: any) => {
      const params = Object.values(obj)
      accumulator.sqls.push(QueryBuilder.insertOrReplace(this.tableName, obj))
      accumulator.params.push(params)
      return accumulator
    }, { sqls: [], params: [] })
    return this.executeBulkSql(list.sqls, list.params)
  }

  destroy(id: any) {
    const sql = QueryBuilder.destroy(this.tableName)
    return this.executeSql(sql, [id]).then(() => true)
  }

  destroyAll() {
    const sql = QueryBuilder.destroyAll(this.tableName)
    return this.executeSql(sql).then(() => true)
  }

  find(id: any) {
    const sql = QueryBuilder.find(this.tableName)
    return this.executeSql(sql, [id]).then(({ rows }) => rows[0])
  }

  findBy(where: any = {}) {
    const options = { where, limit: 1 }
    const sql = QueryBuilder.query(this.tableName, options)
    const params = Object.values(options.where)
    return this.executeSql(sql, params).then(({ rows }) => rows[0])
  }

  query(options: any = {}) {
    const sql = QueryBuilder.query(this.tableName, options)
    const params = Object.values(options.where || {})
    return this.executeSql(sql, params).then(({ rows }) => rows)
  }
}
