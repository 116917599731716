
import * as SQLite from 'expo-sqlite'
import { BaseModel, types } from '../classes/index';
import configs from '../../config';

export default class resource extends BaseModel {
    constructor(obj: any) {
        super(obj)
    }

    static get database(): any {
        return async () => SQLite.openDatabase(configs.sqlLiteDbName)
    }

    static get tableName(): any {
        return 'resources'
    }

    static get columnMapping(): any {
        return {
            id: { type: types.INTEGER, primary_key: true, autoincrement: true },
            name: { type: types.TEXT, not_null: true },
            lastChangeDate: { type: types.FLOAT, not_null: true },
            localFilePath: { type: types.TEXT, not_null: true },
        }
    }
}