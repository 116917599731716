import React, { useEffect, useState, useRef } from 'react'
import { Platform, Dimensions, StyleSheet, Text, FlatList, View, Image, Alert } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import Bottom from '../../components/BottomTab';
import { Searchbar, Badge, Button, useTheme } from 'react-native-paper';
import { getRecords, getTemplateById, getGoogleCredentials, convertGoogleDriveImageToBase64 } from '../../Rest';
import { connect } from 'react-redux';
import {
  setRecordSelectedId, setCancelRecordCardLoading,
  cleanRecord,
  addRecordToPrintListData, removeRecordToPrintListData, setRecordsToPrintListData,
  store, setTemplateSelectedId, addRecordToPrintList, removeRecordToPrintList,
  setRecordsToPrintList, setGoogleAccessToken, setRecordsFrom, setRenderedCardsData,
  setRenderedRecordsSaved, setRenderedRecordsAlertText, setTemplateRenderRecords, updateRecordSelected,
  cleanRecordInsertData, cleanRecordUpdateData, cleanRecordSynced, cleanUpdateRecordDataKey
} from '../../../utils/Reducer';
import Loader from '../../components/Loader';
import globalVars from '../../../helpers/store';
import { useTranslation } from 'react-i18next';
import Animated, { BounceInDown, FadeIn } from 'react-native-reanimated';
import * as FileSystem from 'expo-file-system';
import RecordCard from '../../components/RecordCard';
import EmptyList from '../../components/global/emptyList';
import { sleep, deviceIsOnline } from '../../../helpers/utils';
import recordDB from '../../../helpers/db/models/record'
import { findProperty } from '../../../helpers/cardRender/utils';

function RecordScreen(props) {

  const theme = useTheme();
  const { t, i18n } = useTranslation();

  // const { recordList } = props
  const [recordList, setRecordList] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);

  // const { templateProperties } = props
  const [templateProperties, setTemplateProperties] = useState(undefined);

  const templateSelectedId = props.route.params.templateId
  const { updateRecordDataKey } = props
  const { recordsToPrintList } = props
  const { recordsFrom, recordsToPrintListData } = props
  const { recordInsertData, recordUpdateData, recordSynced, recordToRemove, recordSyncError } = props

  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  // const [recordsFrom, setRecordsFrom] = useState(0);
  const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(true);
  const [dataSourceReady, setDataSourceReady] = useState(false);
  const [googleAccessTokenState, setGoogleAccessTokenState] = useState(null);
  const [printBtnEnabled, setPrintBtnEnabled] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [activeSearch, setActiveSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const isFocused = useIsFocused();
  const deviceWidth = Math.round(Dimensions.get('window').width)
  const deviceHeight = Math.round(Dimensions.get('window').height)

  // Handle Redux state changes
  let recordsToPrintListCurrent = recordsToPrintList;
  store.subscribe(() => {
    const storeData = store.getState();

    if (recordsToPrintListCurrent !== storeData.recordsToPrintList && storeData.recordsToPrintList !== undefined) {
      if (selectedId !== null) {
        setSelectedId(null);
      }
      if (storeData.recordsToPrintList.length > 0) {
        setPrintBtnEnabled(true);
      } else {
        setPrintBtnEnabled(false);
      }
    }

    if (storeData.recordSelectedId === undefined) {
      setSelectedId(null);
    }
  });

  const onChangeSearch = async (query) => {
    setSearchQuery(query)
    if (activeSearch === true && query === '') {
      setLoading(true)
      setSelectedId(null)
      store.dispatch(setRecordSelectedId(undefined))
      setRecordList([]);
      setActiveSearch(false);
      const recordsData = await getRecords(templateSelectedId, 0);
      setRecordsCount(recordsData.recordsCount);
      setRecordList(recordsData.recordsList.items);
      store.dispatch(setRecordsFrom(0))
      setLoading(false)
    }
  };
  const searchRecords = async () => {
    if (searchQuery !== '') {
      setLoading(true)
      setSelectedId(null)
      store.dispatch(setRecordSelectedId(undefined))
      setRecordList([]);
      setActiveSearch(true);
      const recordsData = await getRecords(templateSelectedId, 0, searchQuery);
      setRecordsCount(recordsData.recordsCount);
      setRecordList(recordsData.recordsList.items);
      store.dispatch(setRecordsFrom(0))
      setLoading(false)
    }
  }

  useEffect(
    () => {
      (async () => {
        if (isFocused) {
          // recordDB.destroyAll();
        }
      })();
    },
    [isFocused]
  )

  const loadRecords = async () => {

    // console.log("TESTE 1");
    const recordsData = await getRecords(templateSelectedId, 0, searchQuery, (recordsFrom === 0 ? 10 : recordsFrom));
    // console.log("TESTE 3");

    const deviceIsOnlineResult = await deviceIsOnline();
    if (!deviceIsOnlineResult) {
      setDataSourceReady(true);
    }

    if (recordsData !== false) {
      setRecordsCount(recordsData.recordsCount);
      setRecordList(recordsData.recordsList.items);
    }
    return;
  }

  const onRefresh = async () => {
    store.dispatch(setRecordsFrom(0))
    setLoading(true)
    await loadRecords();
    setLoading(false)
    return;
  };

  useEffect(
    () => {
      (async () => {
        if (recordInsertData !== undefined) {
          if (recordInsertData.info.localRecordId !== undefined) {

            recordInsertData['isLocalRecord'] = true;
            recordInsertData['localRecordId'] = recordInsertData.info.localRecordId;

            const recordObj = await recordDB.query({
              where: {
                id_eq: recordInsertData.localRecordId
              },
            });
            if (recordObj[0]?.recordId !== undefined) {
              recordInsertData['recordId'] = recordObj[0].recordId;
              recordInsertData['info']['recordId'] = recordObj[0].recordId;
            }

          } else {
            if (recordInsertData.info?.recordId !== undefined && recordInsertData.info?.recordId !== 0) {
              recordInsertData['recordId'] = recordInsertData.info.recordId
            }
          }
          // console.log(recordInsertData);
          let recordsData = [recordInsertData].concat(recordList);
          setRecordList(recordsData);
          setRecordsCount(recordsCount + 1);
          store.dispatch(cleanRecordInsertData());
        }
      })();
    },
    [recordInsertData]
  )

  useEffect(
    () => {
      (async () => {
        if (recordUpdateData !== undefined) {
          let recordsData = [...recordList];
          let index = recordsData.findIndex(el => el.recordId === recordUpdateData.recordId);
          recordsData[index] = recordUpdateData;
          if (recordsData[index].info.localRecordId !== undefined) {
            recordsData[index]['isLocalRecord'] = true;
            recordsData[index]['localRecordId'] = recordsData[index].info.localRecordId;
          }
          setRecordList(recordsData);
          store.dispatch(cleanRecordUpdateData());
        }
      })();
    },
    [recordUpdateData]
  )

  useEffect(
    () => {
      (async () => {
        if (updateRecordDataKey !== undefined) {
          let recordsData = [...recordList];
          let index = recordsData.findIndex(el => el.recordId === updateRecordDataKey.recordId);
          if (index !== -1 && updateRecordDataKey?.key !== undefined && updateRecordDataKey?.value !== undefined) {
            recordsData[index].info[updateRecordDataKey.key] = updateRecordDataKey.value;
            setRecordList(recordsData);
          }
          store.dispatch(cleanUpdateRecordDataKey());
        }
      })();
    },
    [updateRecordDataKey]
  )

  // useEffect(
  //   () => {
  //     (async () => {
  //      console.log("***************************");
  //      console.log(recordList);
  //      console.log("***************************");
  //     })();
  //   },
  //   [recordList]
  // )

  useEffect(
    () => {
      (async () => {
        if (recordSynced !== undefined) {
          let recordsData = [...recordList];
          let index = recordsData.findIndex(el => el.recordId === recordSynced.tmpRecordId);

          // IF IS UPDATE 
          if (recordsData[index]?.info?.localRecordId !== undefined) {
            recordsData[index]['isLocalRecord'] = false;
            delete recordsData[index]['localRecordId'];
          } else {
            // IF IS NEW RECORD 
            let index = recordsData.findIndex(el => el.localRecordId === recordSynced.tmpRecordId);
            recordsData[index]['info'] = recordSynced.recordInfo;
            recordsData[index]['isLocalRecord'] = false;
            recordsData[index].recordId = recordSynced.cloudRecordId;
            delete recordsData[index]['localRecordId'];
          }
          setRecordList(recordsData);
          store.dispatch(cleanRecordSynced());
        }
      })();
    },
    [recordSynced]
  )

  useEffect(
    () => {
      (async () => {
        if (recordSyncError !== undefined) {
          let recordsData = [...recordList];

          let index = recordsData.findIndex(el => el.localRecordId === recordSyncError.recordId);
          if (recordsData[index] !== undefined) {
            recordsData[index].syncError = recordSyncError.error;
            recordsData[index].newRecord = recordSyncError.newRecord;
            setRecordList(recordsData);
          }

        }
      })();
    },
    [recordSyncError]
  )

  useEffect(
    () => {
      (async () => {
        if (recordToRemove !== undefined) {
          const recordsData = recordList.filter((item) =>
            item.recordId !== recordToRemove
          );
          setRecordsCount(recordsCount - 1);
          setRecordList(recordsData);
        }
      })();
    },
    [recordToRemove]
  )


  useEffect(() => {

    (async () => {
      setLoading(true);
      const templateProperties = await getTemplateById(templateSelectedId);
      setTemplateProperties(templateProperties);

      // Keep this just in case
      const databaseColumn = findProperty(templateProperties, "databaseColumn")
      if (databaseColumn === undefined || databaseColumn == "") {
        globalVars.noDb = true
        props.navigation.navigate('NewRecord')
      } else {
        globalVars.noDb = false
        store.dispatch(setRenderedCardsData([]));
        store.dispatch(setRecordsFrom(0))
        globalVars.controller = new AbortController();
        globalVars.cardLoadCancel = false;
        if (!globalVars.noDb) await loadDataSourceCredentials(templateProperties);
        await loadRecords();
      }
      setLoading(false);
      setRefreshing(false);
    })();

    return () => {
      store.dispatch(cleanRecord());
      store.dispatch(setRecordSelectedId(undefined))
      setRecordList([]);
      store.dispatch(setTemplateSelectedId(undefined))
      store.dispatch(setRecordsToPrintList(undefined));
      store.dispatch(setRecordsToPrintListData([]));
      globalVars.cardLoadCancel = true;
      globalVars.controller.abort();
    }
  }, []);

  const loadDataSourceCredentials = async (_templateProperties) => {
    (async () => {
      if (_templateProperties.dataSource?.type === 'MYSQL') {
        setDataSourceReady(true);
      } else {
        const deviceIsOnlineResult = await deviceIsOnline();
        if (deviceIsOnlineResult) {
          const googleCredentials = await getGoogleCredentials(templateSelectedId);
          if (googleCredentials?.accessToken !== undefined && googleCredentials?.accessToken !== null) {
            setGoogleAccessTokenState(googleCredentials.accessToken);
            store.dispatch(setGoogleAccessToken(googleCredentials.accessToken));
          }
        }
        setDataSourceReady(true);
      }
      return;
    })();
  }

  const ListFooterComponent = () => {
    if (Number(recordsCount) > Number(recordList.length) && loading === false) {
      return (
        <Image style={styles.imageStyle} source={require('../../../assets/loading_dots.gif')} resizeMode='contain' />
      )
    } else {
      return (<Text style={{ padding: 5, marginBottom: 35 }}></Text>);
    }
  }

  const ListWebFooterComponent = () => {
    if (Number(recordsCount) > Number(recordList.length)) {
      return (
        <Button style={{ padding: 5, marginTop: 30, marginBottom: 60 }} onPress={loadMoreResults} >{t('STR_LABEL_LOAD_MORE')}</Button>
      )
    } else {
      return (<Text style={{ padding: 5, marginBottom: 35 }}></Text>);
    }
  }

  const goToPrintSummary = () => {
    // props.navigation.navigate('PrintRecordsSummary', {
    //   templateProperties: templateProperties,
    //   googleAccessToken: googleAccessToken
    // });
    if (Platform.OS !== 'web') {
      props.navigation.navigate('PrintRecordsSummary');
    } else {
      props.navigation.navigate('PrintChooseList', { printSides: "FRONT" });
    }


  }
  const cancelPrintSelection = () => {
    store.dispatch(setRecordsToPrintList(undefined));
    store.dispatch(setRecordsToPrintListData([]));
  }

  const renderItem = ({ item }) => {
    const cardSelected = async () => {

      if (item.syncError === undefined && item.isLocalRecord === true) {
        Alert.alert(
          t('STR_ERROR'),
          t('STR_ERROR_CANT_SELECT_RECORD_UNTIL_SYNC_ENDS')
        );
        return;
      }

      if (recordsToPrintList !== undefined) {
        setSelectedId(null)
        store.dispatch(setRecordSelectedId(undefined))

        if (recordsToPrintList.includes(item.recordId)) {
          store.dispatch(removeRecordToPrintList(item.recordId));
          store.dispatch(removeRecordToPrintListData(item.recordId));
          const newTemplateRenderRecord = recordsToPrintListData.filter(record => record.recordId != item.recordId);
          store.dispatch(setTemplateRenderRecords(newTemplateRenderRecord));
        } else {

          const deviceIsOnlineResult = await deviceIsOnline();
          if (deviceIsOnlineResult === false && item.localRecordId === undefined) {
            Alert.alert(
              t('STR_ERROR'),
              t('STR_ERROR_CANT_SELECT_CLOUD_RECORDS_FOR_PRINTING')
            );
            return;
          }

          if (recordsToPrintList.length >= 10) {
            Alert.alert(
              t('STR_ERROR'),
              t('STR_ERROR_CANT_SELECT_MORE_THAN_10_RECORDS_FOR_PRINTING')
            );
            return;
            return;
          }

          // Convert cloud or local URL to base64 string -- ***** DEPRECATED *****
          // const tplItems = templateProperties.items;
          // for (let index = 0; index < tplItems.length; index++) {
          //   const currentItem = tplItems[index];
          //   if (currentItem.type === "image") {
          //     // if (item.info[currentItem.id].indexOf('drive.google.com/file') !== -1) {
          //     //   const imgObjBase64 = await convertGoogleDriveImageToBase64(item.info[currentItem.id], googleAccessTokenState);
          //     //   item.info[currentItem.id] = imgObjBase64;
          //     // }
          //     // if (item.info[currentItem.id].indexOf('file:///data') !== -1) {
          //     //   const imgObjBase64 = await FileSystem.readAsStringAsync(item.info[currentItem.id], { encoding: FileSystem.EncodingType.Base64 });
          //     //   item.info[currentItem.id] = imgObjBase64;
          //     // }
          //   }
          // }

          store.dispatch(addRecordToPrintList(item.recordId));
          store.dispatch(addRecordToPrintListData(item));
        }

      } else {
        if (selectedId === item.recordId) {
          setSelectedId(null)
          store.dispatch(setRecordSelectedId(undefined))
        } else {
          setSelectedId(item.recordId)
          store.dispatch(setRecordSelectedId(item.recordId))
        }
      }

    }

    return (
      <Animated.View entering={BounceInDown.duration((item?.disableAnimation !== undefined && item?.disableAnimation === true) ? 0 : 1000)} style={styles.cardHolder} >
        <RecordCard
          onPress={cardSelected}
          templateProperties={templateProperties}
          googleAccessToken={googleAccessTokenState}
          info={item.info}
          recordId={item.recordId}
          isLocalRecord={item.localRecordId !== undefined}
          localRecordId={item?.localRecordId}
          syncError={item.syncError}
          newRecord={item?.newRecord}
          selected={selectedId === item.recordId}
          enableRecordSelection={(recordsToPrintList !== undefined)}
          recordSelectedToPrint={(recordsToPrintList !== undefined && recordsToPrintList.includes(item.recordId)) ? true : false}
          style={{ zIndex: 5 }}
        />
      </Animated.View>
    );

  };

  const loadMoreResults = () => {
    (async () => {
      // if already loading more, or all loaded, return
      if (loadingMore) {
        return;
      }
      // set loading more
      setLoadingMore(true);
      store.dispatch(setRecordsFrom(recordsFrom + 10))
      const nextRecords = await getRecords(templateSelectedId, recordsFrom + 10, searchQuery)
      setRecordList(recordList => {
        return [...recordList, ...nextRecords.recordsList.items]
      });
      setLoadingMore(false);
    })();
  }

  return (
    <View style={styles.container}>
      <Searchbar
        placeholder={t('STR_LABEL_SEARCH')}
        onChangeText={onChangeSearch}
        value={searchQuery}
        style={[styles.searchbar, { backgroundColor: theme.colors.textInputBackground }]}
        onIconPress={searchRecords}
        onSubmitEditing={searchRecords}
      />

      {dataSourceReady && templateProperties !== undefined && recordList !== undefined &&

        <FlatList
          style={(Platform.OS === 'web') ? { maxHeight: deviceHeight - 160, width: '100%' } : {}}
          contentContainerStyle={(deviceWidth > 1100) ? { alignItems: 'center', justifyContent: 'center' } : { alignItems: 'stretch', justifyContent: 'center' }}
          numColumns={Platform.OS === 'web' ? ((deviceWidth > 1100) ? 2 : 1) : 1}
          data={recordList}
          // keyExtractor={(item) => item.recordId}
          keyExtractor={(item, index) => {
            // return item.recordId.toString();
            return index.toString();
          }}
          renderItem={renderItem}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          // extraData={selectedId}
          onMomentumScrollBegin={() => { setOnEndReachedCalledDuringMomentum(false); }}
          onEndReachedThreshold={0.5}
          onEndReached={(distanceFromEnd) => {
            if (Platform.OS !== 'web'
              // && !onEndReachedCalledDuringMomentum
              && (Number(recordsCount) > Number(recordList.length))) {
              loadMoreResults();
              setOnEndReachedCalledDuringMomentum(true);
            }
          }}
          ListFooterComponent={() => (Platform.OS === 'web') ? <ListWebFooterComponent /> : <ListFooterComponent />}
          // initialNumToRender={10}
          windowSize={5}
          removeClippedSubviews={true}
        />

      }

      {
        dataSourceReady && templateProperties !== undefined && recordList !== undefined && recordsCount !== undefined &&
        <View style={styles.countBadgeHolder}>
          <Animated.View entering={FadeIn.duration(1000)}  >
            <Badge style={styles.countBadge}>{((recordsFrom + 10) > recordsCount ? recordsCount : recordsFrom + 10)} / {recordsCount}</Badge>
          </Animated.View>
        </View>
      }
      {loading === true &&
        <Loader loading={loading} />
      }
      <View style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} >
        {recordsToPrintList === undefined &&
          <Bottom
            navigation={props.navigation}
            options1={t('STR_LABEL_ADD_RECORD')}
            options2={t('STR_LABEL_EDIT_RECORD')}
            selected={selectedId}
          />
        }
        {recordsToPrintList !== undefined &&
          <View style={styles.bottomContainer} >
            <Button textColor={theme.colors.white} onPress={() => cancelPrintSelection()}>{t('STR_CANCEL')}</Button>
            <Button textColor={theme.colors.white} onPress={() => goToPrintSummary()} disabled={printBtnEnabled ? false : true}>{t('STR_LABEL_PRINT')}</Button>
          </View>
        }
      </View>
      {recordsCount === 0 && loading === false &&
        <EmptyList />
      }
    </View >
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingBottom: 20
  },
  searchbar: {
    width: '100%'
  },
  imageStyle: {
    width: 150,
    height: 100,
    marginTop: 20,
    marginBottom: 50,
    marginHorizontal: 8,
    alignSelf: 'center'

  },
  countBadgeHolder: {
    zIndex: 10,
    position: 'absolute',
    top: 55,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  countBadge: {
    // backgroundColor: '#D9D9D9',
    // backgroundColor: '#05AC72',
    backgroundColor: 'rgba(5,172,114, 0.6)',
    height: 30,
    width: 80,

    fontWeight: 'bold',
    fontSize: 14,
    zIndex: 11,
    ...Platform.select({
      ios: {
        borderRadius: 15,
        lineHeight: 30,
      },
      android: {
        borderRadius: 50,
      },
      default: {
        lineHeight: 30,
        borderRadius: 50,
      }
    })
  },
  bottomContainer: {
    width: '100%',
    backgroundColor: '#05AC72',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 60
  },
  cardHolder: {
    zIndex: 2,
    ...Platform.select({
      ios: {
      },
      android: {
      },
      default: {
        // width: 600
        maxWidth: '100%',
      }
    })
  },

});

const mapStateToProps = state => {
  return {
    // recordList: state.recordList,
    recordInEdition: state.recordInEdition,
    templateSelectedId: state.templateSelectedId,
    templateProperties: state.templateProperties,
    recordsFrom: state.recordsFrom,
    recordsToPrintList: state.recordsToPrintList,
    recordInsertData: state.recordInsertData,
    recordUpdateData: state.recordUpdateData,
    recordSynced: state.recordSynced,
    recordSyncError: state.recordSyncError,
    recordToRemove: state.recordToRemove,
    recordsToPrintListData: state.recordsToPrintListData,
    updateRecordDataKey: state.updateRecordDataKey
  }
}

export default connect(mapStateToProps, {})(RecordScreen);