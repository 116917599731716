
import React, { useState, useEffect } from 'react';
import { ptToPx } from '../../../../helpers/cardRender/utils';
import { Skia, Rect, Group, Image, useImage, Circle, Fill } from "@shopify/react-native-skia";

export type props = {
    id: number,
    xmlObj: any,
    imageBase64Data: string | null
};

const BackgroundItem: React.FC<props> = ({
    id,
    xmlObj,
    imageBase64Data
}) => {
    const startX: number = ptToPx(xmlObj.position.pos1.x);
    const startY: number = ptToPx(xmlObj.position.pos1.y);
    let endX: number;
    let endY: number;
    let iHaveImage: boolean = false;
    let bkgColor: string = "00FFFFFF";
    let image: any = null;

    if (Array.isArray(xmlObj.background.type)) {
        if (xmlObj.background.type[0] === "solid" || xmlObj.background.type[1].solid !== null) {
            if (xmlObj.background?.type[1]?.solid?.color[0] !== undefined) {
                bkgColor = xmlObj.background.type[1].solid.color[0]
            }else if (xmlObj.background?.type[1]?.solid?.color !== undefined && typeof xmlObj.background?.type[1]?.solid?.color !== 'object') {
                bkgColor = xmlObj.background.type[1].solid.color
            }
        }
    }

    if (xmlObj?.paperSize?.width) {
        endX = xmlObj.paperSize.width;
    } else {
        endX = ptToPx(xmlObj.position.pos2.x);
    }

    if (xmlObj?.paperSize?.height) {
        endY = xmlObj.paperSize.height;
    } else {
        endY = ptToPx(xmlObj.position.pos2.y)
    }

    const imageWidth: number = endX - startX;
    const imageHeight: number = endY - startY;

    if (imageBase64Data !== null) {
        iHaveImage = true;

        const imageBase64 = Skia.Image.MakeImageFromEncoded(Skia.Data.fromBase64(imageBase64Data));
        //image = useImage(imageBase64Data);
        image = imageBase64;
    }

    if (xmlObj.background?.type && xmlObj.background?.type === "none") {
        bkgColor = "transparent";
        iHaveImage = false;
    }

    if (!iHaveImage) {
        return (
            <Rect
                x={startX}
                y={startY}
                width={imageWidth}
                height={imageHeight}
                color={bkgColor}
            />
        );
    } else {
        return (
            <Group>
                <Fill color={bkgColor} />
                {image && (
                    <Image
                        image={image}
                        fit="cover"
                        x={startX}
                        y={startY}
                        width={imageWidth}
                        height={imageHeight}
                        blendMode="srcATop"
                    />
                )}
            </Group>
        );
    }
};

export default BackgroundItem;