
const svg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="84.27px" height="84.27px" viewBox="0 0 84.27 84.27" enable-background="new 0 0 84.27 84.27" xml:space="preserve">
<polygon fill-rule="evenodd" clip-rule="evenodd" points="42.137,17.556 54.426,26.931 66.715,36.341 62.01,51.511 57.34,66.714 
	42.137,66.714 26.932,66.714 22.26,51.511 17.555,36.341 29.846,26.931 "/>
</svg>`
export { svg };
