// import { registerRootComponent } from 'expo';
// import { Provider } from 'react-redux'
// import App from './App';
// import { store } from './utils/Reducer'
// import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
// import { I18nextProvider } from 'react-i18next';
// import i18n from './translations/i18n.config';

// const theme = {
//   ...DefaultTheme,
//   colors: {
//     ...DefaultTheme.colors,
//     primary: '#05AC72',
//     secondary: '#646464',
//     white: '#fff',
//   },
// };

// const RNRedux = () => (
//   <Provider store={store}>
//     <PaperProvider theme={theme}>
//       <I18nextProvider i18n={i18n}>
//           <App />
//       </I18nextProvider>
//     </PaperProvider>
//   </Provider>
// )
// // registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// // It also ensures that whether you load the app in Expo Go or in a native build,
// // the environment is set up appropriately
// registerRootComponent(RNRedux);


import 'expo/build/Expo.fx';
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import { createRoot } from 'react-dom/client';
import { createElement } from 'react';
import { Platform } from 'react-native';

import { Provider } from 'react-redux'
import App from './App';
import { store } from './utils/Reducer'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n.config';

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#05AC72',
    secondary: '#646464',
    white: '#fff',    
    black: '#000',    
    cardBackground: "#e1e2e3",
    textInputBackground: "#EEEEEE",
    lightGrey: "#969696",
    grey: "#646464",
    darkGrey: "#323232",
    warning: "#FFC300",
    error: "#FF3C3C",
  },
  roundness: 0
};

const RNRedux = () => (
  <Provider store={store}>
    <PaperProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
          <App />
      </I18nextProvider>
    </PaperProvider>
  </Provider>
)

// @see https://github.com/expo/expo/issues/18485
if ('web' === Platform.OS) { 
  const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));
  rootTag.render(createElement(RNRedux));
} else {
  registerRootComponent(RNRedux);
}