import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet, View, ScrollView, BackHandler, Text, Alert, RefreshControl } from 'react-native'; // Alert
import { useIsFocused, useNavigation, CommonActions } from "@react-navigation/native";
import { getTemplates, getTemplatesWeb, removeTemplate, getPrinterResources, getTemplateById } from '../../Rest'
import { connect } from 'react-redux';
import { store, cleanRecord, setRecordSelectedId, setRecordList, setTemplateProperties } from '../../../utils/Reducer';
import Card from '../../components/TemplateCard';
import Loader from '../../components/Loader';
import { setTemplateSelectedId } from '../../../utils/Reducer';
import { useFocusEffect } from '@react-navigation/native';
import Animated, { BounceInDown } from 'react-native-reanimated';
import { Badge, useTheme, Button } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTranslation } from 'react-i18next';
import renderDB from '../../../helpers/db/models/render'
import globalVars from '../../../helpers/store';
import { sleep, deviceIsOnline } from '../../../helpers/utils';
import { findProperty } from '../../../helpers/cardRender/utils';

function TemplateScreen(props) {
  //  throw new Error("My first Sentry error!");

  const theme = useTheme();
  const navigation = useNavigation();
  // const { templateList } = props

  const { t, i18n } = useTranslation();
  const { templateInEdition } = props
  const [loading, setLoading] = useState(false);
  const [sortDesc, setSortDesc] = useState(true);
  const [reloadCtr, setReloadCtr] = useState(true);
  const [templateList, setTemplateList] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loaderText, setLoaderText] = useState(undefined);

  const isFocused = useIsFocused();

  useEffect(
    () => {
      (async () => {
        setLoading(true);
        await loadTemplates();
        setLoading(false);
      })();
    },
    [sortDesc, reloadCtr]
  )
  // useEffect(
  //   () => {
  //     (async () => {
  //       setLoading(true);
  //       await loadTemplates();
  //       setLoading(false);
  //     })();
  //   },
  //   []
  // )

  const loadTemplates = async () => {
    setLoaderText("Downloading templates...")
    store.dispatch(cleanRecord());
    store.dispatch(setRecordSelectedId(undefined));
    store.dispatch(setRecordList(undefined));

    if (Platform.OS !== 'web') {
      renderDB.destroyAll();
    }
    globalVars.controller = new AbortController();
    let templateData = [];
    if (Platform.OS === 'web') {
      templateData = await getTemplatesWeb(sortDesc);
    } else {
      templateData = await getTemplates(sortDesc);
    }
    setTemplateList(templateData);

    if (Platform.OS !== 'web') {
      const deviceIsOnlineResult = await deviceIsOnline();
      if (deviceIsOnlineResult) {
        // Get resources
        setLoaderText("Downloading resources...")
        await getPrinterResources()

      }
    }

    setLoaderText(undefined)
    return;
  };

  const onRefresh = async () => {
    setLoading(true);
    await loadTemplates();
    setLoading(false);
    setRefreshing(false)
  };

  // PREVENT GO BACK ON THIS SCREEN
  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        // Return true to stop default back navigaton
        // Return false to keep default back navigaton
        return true;
      };

      // Add Event Listener for hardwareBackPress
      BackHandler.addEventListener(
        'hardwareBackPress',
        onBackPress
      );
      return () => {
        // Once the Screen gets blur Remove Event Listener
        BackHandler.removeEventListener(
          'hardwareBackPress',
          onBackPress
        );
      };
    }, []),
  );

  const confirmDelete = (templateID) => {
    Alert.alert(
      t('STR_ALERT_ATTENTION'),
      t('STR_ALERT_REMOVE_TEMPLATE_CONFIRM'),
      [
        {
          text: t('STR_CANCEL'),
          style: "cancel"
        },
        {
          text: t('STR_YES'), onPress: () => deleteTemplate(templateID)
        }
      ]
    );
  }

  const deleteTemplate = async (templateID) => {
    const removeResult = await removeTemplate(templateID);
    if (removeResult === true) {
      setReloadCtr(!reloadCtr);
    }
  }

  const handleTemplateSelected = async (template) => {

    let databaseColumn = undefined;
    if (template.properties !== undefined) {
      databaseColumn = findProperty(JSON.parse(template.properties), "databaseColumn");
    } else {
      if (template.templateProperties !== undefined) {
        databaseColumn = findProperty(JSON.parse(template.templateProperties), "databaseColumn");
      }
    }

    if (databaseColumn === undefined || databaseColumn == "") {
      store.dispatch(setTemplateSelectedId(template.templateId))
      const templateProperties = await getTemplateById(template.templateId);
      setTemplateProperties(templateProperties);
      globalVars.noDb = true
      props.navigation.navigate('NewRecord')
    } else {
      store.dispatch(setTemplateSelectedId(template.templateId))
      props.navigation.navigate('Records', {
        templateId: template.templateId
      });
    }

  }
  return (
    <View style={{ height: '100%' }}>
      <View style={styles.sortContainer}>
        <Badge
          onPress={() => setSortDesc(!sortDesc)}
          size={50}
          style={{ backgroundColor: 'rgba(5,172,114, 0.6)' }}>
          <MaterialCommunityIcons style={styles.filterBtnIcon} name={sortDesc === true ? 'sort-descending' : 'sort-ascending'} size={25} color={theme.colors.white} />
        </Badge>
      </View>

      <ScrollView contentContainerStyle={{ flexGrow: 1 }} refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }>
        <View style={styles.container}>
          {templateList !== undefined &&
            <View style={styles.CardContainer}>
              {templateList.map((template, index) => (
                <Animated.View style={styles.CardHolder} key={index} entering={BounceInDown.duration(((Number(index) * 250) + 1000))} >
                  <Card onPress={() => handleTemplateSelected(template)}
                    key={index}
                    text={template.name}
                    image={template.thumbnail}
                    id={template.templateId}
                    updateDate={template.updateDate}
                    confirmDelete={confirmDelete}
                  />
                </Animated.View>
              ))}
            </View>
          }
        </View>
        {loading === true &&
          <Loader loading={loading} text={loaderText} />
        }
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  CardHolder: {
    ...Platform.select({
      ios: {
        width: '100%',
        alignItems: "center",
        justifyContent: "center",
      },
      android: {
        width: '100%',
        alignItems: "center",
        justifyContent: "center",
      },
      default: {
        width: 250,
        marginHorizontal: 10
      }
    })
  },
  CardContainer: {
    marginTop: 20,
    ...Platform.select({
      ios: {
        width: '100%',
        alignItems: "center",
        justifyContent: "center",
      },
      android: {
        width: '100%',
        alignItems: "center",
        justifyContent: "center",
      },
      default: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        flexWrap: 'wrap',
      }
    })
  },
  sortContainer: {
    ...Platform.select({
      ios: {
        position: 'absolute',
      },
      android: {
        position: 'absolute',
      },
      default: {
        position: 'fixed',
      }
    }),    
    right: 5,
    bottom: 5,
    zIndex: 2
  }
});

const mapStateToProps = state => {
  return {
    templateInEdition: state.templateInEdition,
  }
}

export default connect(mapStateToProps, {})(TemplateScreen);