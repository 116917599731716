import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Platform,
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { webAPIUser, webAPIPass } from '../../Rest';
import { useTranslation } from 'react-i18next';
import configs from '../../../helpers/config';
import base64 from 'react-native-base64';

const ResetScreen = (props) => {
  const { t, i18n } = useTranslation();
  const { userRegist } = props

  useEffect(
    () => {
    }, 
    [userRegist]
  )

  const [values, setValues] = useState({ masterKey: '', password: '', confirmPassword: '', oemId: 35 })

  const handleSubmitPress = () => {
    if (values.masterKey === '') {
      alert(t('STR_ALERT_FILL_MASTERKEY'));
      return;
    }
    if (values.password === '') {
      alert(t('STR_ALERT_FILL_PASSWORD'));
      return;
    }
    if (values.password !== values.confirmPassword) {
      alert(t('STR_ALERT_PASSWORDS_NOT_MATCH'));
      return;
    } 

    fetch(configs.APPUrls.webApi + '/v1/account/reset', {  
      method: 'POST',
      headers: {
        'Authorization': 'Basic ' + base64.encode(webAPIUser + ":" + webAPIPass),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'email': userRegist.email, 'newPassword': values.password, 'oemId': values.oemId, 'masterKey': values.masterKey })
    })
      .then(response => {
        if (response.ok) {
          props.navigation.navigate('SignIn');
          return
        }
      });

  }

  return (
    <View style={styles.mainBody}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <View style={styles.formHolder}>
          <KeyboardAvoidingView enabled behavior="position">
            <View style={{ alignItems: 'center' }}>
              <Icon name="person-circle-outline" size={150} color="#000000" />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserEmail) => setValues({ ...values, masterKey: UserEmail })}
                placeholder={t('STR_LABEL_MASTER_KEY')}
                placeholderTextColor="#8b9cb5"
                autoCapitalize="none"
                keyboardType="email-address"
                returnKeyType="next"
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserPass) => setValues({ ...values, password: UserPass })}
                placeholder={t('STR_LABEL_PASSWORD')}
                placeholderTextColor="#8b9cb5"
                keyboardType="default"
                onSubmitEditing={Keyboard.dismiss}
                blurOnSubmit={false}
                secureTextEntry={true}
                underlineColorAndroid="#f000"
                returnKeyType="next"
                autoCapitalize='none'
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserPass) => setValues({ ...values, confirmPassword: UserPass })}
                placeholder={t('STR_LABEL_PASSWORD_CONFIRM')}
                placeholderTextColor="#8b9cb5"
                keyboardType="default"
                onSubmitEditing={Keyboard.dismiss} 
                blurOnSubmit={false}
                secureTextEntry={true}
                underlineColorAndroid="#f000"
                returnKeyType="next"
                autoCapitalize='none'
              />
            </View>
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={handleSubmitPress}>
              <Text style={styles.buttonTextStyle}>{t('STR_RESET')}</Text>
            </TouchableOpacity> 
          </KeyboardAvoidingView>
        </View>
      </ScrollView>
    </View>
  );
};

const mapStateToProps = state => {
  return {
    userRegist: state.userRegist,
  }
}

export default connect(mapStateToProps, {})(ResetScreen)


const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignContent: 'center',
  },
  formHolder: {
    ...Platform.select({
      ios: {
        width: '100%',
      },
      android: {
        width: '100%',
      }, 
      default: {
        width: 500,
      }
    }),
    alignSelf: 'center'
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#05AC72',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#05AC72',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
});