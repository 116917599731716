import React from "react";
import { View, StyleSheet, Platform, Alert } from "react-native";
import { Button, useTheme } from "react-native-paper";
import { createRecord, updateRecord } from "../Rest";
import {
    store, submitRecordUpdate, submitRecordInsert,
    addRecordsCount, removeLastRecordFromList, removeRecordsFrom,
    setLocalRecordId, setRecordSelectedId, setLocalRecordOperationType,
    updateRecordSelected, setRecordSyncError, removeRecordsCount,
    addRecordToPrintList, addRecordToPrintListData, setRecordsToPrintList, setRecordsToPrintListData
} from '../../utils/Reducer';
import globalVars from '../../helpers/store';
import { useTranslation } from 'react-i18next';
import recordDB from '../../helpers/db/models/record'
import * as FileSystem from 'expo-file-system';
import { deviceIsOnline, sleep } from '../../helpers/utils';

const Bottom = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const alertBox = (title, body) => {
        if (Platform.OS === 'web') {
            alert(title + '\n' + body);
        } else {
            Alert.alert(
                title,
                body
            );
        }
        return;
    }

    async function convertLocalTmpFile(base64Data) {
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const base64Code = base64Data.split("data:image/png;base64,")[1];
                    const filename = FileSystem.cacheDirectory + Math.floor(Date.now() * Math.random()).toString() + ".png";
                    await FileSystem.writeAsStringAsync(filename, base64Code, {
                        encoding: FileSystem.EncodingType.Base64,
                    });
                    resolve(filename);
                } catch (e) {
                    resolve("");
                }
            })();
        });
    }

    const handleOnPress = (toPrint) => {
        props.loading(true);
        (async () => {
            if (globalVars.noDb) {
                store.dispatch(setRecordsToPrintList([]))
                store.dispatch(setRecordsToPrintListData([]))
            }

            const deviceIsOnlineResult = await deviceIsOnline();
            //console.log(props.record.info);
            let haveMissingFields = false;
            let missingFields = [];

            let record = {
                recordId: globalVars.noDb ? 0 : props.record.recordId,
                templateId: props.record.templateId,
                info: {},
                templateProperties: props.templateProperties,
                accessToken: props.accessToken
            }

            Object.keys(props.record.info).forEach(function (key, index) {
                record.info[key] = props.record.info[key];
                for (let itemIndex = 0; itemIndex < props.templateProperties.items.length; itemIndex++) {
                    const currentItem = props.templateProperties.items[itemIndex];
                    if (currentItem.id === key) {
                        if (currentItem.required === true && props.record.info[key].trim() === "") {
                            haveMissingFields = true;
                            missingFields.push(currentItem.displayName);
                        }
                    }
                }

            });

            if (haveMissingFields) {
                alertBox(
                    t('STR_ERROR'),
                    t('STR_ALERT_MISSING_FIELDS', { missingFields: missingFields.join(",") })
                );
                props.loading(false);
                return;
            } else {

                if (props.insertMode === true) {

                    if (Platform.OS === 'web') {

                        const createResponse = await createRecord(record);
                        if (createResponse !== false) {
                            record["recordId"] = createResponse;
                            store.dispatch(updateRecordSelected({ field: "recordId", value: createResponse }))
                            store.dispatch(submitRecordInsert(record));
                        } else {
                            // TODO - Add error handler 
                        }

                    } else {

                        if (globalVars.noDb) {
                            await sleep(100);
                            store.dispatch(addRecordToPrintList(props.record.recordId));
                            store.dispatch(addRecordToPrintListData(props.record));
                            props.navigation.navigate('PrintRecordsSummary');
                            return;
                        }

                        for (const key in record.info) {
                            if (
                                record.info[key].indexOf('drive.google.com') === -1
                                && record.info[key] != ""
                                && record.info[key].indexOf('base64,') !== -1) {
                                const imageURL = await convertLocalTmpFile(record.info[key]);
                                record.info[key] = imageURL;
                                store.dispatch(updateRecordSelected({ field: key, value: imageURL }))
                            }
                        }

                        record.recordId = Math.floor(Date.now() * Math.random()); // Temporary record ID
                        const recordData = {
                            newRecord: true,
                            templateId: props.record.templateId,
                            recordInfo: JSON.stringify(record),
                            recordId: record.recordId
                        }
                        const recordObj = new recordDB(recordData);
                        const newRecord = await recordObj.save();
                        record.newRecord = true;
                        record.localRecordId = newRecord.id; // Local record ID 
                        record.info = JSON.stringify(record.info);
                        store.dispatch(updateRecordSelected({ field: "localRecordId", value: record.localRecordId }))
                        store.dispatch(submitRecordInsert(record));
                        store.dispatch(addRecordsCount());
                        // store.dispatch(removeLastRecordFromList());      
                        createRecord(record, true);
                    }

                } else {

                    if (Platform.OS === 'web') {
                        await updateRecord(record);
                        store.dispatch(submitRecordUpdate());
                    } else {

                        for (const key in record.info) {
                            if (
                                record.info[key].indexOf('drive.google.com') === -1
                                && record.info[key] != ""
                                && record.info[key].indexOf('base64,') !== -1) {
                                const imageURL = await convertLocalTmpFile(record.info[key]);
                                record.info[key] = imageURL;
                                store.dispatch(updateRecordSelected({ field: key, value: imageURL }))
                            }
                        }

                        const operationData = {
                            recordId: props.record.recordId,
                            operationType: 'update'
                        }

                        if (deviceIsOnlineResult) {

                            record.recordId = props.record.recordId; // Temporary record ID

                            const recordData = {
                                newRecord: false,
                                templateId: props.record.templateId,
                                recordInfo: JSON.stringify(record)
                            }

                            if (props.record?.isLocalRecord === true) {

                                let localRecordData = await recordDB.query({
                                    where: {
                                        recordId_eq: record.recordId
                                    }
                                });
                                let localDbId = localRecordData[0].id;
                                await recordDB.destroy(localDbId);
                                recordData.newRecord = true;
                                recordData.recordId = record.recordId;
                                record.localRecordId = record.recordId; // Local record ID 
                            } else {
                                recordData.recordId = record.recordId;
                            }

                            const newRecord = await recordDB.create(recordData);

                            if (props.record?.isLocalRecord === true) {

                                store.dispatch(updateRecordSelected({ field: "localRecordId", value: record.localRecordId }))
                                store.dispatch(submitRecordUpdate());
                                store.dispatch(addRecordsCount());
                                // store.dispatch(removeLastRecordFromList());      
                                createRecord(record, true);

                            } else {

                                record.localRecordId = newRecord.id; // Local record ID 
                                // record.info = JSON.stringify(record.info); 
                                const localDataObj = {
                                    recordId: props.record.recordId,
                                    localRecordId: newRecord.id
                                }

                                store.dispatch(updateRecordSelected({ field: "localRecordId", value: record.localRecordId }))
                                store.dispatch(submitRecordUpdate());
                                store.dispatch(setLocalRecordOperationType(operationData))
                                store.dispatch(setRecordSelectedId(undefined))
                                store.dispatch(setLocalRecordId(localDataObj));
                                updateRecord(record, true);
                            }

                        } else {

                            const localDataObj = {
                                recordId: record.recordId,
                                localRecordId: record.recordId
                            }
                            store.dispatch(setLocalRecordId(localDataObj));
                            store.dispatch(updateRecordSelected({ field: "localRecordId", value: record.recordId }))
                            store.dispatch(submitRecordUpdate());
                            store.dispatch(setLocalRecordOperationType(operationData));
                            store.dispatch(setRecordSelectedId(undefined));

                            let localRecordData = await recordDB.query({
                                where: {
                                    recordId_eq: record.recordId
                                }
                            });
                            let localDbId = localRecordData[0].id;

                            const localRecord = await recordDB.find(localDbId)
                            localRecord.recordInfo = JSON.stringify(record)
                            localRecord.save();
                            const recordData = {
                                newRecord: true,
                                tmpRecordId: record.recordId,
                                error: "Device is offline"
                            }
                            store.dispatch(setRecordSyncError(recordData));
                        }

                    }

                }

                props.loading(false);
                props.navigation.goBack()

            }

        })();
    }
    return (
        <View style={styles.container} >
            <Button textColor={theme.colors.white} onPress={() => handleOnPress(false)}>{props.option1}</Button>
            {/* <Button color='#ffffff' onPress={() => handleOnPress(true)}>{props.option2}</Button> */}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        flex: 1,
        backgroundColor: '#05AC72',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60
    }
});

export default Bottom;