
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Text, View, Dimensions, Image, Button, TextInput } from 'react-native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import globalVars from '../../../helpers/store';
import DateTimePicker from '@react-native-community/datetimepicker';
import { TouchableOpacity } from 'react-native';
import { useTheme } from 'react-native-paper';
import { sleep } from '../../../helpers/utils';
import { deviceIsOnline } from '../../../helpers/utils';
import { getGoogleCredentials, convertGoogleDriveImageToBase64 } from '../../Rest';
import ImageComponent from '../../components/ImageComponent';
import uuid from 'react-native-uuid';
import { FAB } from 'react-native-paper';
import { store, setGoogleAccessToken } from '../../../utils/Reducer';

export type props = {
    record: any,
    propertyId: string
    placeholder: string,
    onPress: any,
    value: string,
    templateProperties: any
    templateId: number
    googleAccessToken: string
};

const RecordImage: React.FC<props> = ({
    record,
    propertyId,
    placeholder,
    onPress,
    value,
    templateProperties,
    templateId,
    googleAccessToken
}) => {

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [date, setDate] = useState<any>(new Date());
    const [imageLoading, setImageLoading] = useState<boolean>(true);
    const [imageURI, setImageURI] = useState<string | undefined>(undefined)
    const deviceWidth = Math.round(Dimensions.get('window').width)
    const calculatedItemWidth = (deviceWidth / 2) - 25

    useEffect(
        () => {
            (async () => {

                let currentGoogleAccessToken = null;
                const deviceIsOnlineResult = await deviceIsOnline();
                if (deviceIsOnlineResult && record?.isLocalRecord === undefined && !globalVars.noDb) {

                    if (templateProperties.dataSource?.type === 'GOOGLESHEETS') {

                        setImageLoading(true);

                        if (googleAccessToken === undefined) {
                            const googleCredentialsReturn = await getGoogleCredentials(templateId);
                            currentGoogleAccessToken = googleCredentialsReturn.accessToken;
                        } else {
                            currentGoogleAccessToken = googleAccessToken;
                        }

                        const imgObjBase64 = await convertGoogleDriveImageToBase64(value, currentGoogleAccessToken);

                        if (imgObjBase64 !== false && globalVars.cardLoadCancel === false) {
                            setImageURI(imgObjBase64);
                        }
                    } else {
                        if (value !== '' && globalVars.cardLoadCancel === false) {
                            setImageURI(value);
                        }
                        setImageLoading(false);
                    }

                }
                setImageLoading(false);

            })();
        },
        []
    )

    useEffect(
        () => {
            (async () => {
                if (record !== undefined) {

                    if (record.info[propertyId] !== undefined &&
                        record.info[propertyId] !== '' &&
                        record.info[propertyId] !== imageURI &&
                        record.info[propertyId].indexOf('drive.google.com') === -1
                    ) {
                        setImageURI(record.info[propertyId]);
                    }
                }
            })();
        },
        [record]
    )

    return (
        <View style={styles.imageContainer}>

            {imageLoading &&
                <View>
                    <Image style={{
                        width: (Platform.OS !== 'web' ? calculatedItemWidth : 250),
                        aspectRatio: 4 / 4,
                        height: 180,
                        alignSelf: 'center',
                        zIndex: 1
                    }} source={require('../../../assets/loading.gif')} resizeMode='center' />
                </View>
            }

            {!imageLoading && imageURI === undefined &&
                <Image source={require('../../../assets/takePhoto.png')}
                    style={{
                        width: (Platform.OS !== 'web' ? calculatedItemWidth : 250),
                        aspectRatio: 4 / 4,
                        height: 180,
                        alignSelf: 'center',
                    }}
                    resizeMode="contain"
                />
            }

            {!imageLoading && imageURI !== undefined &&
                <View>
                    <Image
                        style={{
                            alignSelf: 'center',
                            width: (Platform.OS !== 'web' ? calculatedItemWidth : 250),
                            aspectRatio: 4 / 4,
                            height: 180,
                            maxWidth: '100%'
                        }}
                        source={{ uri: imageURI }}
                        resizeMode="contain"
                    />
                </View>
            }

            <View style={styles.fabHolder}>
                {Platform.OS !== 'web' &&
                    <FAB
                        theme={{ roundness: 10 }}
                        style={styles.fab}
                        color="#FFF"
                        icon="camera"
                        // size="small"
                        onPress={() => onPress('camera')}
                    />
                }
                <FAB
                    theme={{ roundness: 10 }}
                    style={styles.fab}
                    color="#FFF"
                    icon="folder-image"
                    // size="small"
                    onPress={() => onPress('gallery')}
                />
            </View>

            <View style={styles.imageOverlay}>
                <View style={styles.textHolder}>
                    <Text>{placeholder}</Text>
                </View>
            </View>
        </View >
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width)
const calculatedItemWidth = (deviceWidth / 2) - 20
const styles = StyleSheet.create({
    imageContainer: {
        borderColor: "#646464",
        borderWidth: 1,
        borderStyle: 'solid',
        ...Platform.select({
            ios: {
                maxWidth: calculatedItemWidth,
                width: calculatedItemWidth,
                // borderRadius: 10
            },
            android: {
                maxWidth: calculatedItemWidth,
                width: calculatedItemWidth,
                // borderRadius: 10
            },
            default: {
                width: 250,
                maxWidth: '100%'
            }
        })
    },
    imageOverlay: {
        zIndex: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },

    fabHolder: {
        // marginTop: 20,
        // marginBottom: 20,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 10,
        zIndex: 3
    },
    fab: {
        backgroundColor: "#05AC72",
        display: 'flex',
        marginHorizontal: 5,
        width: 55,
        height: 55
    },
    textHolder: {
        zIndex: 3,
        position: 'absolute',
        alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        height: 22,
        // borderTopRightRadius: 10,
        // borderTopLeftRadius: 10
    }

});

const mapStateToProps = (
    state: {
        templateProperties: any,
        googleAccessToken: string
    }
) => {
    return {
        templateProperties: state.templateProperties,
        googleAccessToken: state.googleAccessToken
    }
}

export default connect(mapStateToProps, {})(RecordImage);
