import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, Dimensions, StyleSheet, View, ScrollView, Image, TouchableOpacity } from 'react-native';
import Animated, { BounceInDown, FadeIn, FadeOut, Layout, ZoomOutUp } from 'react-native-reanimated';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { getPrintJobHistory } from '../../Rest';
import { useTranslation } from 'react-i18next';
import { useIsFocused } from '@react-navigation/native';
import PrintJobCard from '../../components/PrintJobCard';
import EmptyList from '../../components/global/emptyList';
import { FlatList } from 'react-native-gesture-handler';
import { Text, Dialog, Button, Searchbar, Chip, Subheading } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { getPrinterStates } from '../../../helpers/utils';

export type props = {
};

interface Item {
    id: number,
    jobId: string,
    printerSn: string,
    jobStatus: string,
    jobInfo: string,
    recordId: number,
    recordThumbnail: string,
    recordState: number,
    recordStateInfo: string
}

const PrintJobsHistory: React.FC<props> = ({
}) => {
    const { t, i18n } = useTranslation();
    const initialMode = useRef<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [printJobHistoryData, setPrintJobHistoryData] = useState<Item[]>([]);
    const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState<boolean>(true);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [recordsFrom, setRecordsFrom] = useState<number>(0);
    const [recordsTotal, setRecordsTotal] = useState<number>(0);
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedStates, setSelectedStates] = useState<string[]>([]);
    const deviceWidth = Math.round(Dimensions.get('window').width)
    const deviceHeight = Math.round(Dimensions.get('window').height)

    let avaliableStates: {
        hexCode: string;
        code: string;
        description: string;
        label: string;
    }[] = getPrinterStates();

    useEffect(() => {
        initialMode.current = false;
    });

    const isFocused = useIsFocused();

    const addNewItem = useCallback((itemToPush: Item) => {
        setPrintJobHistoryData(printJobHistoryData => {
            const nextItemId = (printJobHistoryData[printJobHistoryData.length - 1]?.id ?? 0) + 1;
            return [...printJobHistoryData,
            {
                id: nextItemId,
                jobId: itemToPush.jobId,
                printerSn: itemToPush.printerSn,
                jobStatus: itemToPush.jobStatus,
                jobInfo: itemToPush.jobInfo,
                recordId: itemToPush.recordId,
                recordThumbnail: itemToPush.recordThumbnail,
                recordState: itemToPush.recordState,
                recordStateInfo: itemToPush.recordStateInfo
            }];
        });
    }, []);

    const loadPrintJobHistory = (start: number, limit: number, isReset: boolean = false, isLoadMore: boolean = false) => {
        (async () => {
            setLoading(true);

            let printJobsData: any;

            if (isReset) {
                printJobsData = await getPrintJobHistory(start, limit);
            } else {
                printJobsData = await getPrintJobHistory(start, limit, searchQuery, selectedStates);
            }

            if (!isLoadMore) {
                setPrintJobHistoryData([]);
            }

            const printJobsDataItemsCount = printJobsData.count;
            setRecordsTotal(printJobsDataItemsCount);

            if (printJobsData?.items?.length === undefined){
                setLoading(false);
                return;
            }

            for (let printJobIndex = 0; printJobIndex < printJobsData.items.length; printJobIndex++) {
                const currentJob = printJobsData.items[printJobIndex];
                let dataToPush: {
                    id: number,
                    jobId: string,
                    printerSn: string,
                    jobStatus: string,
                    jobInfo: string,
                    jobDate: string,
                    recordId: number,
                    recordThumbnail: string,
                    recordState: number,
                    recordStateInfo: string,
                } = {
                    id: 0,
                    jobId: currentJob.jobId,
                    printerSn: currentJob.printerSn,
                    jobStatus: currentJob.jobStatus,
                    jobInfo: currentJob.jobInfo,
                    jobDate: currentJob.jobDate,
                    recordId: currentJob.recordId,
                    recordThumbnail: currentJob.cloudJobRecordThumbnail,
                    recordState: currentJob.recordStatus,
                    recordStateInfo: currentJob.recordStatusInfo
                }
                addNewItem(dataToPush);
            }
            setLoading(false);

        })();
    }

    const loadMoreResults = () => {
        (async () => {
            if (loadingMore) {
                return;
            }
            setLoadingMore(true);
            loadPrintJobHistory(recordsFrom, 10, false, true);
            setRecordsFrom(recordsFrom + 10);
            setLoadingMore(false);
        })();
    }

    useEffect(
        () => {
            (async () => {
                if (isFocused) {
                    loadPrintJobHistory(0, 10)
                    setRecordsFrom(0 + 10)
                }
                else {
                    setLoading(false);
                }
            })();
        },
        []
    )

    const renderItem = (flatItem: any) => {
        let item: Item = flatItem.item;
        return (
            <Animated.View entering={BounceInDown.duration(1000)} style={styles.cardHolder} >
                <PrintJobCard
                    jobId={item.jobId}
                    printerSn={item.printerSn}
                    printJobRecordThumbnail={item.recordThumbnail}
                    recordId={item.recordId}
                    recordState={item.recordState}
                    recordStateInfo={item.recordStateInfo}
                ></PrintJobCard>
            </Animated.View>
        );
    };

    const ListWebFooterComponent = () => {
        if (Number(recordsTotal) > Number(printJobHistoryData.length)) {
          return (
            <Button style={{ padding: 5, marginTop: 30, marginBottom: 60 }} onPress={loadMoreResults} >{t('STR_LABEL_LOAD_MORE')}</Button>
          )
        } else {
          return (<Text style={{ padding: 5, marginBottom: 35 }}></Text>);
        }
      }
    const ListFooterComponent = () => {
        if (Number(recordsTotal) > Number(printJobHistoryData.length)) {
            return (
                <Image style={styles.imageStyle} source={require('../../../assets/loading_dots.gif')} resizeMode='contain' />
            )
        } else {
            return (<Text></Text>);
        }
    }
    const showFilters = () => setFilterVisible(true);
    const hideFilters = () => setFilterVisible(false);
    const onChangeSearch = (query: string) => {
        setSearchQuery(query);
    };

    const toogleChipFilter = (code: string) => {
        if (selectedStates.includes(code)) {
            setSelectedStates(currentItems => {
                return currentItems.filter(item => item !== code);
            });
        } else {
            setSelectedStates(current => [...current, code]);
        }
    };
    const clearFilters = () => {
        setSearchQuery('');
        setSelectedStates([]);
        setFilterVisible(false);
        loadPrintJobHistory(0, 10, true);
        setRecordsFrom(0 + 10);
    };
    const SearchWithFilters = () => {
        setFilterVisible(false);
        loadPrintJobHistory(0, 10);
        setRecordsFrom(0 + 10);
    };

    return (
        <View style={styles.container}>
            <Loader loading={loading} />
            {printJobHistoryData.length === 0 && loading === false &&
                <EmptyList />
            }
            {printJobHistoryData.length > 0 &&
                <FlatList
                    style={(Platform.OS === 'web') ? { overflow: 'auto', maxHeight: deviceHeight - 120, width: '100%' } : {}}
                    contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
                    numColumns={Platform.OS === 'web' ? 2 : 1}
                    data={printJobHistoryData}
                    keyExtractor={(item, index) => {
                        return item.id.toString();
                    }}
                    renderItem={renderItem}
                    onMomentumScrollBegin={() => { setOnEndReachedCalledDuringMomentum(false); }}
                    onEndReachedThreshold={0.5}
                    onEndReached={(distanceFromEnd) => {
                        if (!onEndReachedCalledDuringMomentum
                            && (Number(recordsTotal) > Number(printJobHistoryData.length))) {
                            loadMoreResults();
                            setOnEndReachedCalledDuringMomentum(true);
                        }
                    }}
                    ListFooterComponent={() => (Platform.OS === 'web') ? <ListWebFooterComponent /> : <ListFooterComponent />}
                />
            }

            {loading === false && filterVisible == false &&
                <Animated.View
                    entering={FadeIn.delay(0).duration(500)}
                    exiting={FadeOut.delay(0).duration(500)}
                    style={styles.filterBtnHolder} >

                    <TouchableOpacity onPress={showFilters}>
                        <View style={styles.filterContainer} >
                            <MaterialCommunityIcons style={styles.filterBtnIcon} name="filter" size={28} color="#FFF" />
                            <Subheading style={styles.filterBtnText}>{t('STR_LABEL_FILTER')}</Subheading>
                        </View>
                    </TouchableOpacity>

                </Animated.View>
            }
            <Dialog visible={filterVisible} onDismiss={hideFilters} >
                <Dialog.ScrollArea>
                    <ScrollView contentContainerStyle={{ paddingHorizontal: 5, paddingVertical: 10 }}>
                        {filterVisible &&
                            <View>
                                <Searchbar
                                    placeholder={t('STR_LABEL_SEARCH')}
                                    onChangeText={onChangeSearch}
                                    value={searchQuery}
                                    style={styles.searchbar}
                                />
                            </View>
                        }
                        <View style={styles.dialogChipContainer}>
                            <View style={styles.dialogChipHolder}>
                                {avaliableStates.length > 0 &&
                                    avaliableStates.map((item, index) => {
                                        return (
                                            <Chip
                                                icon={(selectedStates.includes(item.code) ? '' : 'checkbox-blank-outline')}
                                                selected={(selectedStates.includes(item.code) ? true : false)}
                                                key={index}
                                                style={[styles.dialogChipStyle, (selectedStates.includes(item.code) ? styles.dialogChipStyleSelected : '')]}
                                                onPress={() => toogleChipFilter(item.code)}>
                                                {t(item.label)}
                                            </Chip>
                                        );
                                    })
                                }
                            </View>

                        </View>
                        <View style={styles.dialogBtnsHolder}>
                            <Button color='#000' onPress={() => clearFilters()}>{t('STR_RESET')}</Button>
                            <Button color='#05AC72' onPress={() => SearchWithFilters()} >{t('STR_APPLY')}</Button>
                        </View>
                    </ScrollView>
                </Dialog.ScrollArea>
            </Dialog>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',        
        paddingBottom: 15,
    },
    bottomContainer: {
        width: '100%',
        backgroundColor: '#05AC72',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        zIndex: 2
    },
    imageStyle: {
        width: 150,
        height: 100,
        marginTop: 20,
        marginBottom: 50,
        marginHorizontal: 8,
        alignSelf: 'center'

    },
    filterBtnHolder: {
        // backgroundColor: '#969696',
        // borderColor: "#7A7A7A",
        // borderWidth: 2,

        backgroundColor: 'rgba(5,172,114, 0.6)',

        position: 'absolute',
        bottom: 10,
        paddingHorizontal: 20,
        height: 40,
        borderRadius: 30,
        justifyContent: 'center',
        alignItems: 'center'
    },
    filterBtnIcon: {
        // alignSelf: 'center'
    },
    filterBtnText: {
        color: '#FFF'
    },
    filterContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: "center",
    },
    dialogBtnsHolder: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    searchbar: {
        marginTop: 10,
        width: '100%'
    },
    dialogChipContainer: {
        flex: 1,
        marginTop: 10,
        marginBottom: 10,
        alignItems: "center"
    },
    dialogChipHolder: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingHorizontal: 12,
    },
    dialogChipStyle: {
        margin: 4,
    },
    dialogChipStyleSelected: {
        backgroundColor: '#05AC72',
    },
    cardHolder: {
        zIndex: 2,
        marginHorizontal: 10,
        ...Platform.select({
            ios: {
            },
            android: {
            },
            default: {
                // width: 600                    
                
            }
        })
    },
});

const mapStateToProps = (
    state: {
    }
) => {
    return {
    }
}
export default connect(mapStateToProps, {})(PrintJobsHistory);