
const svg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="84.27px" height="84.27px" viewBox="0 0 84.27 84.27" enable-background="new 0 0 84.27 84.27" xml:space="preserve">
<polygon fill-rule="evenodd" clip-rule="evenodd" points="42.135,12.582 50.603,15.404 59.07,18.227 64.304,25.615 69.536,33.002 
	69.536,42.135 69.536,51.268 64.304,58.655 59.07,66.044 50.603,68.865 42.135,71.688 33.667,68.865 25.2,66.044 19.966,58.655 
	14.733,51.268 14.733,42.135 14.733,33.002 19.966,25.615 25.2,18.227 33.667,15.404 "/>
</svg>`
export { svg };



