import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet, View, ScrollView, RefreshControl } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import { getPrinters } from '../../Rest'
import { connect } from 'react-redux';
import { store, setPrinterSelectedId, setPrintersList } from '../../../utils/Reducer';
import Card from '../../components/PrinterCard';
import Animated, { BounceInDown, BounceOutDown } from 'react-native-reanimated';
import printerDB from '../../../helpers/db/models/printer'

import Loader from '../../components/Loader';
import { deviceIsOnline, sleep } from '../../../helpers/utils';

function PrinterListScreen(props) {

    const { printersList } = props
    const [loading, setLoading] = useState(false);
    const [localPrintList, setLocalPrintList] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const isFocused = useIsFocused();

    const loadPrinters = async () => {

        const isDeviceOnline = await deviceIsOnline();
        store.dispatch(setPrintersList(undefined));

        if (Platform.OS !== 'web') {

            // Load local printers
            let localPrinters = await printerDB.query({
                where: {
                    localPrinter_eq: true
                }
            });

            if (!isDeviceOnline) {                
                let localCloudPrinters = await printerDB.query({
                    where: {
                        localPrinter_eq: false
                    }
                });
                let allLocalPrinters = [...localPrinters, ...localCloudPrinters];                
                setLocalPrintList(allLocalPrinters); 
            }else{ 
                setLocalPrintList(localPrinters)
            }
        }

        if (isDeviceOnline) {
            await getPrinters();
        }
        return;
    };

    useEffect(
        () => {
            (async () => {
                // await printerDB.destroyAll();
                if (isFocused) {
                    await loadPrinters();
                    setIsOpen(true);
                }
                else {
                    setIsOpen(false);
                    setLoading(false);
                }
            })();
        },
        [isFocused]
    )

    const onRefresh = async () => {
        setLoading(true);
        await loadPrinters();
        setLoading(false);
        setRefreshing(false)
    };


    const handlePrinterSelected = (printer) => {
        // store.dispatch(setPrinterSelectedId(printer.serial_number))        
        if (printer.localPrinter === undefined || printer.localPrinter === true){
            props.navigation.navigate('PrinterDetail', {
                printerSN: printer?.ipAddress === undefined ? printer.serial_number : printer.printerSn,
                localPrinter: printer?.ipAddress === undefined ? false : true,
            });
        }
    }

    return (
        <ScrollView
            // contentContainerStyle={{ flexGrow: 1 }}
            style={{ backgroundColor: '#FFF' }}
            refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
        >

            <View style={styles.container}>

                {(localPrintList !== undefined || printersList !== undefined) && isOpen === true &&
                    <View style={{ marginTop: 20 }}></View>
                }

                {localPrintList !== undefined && isOpen === true &&

                    <View style={styles.CardContainer}>
                        {localPrintList.map((printer, index) => (
                            <Animated.View style={styles.CardHolder} key={index} entering={BounceInDown.duration(((Number(index) * 250) + 1000))}>
                                <Card onPress={() => handlePrinterSelected(printer)}
                                    key={index}
                                    printer={printer}
                                />
                            </Animated.View>
                        ))}
                    </View>
                }

                {printersList !== undefined && isOpen === true &&

                    <View style={styles.CardContainer}>
                        {printersList.map((printer, index) => (
                            <Animated.View style={styles.CardHolder} key={index} entering={BounceInDown.duration(((Number(index) * 250) + 1000))}>
                                <Card onPress={() => handlePrinterSelected(printer)}
                                    key={index}
                                    printer={printer}
                                />
                            </Animated.View>
                        ))}
                    </View>
                }

                {printersList === undefined && isFocused &&
                    <Loader loading={loading} />
                }
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',

        ...Platform.select({
            ios: {
                alignItems: 'center',
            },
            android: {
                alignItems: 'center',
            },
            default: {
                alignItems: 'flex-start'
            }
        })
    },
    CardContainer: {
        marginTop: 0,
        ...Platform.select({
            ios: {
                // width: '100%',
                alignItems: 'center',
                justifyContent: "center",
            },
            android: {
                // width: '100%',
                // alignItems: 'flex-start',
                // justifyContent: "center",
                alignItems: 'center',
                justifyContent: "center",
            },
            default: {
                flex: 1,
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: 'wrap',
                maxWidth: '100%'
            }
        })
    },
    CardHolder: {
        marginHorizontal: 20,
        ...Platform.select({
            ios: {
                // width: '100%',
                // alignItems: "center",
                // justifyContent: "center",
            },
            android: {
                // width: '80%',
                // alignItems: "center",
                // justifyContent: "center",                
            },
            default: {
                // width: 250,
                // marginHorizontal: 10,
                maxWidth: '100%'
            }
        })
    },
});

const mapStateToProps = state => {
    return {
        printersList: state.printersList
    }
}

export default connect(mapStateToProps, {})(PrinterListScreen);