import React from "react";
import { connect } from 'react-redux';
import { View, StyleSheet, Dimensions, Alert } from "react-native";
import { Button, useTheme } from "react-native-paper";
import { deviceIsOnline } from '../../helpers/utils';
import recordDB from '../../helpers/db/models/record'

const Bottom = (props) => {
    const theme = useTheme();
    const { recordSelectedId } = props

    const editRecord = async () => {

        const deviceIsOnlineResult = await deviceIsOnline();
        if (deviceIsOnlineResult == true) {
            props.navigation.navigate('EditRecord')
        } else {

            // Check if record exist on local database
            let localRecordData = await recordDB.query({
                where: {
                    recordId_eq: recordSelectedId
                }
            });

            if (localRecordData[0] === undefined) {
                Alert.alert(
                    "Error",
                    'Can\'t edit cloud records without active internet connection.'
                );
            } else {
                props.navigation.navigate('EditRecord')
            }
        }

    }

    return (
        <View style={[styles.container, { backgroundColor: theme.colors.primary }]} >
            <Button textColor={theme.colors.white} onPress={() => props.navigation.navigate('NewRecord')} disabled={props.selected !== null ? true : false}>{props.options1} </Button>
            <Button textColor={theme.colors.white} onPress={() => { editRecord(); }} disabled={props.selected !== null ? false : true} >{props.options2}</Button>
        </View>
    );
};

const deviceWidth = Math.round(Dimensions.get('window').width);

const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: 60
    }
});

// export default Bottom;

const mapStateToProps = state => {
    return {
        recordSelectedId: state.recordSelectedId
    }
}
export default connect(mapStateToProps, {})(Bottom);
